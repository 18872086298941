import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { User } from 'src/app/models/user';
import { NgForm } from '@angular/forms';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { RoleService } from 'src/app/services/role.service';
import { ListArray } from 'src/app/models/listArray';
@Component({
  selector: 'app-user-maintenance-fields',
  templateUrl: './user-maintenance-fields.component.html',
  styleUrls: ['./user-maintenance-fields.component.scss']
})
export class UserMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() userViewMode: boolean = false;
  @Input() userUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction2 = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() user: User = {} as User;

  attributes: Attribute[] = new Array<Attribute>();

  dropdownSettings = {};

  list = {};
  selected = {};
  alert = false;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private roleService: RoleService,
    private notificationService: NotificationService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(user.userRole, user.userCode, 'userMaintenance');
    const statusList = await this.sharedUtils.getStatusSelect();
    const roleList = await this.getRole(user.userRole, user.userCompany, '1');
    await this.addSelectList(roleList, 'roleCode', 'roleName', 'userRole');
    await this.addSelectList(statusList, 'id', 'itemName', 'userStatus');
    if (this.userViewMode) {
      this.fntDropdownSettings(true);
    } else {
      this.fntDropdownSettings(false);
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(list, id, name);
    if (this.user[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] = await this.sharedUtils.selectFromList(this.list[attributeBusinessCode], this.user[attributeBusinessCode]);
    }
  }

  async getRole(role, company, status) {
    this.loading = true;
    return this.roleService.roleGetAll(role, company, status).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService.attributeGetRoleUserForm(role, user, form).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        user
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    this.user[attributeBusinessCode] = item.id;
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    if (this.form.valid || this.userUpdateMode) {
      const validatedUser = this.user;
      if(this.userUpdateMode){
        this.saveAction.emit(validatedUser);
      }else{
        this.saveAction2.emit(validatedUser);
      }  
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');
      // const validatedUser = false;
      // this.saveAction.emit(validatedUser);
    }
  }

  // Función para manipular el dropdown
  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
        disabled: true
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

}
