<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row"
    *ngIf="!(clientCreateMode || clientUpdateMode || clientViewMode || saleMode || clientHistoryMode || quotationMode)">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Listado de clientes
          </h4>
          <div class="toolbar">
            <app-client-maintenance-toolbox [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"></app-client-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-client-maintenance-table [clientList]="clientList" (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
            (saleAction)="saleAction($event)" (historyAction)="historyAction($event)"
            (quotationAction)="quotationAction($event)" [readPermission]="readPermission"
            [updatePermission]="updatePermission" [deletePermission]="deletePermission"
            [viewPermission]="viewPermission" [xlsxExportDate]="xlsxExportDate" [salePermission]="salePermission"
            [historyPermission]="historyPermission" [quotationPermission]="quotationPermission">
          </app-client-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-client-maintenance-fields *ngIf="clientCreateMode || clientUpdateMode || clientViewMode"
    [headerActionType]="headerActionType" [clientViewMode]="clientViewMode" [clientUpdateMode]="clientUpdateMode"
    [client]="client" (cancelAction)="cancelAction()" (saveAction)="saveAction($event)">
  </app-client-maintenance-fields>

  <app-sale-manager 
    *ngIf="saleMode" 
    [clientSaleOption]="clientSaleOption"
    [clientSaleList]="clientSaleList" 
    [cancelSalePermission]="cancelSalePermission"
    (cancelClientSale)="cancelAction()">
  </app-sale-manager>

  <app-quotation-manager 
    *ngIf="quotationMode" 
    [clientQuotationList]="clientQuotationList"
    [cancelQuotationPermission]="cancelQuotationPermission"
    (cancelClientQuotation)="cancelAction()">
  </app-quotation-manager>

  <app-client-history *ngIf="clientHistoryMode" [headerActionType]="headerActionType" [client]="client"
    (cancelAction)="cancelAction()">
  </app-client-history>

</div>