<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
    <div class="row" *ngIf="!(phraseCreateMode || phraseUpdateMode || phraseViewMode )">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        Listado de frases
                    </h4>
                    <div class="toolbar">
                        <app-phrase-maintenance-toolbox [createPermission]="createPermission"
                            [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
                            (refreshAction)="refreshAction()" (xlsxExportAction)="xlsxExportAction()">

                        </app-phrase-maintenance-toolbox>
                    </div>
                </div>
                <div class="card-body">
                    <app-phrase-maintenance-table [phraseList]="phraseList" (viewAction)="viewAction($event)"
                        (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
                        [readPermission]="readPermission" [updatePermission]="updatePermission"
                        [deletePermission]="deletePermission" [viewPermission]="viewPermission"
                        [xlsxExportDate]="xlsxExportDate">

                    </app-phrase-maintenance-table>
                </div>
            </div>
        </div>
    </div>
    <app-phrase-maintenance-fields *ngIf="phraseCreateMode || phraseUpdateMode || phraseViewMode"
        [headerActionType]="headerActionType" [phraseViewMode]="phraseViewMode" [phraseUpdateMode]="phraseUpdateMode"
        [phrase]="phrase" (cancelAction)="cancelAction()" (saveAction)="saveAction($event)">
    </app-phrase-maintenance-fields>

</div>