import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { InvoiceRecurring } from 'src/app/models/invoiceRecurring';
import { InvoiceRecurringDetail } from 'src/app/models/invoiceRecurringDetail';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { InvoiceRecurringService } from 'src/app/services/invoice-recurring.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from 'src/app/services/inventory.service';
import { Store } from 'src/app/models/store';
const uuid = require('uuid');
import swal from 'sweetalert2';
import { PackageService } from 'src/app/services/package.service';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { ListArray } from 'src/app/models/listArray';
import { SellerService } from 'src/app/services/seller.service';

@Component({
  selector: 'app-invoice-recurring-generate',
  templateUrl: './invoice-recurring-generate.component.html',
  styleUrls: ['./invoice-recurring-generate.component.scss'],
})
export class InvoiceRecurringGenerateComponent implements OnInit {
  loading = false;

  @Input() clientSaleList;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() cancelActionList = new EventEmitter<any>();

  @Input() headerActionType: string;
  @Input() invoiceRecurringViewMode: boolean = false;
  @Input() invoiceRecurringUpdateMode: boolean = false;
  @Input() _invoiceRecurring;

  invoiceRecurring: InvoiceRecurring = {
    invoiceRecurringClient: 0,
    invoiceRecurringDetail: new Array<InvoiceRecurringDetail>(),
    invoiceRecurringPayment: {
      invoiceRecurringPaymentMethod: 1,
    },
  } as InvoiceRecurring;

  company = {} as Company;
  store = {} as Store;
  invoiceRecurringDetail = {
    invoiceRecurringDetailQuantity: 0,
    invoiceRecurringDetailSubtotal: 0,
    invoiceRecurringDetailTotal: 0,
    invoiceRecurringDetailUnitPrice: 0,
    invoiceRecurringDetailDiscount: 0,
    invoiceRecurringDetailCost: 0,
    invoiceRecurringDetailMeasure: 'UNI',
    invoiceRecurringDetailInventory: 0,
  } as InvoiceRecurringDetail;
  invoiceRecurringClient = {
    clientTaxNumber: ""
  } as Client;
  client = {} as Client;

  invoiceRecurringSaleService = false;
  invoiceRecurringSaleBenefit = false;
  invoiceRecurringSaleInventory = false;
  invoiceRecurringInventory = false;

  invoiceRecurringAddItemTypeMode = 'N';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  public nowDay: string;
  public now: string;
  public date: string;

  dropdownSettings = {};
  dropdownSettingsProduct = {};

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;

  alert = false;
  alertHeader = false;
  minDateStart: string;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private invoiceRecurringService: InvoiceRecurringService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private sellerService: SellerService
  ) { }

  async ngOnInit() {
    await this.setDefault();

    //OBTENER FECHA
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.now = yyyy + '-' + mm + '-' + dd;
    this.invoiceRecurring.invoiceRecurringDate = yyyy + '-' + mm + '-' + dd;
    this.nowDay = this.invoiceRecurring.invoiceRecurringDate;
    this.date = this.now;
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    this.invoiceRecurring.invoiceRecurringDate = await this.sharedUtils.formatDate();
    this.invoiceRecurring.invoiceRecurringDateStart = await this.sharedUtils.formatDate();
    this.minDateStart = await this.sharedUtils.formatDate();
    this.invoiceRecurring.invoiceRecurringUser = user.userCode;
    this.invoiceRecurring.invoiceRecurringCompany = user.userCompany;
    this.invoiceRecurring.invoiceRecurringStore = user.userStore;

    this.invoiceRecurringClient.clientCode = undefined;
    this.invoiceRecurringClient.clientAddress = environment.clientDefaultAddress;

    this.invoiceRecurringClient.clientPhoneOne = null;
    this.invoiceRecurringClient.clientPhoneTwo = null;
    this.invoiceRecurringClient.clientPhoneThree = null;
    this.invoiceRecurringClient.clientAddressZone = '0';
    this.invoiceRecurringClient.clientDepartment = null;
    this.invoiceRecurringClient.clientMunicipality = null;
    this.invoiceRecurringClient.clientCredit = null;
    this.invoiceRecurringClient.clientCreditTime = null;
    this.invoiceRecurringClient.clientCreditAmount = null;
    this.invoiceRecurring.invoiceRecurringCurrency = 'GTQ';
    this.invoiceRecurring.invoiceRecurringChangeRate = 1;
    this.invoiceRecurring.invoiceRecurringTotal = 0;
    this.invoiceRecurring.invoiceRecurringFrequency = 0;
    this.invoiceRecurring.invoiceRecurringDuration = undefined;
    this.invoiceRecurring.invoiceRecurringDurationType = 0;
    this.invoiceRecurring.invoiceRecurringFrequencyType = 0;
    this.invoiceRecurring.invoiceRecurringAddressSelected = 0;


    this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentMethod = 1;
    this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentQuantity = 1;
    this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentTime = 1;
    this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentTimeMethod = 1;
    this.invoiceRecurring.invoiceRecurringDocumentSearch = 1;
    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
      // this.getSeller(user.userCompany),
    ]).then(async (response) => {
      // const sellerList = response[2][0].sellerName;
      // await this.addSelectList(
      //   response[2],
      //   'sellerCode',
      //   'sellerName',
      //   'sellerShow'
      // );

      this.invoiceRecurringClient.clientDepartment = response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.invoiceRecurringClient.clientDepartment
      );
      this.invoiceRecurringClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];
      this.company = companys[0];
      this.invoiceRecurringClient.clientEmail = this.company.companyEmail;
      this.invoiceRecurringClient.clientCompany = this.company.companyCode;
      this.invoiceRecurringSaleInventory = Boolean(this.company.companySaleInventory);
      this.invoiceRecurringSaleBenefit = Boolean(this.company.companySaleBenefit);
      this.invoiceRecurringSaleService = Boolean(this.company.companySaleService);
      this.invoiceRecurringInventory = Boolean(this.company.companyInventory);

    });

    if (this._invoiceRecurring != undefined) {
      const clientInf = (await this.getClient(this._invoiceRecurring.invoiceRecurringClient || '')) as Client;

      for (const items of this._invoiceRecurring.invoiceRecurringDetail) {

        this.invoiceRecurringDetail.invoiceRecurringDetailCode = uuid.v4();
        this.invoiceRecurringDetail.invoiceRecurringDetailDescription = items.invoiceRecurringDetailDescription;
        this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice = parseFloat(items.invoiceRecurringDetailUnitPrice);
        this.invoiceRecurringDetail.invoiceRecurringDetailQuantity = parseFloat(items.invoiceRecurringDetailQuantity);
        this.invoiceRecurringDetail.invoiceRecurringDetailDiscount = parseFloat(items.invoiceRecurringDetailDiscount);
        this.invoiceRecurringDetail.invoiceRecurringDetailSubtotal = parseFloat(items.invoiceRecurringDetailSubtotal);
        this.invoiceRecurringDetail.invoiceRecurringDetailTotal = parseFloat(items.invoiceRecurringDetailTotal);
        this.invoiceRecurringDetail.invoiceRecurringDetailInventory = parseFloat(items.invoiceRecurringDetailInventory);
        this.invoiceRecurringDetail.invoiceRecurringDetailIsService = items.invoiceRecurringDetailIsService;
        this.invoiceRecurring.invoiceRecurringDetail.push(this.invoiceRecurringDetail);
        this.invoiceRecurring.invoiceRecurringDetail = [...this.invoiceRecurring.invoiceRecurringDetail];
        this.invoiceRecurringDetail = {
          invoiceRecurringDetailDescription: undefined,
          invoiceRecurringDetailQuantity: 0,
          invoiceRecurringDetailSubtotal: 0,
          invoiceRecurringDetailTotal: 0,
          invoiceRecurringDetailUnitPrice: 0,
          invoiceRecurringDetailDiscount: 0,
          invoiceRecurringDetailCost: 0,
          invoiceRecurringDetailMeasure: 'UNI',
          invoiceRecurringDetailInventory: 0,
        } as InvoiceRecurringDetail;
        await this.calculateTotals();
      }

      this.invoiceRecurring.invoiceRecurringCode = this._invoiceRecurring.invoiceRecurringCode;
      this.invoiceRecurring.invoiceRecurringDeleted = this._invoiceRecurring.invoiceRecurringDeleted;
      this.invoiceRecurring.invoiceRecurringFrequency = this._invoiceRecurring.invoiceRecurringFrequency;
      this.invoiceRecurring.invoiceRecurringFrequencyType = this._invoiceRecurring.invoiceRecurringFrequencyType;
      this.invoiceRecurring.invoiceRecurringDuration = this._invoiceRecurring.invoiceRecurringDuration;
      this.invoiceRecurring.invoiceRecurringDurationType = this._invoiceRecurring.invoiceRecurringDurationType;
      this.invoiceRecurring.invoiceRecurringDateStart = this._invoiceRecurring.invoiceRecurringDateStart;

      this.invoiceRecurringClient.clientCode = clientInf.clientCode;
      this.invoiceRecurringClient.clientName = clientInf.clientName;
      this.invoiceRecurringClient.clientTaxNumber = clientInf.clientTaxNumber;
      this.invoiceRecurringClient.clientIdentificationDocument = clientInf.clientIdentificationDocument;
      this.invoiceRecurringClient.clientPhoneOne = clientInf.clientPhoneOne + ' ' + clientInf.clientPhoneTwo + ' ' + clientInf.clientPhoneThree;
      this.invoiceRecurringClient.clientEmail = clientInf.clientEmail || this.invoiceRecurringClient.clientEmail;
      this.invoiceRecurringClient.clientPhone = clientInf.clientPhoneOne;

      this.addressSelected = this._invoiceRecurring.invoiceRecurringAddressSelected;

      if (this._invoiceRecurring.invoiceRecurringAddressSelected == 0) {
        this.invoiceRecurringClient.clientDepartment = '1';
        this.invoiceRecurringClient.clientMunicipality = '1';
        this.invoiceRecurringClient.clientAddress = 'Ciudad';
        this.invoiceRecurringClient.clientAddressZone = '0';
      } else {
        this.invoiceRecurringClient.clientAddress = clientInf.clientAddress || this.invoiceRecurringClient.clientAddress;
        this.invoiceRecurringClient.clientAddressZone = clientInf.clientAddressZone || this.invoiceRecurringClient.clientAddressZone;

        if (clientInf['clientDepartment'] != undefined) {
          this.invoiceRecurringClient.clientDepartment = clientInf.clientDepartment;

          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.invoiceRecurringClient['clientDepartment'] || null
          );
          this.invoiceRecurringClient.clientMunicipality = clientInf.clientMunicipality || null;
          const municipalityList = await this.getMunicipality(
            clientInf.clientDepartment
          );
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }
      }
    }

    if (this.invoiceRecurringViewMode) {
      this.fntDropdownSettings(true);
      this.fntDropdownSettings(true);
    } else if (this.invoiceRecurringUpdateMode) {
      this.fntDropdownSettings(true);
      this.fntDropdownSettingsProduct(false);
    } else {
      this.fntDropdownSettings(false);
      this.fntDropdownSettingsProduct(false);
    }
  }

  async cancel() {
    if (this._invoiceRecurring != undefined) {
      this.cancelActionList.emit();
    } else {
      await this.setDefault();
      this.cancelAction.emit();
    }
  }

  async searchClient() {
    if (this.clientSaleList == undefined) {
      if (this.invoiceRecurring.invoiceRecurringDocumentSearch == 1
        && (this.invoiceRecurringClient.clientTaxNumber == '' || this.invoiceRecurringClient.clientTaxNumber == undefined)) {
        this.notificationService.showError("Debes ingresar un NIT.");
      } else if (this.invoiceRecurring.invoiceRecurringDocumentSearch ==
        0 && (this.invoiceRecurringClient.clientIdentificationDocument == '' || this.invoiceRecurringClient.clientIdentificationDocument == undefined)) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      } else {

        if (this.invoiceRecurring.invoiceRecurringDocumentSearch == 1) {
          this.client = (await this.getClientGetTaxNumber(
            this.invoiceRecurringClient?.clientTaxNumber || 'CF'
          )) as Client;
        } else {
          this.client = (await this.getClientGetIdentificationDocument(
            this.invoiceRecurringClient?.clientIdentificationDocument || ''
          )) as Client;
        }
        this.invoiceRecurringClient.clientCode = this.client.clientCode;
        this.invoiceRecurringClient.clientName = this.client.clientName;
        this.invoiceRecurringClient.clientIdentificationDocument =
          this.client.clientIdentificationDocument;
        this.invoiceRecurringClient.clientEmail =
          this.client.clientEmail || this.invoiceRecurringClient.clientEmail;
        this.invoiceRecurringClient.clientDepartment = '1';
        this.invoiceRecurringClient.clientMunicipality = '1';
        this.invoiceRecurringClient.clientAddress = 'Ciudad';
        this.invoiceRecurringClient.clientAddressZone = '0';

      }
    }
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async validateDate($event) {
    $event.preventDefault();
    this.invoiceRecurring.invoiceRecurringDate = this.invoiceRecurring.invoiceRecurringDateStart;
    this.invoiceRecurring.invoiceRecurringDateNext = this.invoiceRecurring.invoiceRecurringDateStart;
  }

  async changeCurrency(event) {
    this.invoiceRecurring.invoiceRecurringChangeRate =
      event == 'GTQ' ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.invoiceRecurring.invoiceRecurringDocumentSearch = event;
  }

  async changeFrequencyType(event) {
    this.invoiceRecurring.invoiceRecurringFrequencyType = event;
  }

  async changeDurationType(event) {
    this.invoiceRecurring.invoiceRecurringDurationType = event;
    if (event == 0) {
      this.invoiceRecurring.invoiceRecurringDuration = undefined;
    } else {
      this.invoiceRecurring.invoiceRecurringDuration = 0;
    }
  }

  async changeAddress(event) {

    this.invoiceRecurring.invoiceRecurringAddressSelected = event;

    if (event == 0) {
      this.invoiceRecurringClient.clientDepartment = '1';
      this.invoiceRecurringClient.clientMunicipality = '1';
      this.invoiceRecurringClient.clientAddress = 'Ciudad';
      this.invoiceRecurringClient.clientAddressZone = '0';
    } else {
      this.invoiceRecurringClient.clientAddress = this.client.clientAddress || this.invoiceRecurringClient.clientAddress;;
      this.invoiceRecurringClient.clientAddressZone = this.client.clientAddressZone || this.invoiceRecurringClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.invoiceRecurringClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceRecurringClient['clientDepartment']
        );
        this.invoiceRecurringClient.clientMunicipality = this.client.clientMunicipality;
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }
    }
  }

  async changePaymentMethod(event) {
    this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentMethod = event;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceRecurringAddItemTypeMode = this.invoiceRecurringDetail.invoiceRecurringDetailIsService;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      );
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.invoiceRecurringDetail.invoiceRecurringDetailDescription = inventoryItem.inventoryName;
      this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice = inventoryItem.inventoryPrice;
      this.invoiceRecurringDetail.invoiceRecurringDetailInventory = inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.invoiceRecurringDetail.invoiceRecurringDetailDescription = packageItem.packageName;
        this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice = packageItem.packagePrice;
        this.invoiceRecurringDetail.invoiceRecurringDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.invoiceRecurringClient.clientDepartment = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.invoiceRecurringClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      case 'clientMunicipality':
        this.invoiceRecurringClient.clientMunicipality = item.id;
        break;
      case 'sellerShow':
        this.invoiceRecurring.invoiceRecurringSeller = item.id;
        break;
      default:
        this.invoiceRecurringClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.invoiceRecurringClient[attributeBusinessCode] == undefined ||
        this.invoiceRecurringClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.invoiceRecurringClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.invoiceRecurringDetail.invoiceRecurringDetailSubtotal =
      this.invoiceRecurringDetail.invoiceRecurringDetailQuantity *
      this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice;
    this.invoiceRecurringDetail.invoiceRecurringDetailTotal =
      this.invoiceRecurringDetail.invoiceRecurringDetailQuantity *
      this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice -
      this.invoiceRecurringDetail.invoiceRecurringDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceRecurringDetail = {
      invoiceRecurringDetailQuantity: 0,
      invoiceRecurringDetailSubtotal: 0,
      invoiceRecurringDetailTotal: 0,
      invoiceRecurringDetailUnitPrice: 0,
      invoiceRecurringDetailDiscount: 0,
      invoiceRecurringDetailCost: 0,
      invoiceRecurringDetailMeasure: 'UNI',
      invoiceRecurringDetailInventory: 0,
      invoiceRecurringDetailIsService: this.invoiceRecurringDetail.invoiceRecurringDetailIsService
    } as InvoiceRecurringDetail;
    this.invoiceRecurringDetail.invoiceRecurringDetailQuantity = 0;
    this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice = 0;
    if (this.invoiceRecurringDetail.invoiceRecurringDetailIsService == undefined) {
      this.invoiceRecurringDetail.invoiceRecurringDetailIsService = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.invoiceRecurringDetail.invoiceRecurringDetailIsService == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.invoiceRecurringDetail.invoiceRecurringDetailIsService = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;

      if (this.invoiceRecurringDetail.invoiceRecurringDetailIsService == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //   let packageList = await this.getPackage(user.userStore);
        //   let packageListInsert = new Array();
        //   for (const _package of packageList) {
        //     let insertPackage = true;
        //     for (const packageDetail of _package.packageDetail) {
        //       if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //         insertPackage = false;
        //       }
        //     }
        //     if (insertPackage) {
        //       packageListInsert.push(_package);
        //       inventoryList.push({
        //         inventoryCode: 'PACKAGE-' + _package.packageCode,
        //         inventoryName: _package.packageName,
        //         inventoryNameShow: _package.packageName,
        //       });
        //     }
        //   }
        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }
    }
    this.invoiceRecurringAddItemTypeMode = this.invoiceRecurringDetail.invoiceRecurringDetailIsService;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.invoiceRecurringDetail.invoiceRecurringDetailDescription == undefined ||
      this.invoiceRecurringDetail.invoiceRecurringDetailUnitPrice == 0 ||
      this.invoiceRecurringDetail.invoiceRecurringDetailQuantity == 0 ||
      this.invoiceRecurringDetail.invoiceRecurringDetailSubtotal == 0 ||
      this.invoiceRecurringDetail.invoiceRecurringDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.invoiceRecurringDetail.invoiceRecurringDetailCode = uuid.v4();
      this.invoiceRecurring.invoiceRecurringDetail.push(this.invoiceRecurringDetail);
      this.invoiceRecurring.invoiceRecurringDetail = [...this.invoiceRecurring.invoiceRecurringDetail];
      this.invoiceRecurringDetail = {
        invoiceRecurringDetailQuantity: 0,
        invoiceRecurringDetailSubtotal: 0,
        invoiceRecurringDetailTotal: 0,
        invoiceRecurringDetailUnitPrice: 0,
        invoiceRecurringDetailIsService: this.invoiceRecurringDetail.invoiceRecurringDetailIsService
      } as InvoiceRecurringDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.invoiceRecurring.invoiceRecurringDetail.find(
      (x) => x.invoiceRecurringDetailCode == this.activeRow.invoiceRecurringDetailCode
    );
    const indexDetail = this.invoiceRecurring.invoiceRecurringDetail.indexOf(findDetail);
    this.invoiceRecurring.invoiceRecurringDetail.splice(indexDetail, 1);
    this.invoiceRecurring.invoiceRecurringDetail = [...this.invoiceRecurring.invoiceRecurringDetail];
    await this.calculateTotals();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.invoiceRecurring.invoiceRecurringSubtotal = 0;
    this.invoiceRecurring.invoiceRecurringTotal = 0;
    for (const iterator of this.invoiceRecurring.invoiceRecurringDetail) {
      this.invoiceRecurring.invoiceRecurringSubtotal += iterator.invoiceRecurringDetailSubtotal;
      this.invoiceRecurring.invoiceRecurringTotal += iterator.invoiceRecurringDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.invoiceRecurringClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.invoiceRecurringClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoiceRecurring.invoiceRecurringClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {
      this.invoiceRecurringClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.invoiceRecurringClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdateSale(this.invoiceRecurringClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoiceRecurring.invoiceRecurringClient = this.invoiceRecurringClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  async save() {
    if (this.invoiceRecurringUpdateMode) {
      await this.updateAction();
    } else {
      this.alertHeader = true;
      this.invoiceRecurringClient.clientTaxNumber = this.invoiceRecurringClient.clientTaxNumber.toUpperCase();
      await this.calculateTotals();
      if (this.invoiceRecurring.invoiceRecurringDocumentSearch == 1) {
        if (this.invoiceRecurringClient.clientTaxNumber == undefined || this.invoiceRecurringClient.clientTaxNumber == '') {
          this.invoiceRecurringClient.clientTaxNumber = 'CF';
          this.invoiceRecurringClient.clientName = 'Consumidor final';
        }
      }
  
      if (
        this.invoiceRecurring.invoiceRecurringDocumentSearch == 0 &&
        (
          this.invoiceRecurringClient.clientIdentificationDocument == '' ||
          this.invoiceRecurringClient.clientIdentificationDocument == undefined
        )
      ) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      } else if (this.invoiceRecurring.invoiceRecurringDetail.length == 0) {
        this.notificationService.showError(
          'Debes agregar detalle de venta.'
        );
      } else if (
        this.invoiceRecurring.invoiceRecurringTotal > 2500 &&
        (
          this.invoiceRecurringClient.clientTaxNumber == "CF" || this.invoiceRecurringClient.clientTaxNumber == "C/F" ||
          this.invoiceRecurringClient.clientTaxNumber == "cf" || this.invoiceRecurringClient.clientTaxNumber == "c/f"
        ) &&
        (
          this.invoiceRecurringClient.clientIdentificationDocument == '' || this.invoiceRecurringClient.clientIdentificationDocument == undefined
        )
      ) {
        this.notificationService.showError('Para emitir facturas con montos mayores a Q2,500.00 debes ingresar un NIT o DPI.');
      } else {
  
        if (
          this.store.storeOnline == 0 &&
          this.invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentMethod == 1
        ) {
          swal
            .fire({
              title:
                'Total Q' +
                this.invoiceRecurring.invoiceRecurringTotal +
                '<br/>Cantidad de efectivo:',
              // text: "¿Desea eliminar cuenta contable?",
              icon: 'question',
              html:
                '<div class="form-group">' +
                '<input id="input-field" type="text" class="form-control" />' +
                '</div>',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
              },
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cancelar',
              buttonsStyling: false,
            })
            .then((result) => {
              if (result.value) {
                const efectivo = (
                  document.getElementById('input-field') as HTMLInputElement
                ).value;
                swal.fire({
                  title: 'Cambio',
                  text:
                    'su cambio es ' +
                    (parseFloat(efectivo) -
                      parseFloat(this.invoiceRecurring.invoiceRecurringTotal)) +
                    '.',
                  icon: 'info',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                });
                this.saveAction();
              }
            });
        } else {
          this.saveAction();
        }
      }
    }    
  }

  async saveAction() {
    this.loading = true;
    if (await this.validateClient()) {
      let response;
      response = await this.invoiceRecurringService.invoiceRecurringInsert(this.invoiceRecurring);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        if (response.result == true) {
          if (response.receiptPdfUrl != null) {
            this.notificationService.showSuccess(response.message);
            await window.open(response.receiptPdfUrl, 'popup', 'width=600,height=600');
            await window.open(environment.infilePdfUrl + response.invoiceAuthorization, 'popup', 'width=600,height=600');
          } else {
            this.notificationService.showSuccess("Factura recurrente creada con éxito.");
          }       
          this.cancel();
        } else {
          this.notificationService.showError(response.message);
          // for (const err of response.errorList) {
          //   console.error(err.mensaje_error);
          // }
        }
      }
    }
    this.loading = false;
  }

  async updateAction() {
    this.loading = true;
    let response;

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    this.invoiceRecurring['invoiceRecurringModifyDate'] = new Date();
    this.invoiceRecurring['invoiceRecurringModifyUser'] = user.userCode;

    response = await this.invoiceRecurringService.invoiceRecurringUpdate(this.invoiceRecurring);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService
      .selleryGetCompanyEnable(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.invoiceRecurring.invoiceRecurringDetail[rowIndex][cell] = val;
    this.invoiceRecurring.invoiceRecurringDetail = [...this.invoiceRecurring.invoiceRecurringDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

  fntDropdownSettingsProduct(disabled) {
    if (disabled) {
      this.dropdownSettingsProduct = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettingsProduct = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
