<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    *ngIf="
      !(
        purchaseGenerateMode ||
        purchaseViewMode ||
        purchaseListMode ||
        purchaseReceiveMode
      )
    "
  >
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-cash-register"></i> Compras
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Ingresar"
              container="body"
              (click)="purchaseGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Ingresar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="purchaseList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-purchase-generate
    *ngIf="purchaseGenerateMode || purchaseViewMode"
    (cancelAction)="cancelAction()"
    [purchaseViewMode]="purchaseViewMode"
    [purchase]="purchase"
    (cancelViewAction)="cancelViewAction()"
  >
  </app-purchase-generate>
  <app-purchase-list
    *ngIf="purchaseListMode"
    (cancelAction)="cancelAction()"
    (receiveAction)="purchaseReceive($event)"
    [authorizePermission]="authorizePermission"
    [unauthorizePermission]="unauthorizePermission"
    [deletePermission]="deletePermission"
    [receivePermission]="receivePermission"
    [xlsxExportPermission]="xlsxExportPermission"
    (viewAction)="purchaseView($event)"
    [reportStartDate]="reportStartDate"
    [reportEndDate]="reportEndDate"
  >
  </app-purchase-list>
  <app-purchase-receive
    *ngIf="purchaseReceiveMode"
    (cancelAction)="cancelAction()"
    [purchase]="purchase"
  >
  </app-purchase-receive>
</div>
