import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { ListArray } from 'src/app/models/listArray';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-inventory-maintenance-drop',
  templateUrl: './inventory-maintenance-drop.component.html',
  styleUrls: ['./inventory-maintenance-drop.component.scss'],
})
export class InventoryMaintenanceDropComponent implements OnInit {
  loading;
  @Input() headerActionType;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  inventoryDrop = {} as any;
  inventoryList = [];

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccione',
    enableSearchFilter: true,
    classes: '',
    lazyLoading: true,
    maxHeight: 150,
  };

  constructor(
    private sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private inventoryService: InventoryService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.inventoryList = await this.sharedUtils.transformToList(
      await this.getInventory(user.userStore),
      'inventoryCode',
      'inventoryNameShow'
    );
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    const store = JSON.parse(localStorage.getItem('store') || '{}');
    this.saveAction.emit({
      inventoryDropCompany: company.companyCode,
      inventoryDropStore: store.storeCode,
      inventoryDropUser: user.userCode,
      inventoryDropInventory: this.inventoryDrop.inventoryDropInventory,
      inventoryDropQuantity: this.inventoryDrop.inventoryDropQuantity,
      inventoryDropComment: this.inventoryDrop.inventoryDropComment,
    });
  }

  async onItemSelect(item: ListArray) {
    this.inventoryDrop.inventoryDropInventory = item.id;
  }
}
