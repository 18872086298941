import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client';
import { Seller } from '../models/seller';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // seller maintenance
  async sellerGetStore(store): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-seller/store/' + store + queryParam, {
        headers,
      })
      .toPromise();
  }

  async sellerGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-seller/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async selleryGetCompanyEnable(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-seller/enabled/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async sellerInsert(seller: Seller) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-seller', seller, { headers })
      .toPromise();
  }

  async sellerUpdate(seller: Seller) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-seller/', seller, { headers })
      .toPromise();
  }

  async sellerDelete(seller: Seller) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-seller/delete/', seller, { headers })
      .toPromise();
  }
}
