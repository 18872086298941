import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubCategory } from 'src/app/models/subCategory';
import { SubCategoryService } from 'src/app/services/sub-category.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-sub-category-maintenance',
  templateUrl: './sub-category-maintenance.component.html',
  styleUrls: ['./sub-category-maintenance.component.scss']
})
export class SubCategoryMaintenanceComponent implements OnInit {
  loading = false;
  subCategoryList: SubCategory[];
  subCategory: SubCategory;
  tableHeaders: [] = [];

  headerActionType: string;
  subCategoryCreateMode: boolean = false;
  subCategoryUpdateMode: boolean = false;
  subCategoryDeleteMode: boolean = false;
  subCategoryViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private subCategoryService: SubCategoryService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Subcategoría"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.subCategoryList = await this.getSubCategory(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getSubCategory(company) {
    this.loading = true;
    return this.subCategoryService.subCategoryGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
}

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear subcategoría';
    this.subCategoryCreateMode = true;
    this.subCategory = {} as SubCategory;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.subCategory.subCategoryStatus = 1;
    // this.subCategory.subCategoryCompany = user.userCompany;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver subcategoría';
    this.subCategoryViewMode = true;
    this.subCategory = event as SubCategory;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar subcategoría';
    this.subCategoryUpdateMode = true;
    this.subCategory = event as SubCategory;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar subcategoría';
    this.subCategoryDeleteMode = true;
    /*  const subCategoryDeleted = {
        subCategoryCode: event.subCategoryCode,
        subCategoryDeleted: 1
      };*/
    swal.fire({
      title: "Atención",
      text: "¿Desea eliminar la subcategoría?",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(event);
      }
    });
  }

  async cancelAction(event) {
    this.subCategoryCreateMode = false;
    this.subCategoryViewMode = false;
    this.subCategoryUpdateMode = false;
    this.subCategoryDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    this.subCategoryList = await this.getSubCategory(user.userCompany);
  }

  async saveAction(subCategory) {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    let response;
    this.loading = true;
    if (this.subCategoryCreateMode == true) {
      this.subCategory.subCategoryCompany = user.userCompany;
      this.subCategory.subCategoryStore = user.userStore;
      response = await this.subCategoryService.subCategoryInsert(subCategory);
    }
    if (this.subCategoryUpdateMode == true) {
      subCategory['subCategoryModifyDate'] = new Date();
      response = await this.subCategoryService.subCategoryUpdate(subCategory);
    }
    if (this.subCategoryDeleteMode == true) {
      subCategory['subCategoryDeletedDate'] = new Date();
      response = await this.subCategoryService.subCategoryDelete(subCategory);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);

    }
    this.loading = false;
  }

}
