import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Accountant } from 'src/app/models/accountant';
import { User } from 'src/app/models/user';
import { RoleService } from 'src/app/services/role.service';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
@Component({
  selector: 'app-accountant-maintenance-fields',
  templateUrl: './accountant-maintenance-fields.component.html',
  styleUrls: ['./accountant-maintenance-fields.component.scss']
})
export class AccountantMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() accountantViewMode: boolean = false;
  @Input() accountantUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() accountant: Accountant = {} as Accountant;
  @Input() user: User = {} as User;
  attributes: Attribute[] = new Array<Attribute>();

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar'
  };

  list = {};
  selected = {};

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private roleService: RoleService,
    private notificationService: NotificationService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(user.userRole, user.userCode, 'accountantMaintenance');
    const typeList = await this.sharedUtils.getAccountantType();
    await this.addSelectList(typeList, 'id', 'itemName', 'accountantType');

  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(list, id, name);
    if (this.accountant[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] = await this.sharedUtils.selectFromList(this.list[attributeBusinessCode], this.accountant[attributeBusinessCode]);
    }
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    this.accountant[attributeBusinessCode] = item.id;
  }
  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService.attributeGetRoleUserForm(role, user, form).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    const validatedUser = this.accountant;
    this.saveAction.emit(validatedUser);
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }

}
