<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Recibir compra
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-danger btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Proveedor</label>
                <div class="form-group">
                  <angular2-multiselect [data]="providerListSelect" [(ngModel)]="providerSelected"
                    [settings]="dropdownSettings" (onSelect)="onItemSelectProvider($event)">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-2">
                <label>Numero de cotización</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="purchase.purchaseQuotationNumber"
                    placeholder="Numero de cotización" [disabled]="true" />
                </div>
              </div>
              <div class="col-md-2">
                <label>Numero de Serie</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="purchase.purchaseSeries"
                    placeholder="Numero de serie" />
                </div>
              </div>
              <div class="col-md-2">
                <label>Numero de Factura</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="purchase.purchaseNumber"
                    placeholder="Numero de numero" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Factura</label>
                <span [class]="classImageBtn">
                  <span class="fileinput-new">PDF</span>
                  <input type="file" (change)="addFile($event)" />
                </span>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
              [rows]="purchase.purchaseDetail" (activate)="onActivate($event)">
              <ngx-datatable-column name="Nombre">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <span *ngIf="row.purchaseDetailInventory != 0">{{row.purchaseDetailDescription}}</span>
                  <input autofocus *ngIf="row.purchaseDetailInventory == 0"
                    (keyup)="updateValue($event, 'purchaseDetailDescription', rowIndex)" type="text"
                    [value]="row.purchaseDetailDescription" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Código">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <span *ngIf="row.purchaseDetailInventory != 0">{{row.purchaseDetailInventoryBusinessCode}}</span>
                  <input autofocus *ngIf="row.purchaseDetailInventory == 0"
                    (keyup)="updateValue($event, 'purchaseDetailInventoryBusinessCode', rowIndex)" type="text"
                    [value]="row.purchaseDetailInventoryBusinessCode" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Costo">
                <ng-template ngx-datatable-header-template>
                  <span>Costo</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.purchaseDetailUnitPrice | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad Solicitada">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad Solicitada</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.purchaseDetailRequestQuantity | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad Recibida">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="updateValue($event, 'purchaseDetailRecieveQuantity', rowIndex)" type="number"
                    [value]="row.purchaseDetailRecieveQuantity" min="0"/>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
