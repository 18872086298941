import { Component, OnInit } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentRegisterService } from 'src/app/services/payment-register.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/account';
import { ExportService } from 'src/app/services/export.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-account-collect',
  templateUrl: './account-collect.component.html',
  styleUrls: ['./account-collect.component.scss']
})
export class AccountCollectComponent implements OnInit {
  loading = false;
  accountList: Account[];
  account: Account;

  headerActionType: string;
  accountCreateMode: boolean = false;
  accountUpdateMode: boolean = false;
  accountDeleteMode: boolean = false;
  accountViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private accountService: AccountService,
    private paymentRegisterService: PaymentRegisterService,
    private permissionService: PermissionService,
    private router: Router,
    private exportService: ExportService,
    private titleService: TitleService,
  ) { this.titleService.emitChange("Contabilidad"); }

  async ngOnInit(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(await this.getTableHeader(user.userRole, user.userCode, 'accountCollect'), 'tableHeaderTitle', 'tableHeaderBusinessCode', 'tableHeaderPipe');
    await this.getReport();

    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;          
          default:
            break;
        }
      }
    }
  }

  changePayment(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.reportData[rowIndex][cell] = val;
    this.reportData = [...this.reportData];
  }

  async refresh() {
    await this.ngOnInit();
  }

  async addPay(event) {
    event.preventDefault();
    if (
      this.activeRow.salePaymentMethod == null || 
      this.activeRow.salePaymentMethod == undefined || 
      this.activeRow.salePaymentMethod == ''
    ) {
      this.notificationService.showError('Debes seleccionar un método de pago.');
    } else if (
      this.activeRow.salePaymentAmount === null || 
      this.activeRow.salePaymentAmount === undefined || 
      this.activeRow.salePaymentAmount === ''
    ) {
      this.notificationService.showError('Para registrar un pago debes ingresar un monto.');      
    } else if (    
      this.activeRow.salePaymentAmount === 0
    ) {
      this.notificationService.showError('Para registrar un pago el monto no puede ser 0.');      
    } else if (
      this.activeRow.salePaymentAmount > this.activeRow.saleBalance
    ) {
      this.notificationService.showError('Para registrar un pago el monto debe ser menor o igual al saldo.');      
    } else {
      this.activeRow['paymentRegisterPaymentMethod'] = this.activeRow.salePaymentMethod;
      this.activeRow['paymentRegisterTypeSale'] = this.activeRow.saleType;
      this.activeRow['paymentRegisterSale'] = this.activeRow.saleCode;
      this.activeRow['paymentRegisterPurchase'] = 0;
      this.activeRow['paymentRegisterDescription'] = this.activeRow.salePaymentMethod;
      this.activeRow['paymentRegisterAmount'] = this.activeRow.salePaymentAmount;
      this.activeRow['paymentRegisterSupport'] = this.activeRow.salePaymentVoucher || '';
      this.activeRow['paymentRegisterImageFileName'] = this.activeRow.saleImageFileName || '';
      this.activeRow['paymentRegisterImageFile'] = this.activeRow.saleImageFile || '';
      let response;
      this.loading = true;
      response = await this.paymentRegisterService.paymentRegisterInsert(this.activeRow);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        if (this.activeRow.saleType == 'Factura Cambiaria') {
          window.open(response.receiptPdfUrl, 'popup', 'width=600,height=600');
        }
        await this.ngOnInit();
      }
      this.loading = false;
    }   
  } 

  async openFileInput(e) {
    e.preventDefault();
    this.xlsxExportDate = undefined;
    document.getElementById('fileInput').click();       
  }

  async uploadImage(event) {
    event.preventDefault();
    this.xlsxExportDate = undefined;
    this.activeRow.saleImageFileName = event.target.files[0].name;
    this.activeRow.saleImageFile = await this.sharedUtils.convertBase64(event.target.files.item(0));
    this.activeRow.classImageBtn = "btn btn-info btn-icon btn-sm btn-simple upload";  
  }


  async cancelAction(event) {   
    await this.getReport();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader.tableHeaderGetRoleUserTable(role, user, table).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = report.map(row => {
      row['salePaymentAmount'] = 0;
      row['classImageBtn'] = "btn btn-icon btn-sm btn-simple upload";
      row['saleImageFileName'] = "";
      row['saleImageFile'] = "";
      return row;
    });
    this.reportDataFilter = [...this.reportData];
  }
  

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.accountService.accountCollect(user.userStore).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.reportDataFilter = response.records;
        return response.records;     
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async xlsxExportAction() {
    this.exportService.xlsxExport(
      this.reportDataFilter,
      this.tableHeaders,
      'Reporte',
      'Reporte de cuentas por cobrar'
    );
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear propietario';
    this.accountCreateMode = true;
    this.account = {} as Account;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.account.accountCompany = user.userCompany;
    this.account.accountStatus = 1;
  } 

}
