<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    class="row"
    *ngIf="
      !(
        providerCreateMode ||
        providerUpdateMode ||
        providerViewMode ||
        providerShowMode
      )
    "
  >
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de proveedores</h4>
          <div class="toolbar">
            <app-provider-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
            ></app-provider-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-provider-maintenance-table
            [providerList]="providerList"
            (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)"
            (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission"
            [updatePermission]="updatePermission"
            [deletePermission]="deletePermission"
            [viewPermission]="viewPermission"
            [xlsxExportDate]="xlsxExportDate"
            [tableHeaders]="tableHeaders"
          >
          </app-provider-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-provider-maintenance-fields
    *ngIf="providerCreateMode || providerUpdateMode || providerViewMode"
    [headerActionType]="headerActionType"
    [providerViewMode]="providerViewMode"
    [providerUpdateMode]="providerUpdateMode"
    [provider]="provider"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-provider-maintenance-fields>
</div>
