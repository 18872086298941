import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from 'src/app/services/inventory.service';
import { Store } from 'src/app/models/store';
const uuid = require('uuid');
import swal from 'sweetalert2';
import { PackageService } from 'src/app/services/package.service';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { ListArray } from 'src/app/models/listArray';
import { SellerService } from 'src/app/services/seller.service';
import { QuotationService } from 'src/app/services/quotation.service';

@Component({
  selector: 'app-invoice-generate',
  templateUrl: './invoice-generate.component.html',
  styleUrls: ['./invoice-generate.component.scss'],
})
export class InvoiceGenerateComponent implements OnInit {
  loading = false;

  @Input() quotationList;
  @Input() clientSaleList;

  @Output() cancelAction = new EventEmitter<any>();

  invoice: Invoice = {
    invoiceClient: 0,
    invoiceClientAddressShow: 'Ciudad',
    invoiceDetail: new Array<InvoiceDetail>(),
    invoicePayment: {
      invoicePaymentMethod: 1,
    },
  } as Invoice;

  company = {} as Company;
  store = {} as Store;
  invoiceDetail = {
    invoiceDetailQuantity: 0,
    invoiceDetailSubtotal: 0,
    invoiceDetailTotal: 0,
    invoiceDetailUnitPrice: 0,
    invoiceDetailDiscount: 0,
    invoiceDetailCost: 0,
    invoiceDetailMeasure: 'UNI',
    invoiceDetailInventory: 0,
  } as InvoiceDetail;
  invoiceClient = {
    clientTaxNumber: ""
  } as Client;
  client = {} as Client;

  invoiceSaleService = false;
  invoiceSaleBenefit = false;
  invoiceSaleInventory = false;
  invoiceInventory = false;

  invoiceAddItemTypeMode = 'N';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  public nowDay: string;
  public now: string;
  public date: string;

  alert = false;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;

  clientInf;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private invoiceService: InvoiceService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private sellerService: SellerService,
    private quotationService: QuotationService
  ) { }

  async ngOnInit() {
    await this.setDefault();

    //OBTENER FECHA
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.now = yyyy + '-' + mm + '-' + dd;
    this.invoice.invoiceDate = yyyy + '-' + mm + '-' + dd;
    this.nowDay = this.invoice.invoiceDate;
    this.date = this.now;
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    this.invoice.invoiceDate = await this.sharedUtils.formatDate();
    this.invoice.invoiceUser = user.userCode;
    this.invoice.invoiceCompany = user.userCompany;
    this.invoice.invoiceStore = user.userStore;

    this.invoiceClient.clientCode = undefined;
    this.invoiceClient.clientAddress = environment.clientDefaultAddress;

    this.invoiceClient.clientPhoneOne = null;
    this.invoiceClient.clientPhoneTwo = null;
    this.invoiceClient.clientPhoneThree = null;
    this.invoiceClient.clientAddressZone = '0';
    this.invoiceClient.clientDepartment = null;
    this.invoiceClient.clientMunicipality = null;
    this.invoiceClient.clientCredit = null;
    this.invoiceClient.clientCreditTime = null;
    this.invoiceClient.clientCreditAmount = null;
    this.invoice.invoiceCurrency = 'GTQ';
    this.invoice.invoiceChangeRate = 1;
    this.invoice.invoiceTotal = 0;

    this.invoice.invoicePayment.invoicePaymentMethod = 1;
    this.invoice.invoicePayment.invoicePaymentQuantity = 1;
    this.invoice.invoicePayment.invoicePaymentTime = 1;
    this.invoice.invoicePayment.invoicePaymentTimeMethod = 1;
    this.invoice.invoiceDocumentSearch = 1;
    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
      // this.getSeller(user.userCompany),
    ]).then(async (response) => {
      // const sellerList = response[2][0].sellerName;
      // await this.addSelectList(
      //   response[2],
      //   'sellerCode',
      //   'sellerName',
      //   'sellerShow'
      // );

      this.invoiceClient.clientDepartment = response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.invoiceClient.clientDepartment
      );
      this.invoiceClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];
      this.company = companys[0];
      this.invoiceClient.clientEmail = this.company.companyEmail;
      this.invoiceClient.clientCompany = this.company.companyCode;
      this.invoiceSaleInventory = Boolean(this.company.companySaleInventory);
      this.invoiceSaleBenefit = Boolean(this.company.companySaleBenefit);
      this.invoiceSaleService = Boolean(this.company.companySaleService);
      this.invoiceInventory = Boolean(this.company.companyInventory);

    });

    if (this.clientSaleList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      const client = (await this.getClient(this.clientSaleList.clientCode || '')) as Client;

      this.invoiceClient.clientCode = client.clientCode;
      this.invoiceClient.clientName = client.clientName;
      this.invoiceClient.clientTaxNumber = client.clientTaxNumber;
      this.invoiceClient.clientIdentificationDocument = client.clientIdentificationDocument;
      this.invoiceClient.clientPhoneOne = client.clientPhoneOne + ' ' + client.clientPhoneTwo + ' ' + client.clientPhoneThree;
      this.invoiceClient.clientPhone = client.clientPhoneOne;
      this.invoiceClient.clientEmail = client.clientEmail || this.invoiceClient.clientEmail;
      this.invoiceClient.clientAddress = client.clientAddress || this.invoiceClient.clientAddress;
      this.invoiceClient.clientAddressZone = client.clientAddressZone || this.invoiceClient.clientAddressZone;

      if (client['clientDepartment'] != undefined) {
        this.invoiceClient.clientDepartment = client.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === client.clientMunicipality
        );
        if (!validMunicipality) {
          this.invoiceClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceClient.clientMunicipality = client.clientMunicipality || null;
        }  
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

    }

    if (this.quotationList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      this.clientInf = (await this.getClient(this.quotationList.quotationClient || '')) as Client;

      for (const items of this.quotationList.quotationDetail) {
        this.invoiceDetail.invoiceDetailCode = uuid.v4();
        this.invoiceDetail.invoiceDetailDescription = items.quotationDetailDescription;
        this.invoiceDetail.invoiceDetailUnitPrice = parseFloat(items.quotationDetailUnitPrice);
        this.invoiceDetail.invoiceDetailQuantity = parseFloat(items.quotationDetailQuantity);
        this.invoiceDetail.invoiceDetailDiscount = 0;
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat(items.quotationDetailTotal);
        this.invoiceDetail.invoiceDetailTotal = parseFloat(items.quotationDetailTotal);
        this.invoiceDetail.invoiceDetailInventory = parseFloat(items.quotationDetailInventory);
        this.invoiceDetail.invoiceDetailIsService = items.quotationDetailType;
        this.invoice.invoiceDetail.push(this.invoiceDetail);
        this.invoice.invoiceDetail = [...this.invoice.invoiceDetail];
        this.invoiceDetail = {
          invoiceDetailDescription: undefined,
          invoiceDetailQuantity: 0,
          invoiceDetailSubtotal: 0,
          invoiceDetailTotal: 0,
          invoiceDetailUnitPrice: 0,
          invoiceDetailDiscount: 0,
          invoiceDetailCost: 0,
          invoiceDetailInventory: 0,
        } as InvoiceDetail;
        await this.calculateTotals();
      }

      this.invoiceClient.clientCode = this.clientInf.clientCode;
      this.invoiceClient.clientName = this.clientInf.clientName;
      this.invoiceClient.clientTaxNumber = this.clientInf.clientTaxNumber;
      this.invoiceClient.clientIdentificationDocument = this.clientInf.clientIdentificationDocument;
      this.invoiceClient.clientPhoneOne = this.clientInf.clientPhoneOne + ' ' + this.clientInf.clientPhoneTwo + ' ' + this.clientInf.clientPhoneThree;
      this.invoiceClient.clientPhone = this.clientInf.clientPhoneOne;
      this.invoiceClient.clientEmail = this.clientInf.clientEmail || this.invoiceClient.clientEmail;
      this.invoiceClient.clientAddress = this.clientInf.clientAddress || this.invoiceClient.clientAddress;
      this.invoiceClient.clientAddressZone = this.clientInf.clientAddressZone || this.invoiceClient.clientAddressZone;

      if (this.clientInf['clientDepartment'] != undefined) {
        this.invoiceClient.clientDepartment = this.clientInf.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          this.clientInf.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.clientInf.clientMunicipality
        );
        if (!validMunicipality) {
          this.invoiceClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceClient.clientMunicipality = this.clientInf.clientMunicipality || null;
        }  
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

    }
  }

  async cancel() {
    this.clientSaleList = undefined;
    this.quotationList = undefined;
    await this.setDefault();
    this.cancelAction.emit();
  }

  async searchClient() {
    if (this.clientSaleList == undefined && this.quotationList == undefined) {
      if (this.invoice.invoiceDocumentSearch == 1
        && (this.invoiceClient.clientTaxNumber == '' || this.invoiceClient.clientTaxNumber == undefined)) {
        this.notificationService.showError("Debes ingresar un NIT.");
      } else if (this.invoice.invoiceDocumentSearch ==
        0 && (this.invoiceClient.clientIdentificationDocument == '' || this.invoiceClient.clientIdentificationDocument == undefined)) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      } else {

        if (this.invoice.invoiceDocumentSearch == 1) {
          this.client = (await this.getClientGetTaxNumber(
            this.invoiceClient?.clientTaxNumber || 'CF'
          )) as Client;
        } else {
          this.client = (await this.getClientGetIdentificationDocument(
            this.invoiceClient?.clientIdentificationDocument || ''
          )) as Client;
        }
        this.invoiceClient.clientCode = this.client.clientCode;
        this.invoiceClient.clientName = this.client.clientName;
        this.invoiceClient.clientIdentificationDocument =
          this.client.clientIdentificationDocument;
        this.invoiceClient.clientEmail =
          this.client.clientEmail || this.invoiceClient.clientEmail;
        if (this.client['clientDepartment'] != undefined) {
          this.invoiceClient.clientDepartment = this.client.clientDepartment;
          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.invoiceClient['clientDepartment'] || null
          );
          const municipalityList = await this.getMunicipality(
            this.client.clientDepartment
          );
          const validMunicipality = municipalityList.find(
            (mun) => mun.municipalityCode === this.client.clientMunicipality
          );
    
          if (!validMunicipality) {
            this.invoiceClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
          } else {
            this.invoiceClient.clientMunicipality = this.client.clientMunicipality || null;
          }
    
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }    

      }
    }
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }  

  async validateDate($event) {
    $event.preventDefault();

    const nowDate = new Date(this.nowDay);
    const inputDate = new Date(this.invoice.invoiceDate);

    const minDate = new Date(nowDate);
    minDate.setDate(nowDate.getDate() - 5);

    const maxDate = new Date(nowDate);
    maxDate.setDate(nowDate.getDate() + 5);

    if (inputDate >= minDate && inputDate <= maxDate) {
    } else {
      this.invoice.invoiceDate = this.nowDay;
      this.notificationService.showWarning('Fecha fuera de rango.');
    }
  }

  async changeCurrency(event) {
    this.invoice.invoiceChangeRate =
      event == 'GTQ' ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.invoice.invoiceDocumentSearch = event;
  }

  async changeAddress(event) {

    if (event == 0) {
      this.invoiceClient.clientAddress = 'Ciudad';
    } else {
      this.invoiceClient.clientAddress = this.client.clientAddress || this.invoiceClient.clientAddress;;
      this.invoiceClient.clientAddressZone = this.client.clientAddressZone || this.invoiceClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.invoiceClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceClient['clientDepartment']
        );
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.client.clientMunicipality
        );
  
        if (!validMunicipality) {
          this.invoiceClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceClient.clientMunicipality = this.client.clientMunicipality || null;
        }
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }
    }
  }

  async changePaymentMethod(event) {
    this.invoice.invoicePayment.invoicePaymentMethod = event;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceAddItemTypeMode = this.invoiceDetail.invoiceDetailIsService;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      );
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.invoiceDetail.invoiceDetailDescription = inventoryItem.inventoryName;
      this.invoiceDetail.invoiceDetailUnitPrice = inventoryItem.inventoryPrice;
      this.invoiceDetail.invoiceDetailInventory = inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.invoiceDetail.invoiceDetailDescription = packageItem.packageName;
        this.invoiceDetail.invoiceDetailUnitPrice = packageItem.packagePrice;
        this.invoiceDetail.invoiceDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.invoiceClient.clientDepartment = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.invoiceClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      case 'clientMunicipality':
        this.invoiceClient.clientMunicipality = item.id;
        break;
      case 'sellerShow':
        this.invoice.invoiceSeller = item.id;
        break;
      default:
        this.invoiceClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.invoiceClient[attributeBusinessCode] == undefined ||
        this.invoiceClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.invoiceClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.invoiceDetail.invoiceDetailSubtotal =
      this.invoiceDetail.invoiceDetailQuantity *
      this.invoiceDetail.invoiceDetailUnitPrice;
    this.invoiceDetail.invoiceDetailTotal =
      this.invoiceDetail.invoiceDetailQuantity *
      this.invoiceDetail.invoiceDetailUnitPrice -
      this.invoiceDetail.invoiceDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceDetail = {
      invoiceDetailQuantity: 0,
      invoiceDetailSubtotal: 0,
      invoiceDetailTotal: 0,
      invoiceDetailUnitPrice: 0,
      invoiceDetailDiscount: 0,
      invoiceDetailCost: 0,
      invoiceDetailMeasure: 'UNI',
      invoiceDetailInventory: 0,
      invoiceDetailIsService: this.invoiceDetail.invoiceDetailIsService
    } as InvoiceDetail;
    this.invoiceDetail.invoiceDetailQuantity = 0;
    this.invoiceDetail.invoiceDetailUnitPrice = 0;
    if (this.invoiceDetail.invoiceDetailIsService == undefined) {
      this.invoiceDetail.invoiceDetailIsService = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.invoiceDetail.invoiceDetailIsService == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.invoiceDetail.invoiceDetailIsService = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;

      if (this.invoiceDetail.invoiceDetailIsService == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //   let packageList = await this.getPackage(user.userStore);
        //   let packageListInsert = new Array();
        //   for (const _package of packageList) {
        //     let insertPackage = true;
        //     for (const packageDetail of _package.packageDetail) {
        //       if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //         insertPackage = false;
        //       }
        //     }
        //     if (insertPackage) {
        //       packageListInsert.push(_package);
        //       inventoryList.push({
        //         inventoryCode: 'PACKAGE-' + _package.packageCode,
        //         inventoryName: _package.packageName,
        //         inventoryNameShow: _package.packageName,
        //       });
        //     }
        //   }
        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }
    }
    this.invoiceAddItemTypeMode = this.invoiceDetail.invoiceDetailIsService;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.invoiceDetail.invoiceDetailDescription == undefined ||
      this.invoiceDetail.invoiceDetailUnitPrice == 0 ||
      this.invoiceDetail.invoiceDetailQuantity == 0 ||
      this.invoiceDetail.invoiceDetailSubtotal == 0 ||
      this.invoiceDetail.invoiceDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.invoiceDetail.invoiceDetailCode = uuid.v4();
      this.invoice.invoiceDetail.push(this.invoiceDetail);
      this.invoice.invoiceDetail = [...this.invoice.invoiceDetail];
      this.invoiceDetail = {
        invoiceDetailQuantity: 0,
        invoiceDetailSubtotal: 0,
        invoiceDetailTotal: 0,
        invoiceDetailUnitPrice: 0,
        invoiceDetailIsService: this.invoiceDetail.invoiceDetailIsService
      } as InvoiceDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.invoice.invoiceDetail.find(
      (x) => x.invoiceDetailCode == this.activeRow.invoiceDetailCode
    );
    const indexDetail = this.invoice.invoiceDetail.indexOf(findDetail);
    this.invoice.invoiceDetail.splice(indexDetail, 1);
    this.invoice.invoiceDetail = [...this.invoice.invoiceDetail];
    await this.calculateTotals();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.invoice.invoiceSubtotal = 0;
    this.invoice.invoiceTotal = 0;
    for (const iterator of this.invoice.invoiceDetail) {
      this.invoice.invoiceSubtotal += iterator.invoiceDetailSubtotal;
      this.invoice.invoiceTotal += iterator.invoiceDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.invoiceClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.invoiceClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoice.invoiceClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {
      this.invoiceClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.invoiceClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdateSale(this.invoiceClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoice.invoiceClient = this.invoiceClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  async save() {
    this.invoiceClient.clientTaxNumber = this.invoiceClient.clientTaxNumber.toUpperCase();
    await this.calculateTotals();
    if (this.invoice.invoiceDocumentSearch == 1) {
      if (this.invoiceClient.clientTaxNumber == undefined || this.invoiceClient.clientTaxNumber == '') {
        this.invoiceClient.clientTaxNumber = 'CF';
        this.invoiceClient.clientName = 'Consumidor final';
      }
    }

    if (
      this.invoice.invoiceDocumentSearch == 0 &&
      (
        this.invoiceClient.clientIdentificationDocument == '' ||
        this.invoiceClient.clientIdentificationDocument == undefined
      )
    ) {
      this.notificationService.showError("Debes ingresar un número de DPI.");
    } else if (this.invoice.invoiceDetail.length == 0) {
      this.notificationService.showError(
        'Debes agregar detalle de venta.'
      );
    } else if (
      this.invoice.invoiceTotal > 2500 &&
      (
        this.invoiceClient.clientTaxNumber == "CF" || this.invoiceClient.clientTaxNumber == "C/F" ||
        this.invoiceClient.clientTaxNumber == "cf" || this.invoiceClient.clientTaxNumber == "c/f"
      ) &&
      (
        this.invoiceClient.clientIdentificationDocument == '' || this.invoiceClient.clientIdentificationDocument == undefined
      )
    ) {
      this.notificationService.showError('Para emitir facturas con montos mayores a Q2,500.00 debes ingresar un NIT o DPI.');
    } else {

      if (
        this.store.storeOnline == 0 &&
        this.invoice.invoicePayment.invoicePaymentMethod == 1
      ) {
        swal
          .fire({
            title:
              'Total Q' +
              this.invoice.invoiceTotal +
              '<br/>Cantidad de efectivo:',
            // text: "¿Desea eliminar cuenta contable?",
            icon: 'question',
            html:
              '<div class="form-group">' +
              '<input id="input-field" type="text" class="form-control" />' +
              '</div>',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
            },
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              const efectivo = (
                document.getElementById('input-field') as HTMLInputElement
              ).value;
              swal.fire({
                title: 'Cambio',
                text:
                  'su cambio es ' +
                  (parseFloat(efectivo) -
                    parseFloat(this.invoice.invoiceTotal)) +
                  '.',
                icon: 'info',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
              this.saveAction();
            }
          });
      } else {
        this.saveAction();
      }
    }
  }

  async saveAction() {

    if (this.quotationList != null) {
      this.invoice.invoiceQuotation = this.quotationList.quotationCode;
    } else {
      this.invoice.invoiceQuotation = null;
    }

    if (this.addressSelected == 0) {
      this.invoice.invoiceClientAddressShow = 'Ciudad';
    } else {
      this.invoice.invoiceClientAddressShow = `${this.invoiceClient.clientAddress}, Zona ${this.invoiceClient.clientAddressZone}, ${this.selected['clientDepartment'][0]['itemName']}, ${this.selected['clientMunicipality'][0]['itemName']}`;             
    }

    this.loading = true;
    if (await this.validateClient()) {
      let response;
      response = await this.invoiceService.invoiceInsert(this.invoice);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        if (response.result == true) {

          if (this.quotationList != undefined) {
            await this.saveStatusQuotation();
          }

          await window.open(
            response.invoicePdfUrl,
            'popup',
            'width=600,height=600'
          );
          await this.notificationService.showSuccess("Factura generada con éxito.");
          await window.open(
            environment.infilePdfUrl + response.invoiceAuthorization,
            'popup',
            'width=600,height=600'
          );
          this.cancel();
        } else {
          this.notificationService.showError(response.message);
          // for (const err of response.errorList) {
          //   console.error(err.mensaje_error);
          // }
        }
      }
    }
    this.loading = false;
  }

  async saveStatusQuotation() {
    let response;

    const quotationUpdate = {
      quotationCode: this.quotationList.quotationCode,
      quotationStatus: 3
    }
    response = await this.quotationService.quotationUpdate(quotationUpdate);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.quotationList = undefined;
    }
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService
      .selleryGetCompanyEnable(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.invoice.invoiceDetail[rowIndex][cell] = val;
    this.invoice.invoiceDetail = [...this.invoice.invoiceDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
