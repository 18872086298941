<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    *ngIf="!(transferGenerateMode || transferListMode || transferReceiveMode)"
  >
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title"><i class="fas fa-truck"></i> Traslados</h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              title="Generar"
              *ngIf="createPermission"
              (click)="transferGenerate()"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              title="Listar"
              *ngIf="readPermission"
              (click)="transferList()"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-transfer-generate
    *ngIf="transferGenerateMode"
    (cancelAction)="cancelAction()"
  ></app-transfer-generate>
  <app-transfer-list
    *ngIf="transferListMode"
    (cancelAction)="cancelAction()"
    (receiveAction)="transferReceive($event)"
    [viewPermission]="viewPermission"
    [xlsxExportPermission]="xlsxExportPermission"
    [authotizePermission]="authotizePermission"
    [unAuthotizePermission]="unAuthotizePermission"
    [receivePermission]="receivePermission"
    [unReceivePermission]="unReceivePermission"
  >
  </app-transfer-list>
  <app-transfer-receive
    *ngIf="transferReceiveMode"
    [transfer]="transfer"
    (cancelAction)="cancelAction()"
  >
  </app-transfer-receive>
</div>
