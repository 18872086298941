<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row" *ngIf="!(colorCreateMode || colorUpdateMode || colorViewMode)">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Listado de colores
          </h4>
          <div class="toolbar">
            <app-color-maintenance-toolbox [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"></app-color-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-color-maintenance-table [colorList]="colorList" (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission" [updatePermission]="updatePermission"
            [deletePermission]="deletePermission" [viewPermission]="viewPermission" [xlsxExportDate]="xlsxExportDate">
          </app-color-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-color-maintenance-fields *ngIf="colorCreateMode || colorUpdateMode || colorViewMode"
    [headerActionType]="headerActionType" [colorViewMode]="colorViewMode" [colorUpdateMode]="colorUpdateMode"
    [color]="color" (cancelAction)="cancelAction()" (saveAction)="saveAction($event)">
  </app-color-maintenance-fields>
</div>
