import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Routes } from '../models/routes';
import { BankAccount } from '../models/bankAccount';
import { User } from '../models/user';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // routes maintenance
  async routesGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-routes/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async routesInsert(routes: Routes) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-routes', routes, { headers })
      .toPromise();
  }

  async routesUpdate(routes: Routes) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-routes', routes, { headers })
      .toPromise();
  }
}
