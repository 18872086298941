<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Generar factura cambiaria</h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm"
                  [(ngModel)]="invoiceExchange.invoiceExchangeDate" (change)="validateDate($event)" />
              </div>
              <div class="col-md-3">
                <label>Tipo de documento</label>
                <select class="form-control" [(ngModel)]="invoiceExchange.invoiceExchangeDocumentSearch"
                  (change)="changeDocumentType($event.target.value)">
                  <option value="1">NIT</option>
                  <option value="0">DPI</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="invoiceExchange.invoiceExchangeDocumentSearch == 1">
                <label>NIT</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceExchangeClient.clientTaxNumber"
                    (focusout)="searchClient()" placeholder="NIT" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="invoiceExchange.invoiceExchangeDocumentSearch == 0">
                <label>DPI</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="
                      invoiceExchangeClient.clientIdentificationDocument
                    " (focusout)="searchClient()" placeholder="DPI" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Nombre</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceExchangeClient.clientName"
                    placeholder="Nombre" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Correo electrónico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceExchangeClient.clientEmail"
                    placeholder="Correo electrónico" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <select class="form-control" [(ngModel)]="addressSelected"
                  (change)="changeAddress($event.target.value)">
                  <option value="0">Ciudad</option>
                  <option value="1">Ingresar dirección</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceExchangeClient.clientAddress"
                    placeholder="Dirección" [disabled]="addressSelected == 0"/>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Zona</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="invoiceExchangeClient.clientAddressZone"
                    placeholder="Zona" min="0" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Departamento</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientDepartment']" *ngIf="list['clientDepartment']"
                    [(ngModel)]="selected['clientDepartment']" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'clientDepartment')">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Municipio</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientMunicipality']" *ngIf="list['clientMunicipality']"
                    [(ngModel)]="selected['clientMunicipality']" [settings]="dropdownSettings" (onSelect)="
                      onItemSelectClient($event, 'clientMunicipality')
                    ">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <hr />
            <div class="row" *ngIf="quotationList == undefined">           
              <div class="col-md-3">
                <label>Moneda</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="invoiceExchange.invoiceExchangeCurrency"
                    (change)="changeCurrency($event.target.value)">
                    <option value="GTQ">Q - Quetzales</option>
                    <option value="USD">$ - Dolares</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailIsService"
                    (change)="changeDetailType($event.target.value)">
                    <option value="N">Seleccionar...</option>
                    <option value="B" *ngIf="invoiceExchangeInventory">
                      Bien
                    </option>
                    <option value="SD" *ngIf="invoiceExchangeInventory">
                      Servicio
                    </option>
                    <option value="BD">
                      Bien descripción
                    </option>
                    <option value="S">
                      Servicio descripción
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="this.invoiceExchange.invoiceExchangeChangeRate != 1">
                <label>Tasa de cambio</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="invoiceExchange.invoiceExchangeChangeRate" placeholder="Tasa de cambio"
                    [disabled]="true" />
                </div>
              </div>
              <!-- <div class="col-md-2">
                <label>Tipo de pago</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentMethod">
                    <option value="1">Efectivo</option>
                    <option value="2">Tarjeta</option>
                    <option value="3">Cheque</option>
                    <option value="4">Deposito</option>
                    <option value="5">Transferencia</option>
                  </select>
                </div>
              </div> -->
              <!-- <div class="col-md-2" *ngIf="invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentMethod != 1">
                <label>Numero Autorización</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #FFFFFF;"
                    [(ngModel)]="invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentTransferNumber" placeholder="Numero Autorización"
                    [disabled]="true" />
                </div>
              </div> -->
            </div>
            <div class="row" *ngIf="quotationList == undefined">
              <div class="col-md-6" *ngIf="invoiceExchangeAddItemTypeMode == 'B' || invoiceExchangeAddItemTypeMode == 'SD'">
                <label>Productos</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="invoiceExchangeAddItemTypeMode == 'S' || invoiceExchangeAddItemTypeMode == 'BD'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailDescription"
                    placeholder="Descripción" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="invoiceExchangeAddItemTypeMode == 'N'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailDescription"
                    placeholder="Descripción" disabled 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>P. Unitario</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailUnitPrice"
                    placeholder="Precio unitario" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailUnitPrice == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailQuantity"
                    placeholder="Cantidad" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailQuantity == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Descuento</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailDiscount"
                    placeholder="Descuento" (blur)="calculate()" min="0" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Subtotal</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailSubtotal"
                    placeholder="Subtotal" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailSubtotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Total</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceExchangeDetail.invoiceExchangeDetailTotal"
                    placeholder="Total" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceExchangeDetail.invoiceExchangeDetailTotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="insertItem()">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="invoiceExchange.invoiceExchangeDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="250" [rows]="invoiceExchange.invoiceExchangeDetail"
              (activate)="onActivate($event)">
              <ngx-datatable-column *ngIf="quotationList == undefined" [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)" ngbTooltip="Eliminar" container="body"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descripción">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="
                                                              changeDescription($event, 'invoiceExchangeDetailDescription', rowIndex)
                                                            " type="text"
                    [value]="row.invoiceExchangeDetailDescription || 0" />
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column
                name="Descripción"
                prop="invoiceExchangeDetailDescription"
              ></ngx-datatable-column> -->
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeDetailQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descuento">
                <ng-template ngx-datatable-header-template>
                  <span>Descuento</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeDetailDiscount | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Subtotal">
                <ng-template ngx-datatable-header-template>
                  <span>Subtotal</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeDetailSubtotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total:
                    {{
                    invoiceExchange.invoiceExchangeTotal | number : "1.2-2"
                    }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Cantidad de abonos</label>
                <div class="form-group">
                  <input type="text" class="form-control form-control-sm"
                  [(ngModel)]="invoiceExchange.invoiceExchangePaymentQuantity" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Frecuencia de vencimiento</label>
                <div class="form-group">
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="
                      invoiceExchange.invoiceExchangePaymentFrequency
                    " />
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group" *ngIf="items">
                  <button class="btn btn-sm btn-info btn-simple" ngbTooltip="Agregar" container="body" (click)="calculateComplement()">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="form-group" *ngIf="!items">
                  <button class="btn btn-sm btn-info btn-simple" ngbTooltip="Agregar" container="body" (click)="calculateComplement()" disabled>
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="250"
              [rows]="invoiceExchange.invoiceExchangeSubscription" (activate)="onActivate($event)">
              <ngx-datatable-column name="No" prop="invoiceExchangeSubscriptionNumber"></ngx-datatable-column>
              <ngx-datatable-column name="Monto">
                <ng-template ngx-datatable-header-template>
                  <span>Fecha</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{
                  row.invoiceExchangeSubscriptionDate | date : "dd/MM/yyyy"
                  }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Monto">
                <ng-template ngx-datatable-header-template>
                  <span>Monto</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceExchangeSubscriptionAmount | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>