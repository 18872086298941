import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client';
import { BankAccount } from '../models/bankAccount';
import { User } from '../models/user';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async bankAccountGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-bankAccount/company/' +
          company +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async bankAccountInsert(bankAccount: BankAccount) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-bankAccount', bankAccount, { headers })
      .toPromise();
  }

  async bankAccountUpdate(bankAccount: BankAccount) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-bankAccount', bankAccount, { headers })
      .toPromise();
  }

  async bankGetAll(): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-bank/' + queryParam, { headers })
      .toPromise();
  }
}
