import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(
    private toastr: ToastrService,
  ) { }

  showSuccess(message, confirm?: boolean) {
    let imageUrl = 'assets/img/success.png';
    $.notify({
      message: message
    }, {
      type: 'success',
      timer: 4000,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert" style="padding: 10px; margin: 0; font-size: 14px;">' + // Ajustar tamaño y estilo
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="nc-icon nc-simple-remove"></i></button>' +
        '<div style="display: flex; align-items: center; padding: 5px 0; margin: 0;">' + 
        '<img src="' + imageUrl + '" alt="Icon" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle;">' +
        '<span data-notify="message" style="margin: 0; line-height: 1.5;">{2}</span>' +
        '</div>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  showWarning(message) {
    console.warn(message);
    let imageUrl = 'assets/img/warning.png'; 

    $.notify({
      message: message
    }, {
      type: 'warning',
      timer: 4000,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert" style="padding: 10px; margin: 0; font-size: 14px;">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="nc-icon nc-simple-remove"></i></button>' +
        '<div style="display: flex; align-items: center; padding: 5px 0; margin: 0;">' + 
        '<img src="' + imageUrl + '" alt="Icon" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle;">' +
        '<span data-notify="message" style="margin: 0; line-height: 1.5;">{2}</span>' +
        '</div>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  showError(message) {
    console.error(message);
    let imageUrl = 'assets/img/error.png';

    $.notify({
      message: message
    }, {
      type: 'danger',
      timer: 4000,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert" style="padding: 10px; margin: 0; font-size: 14px;">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="nc-icon nc-simple-remove"></i></button>' +
        '<div style="display: flex; align-items: center; padding: 5px 0; margin: 0;">' + 
        '<img src="' + imageUrl + '" alt="Icon" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle;">' +
        '<span data-notify="message" style="margin: 0; line-height: 1.5;">{2}</span>' +
        '</div>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
