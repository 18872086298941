<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-sm-6 col-md-8">
    <button class="btn btn-sm btn-success btn-simple" *ngIf="createPermission" (click)="createCategory()" title="Crear">
      <i class="fas fa-plus"></i>
    </button>
    <button class="btn btn-sm btn-default btn-simple" (click)="refreshCategory()" title="Refrescar">
      <i class="fas fa-sync-alt"></i>
    </button>
    <button class="btn btn-sm btn-default btn-simple" *ngIf="xlsxExportPermission" (click)="xlsxExport()"
      title="Exportar XLSX">
      <i class="fas fa-file-excel" style="color: green;"></i>
    </button>
  </div>
</div>
