<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div *ngIf="!(shippingScheduleMode || shippingListMode)">
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title"><i class="fas fa-dolly"></i> Envíos</h5>
          </div>
          <div class="card-body">
            <button class="btn btn-sm btn-success btn-simple" title="Solicitar" (click)="shippingSchedule()" *ngIf="createPermission">
              <i class="fas fa-calendar-day"></i> Agendar
            </button>
            <button class="btn btn-sm btn-info btn-simple" title="Listar" (click)="shippingList()" *ngIf="readPermission">
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-shipping-schedule *ngIf="shippingScheduleMode" (cancelAction)="cancelAction()">
  </app-shipping-schedule>
  <app-shipping-list *ngIf="shippingListMode" (cancelAction)="cancelAction()" [viewPermission]="viewPermission" [deletePermission]="deletePermission" [xlsxExportPermission]="xlsxExportPermission">
  </app-shipping-list>
</div>
