import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-routes-maintenance-toolbox',
  templateUrl: './routes-maintenance-toolbox.component.html',
  styleUrls: ['./routes-maintenance-toolbox.component.scss']
})
export class RoutesMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  async createRoutes() {
    this.createAction.emit();
  }
  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshRoutes() {
    this.refreshAction.emit();
  }

}
