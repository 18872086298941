import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { PurchaseService } from 'src/app/services/purchase.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/export.service';
import swal from "sweetalert2";
@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
})
export class PurchaseListComponent implements OnInit {
  loading = false;

  @Input() reportStartDate;
  @Input() reportEndDate;

  @Input() authorizePermission: boolean;
  @Input() unauthorizePermission: boolean;
  @Input() deletePermission: boolean;
  @Input() receivePermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() receiveAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private purchaseService: PurchaseService,
    private exportService: ExportService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'purchaseReportDate'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    await this.getReport();
  }

  async refresh() {
    await this.ngOnInit();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if ((this.reportStartDate == undefined && this.reportEndDate == undefined)){
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async xlsxExport() {
    this.exportService.xlsxExport(
      this.reportData,
      this.tableHeaders,
      'Reporte',
      'Reporte de compras'
    );
  }

  async view(event) {
    event.preventDefault();
    this.viewAction.emit({activeRow: this.activeRow, reportStartDate:this.reportStartDate, reportEndDate:this.reportEndDate});
  }

  viewFile(event) {
    event.preventDefault();
    window.open(this.activeRow.purchaseInvoiceFileUrl, 'popup', 'width=600,height=600');
  }

  async receive(event) {
    event.preventDefault();
    this.receiveAction.emit(this.activeRow);
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.purchaseService
      .purchaseGetCompamy(
        user.userCompany,
        this.reportStartDate,
        this.reportEndDate
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
    this.reportDataFilter = [...this.reportDataFilter];
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async authorize(event) {
    event.preventDefault();
    const purchase = this.activeRow;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let response;
    const authorizeModel = {
      purchaseAuthorizedUser: user.userCode,
      purchaseQuoteNumber: purchase.purchaseQuoteNumber,
      purchaseCode: purchase.purchaseCode,
      purchaseProvider: purchase.purchaseProvider,
    };
    this.loading = true;
    response = await this.purchaseService.purchaseAuthorize(authorizeModel);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  async unAuthorize(event) {
    event.preventDefault();
    const purchase = this.activeRow;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let response;
    const authorizeModel = {
      purchaseAuthorizedUser: user.userCode,
      purchaseQuoteNumber: purchase.purchaseQuoteNumber,
      purchaseCode: purchase.purchaseCode,
      purchaseProvider: purchase.purchaseProvider,
    };
    this.loading = true;
    response = await this.purchaseService.purchaseUnauthorize(authorizeModel);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  async delete(event) {
    event.preventDefault();
    const purchase = this.activeRow;


    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(async result => {
      if (result.value) {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
    let response;
    const authorizeModel = {
      purchaseAuthorizedUser: user.userCode,
      purchaseQuoteNumber: purchase.purchaseQuoteNumber,
      purchaseCode: purchase.purchaseCode,
      purchaseProvider: purchase.purchaseProvider,
    };
    this.loading = true;
    response = await this.purchaseService.purchaseDelete(authorizeModel);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.getReport();
    }
    this.loading = false;
      }
    });



    
  }
}
