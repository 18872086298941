<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{headerActionType}}</h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body" *ngIf="!invoiceRecurringViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Frecuencia</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="invoiceRecurring.invoiceRecurringFrequency"
                    placeholder="Frecuencia" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        invoiceRecurring.invoiceRecurringFrequency == '' || 
                        invoiceRecurring.invoiceRecurringFrequency == undefined
                      ">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label></label>
                <select class="form-control" [(ngModel)]="invoiceRecurring.invoiceRecurringFrequencyType"
                  (change)="changeFrequencyType($event.target.value)"
                  [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode">
                  <option value="0">Días</option>
                  <option value="1">Semanas</option>
                  <option value="2">Meses</option>
                  <option value="3">Años</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Duración</label>
                <select class="form-control" [(ngModel)]="invoiceRecurring.invoiceRecurringDurationType"
                  (change)="changeDurationType($event.target.value)"
                  [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode">
                  <option value="0">Indefinido</option>
                  <option value="1">Repeticiones</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Número de repeticiones</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="invoiceRecurring.invoiceRecurringDuration"
                    placeholder="Tiempo indefinido" [disabled]="invoiceRecurring.invoiceRecurringDurationType == 0 || invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                  />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        (
                          invoiceRecurring.invoiceRecurringDuration == '' || 
                          invoiceRecurring.invoiceRecurringDuration == undefined 
                        ) && 
                        invoiceRecurring.invoiceRecurringDurationType == 1
                      ">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Fecha inicio</label>
                <input type="date" class="form-control form-control-sm"
                  [(ngModel)]="invoiceRecurring.invoiceRecurringDateStart" (change)="validateDate($event)"
                  [min]="minDateStart" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode" />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="invoiceRecurring.invoiceRecurringDate"
                  (change)="validateDate($event)" disabled/>
              </div>
              <div class="col-md-3">
                <label>Tipo de documento</label>
                <select class="form-control" [(ngModel)]="invoiceRecurring.invoiceRecurringDocumentSearch"
                  (change)="changeDocumentType($event.target.value)"
                  [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                >
                  <option value="1">NIT</option>
                  <option value="0">DPI</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="invoiceRecurring.invoiceRecurringDocumentSearch == 1">
                <label>NIT</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceRecurringClient.clientTaxNumber"
                    (focusout)="searchClient()" placeholder="NIT" onkeyup="this.value = this.value.toUpperCase();" 
                    [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                  />
                </div>
              </div>
              <div class="col-md-3" *ngIf="invoiceRecurring.invoiceRecurringDocumentSearch == 0">
                <label>DPI</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceRecurringClient.clientIdentificationDocument"
                    (focusout)="searchClient()" placeholder="DPI" 
                    [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label>Nombre</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceRecurringClient.clientName" placeholder="Nombre" 
                    [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label>Correo electrónico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceRecurringClient.clientEmail"
                    placeholder="Correo electrónico" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"/>
                </div>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <select class="form-control" [(ngModel)]="addressSelected"
                  (change)="changeAddress($event.target.value)"
                  [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"
                >
                  <option value="0">Ciudad</option>
                  <option value="1">Ingresar dirección</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="invoiceRecurringClient.clientAddress"
                    placeholder="Dirección" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode || addressSelected == 0"/>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Zona</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="invoiceRecurringClient.clientAddressZone"
                    placeholder="Zona" min="0" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode"/>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Departamento</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientDepartment']" *ngIf="list['clientDepartment']"
                    [(ngModel)]="selected['clientDepartment']" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'clientDepartment')">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Municipio</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientMunicipality']" *ngIf="list['clientMunicipality']"
                    [(ngModel)]="selected['clientMunicipality']" [settings]="dropdownSettings" (onSelect)="
                      onItemSelectClient($event, 'clientMunicipality')
                    ">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">           
              <div class="col-md-3">
                <label>Moneda</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="invoiceRecurring.invoiceRecurringCurrency"
                    (change)="changeCurrency($event.target.value)" [disabled]="invoiceRecurringViewMode || invoiceRecurringUpdateMode">
                    <option value="GTQ">Q - Quetzales</option>
                    <option value="USD">$ - Dolares</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailIsService"
                    (change)="changeDetailType($event.target.value)" [disabled]="invoiceRecurringViewMode">
                    <option value="N">Seleccionar...</option>
                    <option value="B" *ngIf="invoiceRecurringInventory">
                      Bien
                    </option>
                    <option value="SD" *ngIf="invoiceRecurringInventory">
                      Servicio
                    </option>
                    <option value="BD">
                      Bien descripción
                    </option>
                    <option value="S">
                      Servicio descripción
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="invoiceRecurring.invoiceRecurringChangeRate != 1">
                <label>Tasa de cambio</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="invoiceRecurring.invoiceRecurringChangeRate" placeholder="Tasa de cambio" [disabled]="true" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Tipo de pago</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentMethod"
                    (change)="changePaymentMethod($event.target.value)">
                    <option value="1">Efectivo</option>
                    <option value="2">Tarjeta</option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Vendedor</label>
                <div class="form-group">
                  <angular2-multiselect
                    [data]="list['sellerShow']"
                    *ngIf="list['sellerShow']"
                    [(ngModel)]="selected['sellerShow']"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'sellerShow')"
                  >
                  </angular2-multiselect>
                </div>
              </div> -->
              <div class="col-md-3" *ngIf="invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentMethod != 1">
                <label>Numero Autorización</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff" [(ngModel)]="
                      invoiceRecurring.invoiceRecurringPayment.invoiceRecurringPaymentTransferNumber
                    " placeholder="Numero Autorización" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="invoiceRecurringAddItemTypeMode == 'B' || invoiceRecurringAddItemTypeMode == 'SD'">
                <label>Productos</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettingsProduct" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="invoiceRecurringAddItemTypeMode == 'S' || invoiceRecurringAddItemTypeMode == 'BD'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailDescription"
                    placeholder="Descripción" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="invoiceRecurringAddItemTypeMode == 'N'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailDescription"
                    placeholder="Descripción" disabled 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>P. Unitario</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailUnitPrice"
                    placeholder="Precio unitario" (blur)="calculate()" min="0" 
                    [disabled]="invoiceRecurringViewMode"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailUnitPrice == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailQuantity"
                    placeholder="Cantidad" (blur)="calculate()" min="0" 
                    [disabled]="invoiceRecurringViewMode"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailQuantity == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Descuento</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailDiscount"
                    placeholder="Descuento" (blur)="calculate()" min="0" 
                    [disabled]="invoiceRecurringViewMode"
                  />
                </div>
              </div>
              <div class="col-md-1">
                <label>Subtotal</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailSubtotal"
                    placeholder="Subtotal" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailSubtotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Total</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="invoiceRecurringDetail.invoiceRecurringDetailTotal"
                    placeholder="Total" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="invoiceRecurringDetail.invoiceRecurringDetailTotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="insertItem()"
                    [disabled]="invoiceRecurringViewMode"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="invoiceRecurring.invoiceRecurringDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="250" [rows]="invoiceRecurring.invoiceRecurringDetail"
              (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" *ngIf="!invoiceRecurringViewMode" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!invoiceRecurringViewMode" name="Descripción">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="
                    changeDescription($event, 'invoiceRecurringDetailDescription', rowIndex)
                    " type="text" [value]="row.invoiceRecurringDetailDescription || 0" 
                    [disabled]="invoiceRecurringViewMode"
                  />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="invoiceRecurringViewMode" name="Descripción">
                <ng-template ngx-datatable-header-template>
                  <span>Descripción</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailDescription}}
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column
                name="Descripción"
                prop="invoiceRecurringDetailDescription"
              ></ngx-datatable-column> -->
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descuento">
                <ng-template ngx-datatable-header-template>
                  <span>Descuento</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailDiscount | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Subtotal">
                <ng-template ngx-datatable-header-template>
                  <span>Subtotal</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailSubtotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.invoiceRecurringDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total: {{ invoiceRecurring.invoiceRecurringTotal | number : "1.2-2" }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>