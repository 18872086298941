import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit {
  loading = false;

  @Input() viewPermission: boolean;
  @Input() deletePermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() creditNoteAction = new EventEmitter<any>();

  reportStartDate;
  reportEndDate;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();
  company = {} as Company;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private companyService: CompanyService,
    private invoiceService: InvoiceService,
    private exportService: ExportService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'invoiceReportDate'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    const companys = (await this.getCompany(user.userCompany)) as Company[];
    this.company = companys[0];
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExport() {
    this.exportService.xlsxExport(
      this.reportData,
      this.tableHeaders,
      'Reporte',
      'Reporte de facturas comerciales'
    );
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async createCreditNote(event) {
    event.preventDefault();
    this.creditNoteAction.emit(this.activeRow);
  }

  async view(event) {
    event.preventDefault();
    window.open(
      environment.infilePdfUrl + this.activeRow.invoiceAuthorization,
      'popup',
      'width=600,height=600'
    );
  }

  async delete(event) {
    event.preventDefault();

    swal
      .fire({
        title: '¿Cuál es el motivo de la anulación?',
        html:
          '<div class="form-group">' +
          '<input id="input-field" type="text" class="form-control" />' +
          '</div>',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then(async (result) => {
        const reasonForCancellation = (
          document.getElementById('input-field') as HTMLInputElement
        ).value;
        if (result.value) {
          swal.fire({
            title: 'Éxito!',
            text: 'Anulación enviada.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          });
          await this.cancelInvoice(reasonForCancellation);
        }
      });
  }

  async cancelInvoice(reasonForCancellation) {
    const invoice = this.activeRow;
    invoice.invoiceComment = reasonForCancellation;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let response;
    this.loading = true;
    response = await this.invoiceService.invoiceDelete(invoice);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.getReport();
    }
    this.loading = false;
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.invoiceService
      .invoiceGetDate(user.userStore, this.reportStartDate, this.reportEndDate)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
