import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-sale-recurring-manager',
  templateUrl: './sale-recurring-manager.component.html',
  styleUrls: ['./sale-recurring-manager.component.scss'],
})
export class SaleRecurringManagerComponent implements OnInit {
  loading = false;
  headerActionType: string;

  @Input() cancelSaleRecurringPermission: boolean;
  @Output() cancelSaleRecurringAction = new EventEmitter<any>();

  receiptRecurringGenerateMode: boolean = false;
  receiptRecurringListMode: boolean = false;

  invoiceRecurringGenerateMode: boolean = false;
  invoiceRecurringListMode: boolean = false;

  createPermission: boolean = false;
  readPermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;
  updatePermission: boolean = false;

  store;
  company;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.titleService.emitChange('Ventas recurrentes');
  }

  async ngOnInit() {   
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async receiptRecurringGenerate() {
    this.receiptRecurringGenerateMode = true;
    this.headerActionType = 'Generar recibo recurrente';
  }

  async receiptRecurringList() {
    this.receiptRecurringListMode = true;
  }

  async invoiceRecurringGenerate() {
    this.invoiceRecurringGenerateMode = true;
    this.headerActionType = 'Generar factura comercial recurrente';
  }

  async invoiceRecurringList() {
    this.invoiceRecurringListMode = true;
  }

  async cancelAction() {
    this.receiptRecurringGenerateMode = false;
    this.receiptRecurringListMode = false;

    this.invoiceRecurringGenerateMode = false;
    this.invoiceRecurringListMode = false;

    this.cancelSaleRecurring();
  }

  async cancelSaleRecurring(){
    this.cancelSaleRecurringAction.emit();
  }
}
