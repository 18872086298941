<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body"
            *ngIf="!inventoryViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <div class="row" *ngIf="attributes">
          <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let attribute of attributes">
            <label>{{ attribute.attributeName }}</label>
            <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'text'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="inventory[attribute.attributeBusinessCode]"
                  maxlength="{{ attribute.attributeMaxlength }}" [readonly]="inventoryViewMode || attribute.attributeOrder == 1 && inventoryUpdateMode|| attribute.attributeOrder == 2 && inventoryUpdateMode">
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && inventory[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
              </div>
              <div *ngSwitchCase="'email'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="inventory[attribute.attributeBusinessCode]"
                  maxlength="{{ attribute.attributeMaxlength }}" [readonly]="inventoryViewMode">
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && inventory[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
              </div>
              <div *ngSwitchCase="'number'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="inventory[attribute.attributeBusinessCode]"
                  maxlength="{{ attribute.attributeMaxlength }}" [readonly]="inventoryViewMode">
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && inventory[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
              </div>
              <div *ngSwitchCase="'list'">
                <angular2-multiselect [data]="list[attribute.permissionPath]" *ngIf="list[attribute.permissionPath]"
                  [(ngModel)]="inventory[attribute.permissionMaxlength]" [settings]="dropdownSettings">
                </angular2-multiselect>
                <div *ngIf="alert">
                  <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && inventory[attribute.permissionMaxlength] == null">Campo obligatorio.</label>
                </div>
              </div>
              <div *ngSwitchCase="'date'">
                <input type="date" class="form-control" [(ngModel)]="inventory[attribute.permissionPath]"
                  [readonly]="inventoryViewMode" />
              </div>
              <div *ngSwitchCase="'select'">
                <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                  *ngIf="list[attribute.attributeBusinessCode]" [(ngModel)]="selected[attribute.attributeBusinessCode]"
                  [settings]="dropdownSettings" (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)"
                  disabled="!companyViewMode">
                </angular2-multiselect>
                <div *ngIf="alert">
                  <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && selected[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                </div>
              </div>
              <div *ngSwitchCase="'multiSelect'">
                <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                  *ngIf="list[attribute.attributeBusinessCode]" [(ngModel)]="selected[attribute.attributeBusinessCode]"
                  [settings]="dropdownSettingsMultipleSelect" (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)"
                  disabled="!companyViewMode">
                </angular2-multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
