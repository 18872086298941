<app-loading *ngIf="loading"></app-loading>
<nav class="navbar navbar-expand-lg panel-header panel-header-sm">
  <div class="container-fluid pb-2">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand">{{ title }}</a>
    </div>
    <div class="collapse navbar-collapse justify-content-end">
      <div
      class="input-group no-border"
      style="min-width: 20%; max-width: 35%"
      *ngIf="companyList.length > 0"
      >
        <angular2-multiselect
          [data]="companyList"
          [(ngModel)]="companySelectedItem"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelectCompany($event)"
        >
        </angular2-multiselect>
      </div>
      <div
      class="input-group no-border"
      style="min-width: 20%; max-width: 35%"
      *ngIf="storeList.length > 0"
      >
        <angular2-multiselect
          [data]="storeList"
          [(ngModel)]="storeSelectedItem"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelectStore($event)"
        >
        </angular2-multiselect>
      </div>
    </div>
  </div>
</nav>
