import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  constructor(
    private _http: HttpClient,
    private userService: UserService,
  ) {}

  async rolePermissionGetUserCognito(cognito): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-rolePermission/cognito/' + cognito + queryParam,
        { headers }
      )
      .toPromise();
  }  
}
