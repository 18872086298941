<app-loading *ngIf="loading"></app-loading>
<div 
  class="row"
  *ngIf="!clientVoucherMode"
>
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <label>Cliente:</label>
            <div>
              <input type="text" class="form-control pa" [(ngModel)]="client.clientName" disabled />
            </div>
          </div>
          <div class="col-md-3">
            <label>Monto de crédito:</label>
            <div>
              <input type="text" class="form-control pa" [(ngModel)]="client.clientCreditAmount" disabled />
            </div>
          </div>
          <div class="col-md-3">
            <label>Días de crédito:</label>
            <div>
              <input type="text" class="form-control pa" [(ngModel)]="client.clientCreditTime" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                    (change)="entriesChange($event)">
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input type="search" class="form-control form-control-md" placeholder="Buscar"
                    aria-controls="datatable" (keyup)="filterTable($event)" />
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable *ngIf="tableHeaders.length > 0" class="bootstrap selection-cell" [columnMode]="'force'"
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined"
            [rows]="salesTotalFilter" [scrollbarH]="true" (activate)="onActivate($event)">
            
            <ngx-datatable-column name="Acciones">
              <ng-template ngx-datatable-header-template>
                <span>Acciones</span>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-row="row"
                let-onCheckboxChangeFn="onCheckboxChangeFn">
                <a href="#" class="btn btn-default btn-icon btn-sm btn-simple like"
                  (click)="viewSale($event)" ngbTooltip="Ver venta" container="body" style="margin-right: 2px;"><i class="fas fa-eye"></i></a>

                <a href="#" *ngIf="row.countSaleImages > 0" class="btn btn-dark btn-icon btn-sm btn-simple add" 
                  (click)="viewVoucher($event)" ngbTooltip="Ver comprobante" container="body" style="display: inline-flex; align-items: center; padding: 0; line-height: normal; border: 1px solid #277671;">
                  <img src="assets/img/sale.png" alt="" style="width: 20px; height: 20px; margin: auto; display: block;">
                </a>                 
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="No.">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                <span>{{ rowIndex + 1 }}</span>
              </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column *ngFor="let tableHeader of tableHeaders" [name]="tableHeader.name">
              <ng-template ngx-datatable-header-template>
                <span>{{ tableHeader.name }}</span>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" [ngSwitch]="tableHeader.pipe">               
                <span *ngSwitchCase="'text'">
                  {{ row[tableHeader.prop] }}
                </span>
                <span *ngSwitchCase="'date'">
                  <span *ngIf="isValidDate(row[tableHeader.prop]); else invalidDate">
                    {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                  </span>
                  <ng-template #invalidDate>
                    {{ row[tableHeader.prop] }}
                  </ng-template>
                </span>               
                <span *ngSwitchCase="'hour'">
                  {{ row[tableHeader.prop].slice(0, 5) }}
                </span>
                <span *ngSwitchCase="'currency'">
                  {{ row[tableHeader.prop] | number : "1.2-2" }}
                </span>
                <span *ngSwitchCase="'status'">
                  <span class="status-circle" [ngClass]="{
                    'status-vencido': row[tableHeader.prop] === 'Vencido',
                    'status-proximo': row[tableHeader.prop] === 'Próximo a vencer',
                    'status-curso': row[tableHeader.prop] === 'En curso',
                    'status-pagado': row[tableHeader.prop] === 'Pagado'
                  }">
                    {{ row[tableHeader.prop] }}
                  </span>
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="card">
          <div class="card-body" style="background: #424242; color: #ededed">
            <div class="row">
              <div class="col-lg-3" style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              ">
                Total ventas:
                {{ salesTotalHeader.totalSales | number : "1.2-2" }}
              </div>
              <div class="col-lg-3" style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              ">
                Total pagos:
                {{ salesTotalHeader.totalPaymentMade | number : "1.2-2" }}
              </div>
              <div class="col-lg-3" style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              ">
                Total pendiente de pago:
                {{ salesTotalHeader.totalBalance | number : "1.2-2" }}
              </div>
              <div class="col-lg-3" style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              ">
                Crédito disponible:
                {{ salesTotalHeader.totalCreditAmount | number : "1.2-2" }}
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</div>

<app-client-voucher *ngIf="clientVoucherMode"
  [client]="client" [sales]="activeRow" 
  (cancelImageAction)="cancelImageAction($event)">
</app-client-voucher>