import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-production-maintenance-toolbox',
  templateUrl: './production-maintenance-toolbox.component.html',
  styleUrls: ['./production-maintenance-toolbox.component.scss'],
})
export class ProductionMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {}

  async createProduction() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshProduction() {
    window.location.reload();
  }

  async cancel() {
    this.router.navigate(['inventoryMaintenance']);
  }
}
