<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body"
            *ngIf="!providerViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <form #form="ngForm">
        <div class="card-body">
          <div class="row" *ngIf="attributes">
            <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let attribute of attributes">
              <label>{{ attribute.attributeName }}</label>
              <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
              <div class="form-group" [ngSwitch]="attribute.attributeType">
                <div *ngSwitchCase="'text'">
                  <input type="text" class="{{ attribute.attributeClass }}"
                    name="name_{{attribute.attributeBusinessCode}}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [required]="attribute.attributeRequired == 1"
                    [(ngModel)]="provider[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}" [readonly]="providerViewMode || attribute.attributeUpdateReadonly == 1">
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && provider[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                </div>
                <div *ngSwitchCase="'email'">
                  <input type="text" class="form-control" 
                    name="name_{{attribute.attributeBusinessCode}}"
                    autocomplete="off" 
                    placeholder="{{ attribute.permissionAB }}"
                    [required]="attribute.attributeRequired == 1"
                    [(ngModel)]="provider[attribute.permissionPath]" maxlength="{{ attribute.permissionMaxlength }}"
                    required="{{ attribute.permissionRequired }}" [readonly]="((providerViewMode == true || providerUpdateMode == true) && 
                  (attribute.permissionPath == 'providerEmail'))" [readonly]="providerViewMode || attribute.attributeUpdateReadonly == 1">
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && provider[attribute.permissionPath] == null">Campo obligatorio.</label>
                  </div>
                </div>
                <div *ngSwitchCase="'number'">
                  <input type="number" class="form-control" 
                    name="name_{{attribute.attributeBusinessCode}}"
                    autocomplete="off"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [required]="attribute.attributeRequired == 1"
                    [(ngModel)]="provider[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.permissionMaxlength }}" required="{{ attribute.permissionRequired }}"
                    [readonly]="providerViewMode || attribute.attributeUpdateReadonly == 1">
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && provider[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                </div>
                <div *ngSwitchCase="'list'">
                  <angular2-multiselect [data]="list[attribute.permissionPath]" 
                    *ngIf="list[attribute.permissionPath]" name="name_{{attribute.attributeBusinessCode}}"
                    [(ngModel)]="provider[attribute.permissionMaxlength]" [settings]="dropdownSettings"
                    [readonly]="attribute.attributeUpdateReadonly == 1">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && provider[attribute.permissionMaxlength] == null">Campo obligatorio.</label>
                  </div>
                </div>
                <div *ngSwitchCase="'date'">
                  <input type="date" class="form-control" name="name_{{attribute.attributeBusinessCode}}"
                    [(ngModel)]="provider[attribute.permissionPath]"
                    [readonly]="providerViewMode || attribute.attributeUpdateReadonly == 1" />
                </div>
                <div *ngSwitchCase="'select'">

                  <div *ngSwitchCase="'select'">
                    <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                      *ngIf="list[attribute.attributeBusinessCode]"
                      name="name_{{attribute.attributeBusinessCode}}"
                      [(ngModel)]="selected[attribute.attributeBusinessCode]" [settings]="dropdownSettings"
                      (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)"
                      [readonly]="attribute.attributeUpdateReadonly == 1">
                    </angular2-multiselect>
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && selected[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                  </div>

                </div>

                <div *ngSwitchCase="'boolean'">
                  <bSwitch [switch-on-color]="'primary'" [(ngModel)]="provider[attribute.permissionPath]"
                    [disabled]="providerViewMode" switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                    switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                  </bSwitch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>