
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {
  currentDate = new Date();
  loading = false;
  user = {} as User;
  code;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.changeBackground();
  }

  ngOnDestroy() {
    const $page = document.getElementsByClassName("full-page")[0];
    $page.classList.remove("login-page");
  }

  async changeBackground() {
    const $page = document.getElementsByClassName("full-page")[0];
    const image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage = "url(assets/img/background.jpg)";
    $page.appendChild(image_container);
    $page.classList.add("login-page");
  }

  returnLogin() {
    this.router.navigate(['userLogin']);
  }

  async forgotPassword() {
    this.userService.forgotPassword(this.user.userEmail);
  }

}