


<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body" *ngIf="!roleViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <form #form="ngForm">
          <div class="row" *ngIf="attributes">
            <div class="col-md-3" *ngFor="let attribute of attributes">
              <label>{{ attribute.attributeName }}</label>
              <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
              <div class="form-group" [ngSwitch]="attribute.attributeType">
                <div *ngSwitchCase="'text'">
                  <input type="text" class="{{ attribute.attributeClass }}"
                    name="name_{{attribute.attributeBusinessCode}}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [required]="attribute.attributeRequired == 1"
                    [(ngModel)]="role[attribute.attributeBusinessCode]" maxlength="{{ attribute.attributeMaxlength }}"
                    disabled="{{roleViewMode}}"
                  >
                  <div *ngIf="alert">
                    <label style="color: red;"
                      *ngIf="attribute.attributeRequired == 1 && role[attribute.attributeBusinessCode] == null">
                      Campo obligatorio.
                    </label>
                  </div>                    
                </div>         
                <div *ngSwitchCase="'select'">
                  <angular2-multiselect
                    [data]="list[attribute.attributeBusinessCode]"
                    name="name_{{ attribute.attributeBusinessCode }}"
                    *ngIf="list[attribute.attributeBusinessCode]"
                    [(ngModel)]="selected[attribute.attributeBusinessCode]"
                    [settings]="dropdownSettings"
                    (onSelect)="
                      onItemSelect($event, attribute.attributeBusinessCode)
                    "
                    [disabled]="!roleViewMode"
                  >                  
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;"
                      *ngIf="attribute.attributeRequired == 1 && selected[attribute.attributeBusinessCode] == null">
                      Campo obligatorio.</label>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </form>      
        <hr>
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="form-group">
              <ngx-treeview [config]="config" [items]="items" (filterChange)="onFilterChange($event)"
              (selectedChange)="selectionChange($event)">
            </ngx-treeview>
            </div>
          </div>
        </div>
        
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
