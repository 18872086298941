<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" title="Cancelar">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" title="Guardar"
            *ngIf="!categoryViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <div class="row" *ngIf="attributes">
          <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let attribute of attributes">
            <label>{{ attribute.attributeName }}</label>
            <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'text'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="category[attribute.attributeBusinessCode]" maxlength="{{ attribute.attributeMaxlength }}">
              </div>
              <div *ngSwitchCase="'email'">
                <input type="text" class="form-control" autocomplete="off" placeholder="{{ attribute.permissionAB }}"
                  [(ngModel)]="category[attribute.permissionPath]" maxlength="{{ attribute.permissionMaxlength }}"
                  required="{{ attribute.permissionRequired }}" [readonly]="((categoryViewMode == true || categoryUpdateMode == true) && 
                  (attribute.permissionPath == 'categoryEmail'))">
              </div>
              <div *ngSwitchCase="'number'">
                <input type="number" class="form-control" autocomplete="off" placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="category[attribute.permissionPath]" maxlength="{{ attribute.permissionMaxlength }}"
                  required="{{ attribute.permissionRequired }}">
              </div>
              <div *ngSwitchCase="'list'">
                <angular2-multiselect [data]="list[attribute.permissionPath]" *ngIf="list[attribute.permissionPath]"
                  [(ngModel)]="category[attribute.permissionMaxlength]" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>

              <div *ngSwitchCase="'select'">
                <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                  *ngIf="list[attribute.attributeBusinessCode]" [(ngModel)]="selected[attribute.attributeBusinessCode]"
                  [settings]="dropdownSettings" (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)">
                </angular2-multiselect>
              </div>
              <div *ngSwitchCase="'date'">
                <input type="date" class="form-control" [(ngModel)]="category[attribute.permissionPath]"
                  [readonly]="categoryViewMode" />
              </div>
              <div *ngSwitchCase="'boolean'">
                <bSwitch [switch-on-color]="'primary'" [(ngModel)]="category[attribute.permissionPath]"
                  [disabled]="categoryViewMode" switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                  switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                </bSwitch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
