import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Attribute } from 'src/app/models/attribute';
import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';
import { Role } from 'src/app/models/role';
import { AttributeService } from 'src/app/services/attribute.service';
import { RoleService } from 'src/app/services/role.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { TreeviewItem, TreeviewConfig, TreeItem } from 'ngx-treeview';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-role-permission-maintenance-fields',
  templateUrl: './role-permission-maintenance-fields.component.html',
  styleUrls: ['./role-permission-maintenance-fields.component.scss']
})

export class RolePermissionMaintenanceFieldsComponent implements OnInit {

  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() roleViewMode: boolean = false;
  @Input() roleUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() role: Role = {} as Role;

  attributes: Attribute[] = new Array<Attribute>();

  dropdownSettings = {};

  list = {};
  selected = {};
  alert = false;

  loading = false;

  items: TreeviewItem[];
  values: any[];
  permissions;
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });

  page = 1;
  entrys = environment.tableEntrys;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private roleService: RoleService,
    private rolePermissionService: RolePermissionService,
    private tableHeader: TableHeaderService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'roleMaintenance'
    );
    const statusList = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'roleStatus');
    this.permissions = await this.getRoleUserCognito(
      user.userCognitoCode
    );

    this.items = await this.permissionsTransformToTreeView(this.permissions);

    if (this.roleViewMode) {      
      this.fntDropdownSettings(true);
    } else {
      this.fntDropdownSettings(false);
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(list, id, name);
    if (this.role[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] = await this.sharedUtils.selectFromList(this.list[attributeBusinessCode], this.role[attributeBusinessCode]);
    }
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    this.role[attributeBusinessCode] = item.id;
  }

  async permissionsTransformToTreeView(permissions): Promise<TreeviewItem[]> {
    const assignedPermissions = this.role.rolePermissions?.map(rp => rp.permissionCode) || [];

    return permissions.map(permission => {
      const subItems = permission.children?.map(child => {
        const actionItems = child.children?.map(action => {
          const isChecked = assignedPermissions.includes(action.permission.toString());
          return new TreeviewItem({
            text: action.title,
            value: {
              permissionCode: action.permission,
              subMenuCode: child.permission,
              menuCode: permission.permission
            },
            checked: isChecked,
            children: null
          });
        }) || [];

        const isSubmenuChecked = actionItems.some(item => item.checked);
        const isChecked = assignedPermissions.includes(child.permission.toString());

        return new TreeviewItem({
          text: child.title,
          value: {
            permissionCode: child.permission,
            subMenuCode: child.permission,
            menuCode: permission.permission
          },
          checked: isChecked || isSubmenuChecked,
          children: actionItems.length > 0 ? actionItems : null
        });
      }) || [];

      const isMenuChecked = subItems.some(item => item.checked);
      const isChecked = assignedPermissions.includes(permission.permission.toString());

      return new TreeviewItem({
        text: permission.title,
        value: {
          permissionCode: permission.permission,
          subMenuCode: permission.permission,
          menuCode: permission.permission
        },
        checked: isChecked || isMenuChecked,
        children: subItems.length > 0 ? subItems : null
      });
    });
  }

  onFilterChange(value: string): void { }

  selectionChange(value) {
    this.values = value;
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        user;
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getRoleUserCognito(userCode) {
    this.loading = true;
    return this.rolePermissionService.rolePermissionGetUserCognito(userCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async transformSaveData() {
    const rolePermissions = [];
    if (this.values) {
      for (const value of this.values) {
        ['permissionCode', 'subMenuCode', 'menuCode'].forEach(key => {
          if (!rolePermissions.some(permission => permission.permissionCode === value[key])) {
            rolePermissions.push({ permissionCode: value[key] });
          }
        });
      }
    }
    return rolePermissions;
  }  

  async save() {
    this.role.rolePermissions = await this.transformSaveData();

    this.alert = true;
    if (this.form.valid) {    
      this.saveAction.emit(this.role);    
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');    
    }
  }

  
  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

}
