<app-loading *ngIf="loading"></app-loading>
<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container">
    <div class="navbar-wrapper">
      <a class="navbar-brand" routerLinkActive="active" [routerLink]="['#']">ImFel</a>
    </div>
  </div>
</nav>
<div class="wrapper wrapper-full-page">
  <div class="full-page section-image" data-image="./assets/img/background.jpg">
    <div class="content">
      <div class="container">
        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
          <div class="form">
            <div class="card card-login">
              <div class="card-header">
                <div class="card-header">
                  <h3 class="header text-center">Recuperar clave</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="nc-icon nc-single-02"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Correo electrónico"
                    [(ngModel)]="user.userEmail"
                  />
                </div>
                <br />
              </div>
              <div class="card-footer">
                <button
                  (click)="forgotPassword()"
                  class="btn btn-info btn-round btn-block mb-3"
                >
                  Recuperar
                </button>
                <div class="pull-left">
                  <h6 (click)="returnLogin()">
                    <span class="link footer-link">Regresar</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer footer-black footer-white">
      <div class="container">
        <div class="row">
          <nav class="footer-nav"></nav>
          <div class="credits ml-auto">
            <span class="copyright">
              &copy; {{ currentDate | date : "yyyy" }}, Powered by IMA using AWS
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
