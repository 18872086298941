<app-loading *ngIf="loading"></app-loading>
<button class="btn btn-sm btn-success btn-simple" *ngIf="createPermission" (click)="createRolePermission()" ngbTooltip="Crear" container="body">
  <i class="fas fa-plus"></i>
</button>
<button class="btn btn-sm btn-default btn-simple" (click)="refreshRolePermission()" ngbTooltip="Refrescar" container="body">
  <i class="fas fa-sync-alt"></i>
</button>
<button class="btn btn-sm btn-default btn-simple" *ngIf="xlsxExportPermission" (click)="xlsxExport()"
  ngbTooltip="Exportar XLSX" container="body">
  <i class="fas fa-file-excel" style="color: green;"></i>
</button>
