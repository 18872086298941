import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Password } from 'src/app/models/password';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
  providers: [UserService]
})
export class UserChangePasswordComponent implements OnInit {
  loading;
  public user = {} as User;
  public password = {} as Password;
  public isDisabled: boolean;
  public show: boolean;
  public showTwo: boolean;
  public showConfirm: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.emitChange("Cambiar clave");
    // retorno de información del local storage
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    // variables para mostrar
    this.user.userEmail = _user.userRole ? _user.userEmail : null;
    this.user.userName = _user.userRole ? _user.userName : null;
    this.user.userCognitoCode = _user.userCognitoCode ? _user.userCognitoCode : null;
    this.isDisabled = true;
  }

  changeText() {
    let elemento: any = document.getElementById('pass');

    if (this.show) {
      this.show = false;
      elemento.type = "password";
    }
    else {
      this.show = true;
      elemento.type = "text";
    }
  }

  changeTextTwo() {
    let elemento: any = document.getElementById('passTwo');

    if (this.showTwo) {
      this.showTwo = false;
      elemento.type = "password";
    }
    else {
      this.showTwo = true;
      elemento.type = "text";
    }
  }

  changeTextConfirm() {
    let elemento: any = document.getElementById('passConfirm');

    if (this.showConfirm) {
      this.showConfirm = false;
      elemento.type = "password";
    }
    else {
      this.showConfirm = true;
      elemento.type = "text";
    }
  }

  confirm(val?: string) {
    let passTwo = ((document.getElementById("passTwo") as HTMLInputElement).value);
    let passConfirm = ((document.getElementById("passConfirm") as HTMLInputElement).value);

    if (passTwo === passConfirm) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  changePassword() {

    if (!this.validatePasswordPolicy(this.password.proposedPassword)) {
      return;
    }

    this.loading = true;
    this.isDisabled = true;

    return this.userService.updatePassword(
      this.password.previousPassword,
      this.password.proposedPassword,
      this.user.userCognitoCode).then(
        response => {

          if (response.result == true) {
            this.user.userForceChagePassword = 0;
            this.userService.userUpdate(this.user);
            localStorage.setItem('user', JSON.stringify(this.user));
            localStorage.setItem('userForceChagePassword', '1');
            this.notificationService.showSuccess(response.message);
            this.router.navigate(
              ['home']
            );
          } else {
            this.notificationService.showError('Clave actual incorrecta. Verifica tu clave y vuelve a intentar.');
            this.isDisabled = false;
          }
        }, error => {
          this.notificationService.showError(error);
          this.isDisabled = false;
        }
      ).catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });

  }

  validatePasswordPolicy(password: string): boolean {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-]{8,}$/;

    if (regex.test(password)) {
      return true;
    } else {
      this.notificationService.showError('La clave no cumple con los requisitos necesarios. Verifica y vuelve a intentar.');
      return false;
    }
  }


}
