import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';
import swal from "sweetalert2";
@Component({
  selector: 'app-client-maintenance',
  templateUrl: './client-maintenance.component.html',
  styleUrls: ['./client-maintenance.component.scss']
})
export class ClientMaintenanceComponent implements OnInit {
  loading = false;
  clientList: Client[];
  client: Client;
  tableHeaders: [] = [];
  clientSaleList: [];
  clientQuotationList: [];

  clientSaleOption; 

  headerActionType: string;
  clientCreateMode: boolean = false;
  clientUpdateMode: boolean = false;
  clientDeleteMode: boolean = false;
  clientViewMode: boolean = false;
  saleMode: boolean = false;
  quotationMode: boolean = false;
  clientHistoryMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;
  salePermission: boolean = false;
  quotationPermission: boolean = false;
  historyPermission: boolean = false;
  cancelSalePermission: boolean = false;
  cancelQuotationPermission: boolean = false;

  constructor(
    private clientService: ClientService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Mantenimiento"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.clientList = await this.getClients(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          case 'sale':
            this.salePermission = true;
            break;
          case 'quotation':
            this.quotationPermission = true;
            break;
          case 'history':
            this.historyPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getClients(company) {
    this.loading = true;
    return this.clientService.clientGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear cliente';
    this.clientCreateMode = true;
    this.client = {} as Client;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.client.clientCompany = user.userCompany;
    this.client.clientStore = user.userStore;
    this.client.clientCredit = false;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver cliente';
    this.clientViewMode = true;
    this.client = event as Client;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar cliente';
    this.clientUpdateMode = true;
    this.client = event as Client;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar cliente';
    this.clientDeleteMode = true;
    /*  const clientDeleted = {
        clientCode: event.clientCode,
        clientDeleted: 1
      };*/
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(event);
      }
    });
  }

  async cancelAction(event) {
    this.clientCreateMode = false;
    this.clientViewMode = false;
    this.clientUpdateMode = false;
    this.clientDeleteMode = false;
    this.clientHistoryMode = false;
    this.quotationMode = false;
    this.saleMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.clientList = await this.getClients(user.userCompany);
  }

  async saveAction(client) {
    if (client != false) {
      let response;
      this.loading = true;
      if (this.clientCreateMode == true) {
        response = await this.clientService.clientInsert(client);
      }
      if (this.clientUpdateMode == true) {
        client['clientModifyDate'] = new Date();
        response = await this.clientService.clientUpdate(client);
      }
      if (this.clientDeleteMode == true) {
        client['clientDeletedDate'] = new Date();
        response = await this.clientService.clientDelete(client);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(event);

      }
      this.loading = false;
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }
 

  async historyAction(event){
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver resumen';
    this.clientHistoryMode = true;
    this.client = event as Client;
  }

  async quotationAction(event){
    this.quotationMode = true;
    this.cancelQuotationPermission = true;
    this.clientQuotationList = event;
  }

  async saleAction({client, option}){ 
    this.saleMode = true;
    this.cancelSalePermission = true;
    this.clientSaleList = client;
    this.clientSaleOption = option;
  } 

}
