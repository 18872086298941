import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';

export class SharedUtils {
  async transformToList(list, id, itemName): Promise<any> {
    let response = new Array<ListArray>();
    for (const row of list) {
      let listArray: ListArray = {
        id: row[id],
        itemName: row[itemName],
      };
      await response.push(listArray);
    }
    return response;
  }

  async selectFromList(list: Array<ListArray>, id, idList?): Promise<any> {
    if (typeof id == 'object') {
      const listFind = new Array();
      for (const iterator of id) {
        const value = await list.find((x) => x.id == iterator[idList]);
        if (value !== undefined) {
          listFind.push(value);
        }
      }
      return listFind;
    } else {
      const listFind = await list.find((x) => x.id == id);
      return [listFind];
    }
  }

  async transformToTableHeader(list, name, prop, pipe = ''): Promise<any> {
    const response = new Array<TableHeader>();
    for (const row of list) {
      const tableHeader: TableHeader = {
        name: row[name],
        prop: row[prop],
        pipe: row[pipe] || 'text',
      };
      response.push(tableHeader);
    }
    return response;
  }

  async formatDate(date = new Date()) {
    return [
      date.getFullYear(),
      await this.padTo2Digits(date.getMonth() + 1),
      await this.padTo2Digits(date.getDate()),
    ].join('-');
  }

  async formatDateMonth(date = new Date()) {
    return [
      date.getFullYear(),
      await this.padTo2Digits(date.getMonth() + 1)
    ].join('-');
  }

  async padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  async getRegimeSelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'General',
    });
    await response.push({
      id: '3',
      itemName: 'Pequeño contribuyente',
    });
    return response;
  }

  async getTaxToPaySelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '2',
      itemName: '12%',
    });
    await response.push({
      id: '1',
      itemName: '5%',
    });
    return response;
  }

  //Activo/Inactivo
  async getStatusSelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'Activo',
    });
    await response.push({
      id: '0',
      itemName: 'Inactivo',
    });
    return response;
  }

  //Bien/Servicio
  async getServiceSelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'Bien',
    });
    await response.push({
      id: '0',
      itemName: 'Servicio',
    });
    return response;
  }

  //Si/No
  async getBooleanYesNoSelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'SI',
    });
    await response.push({
      id: '0',
      itemName: 'NO',
    });
    return response;
  }

  // Tipos de Cuenta
  async getBankAccountTypes(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'Monetaria',
    });
    await response.push({
      id: '2',
      itemName: 'Ahorro',
    });
    return response;
  }

  //prendas/articulos
  async getStatusSelectP(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'PRENDA',
    });    
    await response.push({
      id: '0',
      itemName: 'ARTICULO',
    });
    await response.push({
      id: '2',
      itemName: 'PRODUCTO',
    });
    return response;
  }

  //TALLA/TAMAÑO
  async getStatusSelectA(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '2',
      itemName: 'MEDIDA',
    });
    await response.push({
      id: '1',
      itemName: 'TALLA',
    });
    await response.push({
      id: '0',
      itemName: 'TAMAÑO',
    });
    return response;
  }

  //clientes
  async getSelect(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'Si',
    });
    await response.push({
      id: '0',
      itemName: 'No',
    });
    return response;
  }

  //cuentas contables
  async getAccountantType(): Promise<any> {
    let response = new Array<ListArray>();
    await response.push({
      id: '1',
      itemName: 'Entrada',
    });
    await response.push({
      id: '0',
      itemName: 'Salida',
    });
    return response;
  }

  async convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
