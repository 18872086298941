import { Component, OnInit } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentRegisterService } from 'src/app/services/payment-register.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/account';
import { ExportService } from 'src/app/services/export.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-account-pay',
  templateUrl: './account-pay.component.html',
  styleUrls: ['./account-pay.component.scss'],
})
export class AccountPayComponent implements OnInit {
  loading = false;

  accountList: Account[];
  account: Account;

  headerActionType: string;
  accountCreateMode: boolean = false;
  accountUpdateMode: boolean = false;
  accountDeleteMode: boolean = false;
  accountViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private accountService: AccountService,
    private paymentRegisterService: PaymentRegisterService,
    private permissionService: PermissionService,
    private router: Router,
    private exportService: ExportService,
    private titleService: TitleService,    
  ) {}

  async ngOnInit(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(user.userRole, user.userCode, 'accountPay'),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    await this.getReport();

    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
    
    this.titleService.emitChange('Contabilidad');
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async refresh() {
    await this.ngOnInit();
  }

  changePayment(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.reportData[rowIndex][cell] = val;
    this.reportData = [...this.reportData];
  }

  async addPay(event) {
    event.preventDefault();
    if (
      this.activeRow.purchasePaymentMethod == null || 
      this.activeRow.purchasePaymentMethod == undefined || 
      this.activeRow.purchasePaymentMethod == ''
    ) {
      this.notificationService.showError('Debes seleccionar un método de pago.');
    } else if (
      this.activeRow.purchasePaymentAmount === null || 
      this.activeRow.purchasePaymentAmount === undefined || 
      this.activeRow.purchasePaymentAmount === ''
    ) {
      this.notificationService.showError('Para registrar un pago debes ingresar un monto.');      
    } else if (    
      this.activeRow.purchasePaymentAmount === 0
    ) {
      this.notificationService.showError('Para registrar un pago el monto no puede ser 0.');      
    } else if (
      this.activeRow.purchasePaymentAmount > this.activeRow.purchaseBalance
    ) {
      this.notificationService.showError('Para registrar un pago el monto debe ser menor o igual al saldo.');      
    } else {
      this.activeRow['paymentRegisterPaymentMethod'] =
      this.activeRow.purchasePaymentMethod;
      this.activeRow['paymentRegisterTypeSale'] = 'Compra';
      this.activeRow['paymentRegisterSale'] = this.activeRow.purchaseCode;
      this.activeRow['paymentRegisterPurchase'] = this.activeRow.purchaseCode;
      this.activeRow['paymentRegisterDescription'] =this.activeRow.purchasePaymentMethod;
      this.activeRow['paymentRegisterAmount'] = this.activeRow.purchasePaymentAmount;
      this.activeRow['paymentRegisterSupport'] = this.activeRow.purchasePaymentVoucher || '';
      this.activeRow['saleCompany'] = this.activeRow.purchaseCompany;

      let response;
      this.loading = true;
      response = await this.paymentRegisterService.paymentRegisterInsert(
        this.activeRow
      );
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        if (this.activeRow.saleType == 'Factura Cambiaria') {
          window.open(response.receiptPdfUrl, 'popup', 'width=600,height=600');
        }
        await this.ngOnInit();
      }
      this.loading = false;
    }    
  }

  showInvoice(event) {
    event.preventDefault();

    window.open(
      this.activeRow['purchaseInvoiceFileUrl'],
      'popup',
      'width=600,height=600'
    );
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = report.map(row => {
      row['purchasePaymentAmount'] = 0;      
      return row;
    });  
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.accountService
      .accountPay(user.userStore)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.reportDataFilter = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async xlsxExportAction() {
    this.exportService.xlsxExport(
      this.reportDataFilter,
      this.tableHeaders,
      'Reporte',
      'Reporte de cuentas por pagar'
    );
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear propietario';
    this.accountCreateMode = true;
    this.account = {} as Account;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.account.accountCompany = user.userCompany;
    this.account.accountStatus = 1;
  }

}
