import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Inventory } from 'src/app/models/inventory';
import { InventoryImage } from 'src/app/models/inventoryImage';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');

@Component({
  selector: 'app-inventory-maintenance-upload-image',
  templateUrl: './inventory-maintenance-upload-image.component.html',
  styleUrls: ['./inventory-maintenance-upload-image.component.scss']
})
export class InventoryMaintenanceUploadImageComponent implements OnInit {
  loading;
  @Input() headerActionType;
  @Input() inventory: Inventory;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;
  inventoryImageList: Array<InventoryImage> = new Array();
  classImageBtn = "btn btn-block btn-round btn-file";

  constructor(
    private sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = await this.getCompany(user.userCompany);
    for (let index = 0; index < company.companyInventoryImage; index++) {
      const inventoryImage = {
        inventoryImageCode: uuid.v4(),
        classImageBtn: this.classImageBtn
      } as InventoryImage;
      this.inventoryImageList.push(inventoryImage);
    }
    // if (this.inventory.inventoryImages != undefined) {
    //   let counter = 0;
    //   for (const inventoryImage of this.inventory.inventoryImages) {
    //     inventoryImage['classImageBtn'] = "btn btn-success btn-block btn-round btn-file";
    //     this.inventoryImageList[counter] = inventoryImage;
    //     counter++;
    //   }
    // }
  }

  async addFile(e, inventoryImageCode) {
    const findeInvantoryImage = this.inventoryImageList.find(x => x.inventoryImageCode == inventoryImageCode);
    const findIndex = this.inventoryImageList.indexOf(findeInvantoryImage);
    this.inventoryImageList[findIndex].inventoryImageFileName = e.target.files[0].name;
    this.inventoryImageList[findIndex].inventoryImageFile = await this.sharedUtils.convertBase64(e.target.files.item(0));
    this.inventoryImageList[findIndex].inventoryImageUrl = undefined;
    this.inventoryImageList[findIndex].classImageBtn = "btn btn-success btn-block btn-round btn-file";

    if (e.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.inventoryImageList[findIndex].inventoryImagePreview = event.target.result;
      }
    }
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.companyGetCompany(companyCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.saveAction.emit(await this.saveImageModel());
  }

  async saveImageModel() {
    // const inventoryImageUpload = {
    //   inventoryCode: this.inventory.inventoryCode,
    //   inventoryImages: new Array()
    // };
    // for (const inventoryImage of this.inventoryImageList) {
    //   if (inventoryImage.inventoryImageFile != undefined)
    //     inventoryImageUpload.inventoryImages.push({
    //       inventoryImageName: inventoryImage.inventoryImageFileName,
    //       inventoryImage: inventoryImage.inventoryImageFile,
    //       inventoryImageS3Key: inventoryImage.inventoryImageS3Key,
    //       inventoryImageCode: inventoryImage.inventoryImageCode
    //     });
    // }
    // return inventoryImageUpload;
  }

}
