import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserForgotPasswordModalComponent } from '../pages/user/user-forgot-password-modal/user-forgot-password-modal.component';
declare var $: any;

const POOL_DATA = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId,
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url: string = environment.apiUrl;
  public userLogged = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private notificationService: NotificationService
  ) {}

  // user login
  async userSignin(userEmail: string, userPassword: string): Promise<void> {
    const authDetails = new AuthenticationDetails({
      Username: userEmail,
      Password: userPassword,
    });
    const cognitoUser = new CognitoUser({
      Username: userEmail,
      Pool: userPool,
    });
    const that = this;
    this.userLogged.next(false);
    await cognitoUser.authenticateUser(authDetails, {
      async onSuccess(result: any) {
        const userLoggedData = {
          userEmail: result.idToken.payload.email,
          userCognitoCode: result.idToken.payload['cognito:username'],
          userName: result.idToken.payload.name,
        };

        await localStorage.setItem('user', JSON.stringify(userLoggedData));
        await that.userConsumeAttempt({
          userEmail: userEmail,
          userAttempt: 'true',
        });
        await that.notificationService.showSuccess(
          '¡Hola, ' + userLoggedData.userName + '!',
          false
        );
        await that.userLogged.next(true);
        await that.router.navigate(['/home']);
      },
      async onFailure(err: any) {
        localStorage.clear();
        const response = await that.userConsumeAttempt({
          userEmail: userEmail,
          userAttempt: 'false',
        });

        // that.notificationService.showError(response['response']);
        switch (err.code) {
          case 'NotAuthorizedException':
            switch (err.message) {
              case 'User is disabled.':
                that.notificationService.showError('Usuario inactivo.');
                break;
              case 'Incorrect username or password.':
                that.notificationService.showError(
                  'Clave incorrecta. Verifica tu clave y vuelve a intentar.'
                );
                break;
              default:
                that.notificationService.showError(err.message);
                break;
            }
            break;
          case 'UserNotFoundException':
            that.notificationService.showError('Usuario no existe. Verifica tu usuario y vuelve a intentar.');
            break;
          case 'UserNotConfirmedException':
            that.notificationService.showError('Usuario no esta confirmado.');
            break;
          default:
            that.notificationService.showError(
              'Usuario o contraseña inválidos!'
            );
            console.error(err);
            break;
        }

        that.userLogged.next(false);
      },
    });
  }

  async userConsumeAttempt(attempt: {
    userEmail: string;
    userAttempt: string;
  }) {
    return await this._http
      .post(environment.apiUrl + 'imfel-user/attempt', attempt)
      .toPromise();
  }

  async oneUserCognitoBackend(userCognitoCode): Promise<any> {
    const filter = userCognitoCode;
    return this._http
      .get(environment.apiUrl + 'imfel-user/cognito/' + filter)
      .toPromise();
  }

  // user logout
  async userLogout(): Promise<void> {
    await Auth.signOut()
      .then(() => {
        this.userLogged.next(false);
      })
      .catch((err) => {
        this.notificationService.showError(err);
      });
    await this.userLogged.next(false);
    await localStorage.clear();
  }

  // user authentication
  async isUserLogged(): Promise<boolean> {
    const userCurrentLogged = userPool.getCurrentUser();
    if (userCurrentLogged == null) {
      return false;
    }
    const userCurrentLoggedSession = await new Promise<CognitoUserSession>(
      async (resolve, reject) => {
        await userCurrentLogged.getSession(async (err, session) => {
          if (err) {
            this.notificationService.showError(err);
            reject(err);
          } else {
            resolve(session);
          }
        });
      }
    );
    return userCurrentLoggedSession.isValid();
  }

  async isUserAuthenticated(): Promise<Observable<boolean>> {
    const isUserLogged = await this.isUserLogged();
    return await new Observable<boolean>((observable) => {
      observable.next(isUserLogged);
      observable.complete();
    });
  }

  async initUserSecurity() {
    const isUserAuthenticated = await this.isUserAuthenticated();
    isUserAuthenticated.subscribe((isLogged) => {
      this.userLogged.next(isLogged);
    });
  }

  async userGetCurrentSession(): Promise<CognitoUserSession> {
    const userCurrentLogged = userPool.getCurrentUser();
    if (userCurrentLogged == null) {
      this.userLogout();
      this.notificationService.showError('Usuario no encontrado');
      return null;
    }
    const userCurrentLoggedSession = await new Promise<CognitoUserSession>(
      async (resolve, reject) => {
        await userCurrentLogged.getSession(async (err, session) => {
          if (err) {
            this.notificationService.showError(err);
            reject(err);
          } else {
            resolve(session);
          }
        });
      }
    );
    return userCurrentLoggedSession;
  }

  // user maintenance
  async userGetCompany(companyCode): Promise<any> {
    const openedSession = await this.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + (await openedSession.getAccessToken().getJwtToken());
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-user/company/' + companyCode + queryParam,
        { headers }
      )
      .toPromise();
  }

  async userGetDelivery(companyCode): Promise<any> {
    const openedSession = await this.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + (await openedSession.getAccessToken().getJwtToken());
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-user/delivery/' + companyCode + queryParam,
        { headers }
      )
      .toPromise();
  }

  async userInsert(user: User): Promise<any> {
    const openedSession = await this.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-user/cognito/', user, { headers })
      .toPromise();
  }

  async userUpdate(user: User): Promise<any> {
    const openedSession = await this.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-user/cognito/', user, { headers })
      .toPromise();
  }

  // user confirm
  async confirm(user): Promise<any> {
    let params = user;
    return this._http.post(this.url + 'imfel-user/confirm', params).toPromise();
  }

  async oneUserEmailBackend(userEmail): Promise<any> {
    const filter = userEmail;
    return this._http
      .get(environment.apiUrl + 'imfel-user/email/' + filter)
      .toPromise();
  }

  // user change password
  async updatePassword(previousPassword, proposedPassword, cognitoCode): Promise<any> {
    const accessToken: any = await this.userGetCurrentSession();
    const params = {
      accessToken: accessToken.accessToken.jwtToken,
      previousPassword: previousPassword,
      proposedPassword: proposedPassword,
      userCognitoCode: cognitoCode
    };
    return this._http
      .post(this.url + 'imfel-user/changePassword', params)
      .toPromise();
  }

  // user forgot password
  async forgotPassword(username: string) {
    const that = this;
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({      
      onSuccess(result) {
        that.notificationService.showSuccess('Clave restablecida con éxito.');
        that.router.navigateByUrl('/userLogin');
      },
      onFailure(err) {
        switch (err.message) {
          case 'Invalid verification code provided, please try again.': 
            that.notificationService.showError('Código incorrecto. Solicita un nuevo código y vuelve a intentar.');           
            break;          
          default:
            that.notificationService.showError('Usuario no existe. Verifica tu usuario y vuelve a intentar.');
            break;
        }        
      },
      inputVerificationCode() {
        that.notificationService.showSuccess(
          'Código para recuperación de clave enviado. Revisa tu correo.',
          false
        );
        const modalReference: NgbModalRef = that.modalService.open(
          UserForgotPasswordModalComponent,
          {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
          }
        );
        modalReference.result
          .then(
            (result) => {
              cognitoUser.confirmPassword(
                result.verificationCode,
                result.newPassword,
                this
              );
            },
            (reject) => {
              that.notificationService.showWarning(reject);
            }
          )
          .catch((err) => {
            that.notificationService.showError(err);
          });
      },
    });
    return true;
  }
  // user resend code
  async resendCode(userEmail) {
    const cognitUser = new CognitoUser({
      Username: userEmail,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }
}
