import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Transfer } from 'src/app/models/transfer';
import { TransferDetail } from 'src/app/models/transferDetail';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-transfer-manager',
  templateUrl: './transfer-manager.component.html',
  styleUrls: ['./transfer-manager.component.scss'],
})
export class TransferManagerComponent implements OnInit {
  loading = false;

  transferGenerateMode: boolean = false;
  transferListMode: boolean = false;
  transferReceiveMode: boolean = false;

  createPermission: boolean = false;
  readPermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;
  authotizePermission: boolean = false;
  unAuthotizePermission: boolean = false;
  receivePermission: boolean = false;
  unReceivePermission: boolean = false;

  transfer = {
    transferDetail: new Array<TransferDetail>(),
  } as Transfer;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.titleService.emitChange('Traslados');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          case 'auth':
            this.authotizePermission = true;
            break;
          case 'unauth':
            this.unAuthotizePermission = true;
            break;
          case 'unreceive':
            this.unReceivePermission = true;
            break;
          case 'receive':
            this.receivePermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  transferGenerate() {
    this.transferGenerateMode = true;
  }

  transferList() {
    this.transferListMode = true;
  }

  async transferReceive(receiveTransfer) {
    this.transfer = { ...receiveTransfer };
    this.transferListMode = false;
    this.transferReceiveMode = true;
  }

  cancelAction() {
    this.transferGenerateMode = false;
    this.transferListMode = false;
    this.transferReceiveMode = false;
  }
}
