<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Generar recibo</h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="receipt.receiptCreationDate"
                  (change)="validateDate($event)" />
              </div>
              <div class="col-md-3" *ngIf="company.companyClient == 1">
                <label>Tipo de identificador</label>
                <select class="form-control" [(ngModel)]="receipt.receiptDocumentSearch"
                  (change)="changeDocumentType($event.target.value)">
                  <option value="2">Teléfono</option>
                  <option value="0">DPI</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="receipt.receiptDocumentSearch == 2 && company.companyClient == 1">
                <label>Teléfono</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientPhone"
                    (focusout)="searchClient()" placeholder="Teléfono" [textMask]="{mask: contactMask}" 
                  />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" 
                      *ngIf="
                        receipt.receiptDocumentSearch == 2 && 
                        company.companyClient == 1 &&
                        (
                          this.receiptClient.clientPhone == '' || 
                          this.receiptClient.clientPhone == undefined
                        )"
                      >Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="receipt.receiptDocumentSearch == 0 && company.companyClient == 1">
                <label>DPI</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientIdentificationDocument"
                    (focusout)="searchClient()" placeholder="DPI" [textMask]="{mask: dpiMask}" 
                  />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" 
                      *ngIf="
                        receipt.receiptDocumentSearch == 0 && 
                        company.companyClient == 1 &&
                        (
                          receiptClient.clientIdentificationDocument == '' || 
                          receiptClient.clientIdentificationDocument == undefined
                        )"
                      >Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Teléfono</label>
                <label style="color: red;" *ngIf="receipt.receiptDocumentSearch == 2 && company.companyClient == 1">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientPhoneOne"
                    placeholder="Teléfono" [textMask]="{mask: phoneMask}" 
                  />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" 
                      *ngIf="
                        receipt.receiptDocumentSearch == 2 && 
                        company.companyClient == 1 &&
                        (
                          this.receiptClient.clientPhoneOne == '' || 
                          this.receiptClient.clientPhoneOne == undefined
                        )"
                      >Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Nombre</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientName" placeholder="Nombre" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="receiptClient.clientName == undefined || receiptClient.clientName == '' ">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Correo electrónico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientEmail"
                    placeholder="Correo electrónico" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <select class="form-control" [(ngModel)]="addressSelected"
                  (change)="changeAddress($event.target.value)">
                  <option value="0">Ciudad</option>
                  <option value="1">Ingresar dirección</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptClient.clientAddress"
                    placeholder="Dirección" [disabled]="addressSelected == 0" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Zona</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="receiptClient.clientAddressZone"
                    placeholder="Zona" min="0" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Departamento</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientDepartment']" *ngIf="list['clientDepartment']"
                    [(ngModel)]="selected['clientDepartment']" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'clientDepartment')">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Municipio</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['clientMunicipality']" *ngIf="list['clientMunicipality']"
                    [(ngModel)]="selected['clientMunicipality']" [settings]="dropdownSettings" (onSelect)="
                      onItemSelectClient($event, 'clientMunicipality')
                    ">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <hr />
            <div class="row" *ngIf="quotationList == undefined">
              <div class="col-md-3">
                <label>Moneda</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="receipt.receiptCurrency"
                    (change)="changeCurrency($event.target.value)">
                    <option value="1">Q - Quetzales</option>
                    <option value="2">$ - Dólares</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailType"
                    (change)="changeDetailType($event.target.value)">
                    <option value="N">Seleccionar...</option>
                    <option value="B" *ngIf="receiptInventory">
                      Bien
                    </option>
                    <option value="SD" *ngIf="receiptInventory">
                      Servicio
                    </option>
                    <option value="BD">
                      Bien descripción
                    </option>
                    <option value="S">
                      Servicio descripción
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="this.receipt.receiptExchangeRate != 1">
                <label>Tasa de cambio</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="receipt.receiptExchangeRate" placeholder="Tasa de cambio" [disabled]="true" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Tipo de pago</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="receipt.receiptPaymentMethod"
                    (change)="changePaymentMethod($event.target.value)">
                    <option value="1">Efectivo</option>
                    <option value="2">Tarjeta</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Vendedor</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['sellerShow']" *ngIf="list['sellerShow']"
                    [(ngModel)]="selected['sellerShow']" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'sellerShow')">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="this.receipt.receiptPaymentMethod != 1">
                <label>Numero Autorización</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="receipt.receiptVoucher" placeholder="Numero Autorización" />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="quotationList == undefined">
              <div class="col-md-6" *ngIf="receiptAddItemTypeMode == 'B' || receiptAddItemTypeMode == 'SD'">
                <label>Productos</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="receiptAddItemTypeMode == 'S' || receiptAddItemTypeMode == 'BD'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailDescription"
                    placeholder="Descripción" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="receiptAddItemTypeMode == 'N'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailDescription"
                    placeholder="Descripción" disabled 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>P. Unitario</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailUnitPrice"
                    placeholder="Precio unitario" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailUnitPrice == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailQuantity"
                    placeholder="Cantidad" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailQuantity == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Descuento</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailDiscount"
                    placeholder="Descuento" (blur)="calculate()" min="0" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Subtotal</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailSubtotal"
                    placeholder="Subtotal" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailSubtotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Total</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="receiptDetail.receiptDetailTotal"
                    placeholder="Total" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptDetail.receiptDetailTotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="insertItem()">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="receipt.receiptDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="250" [rows]="receipt.receiptDetail"
              (activate)="onActivate($event)">
              <ngx-datatable-column *ngIf="quotationList == undefined" [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="quotationList == undefined" name="Descripción">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="
                    changeDescription($event, 'receiptDetailDescription', rowIndex)
                    " type="text" [value]="row.receiptDetailDescription || 0" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column 
                *ngIf="quotationList != undefined" 
                name="Descripción" 
                prop="receiptDetailDescription">
              </ngx-datatable-column>
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptDetailQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descuento">
                <ng-template ngx-datatable-header-template>
                  <span>Descuento</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptDetailDiscount | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Subtotal">
                <ng-template ngx-datatable-header-template>
                  <span>Subtotal</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptDetailSubtotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total: {{ receipt.receiptTotal | number : "1.2-2" }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>