<app-loading *ngIf="loading"></app-loading>
<div class="sidebar">
  <div class="logo">
    <a class="simple-text logo-mini">
      <!-- <div class="logo-image-small">
      </div>
       <img src="./assets/img/logo.png" /> -->
    </a>
    <a routerLink="/" class="simple-text logo-normal"> IM FEL </a>
    <div class="navbar-minimize">
      <button
        id="minimizeSidebar"
        class="btn btn-simple btn-icon btn-neutral btn-round"
        (click)="minimizeSidebar()"
      >
        <i
          class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
        ></i>
        <i
          class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
        ></i>
      </button>
    </div>
  </div>
  <div class="sidebar-wrapper">
    <div class="user">
      <div class="photo"><img src="../assets/img/face.jpg" /></div>
      <div class="info">
        <a
          data-toggle="collapse"
          href="#collapseProfile"
          (click)="toogleCollapseProfile($event, [])"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseProfile"
        >
          <span>
            <b> {{ fullNameArray[0] + " " }} </b>
            <b> {{ fullNameArray[1] }} </b>
            <br />
            <b *ngIf="fullNameArray[2]"> {{ fullNameArray[2] + " " }} </b>
            <b *ngIf="fullNameArray[3]"> {{ fullNameArray[3] }} </b>
            <b class="caret"></b>
          </span>
        </a>
        <div class="collapse" id="collapseProfile">
          <ul class="nav">
            <li>
              <a routerLink="/userChangePassword">
                <span class="sidebar-mini-icon">CC</span>
                <span class="sidebar-normal">Cambiar clave</span>
              </a>
            </li>
            <li>
              <a (click)="logOut()">
                <span class="sidebar-mini-icon">CS</span>
                <span class="sidebar-normal">Cerrar sesión</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ul class="nav" *ngIf="menuItems">
      <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
        <!--If is a single link-->
        <a
          [routerLink]="[menuitem.path]"
          (click)="goToRoute(menuitem.path)"
          *ngIf="menuitem.type === 'link'"
        >
          <div class="row">
            <div class="col-2">
              <i class="{{ menuitem.icontype }}"></i>
            </div>
            <div class="col-10">
              <p>
                {{ menuitem.title }}
              </p>
            </div>
          </div>
        </a>
        <!--If it have a submenu-->
        <a
          data-toggle="collapse"
          href="#{{ menuitem.collapse }}"
          *ngIf="menuitem.type === 'sub'"
          (click)="
            toogleCollapseMenu($event, menuitem);
            menuitem.isCollapsing === undefined &&
              (menuitem.isCollapsed = !menuitem.isCollapsed)
          "
          [attr.aria-expanded]="!isCollapsedMenu"
        >
          <div class="row">
            <div class="col-2">
              <i class="{{ menuitem.icontype }}"></i>
            </div>
            <div class="col-10">
              <p>
                {{ menuitem.title }}
                <b class="caret"></b>
              </p>
            </div>
          </div>
        </a>

        <!--Display the submenu items-->
        <div
          id="{{ menuitem.collapse }}"
          class="collapse"
          *ngIf="menuitem.type === 'sub'"
          [ngbCollapse]="menuitem.isCollapsedMenu"
        >
          <ul class="nav">
            <li
              routerLinkActive="active"
              *ngFor="let childitem of menuitem.children"
            >
              <a
                [routerLink]="[childitem.path]"
                (click)="goToRoute(childitem.path)"
              >
                <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
                <span class="sidebar-normal">{{ childitem.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
