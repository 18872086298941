import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // client maintenance
  async clientGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-client/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async clientGetTaxNumber(clientCompany, clientTaxNumber): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-client/clientTaxNumber/' +
          clientTaxNumber +
          '/' +
          clientCompany +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async clientGetCode(one): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-client/one/' + 
        one + 
        '/' +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async clientGetIdentificationDocument(
    clientCompany,
    clientTaxNumber
  ): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-client/clientIdentificationDocument/' +
          clientCompany +
          '/' +
          clientTaxNumber +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async clientGetPhone(clientCompany, clientPhone): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-client/clientPhone/' +
          clientCompany +
          '/' +
          clientPhone +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async clientInsert(client: Client) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-client/', client, { headers })
      .toPromise();
  }

  async clientUpdate(client: Client) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-client/', client, { headers })
      .toPromise();
  }

  async clientUpdateSale(client: Client) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-client-sale/', client, { headers })
      .toPromise();
  }

  async clientDelete(client: Client) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-client/delete/', client, { headers })
      .toPromise();
  }
  
  async clientGetSummary(clientCode): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-client/summary/' + 
        clientCode + 
        '/' +
          queryParam,
        { headers }
      )
      .toPromise();
  }
}
