<app-loading *ngIf="loading"></app-loading>
<div class="wrapper wrapper-full-page ">
  <app-sidebar *ngIf="isLogged" (companysFilled)="companysFilled()"></app-sidebar>
  <div [class]="(!isLoginPage && isLogged) ? 'main-panel' : 'full-page section-image'">
    <app-navbar *ngIf="isLogged" [companysDate]="companysDate"></app-navbar>
    <router-outlet></router-outlet>
    <!-- <div *ngIf="!isMap()"> -->
    <app-footer></app-footer>
    <!-- </div> -->
  </div>
</div>
