<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-sm-6 col-md-8">
    <button class="btn btn-sm btn-success btn-simple" *ngIf="createPermission" (click)="createClient()" ngbTooltip="Crear" container="body">
      <i class="fas fa-plus"></i>
    </button>
    <button class="btn btn-sm btn-default btn-simple" (click)="refreshClient()" ngbTooltip="Refrescar" container="body">
      <i class="fas fa-sync-alt"></i>
    </button>
    <button class="btn btn-sm btn-default btn-simple" *ngIf="xlsxExportPermission" (click)="xlsxExport()"
      ngbTooltip="Exportar XLSX" container="body">
      <i class="fas fa-file-excel" style="color: green;"></i>
    </button>
  </div>
</div>
