import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Company } from 'src/app/models/company';
import { ListArray } from 'src/app/models/listArray';
import { Provider } from 'src/app/models/provider';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseDetail } from 'src/app/models/purchaseDetail';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProviderService } from 'src/app/services/provider.service';
import { PurchaseService } from 'src/app/services/purchase.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');

@Component({
  selector: 'app-purchase-generate',
  templateUrl: './purchase-generate.component.html'
})
export class PurchaseGenerateComponent implements OnInit {
  loading = false;

  @Input() purchaseViewMode = false;
  @Output() cancelAction = new EventEmitter<any>();
  @Output() cancelViewAction = new EventEmitter<any>();

  company = {} as Company;

  providerList: Provider[];
  providerListSelect = new Array();
  providerSelected;

  @Input() purchase = {
    purchaseDetail: new Array<PurchaseDetail>(),
  } as Purchase;
  purchaseDetail = {} as PurchaseDetail;

  dropdownSettings = {};
  classImageBtn = 'btn btn-simple btn-block btn-file';
  classImageBtnGreen = 'btn btn-success btn-simple btn-block btn-file';
  classImageBtnGray = 'btn  btn-simple btn-block btn-file';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  inventorySelected;
  inventoryCost;

  alert = false;
  alertHeader = false;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private providerService: ProviderService,
    private purchaseService: PurchaseService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.providerList = await this.getProviders(user.userCompany);
    this.providerListSelect = await this.sharedUtils.transformToList(
      this.providerList,
      'providerCode',
      'providerName'
    );
    // this.inventoryList = await this.sharedUtils.transformToList(
    //   await this.getInventory(user.userStore),
    //   'inventoryCode',
    //   'inventoryNameShow'
    // );
    if (!this.purchaseViewMode) {
      await this.setDefault();
      this.fntDropdownSettings(false);
    } else {
      this.fntDropdownSettings(true);
      if (this.purchase.purchaseProvider != undefined) {
        this.providerSelected = await this.sharedUtils.selectFromList(
          this.providerListSelect,
          this.purchase.purchaseProvider
        );
      }
    }
    this.purchase.purchaseDate = await this.sharedUtils.formatDate();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.purchase.purchaseCompany = user.userCompany;
    this.purchase.purchaseStore = user.userStore;
    this.purchaseDetail.purchaseDetailType = 'I';
    this.purchaseDetail.purchaseDetailRequestQuantity = 0;
    this.purchaseDetail.purchaseDetailCost = 0;
    this.purchase.purchaseQuoteNumber = uuid.v4();
    this.inventorySelected = undefined;
    this.purchaseDetail.purchaseDetailDescription = '';
    this.purchaseDetail.purchaseDetailInventory = 0;
    this.purchaseDetail.purchaseDetailType = 'D';
    this.purchase.purchaseCreationUser = user.userCode;
    this.purchase.purchaseTotal = 0;
  }

  async getProviders(company) {
    this.loading = true;
    return this.providerService
      .providerGetCompany(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getInventoryCost(id) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetCost(id)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCost = response.records;
        this.purchaseDetail.purchaseDetailCost =
          this.inventoryCost[0].inventoryCost;

        //return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async onItemSelectProvider(item: ListArray) {
    this.purchase.purchaseProvider = item.id;
  }

  async onItemSelectInventory(item: ListArray) {
    this.purchaseDetail.purchaseDetailInventory = item.id;
    this.purchaseDetail.purchaseDetailDescription = item.itemName;

    await this.getInventoryCost(item.id);
  }

  async addFile(e) {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
  
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        this.purchase.purchaseQuotationFileName  = selectedFile.name;
        this.purchase.purchaseQuotationFile  = await this.sharedUtils.convertBase64(selectedFile);
        this.classImageBtn = this.classImageBtnGreen;
      } else {
        this.notificationService.showError('Tipo de archivo no permitido.');
      }
    }
  }

  async addItem() {
    this.alert = true;
    if (this.purchaseDetail.purchaseDetailCost == '') {
      this.notificationService.showError('Completa los campos obligatorios.');
    }else if(this.purchaseDetail.purchaseDetailRequestQuantity == undefined || this.purchaseDetail.purchaseDetailRequestQuantity == 0){
      this.notificationService.showError('Completa los campos obligatorios.');
    }else if(this.purchaseDetail.purchaseDetailDescription == 0){
      this.notificationService.showError('Completa los campos obligatorios.');
    }else {
      this.purchaseDetail.purchaseDetailCode = uuid.v4();
      this.purchaseDetail.purchaseDetailSubtotal =
        this.purchaseDetail.purchaseDetailCost *
        this.purchaseDetail.purchaseDetailRequestQuantity;
      this.purchaseDetail.purchaseDetailTax = 0;
      this.purchaseDetail.purchaseDetailTotal =
        this.purchaseDetail.purchaseDetailCost *
        this.purchaseDetail.purchaseDetailRequestQuantity;
      this.purchaseDetail.purchaseDetailUnitPrice =
        this.purchaseDetail.purchaseDetailCost;
      if (this.purchaseDetail.purchaseDetailType == 'I')
        this.purchaseDetail.purchaseDetailDescription =
          this.inventorySelected[0].itemName;
      this.purchase.purchaseDetail.push({ ...this.purchaseDetail });
      this.setDefault();
      this.purchase.purchaseDetail = [...this.purchase.purchaseDetail];
      this.alert = false;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.purchase.purchaseDetail.find(
      (x) => x.purchaseDetailCode == this.activeRow.purchaseDetailCode
    );
    const indexDetail = this.purchase.purchaseDetail.indexOf(findDetail);
    this.purchase.purchaseDetail.splice(indexDetail, 1);
    this.purchase.purchaseDetail = [...this.purchase.purchaseDetail];
    await this.calculateTotals();
  }

  async calculateTotals() {
    this.purchase.purchaseTotal = 0;
    for (const iterator of this.purchase.purchaseDetail) {
      this.purchase.purchaseTotal += iterator.purchaseDetailTotal;
    }
  }

  async cancel() {
    if (this.purchaseViewMode) {
      this.cancelViewAction.emit();
    } else {
      this.cancelAction.emit();
    }
  }

  async save() {
    let correct = true;
    this.alertHeader = true;
    await this.calculateTotals();
    if (this.purchase.purchaseDetail.length == 0 || this.purchase.purchaseDetail == undefined) {
      correct = false;
    }

    if (!correct) {
      if (!this.purchase.purchaseDate) {
        this.notificationService.showError('Completa los campos obligatorios.');
      } else if (this.providerSelected == undefined) {
        this.notificationService.showError('Completa los campos obligatorios.');
      } else {
        this.notificationService.showError("Debes ingresar un producto.");
      }
    } else {
      let response;
      this.loading = true;
      response = await this.purchaseService.purchaseRequest(this.purchase);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        //this.notificationService.showSuccess(response.message);
        this.notificationService.showSuccess("Compra ingresada con éxito.");
        this.cancel();
      }
      this.loading = false;
    }
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        classes: '',
        disabled: true,
        maxHeight: 150,
        lazyLoading: true,
        text: 'Seleccione',
        singleSelection: false,
        enableCheckAll: true,
        enableUnCheckAll: true,
        enableSearchFilter: true,
      };
    } else {
      this.dropdownSettings = {
        classes: '',
        maxHeight: 150,
        lazyLoading: true,
        text: 'Seleccione',
        singleSelection: true,
        enableCheckAll: true,
        enableUnCheckAll: true,
        enableSearchFilter: true,
        noDataLabel: '¡Sin datos!',
      };
    }
  }
}
