<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Agendar envío</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-danger btn-simple"
            (click)="cancel()"
            title="Cancelar"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            class="btn btn-sm btn-default btn-simple"
            (click)="refresh()"
            title="Refrescar"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <div class="card-body"></div>
      <div class="card-footer">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class="form-control form-control-sm"
                    (change)="entriesChange($event)"
                  >
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input
                    type="search"
                    class="form-control form-control-md"
                    placeholder="Buscar"
                    aria-controls="datatable"
                    (keyup)="filterTable($event)"
                  />
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            *ngIf="tableHeaders.length > 0"
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="entries != -1 ? entries : undefined"
            [rows]="reportDataFilter"
            [scrollbarH]="true"
            (activate)="onActivate($event)"
          >
            <ngx-datatable-column
              *ngFor="let tableHeader of tableHeaders"
              [name]="tableHeader.name"
            >
              <ng-template ngx-datatable-header-template>
                <span>{{ tableHeader.name }}</span>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                [ngSwitch]="tableHeader.pipe"
              >
                <span *ngSwitchCase="'text'">
                  {{ row[tableHeader.prop] }}
                </span>
                <span *ngSwitchCase="'date'">
                  {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                </span>
                <span *ngSwitchCase="'currency'">
                  {{ row[tableHeader.prop] | number : "1.2-2" }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Teléfono">
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
              >
                <input
                  style="width: 100%"
                  autofocus
                  (keyup)="updateValue($event, 'clientPhoneOne', rowIndex)"
                  type="text"
                  maxlength="8"
                  [value]="row.clientPhoneOne + ' ' + row.clientPhoneTwo"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Dirección">
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
              >
                <input
                  style="width: 100%"
                  autofocus
                  (keyup)="updateValue($event, 'clientAddressShow', rowIndex)"
                  type="text"
                  [value]="row.clientAddressShow"
                />
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column name="Fecha Envío">
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
              >
                <input
                  autofocus
                  (change)="updateValue($event, 'shippingDate', rowIndex)"
                  type="date"
                  [value]="row.shippingDate"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Comentario">
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
              >
                <input
                  style="width: 100%"
                  autofocus
                  (keyup)="updateValue($event, 'shippingComment', rowIndex)"
                  type="text"
                  [value]=""
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="true"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                Acciones
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <a
                  href="#"
                  class="btn btn-success btn-icon btn-sm btn-simple like"
                  (click)="save($event)"
                  title="Guardar"
                  ><i class="fas fa-save"></i
                ></a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
