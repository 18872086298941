<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{headerActionType}}</h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body" *ngIf="!receiptRecurringViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Frecuencia</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="receiptRecurring.receiptRecurringFrequency"
                    placeholder="Frecuencia" [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        receiptRecurring.receiptRecurringFrequency == '' || 
                        receiptRecurring.receiptRecurringFrequency == undefined
                      ">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label></label>
                <select class="form-control" [(ngModel)]="receiptRecurring.receiptRecurringFrequencyType"
                  (change)="changeFrequencyType($event.target.value)"
                  [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode">
                  <option value="0">Días</option>
                  <option value="1">Semanas</option>
                  <option value="2">Meses</option>
                  <option value="3">Años</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Duración</label>
                <select class="form-control" [(ngModel)]="receiptRecurring.receiptRecurringDurationType"
                  (change)="changeDurationType($event.target.value)"
                  [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode">
                  <option value="0">Indefinido</option>
                  <option value="1">Repeticiones</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Número de repeticiones</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="receiptRecurring.receiptRecurringDuration"
                    placeholder="Tiempo indefinido" [disabled]="receiptRecurring.receiptRecurringDurationType == 0 || receiptRecurringViewMode || receiptRecurringUpdateMode"/>
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        (
                          receiptRecurring.receiptRecurringDuration == '' || 
                          receiptRecurring.receiptRecurringDuration == undefined 
                        ) && 
                        receiptRecurring.receiptRecurringDurationType == 1
                      ">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Fecha inicio</label>
                <input type="date" class="form-control form-control-sm"
                  [(ngModel)]="receiptRecurring.receiptRecurringDateStart" (change)="validateDate($event)"
                  [min]="minDateStart" [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm"
                  [(ngModel)]="receiptRecurring.receiptRecurringCreationDate" (change)="validateDate($event)"
                  disabled />
              </div>
              <div class="col-md-3" *ngIf="company.companyClient == 1">
                <label>Tipo de identificador</label>
                <select class="form-control" [(ngModel)]="receiptRecurring.receiptRecurringDocumentSearch"
                  (change)="changeDocumentType($event.target.value)"
                  [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode">
                  <option value="2">Teléfono</option>
                  <option value="0">DPI</option>
                </select>
              </div>
              <div class="col-md-3"
                *ngIf="receiptRecurring.receiptRecurringDocumentSearch == 2 && company.companyClient == 1">
                <label>Teléfono</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptRecurringClient.clientPhone"
                    (focusout)="searchClient()" placeholder="Teléfono" [textMask]="{mask: contactMask}"
                    [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        receiptRecurring.receiptRecurringDocumentSearch == 2 && 
                        company.companyClient == 1 &&
                        (
                          this.receiptRecurringClient.clientPhone == '' || 
                          this.receiptRecurringClient.clientPhone == undefined
                        )">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3"
                *ngIf="receiptRecurring.receiptRecurringDocumentSearch == 0 && company.companyClient == 1">
                <label>DPI</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control"
                    [(ngModel)]="receiptRecurringClient.clientIdentificationDocument" (focusout)="searchClient()"
                    placeholder="DPI" [textMask]="{mask: dpiMask}"
                    [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        receiptRecurring.receiptRecurringDocumentSearch == 0 && 
                        company.companyClient == 1 &&
                        (
                          receiptRecurringClient.clientIdentificationDocument == '' || 
                          receiptRecurringClient.clientIdentificationDocument == undefined
                        )">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Teléfono</label>
                <label style="color: red;"
                  *ngIf="receiptRecurring.receiptRecurringDocumentSearch == 2 && company.companyClient == 1">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptRecurringClient.clientPhoneOne"
                    placeholder="Teléfono" [textMask]="{mask: phoneMask}"
                    [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="
                        receiptRecurring.receiptRecurringDocumentSearch == 2 && 
                        company.companyClient == 1 &&
                        (
                          this.receiptRecurringClient.clientPhoneOne == '' || 
                          this.receiptRecurringClient.clientPhoneOne == undefined
                        )">Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Nombre</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptRecurringClient.clientName"
                    placeholder="Nombre" [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                  <div *ngIf="alertHeader">
                    <label style="color: red;"
                      *ngIf="receiptRecurringClient.clientName == undefined || receiptRecurringClient.clientName == '' ">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Correo electrónico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptRecurringClient.clientEmail"
                    placeholder="Correo electrónico"
                    [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                </div>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <select class="form-control" [(ngModel)]="addressSelected" (change)="changeAddress($event.target.value)"
                  [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode">
                  <option value="0">Ciudad</option>
                  <option value="1">Ingresar dirección</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Dirección</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="receiptRecurringClient.clientAddress"
                    placeholder="Dirección" [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode || addressSelected == 0" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Zona</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="receiptRecurringClient.clientAddressZone"
                    placeholder="Zona" min="0" [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Departamento</label>
                <div class="form-group">
                  <angular2-multiselect 
                    [data]="list['clientDepartment']" 
                    *ngIf="list['clientDepartment']"
                    [(ngModel)]="selected['clientDepartment']" 
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'clientDepartment')"                   
                    >
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="addressSelected == 1">
                <label>Municipio</label>
                <angular2-multiselect 
                  [data]="list['clientMunicipality']" 
                  *ngIf="list['clientMunicipality']"
                  [(ngModel)]="selected['clientMunicipality']" 
                  [settings]="dropdownSettings" 
                  (onSelect)="onItemSelectClient($event, 'clientMunicipality')"
                >
              </angular2-multiselect>    
              </div>              
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label>Moneda</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="receiptRecurring.receiptRecurringCurrency"
                    (change)="changeCurrency($event.target.value)"
                    [disabled]="receiptRecurringViewMode || receiptRecurringUpdateMode">
                    <option value="1">Q - Quetzales</option>
                    <option value="2">$ - Dólares</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailType"
                    (change)="changeDetailType($event.target.value)" [disabled]="receiptRecurringViewMode">
                    <option value="N">Seleccionar...</option>
                    <option value="B" *ngIf="receiptRecurringInventory">
                      Bien
                    </option>
                    <option value="SD" *ngIf="receiptRecurringInventory">
                      Servicio
                    </option>
                    <option value="BD">
                      Bien descripción
                    </option>
                    <option value="S">
                      Servicio descripción
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="this.receiptRecurring.receiptRecurringChangeRate != 1">
                <label>Tasa de cambio</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="receiptRecurring.receiptRecurringChangeRate" placeholder="Tasa de cambio"
                    [disabled]="true" />
                </div>
              </div>
              <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Tipo de pago</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="receiptRecurring.receiptRecurringPaymentMethod"
                    (change)="changePaymentMethod($event.target.value)">
                    <option value="1">Efectivo</option>
                    <option value="2">Tarjeta</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="store.storeOnline == 0">
                <label>Vendedor</label>
                <div class="form-group">
                  <angular2-multiselect [data]="list['sellerShow']" *ngIf="list['sellerShow']"
                    [(ngModel)]="selected['sellerShow']" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectClient($event, 'sellerShow')">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="this.receiptRecurring.receiptRecurringPaymentMethod != 1">
                <label>Numero Autorización</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #ffffff"
                    [(ngModel)]="receiptRecurring.receiptRecurringVoucher" placeholder="Numero Autorización" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"
                *ngIf="receiptRecurringAddItemTypeMode == 'B' || receiptRecurringAddItemTypeMode == 'SD'">
                <label>Productos</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettingsProduct" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;"
                      *ngIf="receiptRecurringDetail.receiptRecurringDetailDescription == undefined">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6"
                *ngIf="receiptRecurringAddItemTypeMode == 'S' || receiptRecurringAddItemTypeMode == 'BD'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailDescription" placeholder="Descripción" />
                  <div *ngIf="alert">
                    <label style="color: red;"
                      *ngIf="receiptRecurringDetail.receiptRecurringDetailDescription == undefined">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="receiptRecurringAddItemTypeMode == 'N'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailDescription" placeholder="Descripción"
                    disabled />
                  <div *ngIf="alert">
                    <label style="color: red;"
                      *ngIf="receiptRecurringDetail.receiptRecurringDetailDescription == undefined">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>P. Unitario</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailUnitPrice" placeholder="Precio unitario"
                    (blur)="calculate()" min="0" [disabled]="receiptRecurringViewMode"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptRecurringDetail.receiptRecurringDetailUnitPrice == 0">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailQuantity" placeholder="Cantidad"
                    (blur)="calculate()" min="0" [disabled]="receiptRecurringViewMode" />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptRecurringDetail.receiptRecurringDetailQuantity == 0">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Descuento</label>
                <div class="form-group">
                  <input type="number" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailDiscount" placeholder="Descuento"
                    (blur)="calculate()" min="0" [disabled]="receiptRecurringViewMode" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Subtotal</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailSubtotal" placeholder="Subtotal"
                    disabled="true" />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptRecurringDetail.receiptRecurringDetailSubtotal == 0">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Total</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa"
                    [(ngModel)]="receiptRecurringDetail.receiptRecurringDetailTotal" placeholder="Total"
                    disabled="true" />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="receiptRecurringDetail.receiptRecurringDetailTotal == 0">Campo
                      obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="insertItem()"
                    [disabled]="receiptRecurringViewMode">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="receiptRecurring.receiptRecurringDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="250" [rows]="receiptRecurring.receiptRecurringDetail"
              (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" *ngIf="!receiptRecurringViewMode" class="btn btn-danger btn-icon btn-sm btn-simple remove"
                    (click)="deleteItem($event)"><i class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!receiptRecurringViewMode" name="Descripción">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="
                      changeDescription($event, 'receiptRecurringDetailDescription', rowIndex)
                      " type="text" [value]="row.receiptRecurringDetailDescription || 0" 
                      [disabled]="receiptRecurringViewMode"
                  />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="receiptRecurringViewMode" name="Descripción">
                <ng-template ngx-datatable-header-template>
                  <span>Descripción</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailDescription}}
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column name="Descripción" prop="receiptRecurringDetailDescription"></ngx-datatable-column> -->
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descuento">
                <ng-template ngx-datatable-header-template>
                  <span>Descuento</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailDiscount | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Subtotal">
                <ng-template ngx-datatable-header-template>
                  <span>Subtotal</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailSubtotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.receiptRecurringDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total: {{ receiptRecurring.receiptRecurringTotal | number : "1.2-2" }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>