import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-shipping-manager',
  templateUrl: './shipping-manager.component.html',
  styleUrls: ['./shipping-manager.component.scss']
})
export class ShippingManagerComponent implements OnInit {
  loading = false;

  shippingScheduleMode: boolean = false;
  shippingListMode: boolean = false;

  shipping = undefined;

  createPermission: boolean = false;
  readPermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) { this.titleService.emitChange("Envios"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const findCompany = user.userCompanys.find(x => x.companyCode == user.userCompany);
    const findStore = findCompany.companyStores.find(x => x.storeCode == user.userStore);
    if (findStore.storeOnline != 1) {
      this.router.navigate(['/home']);
      this.notificationService.showWarning('Establecimiento no autorizado para esta opción');
    }
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  shippingSchedule() {
    this.shippingScheduleMode = true;
  }

  shippingList() {
    this.shippingListMode = true;
  }

  cancelAction() {
    this.shippingScheduleMode = false;
    this.shippingListMode = false;
  }

}

