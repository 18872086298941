import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { TableHeader } from 'src/app/models/tableHeader';
import { ExportService } from 'src/app/services/export.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import swal from "sweetalert2";
@Component({
  selector: 'app-client-maintenance-table',
  templateUrl: './client-maintenance-table.component.html',
  styleUrls: ['./client-maintenance-table.component.scss']
})
export class ClientMaintenanceTableComponent implements OnInit, OnChanges {
  loading = false;

  @Input() clientList: Array<Client>;
  @Input() readPermission: boolean;
  @Input() updatePermission: boolean;
  @Input() deletePermission: boolean;
  @Input() viewPermission: boolean;
  @Input() xlsxExportDate: any;
  @Input() salePermission: boolean;
  @Input() historyPermission: boolean;
  @Input() quotationPermission: boolean;

  @Output() updateAction = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();
  @Output() saleAction = new EventEmitter<any>();
  @Output() historyAction = new EventEmitter<any>();
  @Output() quotationAction = new EventEmitter<any>();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;
  clientListFilter: Array<Client>;
  lastxlsxExportDate;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    private sharedUtils: SharedUtils,
    private tableHeader: TableHeaderService,
    private notificationService: NotificationService,
    private exportService: ExportService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(await this.getTableHeader(user.userRole, user.userCode, 'clientMaintenance'), 'tableHeaderTitle', 'tableHeaderBusinessCode', 'tableHeaderPipe');
  }

  async ngOnChanges() {
    this.clientListFilter = this.clientList;
    if (this.lastxlsxExportDate !== this.xlsxExportDate) {
      await this.exportXLSX();
    }
  }

  async exportXLSX() {
    this.exportService.xlsxExport(this.clientListFilter, this.tableHeaders, 'Reporte', 'Reporte de clientes');
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader.tableHeaderGetRoleUserTable(role, user, table).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.clientListFilter = this.clientList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  view(event) {
    event.preventDefault();
    this.viewAction.emit(this.activeRow);
  }

  update(event) {
    event.preventDefault();
    this.updateAction.emit(this.activeRow);
  }

  delete(event) {
    event.preventDefault();
    this.deleteAction.emit(this.activeRow);
  }

  async quotation(event){
    event.preventDefault();
    this.quotationAction.emit(this.activeRow);
  } 

  async history(event){
    event.preventDefault();
    this.historyAction.emit(this.activeRow);
  }

  // async sale(event){
  //   event.preventDefault();
  //   this.saleAction.emit(this.activeRow);
  // }

  async sale(event) {
    event.preventDefault();
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    let buttons = '';

    if (company.companyReceipt) {
      buttons += '<button id="recibo" class="swal2-button swal2-styled">Recibo</button>';
    }    
    if (company.companyReceiptBusiness) {
      buttons += '<button id="reciboComercial" class="swal2-button swal2-styled">Recibo comercial</button>';
    }
    if (company.companyInvoice) {
      buttons += '<button id="facturaComercial" class="swal2-button swal2-styled">Factura comercial</button>';
    }
    if (company.companyInvoiceExchange) {
      buttons += '<button id="facturaCambiaria" class="swal2-button swal2-styled">Factura cambiaria</button>'; 
    }    
    
    swal.fire({
      title: '¿Deseas facturar?',
      html: `
        <style>
          .swal2-buttons-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .swal2-button {
            width: 200px;
            padding: 10px;
            font-size: 16px;
            margin: 5px 0;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            border: none;
            display: block;
            color: white;
            background-color: #28a745;
            transition: background-color 0.3s ease;
          }
          .swal2-button:hover {
            background-color: #218838;
          }
          #recibo:hover,
          #reciboComercial:hover,
          #facturaComercial:hover,
          #facturaCambiaria:hover {
            background-color: #218838;
          }
          #cancelar {
            background-color: #e76b3b;
          }
        </style>
        <p>Selecciona el tipo de documento que deseas generar.</p>
        <div class="swal2-buttons-container">          
          ${buttons}
          <button id="cancelar" class="swal2-button swal2-styled">Cancelar</button>
        </div>
      `,
      showConfirmButton: false,
      icon: 'warning',
      willOpen: () => {
        document.getElementById('recibo').addEventListener('click', () => {
          swal.close();
          this.saleAction.emit({client: this.activeRow, option: 1});
        });       
        document.getElementById('facturaComercial').addEventListener('click', () => {
          swal.close();
          this.saleAction.emit({client: this.activeRow, option: 2});
        });
        document.getElementById('facturaCambiaria').addEventListener('click', () => {
          swal.close();
          this.saleAction.emit({client: this.activeRow, option: 3});
        });
        document.getElementById('reciboComercial').addEventListener('click', () => {
          swal.close();
          this.saleAction.emit({client: this.activeRow, option: 4});
        });
        document.getElementById('cancelar').addEventListener('click', () => {
          swal.close();
        });
      },
    });
  }
}
