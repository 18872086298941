<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success btn-simple"
            (click)="save()"
            ngbTooltip="Guardar"
            container="body"
            *ngIf="!packageViewMode"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row" *ngIf="attributes">
          <div
            class="col-md-6 col-lg-4 col-xl-3"
            *ngFor="let attribute of attributes"
          >
            <label>{{ attribute.attributeName }}</label>
            <label style="color: red" *ngIf="attribute.attributeRequired == 1"
              >*</label
            >
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'text'">
                <input
                  type="text"
                  class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="package[attribute.attributeBusinessCode]"
                  maxlength="{{ attribute.attributeMaxlength }}"
                  [readonly]="packageViewMode"
                />
              </div>
              <div *ngSwitchCase="'email'">
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  placeholder="{{ attribute.permissionAB }}"
                  [(ngModel)]="package[attribute.permissionPath]"
                  maxlength="{{ attribute.permissionMaxlength }}"
                  required="{{ attribute.permissionRequired }}"
                  [readonly]="
                    (packageViewMode == true || packageUpdateMode == true) &&
                    attribute.permissionPath == 'packageEmail'
                  "
                />
              </div>
              <div *ngSwitchCase="'number'">
                <input
                  type="number"
                  class="form-control"
                  autocomplete="off"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="package[attribute.permissionPath]"
                  maxlength="{{ attribute.permissionMaxlength }}"
                  required="{{ attribute.permissionRequired }}"
                  [readonly]="packageViewMode"
                />
              </div>
              <div *ngSwitchCase="'list'">
                <angular2-multiselect
                  [data]="list[attribute.permissionPath]"
                  *ngIf="list[attribute.permissionPath]"
                  [(ngModel)]="package[attribute.permissionMaxlength]"
                  [settings]="dropdownSettings"
                >
                </angular2-multiselect>
              </div>
              <div *ngSwitchCase="'date'">
                <input
                  type="date"
                  class="form-control"
                  [(ngModel)]="package[attribute.permissionPath]"
                  [readonly]="packageViewMode"
                />
              </div>
              <div *ngSwitchCase="'select'">
                <angular2-multiselect
                  [data]="list[attribute.attributeBusinessCode]"
                  *ngIf="list[attribute.attributeBusinessCode]"
                  [(ngModel)]="selected[attribute.attributeBusinessCode]"
                  [settings]="dropdownSettings"
                  (onSelect)="
                    onItemSelect($event, attribute.attributeBusinessCode)
                  "
                  disabled="!companyViewMode"
                >
                </angular2-multiselect>
              </div>
              <div *ngSwitchCase="'boolean'">
                <bSwitch
                  [switch-on-package]="'primary'"
                  [(ngModel)]="package[attribute.permissionPath]"
                  [disabled]="packageViewMode"
                  switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                  switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>"
                >
                </bSwitch>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <label>Agregar detalle de promoción:</label>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-sm btn-default btn-simple"
                (click)="addPackageDetail()"
                ngbTooltip="Agregar"
                container="body"
                [disabled]="packageViewMode"
              >
                Agregar detalle de promoción
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="row" *ngIf="packageAddItemMode">
          <div class="col-md-3">
            <label>Productos</label>
            <div class="form-group">
              <angular2-multiselect
                [data]="list['packageDetailInventory']"
                *ngIf="list['packageDetailInventory'].length > 0"
                [(ngModel)]="selected['packageDetailInventory']"
                [settings]="dropdownSettings"
                (onSelect)="
                  onItemSelectDetail($event, 'packageDetailInventory')
                "
              >
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <label>Agregar detalle</label>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-sm btn-default btn-simple"
                (click)="insertPackageDetail()"
                ngbTooltip="Agregar"
                container="body"
              >
                Agregar detalle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="package.packageDetail">
          <div class="col-md-12">
            <ngx-datatable
              class="bootstrap selection-cell"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [limit]="entries != -1 ? entries : undefined"
              [rows]="package.packageDetail"
              (activate)="onActivate($event)"
            >
              <ngx-datatable-column
                [sortable]="true"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="true"
              >
                <ng-template
                  ngx-datatable-header-template
                  let-value="value"
                  let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn"
                >
                  Acciones
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn"
                >
                  <a
                    href="#"
                    class="btn btn-danger btn-icon btn-sm btn-simple remove"
                    (click)="deleteItem($event)"
                    ><i class="fas fa-times"></i
                  ></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Inventario"
                prop="packageDetailInventoryShow"
              ></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
