
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/role';
import { RoleService } from 'src/app/services/role.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-role-permission-maintenance',
  templateUrl: './role-permission-maintenance.component.html',
  styleUrls: ['./role-permission-maintenance.component.scss']
})
export class RolePermissionMaintenanceComponent implements OnInit {
  loading = false;
  roleList: Role[];
  role: Role;
  tableHeaders: [] = [];

  headerActionType: string;
  roleCreateMode: boolean = false;
  roleUpdateMode: boolean = false;
  roleDeleteMode: boolean = false;
  roleViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private roleService: RoleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Mantenimiento"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode, 
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
    this.roleList = await this.getRoles(user.userRole, user.userCompany, '1,0');
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getRoles(role, company, status) {
    this.loading = true;
    return this.roleService.roleGetAll(role, company, status).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear rol';
    this.roleCreateMode = true;
    this.role = {} as Role;
    this.role.roleStatus = 1;
    this.role.roleCompany = user.userCompany;
    this.role.roleStore = user.userStore;
    this.role.roleCreationUser = user.userCode;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver rol';
    this.roleViewMode = true;
    this.role = event as Role;
  }

  async updateAction(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar rol';
    this.roleUpdateMode = true;
    this.role = event as Role;
    this.role.roleModifyUser = user.userCode;
    this.role.roleDeleted = 0;
  }

  async deleteAction(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Borrar rol';
    this.roleDeleteMode = true;
    const roleDeleted = {
      roleCode: event.roleCode,
      roleDeleted: 1,
      roleDeletedUser: user.userCode
    };
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(roleDeleted);
      }
    });   
  }

  async cancelAction(event) {
    this.roleCreateMode = false;
    this.roleViewMode = false;
    this.roleUpdateMode = false;
    this.roleDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.roleList = await this.getRoles(user.userRole, user.userCompany, '1,0');
  }

  async saveAction(role) {
    let response;
    this.loading = true;
    if (this.roleCreateMode == true) {
      response = await this.roleService.roleInsert(role);
    }
    if (this.roleUpdateMode == true) {
      response = await this.roleService.roleUpdate(role);
    }
    if (this.roleDeleteMode == true) {
      response = await this.roleService.roleUpdate(role);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;

  }
}