<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Listar recibos</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            class="btn btn-sm btn-default btn-simple"
            (click)="xlsxExport()"
            ngbTooltip="Exportar XLSX"
            container="body"
            *ngIf="xlsxExportPermission"
          >
            <i class="fas fa-file-excel" style="color: green"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-md-4">
            <label>Desde:</label>
            <div class="form-group">
              <input type="date" class="form-control" [(ngModel)]="reportStartDate" />
            </div>
          </div>
          <div class="col-md-4">
            <label>Hasta:</label>
            <div class="form-group">
              <input type="date" class="form-control" [(ngModel)]="reportEndDate" />
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <div class="form-group w-100">
              <button class="btn btn-info btn-sale w-100" (click)="getReport()" style="margin-top: 2.2rem;">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class="form-control form-control-sm"
                    (change)="entriesChange($event)"
                  >
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input
                    type="search"
                    class="form-control form-control-md"
                    placeholder="Buscar"
                    aria-controls="datatable"
                    (keyup)="filterTable($event)"
                  />
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            *ngIf="reportData !== undefined && tableHeaders.length > 0"
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="entries != -1 ? entries : undefined"
            [rows]="reportDataFilter"
            (activate)="onActivate($event)"
          >
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="true"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                Acciones
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <a
                  href="#"
                  class="btn btn-success btn-icon btn-sm btn-simple like"
                  (click)="view($event)"
                  *ngIf="
                    row.receiptStatus != 2 &&
                    viewPermission
                  "
                  ngbTooltip="Reimprimir"
                  container="body"
                  style="margin-right: 2px;"
                  ><i class="fas fa-eye"></i
                ></a>
                <a
                  href="#"
                  class="btn btn-danger btn-icon btn-sm btn-simple like"
                  *ngIf="
                    row.receiptStatus == 1 &&
                    row.receiptInvoiceShow == 0 &&
                    row.receiptReceiptBusinessShow == 0 &&
                    deletePermission
                  "
                  (click)="delete($event)"
                  ngbTooltip="Anular"
                  container="body"
                  ><i class="fas fa-times"></i
                ></a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              *ngFor="let tableHeader of tableHeaders"
              [name]="tableHeader.name"
            >
              <ng-template ngx-datatable-header-template>
                <span>{{ tableHeader.name }}</span>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                [ngSwitch]="tableHeader.pipe"
              >
                <span *ngSwitchCase="'text'">
                  {{ row[tableHeader.prop] }}
                </span>
                <span *ngSwitchCase="'date'">
                  {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                </span>
                <span *ngSwitchCase="'currency'">
                  {{ row[tableHeader.prop] | number : "1.2-2" }}
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
