import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductionService } from 'src/app/services/production.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from 'sweetalert2';
import { TitleService } from 'src/app/services/title.service';
import { Production } from 'src/app/models/production';

@Component({
  selector: 'app-production-maintenance',
  templateUrl: './production-maintenance.component.html',
  styleUrls: ['./production-maintenance.component.scss'],
})
export class ProductionMaintenanceComponent implements OnInit {
  loading = false;
  productionList: Production[];
  _production: Production;
  tableHeaders: [] = [];

  headerActionType: string;
  productionCreateMode: boolean = false;
  productionUpdateMode: boolean = false;
  productionDeleteMode: boolean = false;
  productionViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private productionService: ProductionService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Recetas');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/productMaintenance/', '')
    );
    await this.permissionReview(permissionList);
    this.productionList = await this.getProductions(user.userStore);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getProductions(store) {
    this.loading = true;
    return this.productionService
      .productionGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear receta';
    this.productionCreateMode = true;
    this._production = {} as Production;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this._production.productionCompany = user.userCompany;
    this._production.productionStore = user.userStore;
    this._production.productionStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver receta';
    this.productionViewMode = true;
    this._production = event as Production;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar receta';
    this.productionUpdateMode = true;
    this._production = event as Production;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar receta';
    this.productionDeleteMode = true;
    const productionDeleted = {
      productionCode: event.productionCode,
      productionDeleted: 1,
    };
    swal
      .fire({
        title: 'Atención',
        text: '¿Desea eliminar la receta?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(productionDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.productionCreateMode = false;
    this.productionViewMode = false;
    this.productionUpdateMode = false;
    this.productionDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.productionList = await this.getProductions(user.userStore);
  }

  async saveAction(_production) {
    let response;
    this.loading = true;
    if (this.productionCreateMode == true) {
      response = await this.productionService.productionInsert(_production);
    }
    if (this.productionUpdateMode == true) {
      _production['productionModifyDate'] = new Date();
      response = await this.productionService.productionUpdate(_production);
    }
    if (this.productionDeleteMode == true) {
      _production['productionDeletedDate'] = new Date();
      response = await this.productionService.productionUpdate(_production);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }
}
