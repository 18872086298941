import { ReceiptBusinessService } from './../../../services/receipt-business.service';
import { ReceiptBusinessDetail } from './../../../models/receiptBusinessDetail';
import { ReceiptBusiness } from './../../../models/receiptBusiness';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from 'src/app/services/inventory.service';
import { Store } from 'src/app/models/store';
const uuid = require('uuid');
import swal from 'sweetalert2';
import { PackageService } from 'src/app/services/package.service';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { ListArray } from 'src/app/models/listArray';
import { SellerService } from 'src/app/services/seller.service';
import { QuotationService } from 'src/app/services/quotation.service';

@Component({
  selector: 'app-receipt-business-generate',
  templateUrl: './receipt-business-generate.component.html',
  styleUrls: ['./receipt-business-generate.component.scss']
})
export class ReceiptBusinessGenerateComponent implements OnInit {
  loading = false;

  @Input() clientSaleList;

  @Input() quotationList;

  @Output() cancelAction = new EventEmitter<any>();

  receiptBusiness: ReceiptBusiness = {
    receiptBusinessClient: 0,
    receiptBusinessClientAddressShow: 'Ciudad',
    receiptBusinessDetail: new Array<ReceiptBusinessDetail>(),
    receiptBusinessPayment: {
      receiptBusinessPaymentMethod: 1,
    },
  } as ReceiptBusiness;

  company = {} as Company;
  store = {} as Store;
  receiptBusinessDetail = {
    receiptBusinessDetailQuantity: 0,
    receiptBusinessDetailSubtotal: 0,
    receiptBusinessDetailTotal: 0,
    receiptBusinessDetailUnitPrice: 0,
    receiptBusinessDetailDiscount: 0,
    receiptBusinessDetailCost: 0,
    receiptBusinessDetailMeasure: 'UNI',
    receiptBusinessDetailInventory: 0,
  } as ReceiptBusinessDetail;
  receiptBusinessClient = {
    clientTaxNumber: ""
  } as Client;
  client = {} as Client;

  receiptBusinessSaleService = false;
  receiptBusinessSaleBenefit = false;
  receiptBusinessSaleInventory = false;
  receiptBusinessInventory = false;


  receiptBusinessAddItemTypeMode = 'N';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  public nowDay: string;
  public now: string;
  public date: string;

  alert = false;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;
  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private receiptBusinessService: ReceiptBusinessService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private sellerService: SellerService,
    private quotationService: QuotationService
  ) { }

  async ngOnInit() {
    await this.setDefault();

    //OBTENER FECHA
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.now = yyyy + '-' + mm + '-' + dd;
    this.receiptBusiness.receiptBusinessDate = yyyy + '-' + mm + '-' + dd;
    this.nowDay = this.receiptBusiness.receiptBusinessDate;
    this.date = this.now;
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    this.receiptBusiness.receiptBusinessDate = await this.sharedUtils.formatDate();
    this.receiptBusiness.receiptBusinessUser = user.userCode;
    this.receiptBusiness.receiptBusinessCompany = user.userCompany;
    this.receiptBusiness.receiptBusinessStore = user.userStore;

    this.receiptBusinessClient.clientCode = undefined;
    this.receiptBusinessClient.clientAddress = environment.clientDefaultAddress;

    this.receiptBusinessClient.clientPhoneOne = null;
    this.receiptBusinessClient.clientPhoneTwo = null;
    this.receiptBusinessClient.clientPhoneThree = null;
    this.receiptBusinessClient.clientAddressZone = '0';
    this.receiptBusinessClient.clientDepartment = null;
    this.receiptBusinessClient.clientMunicipality = null;
    this.receiptBusinessClient.clientCredit = null;
    this.receiptBusinessClient.clientCreditTime = null;
    this.receiptBusinessClient.clientCreditAmount = null;
    this.receiptBusiness.receiptBusinessCurrency = 'GTQ';
    this.receiptBusiness.receiptBusinessChangeRate = 1;
    this.receiptBusiness.receiptBusinessTotal = 0;

    this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentMethod = 1;
    this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentQuantity = 1;
    this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentTime = 1;
    this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentTimeMethod = 1;
    this.receiptBusiness.receiptBusinessDocumentSearch = 1;
    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
      // this.getSeller(user.userCompany),
    ]).then(async (response) => {
      // const sellerList = response[2][0].sellerName;
      // await this.addSelectList(
      //   response[2],
      //   'sellerCode',
      //   'sellerName',
      //   'sellerShow'
      // );

      this.receiptBusinessClient.clientDepartment = response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.receiptBusinessClient.clientDepartment
      );
      this.receiptBusinessClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];

      this.company = companys[0];
      this.receiptBusinessClient.clientEmail = this.company.companyEmail;
      this.receiptBusinessClient.clientCompany = this.company.companyCode;
      this.receiptBusinessSaleInventory = Boolean(this.company.companySaleInventory);
      this.receiptBusinessSaleBenefit = Boolean(this.company.companySaleBenefit);
      this.receiptBusinessSaleService = Boolean(this.company.companySaleService);
      this.receiptBusinessInventory = Boolean(this.company.companyInventory);

    });

    if (this.clientSaleList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });      
      const client = (await this.getClient(this.clientSaleList.clientCode || '')) as Client;

      this.receiptBusinessClient.clientCode = client.clientCode;
      this.receiptBusinessClient.clientName = client.clientName;
      this.receiptBusinessClient.clientTaxNumber = client.clientTaxNumber;
      this.receiptBusinessClient.clientIdentificationDocument = client.clientIdentificationDocument;
      this.receiptBusinessClient.clientPhoneOne = client.clientPhoneOne + ' ' + client.clientPhoneTwo + ' ' + client.clientPhoneThree;
      this.receiptBusinessClient.clientPhone = client.clientPhoneOne;
      this.receiptBusinessClient.clientEmail = client.clientEmail || this.receiptBusinessClient.clientEmail;
      this.receiptBusinessClient.clientAddress = client.clientAddress || this.receiptBusinessClient.clientAddress;
      this.receiptBusinessClient.clientAddressZone = client.clientAddressZone || this.receiptBusinessClient.clientAddressZone;
      if (client['clientDepartment'] != undefined) {
        this.receiptBusinessClient.clientDepartment = client.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptBusinessClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === client.clientMunicipality
        );
        if (!validMunicipality) {
          this.receiptBusinessClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptBusinessClient.clientMunicipality = client.clientMunicipality || null;
        }  
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

      if (client.clientTaxNumber && client.clientTaxNumber != 'CF' && client.clientTaxNumber.length > 5) {
        this.receiptBusiness.receiptBusinessDocumentSearch = 1;
      } else if (client.clientIdentificationDocument && client.clientIdentificationDocument.length == 13) {
        this.receiptBusiness.receiptBusinessDocumentSearch = 0;
      } else {
        if (!client.clientTaxNumber || client.clientTaxNumber === '') {
          client.clientTaxNumber = 'CF';
        }
        this.receiptBusiness.receiptBusinessDocumentSearch = 1;
      }

    }

    if (this.quotationList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      const client = (await this.getClient(this.quotationList.quotationClient || '')) as Client;


      for (const items of this.quotationList.quotationDetail) {
        this.receiptBusinessDetail.receiptBusinessDetailCode = uuid.v4();
        this.receiptBusinessDetail.receiptBusinessDetailDescription = items.quotationDetailDescription;
        this.receiptBusinessDetail.receiptBusinessDetailUnitPrice = parseFloat(items.quotationDetailUnitPrice);
        this.receiptBusinessDetail.receiptBusinessDetailQuantity = parseFloat(items.quotationDetailQuantity);
        this.receiptBusinessDetail.receiptBusinessDetailDiscount = 0;
        this.receiptBusinessDetail.receiptBusinessDetailSubtotal = parseFloat(items.quotationDetailTotal);
        this.receiptBusinessDetail.receiptBusinessDetailTotal = parseFloat(items.quotationDetailTotal);
        this.receiptBusinessDetail.receiptBusinessDetailInventory = parseFloat(items.quotationDetailInventory);
        this.receiptBusinessDetail.receiptBusinessDetailIsService = items.quotationDetailType;
        this.receiptBusiness.receiptBusinessDetail.push(this.receiptBusinessDetail);
        this.receiptBusiness.receiptBusinessDetail = [...this.receiptBusiness.receiptBusinessDetail];
        this.receiptBusinessDetail = {
          receiptBusinessDetailDescription: undefined,
          receiptBusinessDetailQuantity: 0,
          receiptBusinessDetailSubtotal: 0,
          receiptBusinessDetailTotal: 0,
          receiptBusinessDetailUnitPrice: 0,
          receiptBusinessDetailDiscount: 0,
          receiptBusinessDetailCost: 0,
          receiptBusinessDetailInventory: 0,
        } as ReceiptBusinessDetail;
        await this.calculateTotals();
      }

      this.receiptBusinessClient.clientCode = client.clientCode;
      this.receiptBusinessClient.clientName = client.clientName;
      this.receiptBusinessClient.clientTaxNumber = client.clientTaxNumber;
      this.receiptBusinessClient.clientIdentificationDocument = client.clientIdentificationDocument;
      this.receiptBusinessClient.clientPhoneOne = client.clientPhoneOne + ' ' + client.clientPhoneTwo + ' ' + client.clientPhoneThree;
      this.receiptBusinessClient.clientPhone = client.clientPhoneOne;
      this.receiptBusinessClient.clientEmail = client.clientEmail || this.receiptBusinessClient.clientEmail;
      this.receiptBusinessClient.clientAddress = client.clientAddress || this.receiptBusinessClient.clientAddress;
      this.receiptBusinessClient.clientAddressZone = client.clientAddressZone || this.receiptBusinessClient.clientAddressZone;

      if (client['clientDepartment'] != undefined) {
        this.receiptBusinessClient.clientDepartment = client.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptBusinessClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === client.clientMunicipality
        );
        if (!validMunicipality) {
          this.receiptBusinessClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptBusinessClient.clientMunicipality = client.clientMunicipality || null;
        }
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

    }
  }

  async cancel() {
    this.clientSaleList = undefined;
    this.quotationList = undefined;
    this.cancelAction.emit();
  }

  async searchClient() {
    if (this.clientSaleList == undefined && this.quotationList == undefined) {
      if (this.receiptBusiness.receiptBusinessDocumentSearch == 1
        && (this.receiptBusinessClient.clientTaxNumber == '' || this.receiptBusinessClient.clientTaxNumber == undefined)) {
        this.notificationService.showError("Debes ingresar un NIT.");
      } else if (this.receiptBusiness.receiptBusinessDocumentSearch ==
        0 && (this.receiptBusinessClient.clientIdentificationDocument == '' || this.receiptBusinessClient.clientIdentificationDocument == undefined)) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      } else {

        if (this.receiptBusiness.receiptBusinessDocumentSearch == 1) {
          this.client = (await this.getClientGetTaxNumber(
            this.receiptBusinessClient?.clientTaxNumber || 'CF'
          )) as Client;
        } else {
          this.client = (await this.getClientGetIdentificationDocument(
            this.receiptBusinessClient?.clientIdentificationDocument || ''
          )) as Client;
        }
        this.receiptBusinessClient.clientCode = this.client.clientCode;
        this.receiptBusinessClient.clientName = this.client.clientName;
        this.receiptBusinessClient.clientIdentificationDocument =
          this.client.clientIdentificationDocument;
        this.receiptBusinessClient.clientEmail =
          this.client.clientEmail || this.receiptBusinessClient.clientEmail;
        if (this.client['clientDepartment'] != undefined) {
          this.receiptBusinessClient.clientDepartment = this.client.clientDepartment;
          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.receiptBusinessClient['clientDepartment'] || null
          );
          const municipalityList = await this.getMunicipality(
            this.client.clientDepartment
          );
          const validMunicipality = municipalityList.find(
            (mun) => mun.municipalityCode === this.client.clientMunicipality
          );
    
          if (!validMunicipality) {
            this.receiptBusinessClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
          } else {
            this.receiptBusinessClient.clientMunicipality = this.client.clientMunicipality || null;
          }
    
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }               
      }
    }
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }  

  async validateDate($event) {
    $event.preventDefault();

    const nowDate = new Date(this.nowDay);
    const inputDate = new Date(this.receiptBusiness.receiptBusinessDate);

    const minDate = new Date(nowDate);
    minDate.setDate(nowDate.getDate() - 5);

    const maxDate = new Date(nowDate);
    maxDate.setDate(nowDate.getDate() + 5);

    if (inputDate >= minDate && inputDate <= maxDate) {
    } else {
      this.receiptBusiness.receiptBusinessDate = this.nowDay;
      this.notificationService.showWarning('Fecha fuera de rango.');
    }
  }

  async changeCurrency(event) {
    this.receiptBusiness.receiptBusinessChangeRate =
      event == 'GTQ' ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.receiptBusiness.receiptBusinessDocumentSearch = event;
  }

  async changeAddress(event) {

    if (event == 0) {
      this.receiptBusinessClient.clientAddress = 'Ciudad';
    } else {
      this.receiptBusinessClient.clientAddress = this.client.clientAddress || this.receiptBusinessClient.clientAddress;;
      this.receiptBusinessClient.clientAddressZone = this.client.clientAddressZone || this.receiptBusinessClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.receiptBusinessClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptBusinessClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.client.clientMunicipality
        );
  
        if (!validMunicipality) {
          this.receiptBusinessClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptBusinessClient.clientMunicipality = this.client.clientMunicipality || null;
        }
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }
    }
  }

  async changePaymentMethod(event) {
    this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentMethod = event;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptBusinessAddItemTypeMode = this.receiptBusinessDetail.receiptBusinessDetailIsService;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      );
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.receiptBusinessDetail.receiptBusinessDetailDescription = inventoryItem.inventoryName;
      this.receiptBusinessDetail.receiptBusinessDetailUnitPrice = inventoryItem.inventoryPrice;
      this.receiptBusinessDetail.receiptBusinessDetailInventory = inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.receiptBusinessDetail.receiptBusinessDetailDescription = packageItem.packageName;
        this.receiptBusinessDetail.receiptBusinessDetailUnitPrice = packageItem.packagePrice;
        this.receiptBusinessDetail.receiptBusinessDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.receiptBusinessClient.clientDepartment = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.receiptBusinessClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      case 'clientMunicipality':
        this.receiptBusinessClient.clientMunicipality = item.id;
        break;
      case 'sellerShow':
        this.receiptBusiness.receiptBusinessSeller = item.id;
        break;
      default:
        this.receiptBusinessClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.receiptBusinessClient[attributeBusinessCode] == undefined ||
        this.receiptBusinessClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.receiptBusinessClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.receiptBusinessDetail.receiptBusinessDetailSubtotal =
      this.receiptBusinessDetail.receiptBusinessDetailQuantity *
      this.receiptBusinessDetail.receiptBusinessDetailUnitPrice;
    this.receiptBusinessDetail.receiptBusinessDetailTotal =
      this.receiptBusinessDetail.receiptBusinessDetailQuantity *
      this.receiptBusinessDetail.receiptBusinessDetailUnitPrice -
      this.receiptBusinessDetail.receiptBusinessDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptBusinessDetail = {
      receiptBusinessDetailQuantity: 0,
      receiptBusinessDetailSubtotal: 0,
      receiptBusinessDetailTotal: 0,
      receiptBusinessDetailUnitPrice: 0,
      receiptBusinessDetailDiscount: 0,
      receiptBusinessDetailCost: 0,
      receiptBusinessDetailMeasure: 'UNI',
      receiptBusinessDetailInventory: 0,
      receiptBusinessDetailIsService: this.receiptBusinessDetail.receiptBusinessDetailIsService
    } as ReceiptBusinessDetail;
    this.receiptBusinessDetail.receiptBusinessDetailQuantity = 0;
    this.receiptBusinessDetail.receiptBusinessDetailUnitPrice = 0;
    if (this.receiptBusinessDetail.receiptBusinessDetailIsService == undefined) {
      this.receiptBusinessDetail.receiptBusinessDetailIsService = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.receiptBusinessDetail.receiptBusinessDetailIsService == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.receiptBusinessDetail.receiptBusinessDetailIsService = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;

      if (this.receiptBusinessDetail.receiptBusinessDetailIsService == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //   let packageList = await this.getPackage(user.userStore);
        //   let packageListInsert = new Array();
        //   for (const _package of packageList) {
        //     let insertPackage = true;
        //     for (const packageDetail of _package.packageDetail) {
        //       if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //         insertPackage = false;
        //       }
        //     }
        //     if (insertPackage) {
        //       packageListInsert.push(_package);
        //       inventoryList.push({
        //         inventoryCode: 'PACKAGE-' + _package.packageCode,
        //         inventoryName: _package.packageName,
        //         inventoryNameShow: _package.packageName,
        //       });
        //     }
        //   }
        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }
    }
    this.receiptBusinessAddItemTypeMode = this.receiptBusinessDetail.receiptBusinessDetailIsService;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.receiptBusinessDetail.receiptBusinessDetailDescription == undefined ||
      this.receiptBusinessDetail.receiptBusinessDetailUnitPrice == 0 ||
      this.receiptBusinessDetail.receiptBusinessDetailQuantity == 0 ||
      this.receiptBusinessDetail.receiptBusinessDetailSubtotal == 0 ||
      this.receiptBusinessDetail.receiptBusinessDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.receiptBusinessDetail.receiptBusinessDetailCode = uuid.v4();
      this.receiptBusiness.receiptBusinessDetail.push(this.receiptBusinessDetail);
      this.receiptBusiness.receiptBusinessDetail = [...this.receiptBusiness.receiptBusinessDetail];
      this.receiptBusinessDetail = {
        receiptBusinessDetailQuantity: 0,
        receiptBusinessDetailSubtotal: 0,
        receiptBusinessDetailTotal: 0,
        receiptBusinessDetailUnitPrice: 0,
        receiptBusinessDetailIsService: this.receiptBusinessDetail.receiptBusinessDetailIsService
      } as ReceiptBusinessDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.receiptBusiness.receiptBusinessDetail.find(
      (x) => x.receiptBusinessDetailCode == this.activeRow.receiptBusinessDetailCode
    );
    const indexDetail = this.receiptBusiness.receiptBusinessDetail.indexOf(findDetail);
    this.receiptBusiness.receiptBusinessDetail.splice(indexDetail, 1);
    this.receiptBusiness.receiptBusinessDetail = [...this.receiptBusiness.receiptBusinessDetail];
    await this.calculateTotals();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.receiptBusiness.receiptBusinessSubtotal = 0;
    this.receiptBusiness.receiptBusinessTotal = 0;
    for (const iterator of this.receiptBusiness.receiptBusinessDetail) {
      this.receiptBusiness.receiptBusinessSubtotal += iterator.receiptBusinessDetailSubtotal;
      this.receiptBusiness.receiptBusinessTotal += iterator.receiptBusinessDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.receiptBusinessClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.receiptBusinessClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receiptBusiness.receiptBusinessClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {
      this.receiptBusinessClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.receiptBusinessClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdate(this.receiptBusinessClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receiptBusiness.receiptBusinessClient = this.receiptBusinessClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  async save() {
    this.receiptBusinessClient.clientTaxNumber = this.receiptBusinessClient.clientTaxNumber.toUpperCase();
    await this.calculateTotals();
    if (this.receiptBusiness.receiptBusinessDocumentSearch == 1) {
      if (this.receiptBusinessClient.clientTaxNumber == undefined || this.receiptBusinessClient.clientTaxNumber == '') {
        this.receiptBusinessClient.clientTaxNumber = 'CF';
        this.receiptBusinessClient.clientName = 'Consumidor final';
      }
    }

    if (
      this.receiptBusiness.receiptBusinessDocumentSearch == 0 &&
      (
        this.receiptBusinessClient.clientIdentificationDocument == '' ||
        this.receiptBusinessClient.clientIdentificationDocument == undefined
      )
    ) {
      this.notificationService.showError("Debes ingresar un número de DPI.");
    } else if (this.receiptBusiness.receiptBusinessDetail.length == 0) {
      this.notificationService.showError(
        'Debes agregar detalle de venta.'
      );
    } else {

      if (this.receiptBusiness.receiptBusinessTotal >= 2500 &&
        (this.receiptBusinessClient.clientTaxNumber == "CF" || this.receiptBusinessClient.clientTaxNumber == "C/F"
          || this.receiptBusinessClient.clientTaxNumber == "cf" || this.receiptBusinessClient.clientTaxNumber == "c/f")) {
        this.notificationService.showWarning(
          'La venta es mayor a 2,500.00 por favor debe ingresar NIT o DPI'
        );

        return;
      }

      if (
        this.store.storeOnline == 0 &&
        this.receiptBusiness.receiptBusinessPayment.receiptBusinessPaymentMethod == 1
      ) {
        swal
          .fire({
            title:
              'Total Q' +
              this.receiptBusiness.receiptBusinessTotal +
              '<br/>Cantidad de efectivo:',
            // text: "¿Desea eliminar cuenta contable?",
            icon: 'question',
            html:
              '<div class="form-group">' +
              '<input id="input-field" type="text" class="form-control" />' +
              '</div>',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
            },
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              const efectivo = (
                document.getElementById('input-field') as HTMLInputElement
              ).value;
              swal.fire({
                title: 'Cambio',
                text:
                  'su cambio es ' +
                  (parseFloat(efectivo) -
                    parseFloat(this.receiptBusiness.receiptBusinessTotal)) +
                  '.',
                icon: 'info',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
              this.saveAction();
            }
          });
      } else {
        this.saveAction();
      }
    }
  }

  async saveAction() {
    if (this.quotationList != null) {
      this.receiptBusiness.receiptBusinessQuotation = this.quotationList.quotationCode;
    } else {
      this.receiptBusiness.receiptBusinessQuotation = null;
    }

    if (this.addressSelected == 0) {
      this.receiptBusiness.receiptBusinessClientAddressShow = 'Ciudad';
    } else {
      this.receiptBusiness.receiptBusinessClientAddressShow = `${this.receiptBusinessClient.clientAddress}, Zona ${this.receiptBusinessClient.clientAddressZone}, ${this.selected['clientDepartment'][0]['itemName']}, ${this.selected['clientMunicipality'][0]['itemName']}`;             
    }

    this.loading = true;
    if (await this.validateClient()) {
      let response;
      response = await this.receiptBusinessService.receiptBusinessInsert(this.receiptBusiness);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        if (response.result == true) {
          if (this.quotationList != undefined) {
            await this.saveStatusQuotation();
          }

          await this.notificationService.showSuccess(response.message);
          await window.open(
            environment.infilePdfUrl + response.receiptBusinessAuthorization,
            'popup',
            'width=600,height=600'
          );
          this.cancel();
        } else {
          this.notificationService.showError(response.message);
          for (const err of response.errorList) {
            console.error(err.mensaje_error);
          }
        }
      }
    }
    this.loading = false;
  }

  async saveStatusQuotation() {
    let response;

    const quotationUpdate = {
      quotationCode: this.quotationList.quotationCode,
      quotationStatus: 3
    }
    response = await this.quotationService.quotationUpdate(quotationUpdate);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.quotationList = undefined;
    }
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService
      .selleryGetCompanyEnable(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.receiptBusiness.receiptBusinessDetail[rowIndex][cell] = val;
    this.receiptBusiness.receiptBusinessDetail = [...this.receiptBusiness.receiptBusinessDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
