import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Package } from 'src/app/models/package';
import { PackageService } from 'src/app/services/package.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from 'sweetalert2';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-package-maintenance',
  templateUrl: './package-maintenance.component.html',
  styleUrls: ['./package-maintenance.component.scss'],
})
export class PackageMaintenanceComponent implements OnInit {
  loading = false;
  packageList: Package[];
  _package: Package;
  tableHeaders: [] = [];

  headerActionType: string;
  packageCreateMode: boolean = false;
  packageUpdateMode: boolean = false;
  packageDeleteMode: boolean = false;
  packageViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private packageService: PackageService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Promociones');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/productMaintenance/', '')
    );
    await this.permissionReview(permissionList);
    this.packageList = await this.getPackages(user.userStore);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getPackages(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear promoción';
    this.packageCreateMode = true;
    this._package = {} as Package;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this._package.packageCompany = user.userCompany;
    this._package.packageStore = user.userStore;
    this._package.packageStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver promoción';
    this.packageViewMode = true;
    this._package = event as Package;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar promoción';
    this.packageUpdateMode = true;
    this._package = event as Package;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar Promociones';
    this.packageDeleteMode = true;
    const packageDeleted = {
      packageCode: event.packageCode,
      packageDeleted: 1,
    };
    swal
      .fire({
        title: 'Atención',
        text: '¿Desea eliminar Promociones?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(packageDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.packageCreateMode = false;
    this.packageViewMode = false;
    this.packageUpdateMode = false;
    this.packageDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.packageList = await this.getPackages(user.userStore);
  }

  async saveAction(_package) {
    let response;
    this.loading = true;
    if (this.packageCreateMode == true) {
      response = await this.packageService.packageInsert(_package);
    }
    if (this.packageUpdateMode == true) {
      _package['packageModifyDate'] = new Date();
      response = await this.packageService.packageUpdate(_package);
    }
    if (this.packageDeleteMode == true) {
      _package['packageDeletedDate'] = new Date();
      response = await this.packageService.packageUpdate(_package);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }
}
