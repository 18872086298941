<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div *ngIf="!(routeGenerateMode || routeAssignMode || routeTrackingMode)">
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title"><i class="fas fa-route"></i> Rutas</h5>
          </div>
          <div class="card-body">
            <button class="btn btn-sm btn-success btn-simple" title="Generar" (click)="routeGenerate()" *ngIf="createPermission">
              <i class="fas fa-map"></i> Generar
            </button>
            <button class="btn btn-sm btn-info btn-simple" title="Listar" (click)="routeAssign()" *ngIf="assignPermission">
              <i class="fas fa-motorcycle"></i> Asignar
            </button>
            <button class="btn btn-sm btn-warning btn-simple" title="Seguimiento" (click)="routeTracking()" *ngIf="trackingPermission">
              <i class="fas fa-flag-checkered"></i> Seguimiento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-route-generate *ngIf="routeGenerateMode" (cancelAction)="cancelAction()">
  </app-route-generate>
  <app-route-assign *ngIf="routeAssignMode" (cancelAction)="cancelAction()">
  </app-route-assign>
  <app-route-tracking *ngIf="routeTrackingMode" (cancelAction)="cancelAction()">
  </app-route-tracking>
</div>
