import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Phrase } from 'src/app/models/phrase';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-phrase-maintenance-fields',
  templateUrl: './phrase-maintenance-fields.component.html',
  styleUrls: ['./phrase-maintenance-fields.component.scss']
})
export class PhraseMaintenanceFieldsComponent implements OnInit {
  loading = false;
  
  @ViewChild('form') form!: NgForm;


  @Input() headerActionType: string;
  @Input() phraseViewMode: boolean = false;
  @Input() phraseUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() phrase: Phrase = {} as Phrase;

  attributes: Attribute[] = new Array<Attribute>();
  
  list = {};
  selected = {};
  alert = false;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'phraseMaintenance'
    );   
  }
  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.phrase[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.phrase[attributeBusinessCode]
        );
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    if (this.form.valid) {
      const validated = this.phrase;
      this.saveAction.emit(validated);
    } else {
      this.notificationService.showError("Completa los campos obligatorios.");
    }
  }
    
}
