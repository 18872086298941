import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Inventory } from 'src/app/models/inventory';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from 'sweetalert2';
import { TitleService } from 'src/app/services/title.service';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-inventory-maintenance',
  templateUrl: './inventory-maintenance.component.html',
  styleUrls: ['./inventory-maintenance.component.scss'],
})
export class InventoryMaintenanceComponent implements OnInit {
  loading = false;
  inventoryList: Inventory[];
  inventory: Inventory;
  tableHeaders: [] = [];

  headerActionType: string;
  inventoryCreateMode: boolean = false;
  inventoryUpdateMode: boolean = false;
  inventoryDeleteMode: boolean = false;
  inventoryViewMode: boolean = false;
  inventoryUploadImageMode: boolean = false;
  inventoryDropMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;
  uploadImagePermission: boolean = false;
  packagePermission: boolean = false;
  productionPermission: boolean = false;
  dropPermission: boolean = false;

  constructor(
    private inventoryService: InventoryService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Productos');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
    this.inventoryList = await this.getInventorys(user.userStore);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          case 'uploadImage':
            this.uploadImagePermission = true;
            break;
          case 'package':
            this.packagePermission = true;
            break;
          case 'production':
            this.productionPermission = true;
            break;
          case 'drop':
            this.dropPermission = true;
            break;
          default:
            break;
        }
      }
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const findCompany = user.userCompanys.find(
        (x) => x.companyCode == user.userCompany
      );
      const findStore = findCompany.companyStores.find(
        (x) => x.storeCode == user.userStore
      );
      if (findStore.storePurchase != 1) {
        this.createPermission = false;
        this.updatePermission = false;
        this.deletePermission = false;
        this.uploadImagePermission = false;
        this.packagePermission = false;
        this.productionPermission = false;
        this.dropPermission = false;
      }
    }
  }

  async getInventorys(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear producto';
    this.inventoryCreateMode = true;
    this.inventory = {} as Inventory;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.inventory.inventoryCompany = user.userCompany;
    this.inventory.inventoryStore = user.userStore;
    this.inventory.inventoryStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver producto';
    this.inventoryViewMode = true;
    this.inventory = event as Inventory;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar producto';
    this.inventoryUpdateMode = true;
    this.inventory = event as Inventory;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar inventario';
    this.inventoryDeleteMode = true;
    const inventoryDeleted = {
      inventoryCode: event.inventoryCode,
      inventoryDeleted: 1,
    };
    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(inventoryDeleted);
        }
      });
  }

  async uploadImageAction(event) {
    this.headerActionType = 'Subir imágenes al producto';
    this.inventoryUploadImageMode = true;
    this.inventory = event as Inventory;
  }

  async dropAction() {
    this.headerActionType = 'Baja de inventario';
    this.inventoryDropMode = true;
  }

  async cancelAction(event) {
    this.inventoryCreateMode = false;
    this.inventoryViewMode = false;
    this.inventoryUpdateMode = false;
    this.inventoryUpdateMode = false;
    this.inventoryDeleteMode = false;
    this.inventoryUploadImageMode = false;
    this.inventoryDropMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.inventoryList = await this.getInventorys(user.userStore);
  }

  async saveAction(inventory) {
    let response = {} as any;
    this.loading = true;
    if (this.inventoryCreateMode == true) {
      response = await this.inventoryService.inventoryInsert(inventory);
    }
    if (this.inventoryUpdateMode == true) {
      inventory['inventoryModifyDate'] = new Date();
      response = await this.inventoryService.inventoryUpdate(inventory);
    }
    if (this.inventoryDeleteMode == true) {
      inventory['inventoryDeletedDate'] = new Date();
      response = await this.inventoryService.inventoryUpdate(inventory);
    }
    if (this.inventoryUploadImageMode == true) {
      for (const inventoryImage of inventory.inventoryImages) {
        const inventoryImageUpload = {
          inventoryCode: inventory.inventoryCode,
          inventoryImageCode: inventoryImage.inventoryImageCode,
          inventoryImageName: inventoryImage.inventoryImageName,
          inventoryImage: inventoryImage.inventoryImage,
        };
        if (inventoryImage.inventoryImageS3Key != undefined) {
          if (inventoryImage.inventoryImage != undefined) {
            response = await this.inventoryService.inventoryUpdateImage(
              inventoryImageUpload
            );
          } else {
            response['message'] = 'Sin cambios';
          }
        } else {
          response = await this.inventoryService.inventoryInsertImage(
            inventoryImageUpload
          );
        }
      }
    }
    if (this.inventoryDropMode == true) {
      response = await this.inventoryService.inventoryDrop(inventory);
    }
    if (response.errorMessage !== undefined) {
      if(response.errorMessage.includes("cannot be null")){
        this.notificationService.showError("Completa los campos obligatorios.");
      }else{
        this.notificationService.showError(response.errorMessage);
      }
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }
}
