<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" title="Cancelar">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" title="Guardar"
            *ngIf="!routesViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <div class="row" *ngIf="attributes">
          <div class="col-md-3" *ngFor="let attribute of attributes">
            <label>{{ attribute.attributeName }}</label>
            <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'text'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="routes[attribute.attributeBusinessCode]" maxlength="{{ attribute.attributeMaxlength }}" 
                  [readonly]="routesViewMode">
              </div>
              <div *ngSwitchCase="'number'">
                <input type="number" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}"
                  [(ngModel)]="routes[attribute.attributeBusinessCode]" maxlength="{{ attribute.attributeMaxlength }}"
                  [readonly]="routesViewMode">
              </div>
              <div *ngSwitchCase="'select'">
                <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                  *ngIf="list[attribute.attributeBusinessCode]" [(ngModel)]="selected[attribute.attributeBusinessCode]"
                  [settings]="dropdownSettings" (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)">
                </angular2-multiselect>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-3">
            <label>Agregar detalle de ruta:</label>
            <div class="form-group">
              <button type="button" class="btn btn-sm btn-default btn-simple" (click)="addRouteDetail()" title="Agregar"
                [disabled]="routesViewMode">Agregar detalle de ruta</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="row" *ngIf="routesAddItemMode">
          <div class="col-md-3">
            <label>Departamento</label>
            <div class="form-group">
              <angular2-multiselect [data]="list['routesDetailDepartment']"
                *ngIf="list['routesDetailDepartment'].length > 0" [(ngModel)]="selected['routesDetailDepartment']"
                [settings]="dropdownSettings" (onSelect)="onItemSelect($event,'routesDetailDepartment')">
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <label>Municipio</label>
            <div class="form-group">
              <angular2-multiselect [data]="list['routesDetailMunicipality']"
                *ngIf="list['routesDetailMunicipality'].length > 0" [(ngModel)]="selected['routesDetailMunicipality']"
                [settings]="dropdownSettings" (onSelect)="onItemSelect($event,'routesDetailMunicipality')">
              </angular2-multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <label>Zona</label>
            <div class="form-group">
              <input type="number" class="form-control pa" [(ngModel)]="routesDetail.routesDetailZone"
                placeholder="Zona" />
            </div>
          </div>
          <div class="col-md-3">
            <label>Agregar detalle</label>
            <div class="form-group">
              <button type="button" class="btn btn-sm btn-default btn-simple" (click)="insertRouteDetail()"
                title="Agregar">Agregar detalle</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="routes.routesDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
              [rows]="routes.routesDetail" (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Departamento" prop="routesDetailDepartmentShow"></ngx-datatable-column>
              <ngx-datatable-column name="Municipio" prop="routesDetailMunicipalityShow"></ngx-datatable-column>
              <ngx-datatable-column name="Zona" prop="routesDetailZone"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
