import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Routes } from '@angular/router';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import { RoutesService } from 'src/app/services/routes.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-route-generate',
  templateUrl: './route-generate.component.html',
  styleUrls: ['./route-generate.component.scss'],
})
export class RouteGenerateComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportData = new Array();
  reportDataFilter = new Array();

  routesList: Routes[];

  public entries: number = 20;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  reportStartDate;
  reportEndDate;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private routesService: RoutesService,
    private routeService: RouteService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(user.userRole, user.userCode, 'routeGenerate'),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    this.routesList = await this.getRoutes(user.userCompany);
    await this.getReport();
  }

  async refresh() {
    await this.ngOnInit();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async changeRoute(routeCode) {
    this.activeRow['shippingRoute'] = routeCode;
  }

  async save() {
    const routeDetail = this.reportData.filter(
      (x) => x['shippingRoute'] != undefined
    );
    const route = {
      routeDate: this.reportEndDate,
      routeDetail,
    };
    let response;
    this.loading = true;
    response = await this.routeService.routeInsert(route);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.routeService
      .routeGenerate(user.userStore, this.reportStartDate, this.reportEndDate)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getRoutes(company) {
    this.loading = true;
    return this.routesService
      .routesGetCompany(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
