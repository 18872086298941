import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Attribute } from 'src/app/models/attribute';
import { TableHeader } from 'src/app/models/tableHeader';
import { AttributeService } from 'src/app/services/attribute.service';
import { ExportService } from 'src/app/services/export.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReportService } from 'src/app/services/report.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-generate',
  templateUrl: './report-generate.component.html',
  styleUrls: ['./report-generate.component.scss'],
})
export class ReportGenerateComponent implements OnInit {
  loading = false;

  @Input() report;

  @Output() cancelAction = new EventEmitter<any>();

  attributes: Attribute[] = new Array<Attribute>();
  attributesShow: Attribute[] = new Array<Attribute>();

  reportParams = {} as any;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private tableHeader: TableHeaderService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      this.report.formBusinessCode
    );
    this.attributesShow = this.attributes.filter(
      (x) => x.attributeReadonly != 1
    );
    await this.setDefault();
    switch (this.report.formReportType) {
      case 1:
        this.tableHeaders = await this.sharedUtils.transformToTableHeader(
          await this.getTableHeader(
            user.userRole,
            user.userCode,
            this.report.formBusinessCode
          ),
          'tableHeaderTitle',
          'tableHeaderBusinessCode',
          'tableHeaderPipe'
        );
        await this.getReport();
        break;
      case 2:
        await this.getReport();
        const reportHeaders = new Array();
        if (this.reportData.length > 0) {
          for (const header of Object.keys(this.reportData[0])) {
            reportHeaders.push({
              tableHeaderTitle: header.split('-')[0],
              tableHeaderBusinessCode: header,
              tableHeaderPipe: header.split('-')[1],
            });
          }
          this.tableHeaders = await this.sharedUtils.transformToTableHeader(
            reportHeaders,
            'tableHeaderTitle',
            'tableHeaderBusinessCode',
            'tableHeaderPipe'
          );
        }
        break;
      case 3:
        this.tableHeaders = await this.sharedUtils.transformToTableHeader(
          await this.getTableHeader(
            user.userRole,
            user.userCode,
            this.report.formBusinessCode
          ),
          'tableHeaderTitle',
          'tableHeaderBusinessCode',
          'tableHeaderPipe'
        );
        await this.getReport();
        break;
    }
  }

  async setDefault() {
    this.report['total'] = {
      totalCash: {
        header: 'Total efectivo',
        value: 0,
      },
      totalCard: {
        header: 'Total tarjeta',
        value: 0,
      },
      totalDeposit: {
        header: 'Total depósito',
        value: 0,
      },
      totalTransfer: {
        header: 'Total transferencia',
        value: 0,
      },
      totalCheck: {
        header: 'Total cheque',
        value: 0,
      },
      totalPending: {
        header: 'Total pendiente',
        value: 0,
      },
      totalTotal: {
        header: 'Total ventas',
        value: 0,
      },
    };
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const store = JSON.parse(localStorage.getItem('store') || '{}');
    this.reportParams['reportSp'] = this.report.formReport;
    for (const attribute of this.attributes) {
      if (attribute.attributeType == 'number') {
        switch (attribute.attributeBusinessCode) {
          case 'store':
            this.reportParams[attribute.attributeBusinessCode] = user.userStore;
            break;
          case 'company':
            this.reportParams[attribute.attributeBusinessCode] =
              user.userCompany;
            break;
          case 'companySalesysV1':
            this.reportParams[attribute.attributeBusinessCode] =
              store.storeSalesysV1;
            break;
          case 'storeSalesysV1':
            this.reportParams[attribute.attributeBusinessCode] =
              store.storeSalesysV1;
            break;
          case 'user':
            this.reportParams[attribute.attributeBusinessCode] = user.userCode;
            break;
          case 'userSalesysV1':
            this.reportParams[attribute.attributeBusinessCode] =
              user.userSalesysV1;
            break;
          case 'limit':
            this.reportParams[attribute.attributeBusinessCode] = 1000000;
            break;
          case 'page':
            this.reportParams[attribute.attributeBusinessCode] = 1;
            break;
          default:
            break;
        }
      }
      if (attribute.attributeType == 'date') {
        this.reportParams[attribute.attributeBusinessCode] =
          await this.sharedUtils.formatDate();
      }
    }
  }

  async refresh() {
    await this.ngOnInit();
  }

  async getReport() {
    let { report, reportUrlS3, saleTotals } = await this.getData();
    switch (this.report.formReportType) {
      case 3:
        report = await this.getDataS3(reportUrlS3);
        this.reportData = [...report];
        this.reportDataFilter = [...this.reportData];
      default:
        this.validateSaleReport(saleTotals);
        this.reportData = [...report];
        this.reportDataFilter = [...this.reportData];
        break;
    }
  }

  async getData(): Promise<
    | any
    | {
        report: any;
        reportUrlS3: any;
        saleTotals: any;
      }
  > {
    this.loading = true;
    return this.reportService
      .reportGetData(this.reportParams)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return {
          report: response.records,
          reportUrlS3: response.reportUrlS3,
          saleTotals: response.saleTotals,
        };
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDataS3(url) {
    this.loading = true;
    return this.reportService
      .reportGetDataS3(url)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async validateSaleReport(saleTotals) {
    switch (this.reportParams.reportSp) {
      case 'reportSale':
        this.report['total'] = {
          totalCash: {
            header: 'Total efectivo',
            value: saleTotals.cash,
          },
          totalCard: {
            header: 'Total tarjeta',
            value: saleTotals.card,
          },
          totalDeposit: {
            header: 'Total depósito',
            value: saleTotals.deposit,
          },
          totalTransfer: {
            header: 'Total transferencia',
            value: saleTotals.transfer,
          },
          totalCheck: {
            header: 'Total cheque',
            value: saleTotals.paycheck,
          },
          totalPending: {
            header: 'Total pendiente',
            value: saleTotals.pending,
          },
          totalTotal: {
            header: 'Total ventas',
            value: saleTotals.total,
          },
        };
        break;
      case 'reportSaleUser':
        this.report['total'] = {
          totalCash: {
            header: 'Total efectivo',
            value: saleTotals.cash,
          },
          totalCard: {
            header: 'Total tarjeta',
            value: saleTotals.card,
          },
          totalDeposit: {
            header: 'Total depósito',
            value: saleTotals.deposit,
          },
          totalTransfer: {
            header: 'Total transferencia',
            value: saleTotals.transfer,
          },
          totalCheck: {
            header: 'Total cheque',
            value: saleTotals.paycheck,
          },
          totalPending: {
            header: 'Total pendiente',
            value: saleTotals.pending,
          },
          totalTotal: {
            header: 'Total ventas',
            value: saleTotals.total,
          },
        };
        break;
    }
  }

  async exportXLSX() {
    switch (this.reportParams.reportSp) {
      case 'reportSale':
        this.exportService.xlsxExportWithTotal(
          this.reportDataFilter,
          this.tableHeaders,
          'Reporte',
          'Reporte de ' + this.report.formName,
          this.report.total
        );
        break;
      case 'reportSaleUser':
        this.exportService.xlsxExportWithTotal(
          this.reportDataFilter,
          this.tableHeaders,
          'Reporte',
          'Reporte de ' + this.report.formName,
          this.report.total
        );
        break;
      default:
        this.exportService.xlsxExport(
          this.reportDataFilter,
          this.tableHeaders,
          'Reporte',
          'Reporte de ' + this.report.formName
        );
        break;
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    this.cancelAction.emit();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
    this.reportDataFilter = [...this.reportDataFilter];
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
