<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body"
            *ngIf="!clientViewMode">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <form #form="ngForm">
        <div class="card-body">
          <div class="row" *ngIf="attributes">
            <div class="col-md-3" *ngFor="let attribute of attributes">
              <label>{{ attribute.attributeName }}</label>
              <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
              <div class="form-group" [ngSwitch]="attribute.attributeType">
                <div *ngSwitchCase="'text'">
                  <input type="text" class="{{ attribute.attributeClass }}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}" [(ngModel)]="client[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}" [readonly]="clientViewMode || attribute.attributeUpdateReadonly == 1"
                    name="name_{{attribute.attributeBusinessCode}}" [required]="attribute.attributeRequired == 1">
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && client[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                </div>
                <div *ngSwitchCase="'email'">
                  <input type="text" class="{{ attribute.attributeClass }}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}" [(ngModel)]="client[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}" required="{{ attribute.attributeRequired }}"
                    pattern="{{ attribute.attributeRegExp }}" [readonly]="clientViewMode || attribute.attributeUpdateReadonly == 1"
                    name="name_{{attribute.attributeBusinessCode}}" [required]="attribute.attributeRequired == 1">
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && client[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                </div>
                <div *ngSwitchCase="'number'">
                  <input type="number" class="{{ attribute.attributeClass }}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}" [(ngModel)]="client[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}" required="{{ attribute.attributeRequired }}"
                    pattern="{{ attribute.attributeRegExp }}" [readonly]="clientViewMode || attribute.attributeUpdateReadonly == 1" min="0"
                    name="name_{{attribute.attributeBusinessCode}}" [required]="attribute.attributeRequired == 1">
                    <div *ngIf="alert">
                      <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && client[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                    </div>
                </div>
                <div *ngSwitchCase="'select'">
                  <angular2-multiselect [data]="list[attribute.attributeBusinessCode]"
                    *ngIf="list[attribute.attributeBusinessCode]" [(ngModel)]="selected[attribute.attributeBusinessCode]"
                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event,attribute.attributeBusinessCode)"
                    name="name_{{attribute.attributeBusinessCode}}" [required]="attribute.attributeRequired == 1"
                    [readonly]="attribute.attributeUpdateReadonly == 1">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && selected[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
