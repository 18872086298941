import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-maintenance-toolbox',
  templateUrl: './color-maintenance-toolbox.component.html',
  styleUrls: ['./color-maintenance-toolbox.component.scss'],
})
export class ColorMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  async createColor() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshColor() {
    window.location.reload();
  }
}
