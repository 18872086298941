import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Addendum } from 'src/app/models/addendum';
import { NgForm } from '@angular/forms';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { AddendumService } from 'src/app/services/addendum.service';
import { ListArray } from 'src/app/models/listArray';

@Component({
  selector: 'app-addendum-maintenance-fields',
  templateUrl: './addendum-maintenance-fields.component.html',
  styleUrls: ['./addendum-maintenance-fields.component.scss'],
})
export class AddendumMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() addendumViewMode: boolean = false;
  @Input() addendumUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() addendum: Addendum = {} as Addendum;

  attributes: Attribute[] = new Array<Attribute>();

  bankList = new Array();
  list = {};
  selected = {};
  alert = false;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
  };

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private addendumService: AddendumService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'addendumMaintenance'
    );

    const statusList = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'addendumStatus');
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.addendum[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.addendum[attributeBusinessCode]
        );
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelectBank(item: ListArray) {
    this.addendum.addendumStatus = item.id;
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    if(this.form.valid){
      const validatedUser = this.addendum;
      this.saveAction.emit(validatedUser);
    }else{
      const validatedUser = false;
      this.saveAction.emit(validatedUser);
    }
    
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }
}
