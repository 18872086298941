import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-package-maintenance-toolbox',
  templateUrl: './package-maintenance-toolbox.component.html',
  styleUrls: ['./package-maintenance-toolbox.component.scss'],
})
export class PackageMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {}

  async createPackage() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshPackage() {
    this.refreshAction.emit();
  }

  async cancel() {
    this.router.navigate(['inventoryMaintenance']);
  }
}
