import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Article } from 'src/app/models/article';
import { User } from 'src/app/models/user';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
import { RoleService } from 'src/app/services/role.service';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-article-maintenance-fields',
  templateUrl: './article-maintenance-fields.component.html',
  styleUrls: ['./article-maintenance-fields.component.scss'],
})
export class ArticleMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() articleViewMode: boolean = false;
  @Input() articleUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() article: Article = {} as Article;
  @Input() user: User = {} as User;

  attributes: Attribute[] = new Array<Attribute>();

  list = {};
  selected = {};

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
  };

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private articleService: ArticleService,
    private roleService: RoleService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'articleMaintenance'
    );

    const statusList = await this.sharedUtils.getStatusSelectP();
    await this.addSelectList(statusList, 'id', 'itemName', 'articleType');

    const statusList1 = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList1, 'id', 'itemName', 'articleStatus');
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.article[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.article[attributeBusinessCode]
        );
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      default:
        this.article[attributeBusinessCode] = item.id;

        break;
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    const validatedUser = this.article;
    this.saveAction.emit(validatedUser);
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }
}
