import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shipping-schedule',
  templateUrl: './shipping-schedule.component.html',
  styleUrls: ['./shipping-schedule.component.scss'],
})
export class ShippingScheduleComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private shippingService: ShippingService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'shippingSchedule'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    await this.getReport();
  }

  async refresh() {
    await this.ngOnInit();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async save(event) {
    event.preventDefault();
    switch (this.activeRow.saleType) {
      case 'Recibo':
        this.activeRow.saleInvoice = null;
        this.activeRow.saleInvoiceExchange = null;
        break;
      case 'Factura':
        this.activeRow.saleInvoiceExchange = null;
        break;
      case 'Factura Cambiaria':
        this.activeRow.saleInvoiceExchange = this.activeRow.saleInvoice;
        this.activeRow.saleReceipt = null;
        this.activeRow.saleInvoice = null;
        break;
    }
    let response;
    this.loading = true;
    response = await this.shippingService.shippingInsert(this.activeRow);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      window.open(response.shippingPdfUrl, 'popup', 'width=600,height=600');
      await this.ngOnInit();
    }
    this.loading = false;
  }

  updateValue(event, cell, rowIndex) {
    var val = event instanceof Date ? event : event.target.value;
    this.reportDataFilter[rowIndex][cell] = val;
    this.reportDataFilter = [...this.reportDataFilter];
  }

  async getReport() {
    const report = await this.getData();
    for (const data of report) {
      data['shippingDate'] = await this.sharedUtils.formatDate();
    }
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.shippingService
      .shippingGetStore(user.userStore)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
