<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Generar Nota de Crédito
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="creditNote.creditNoteDate"
                  (change)="validateDate($event)" disabled />
              </div>
              <div class="col-md-2">
                <label>Nit</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="creditNoteClient.clientTaxNumber"
                    (focusout)="searchClient()" placeholder="Nit" disabled />
                </div>
              </div>
              <div class=" col-md-3">
                <label>Nombre</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="creditNoteClient.clientName" placeholder="Nombre"
                    disabled />
                </div>
              </div>
              <div class="col-md-3">
                <label>Correo Electronico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="creditNoteClient.clientEmail"
                    placeholder="Correo Electronico" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <label>Dirección</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="creditNoteClient.clientAddress"
                    placeholder="Dirección" disabled />
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <!-- <div class="col-md-2">
                <label>Ingresar articulo al detalle:</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="addItem()">Ingresar
                    articulo</button>
                </div>
              </div> -->
              <div class="col-md-2">
                <label>Moneda</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="creditNote.creditNoteCurrency" disabled
                    (change)="changeCurrency($event.target.value)">
                    <option value="GTQ">Q - Quetzales</option>
                    <option value="USD">$ - Dolares</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2" *ngIf="this.creditNote.creditNoteChangeRate != 1">
                <label>Tasa de cambio</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #FFFFFF;"
                    [(ngModel)]="creditNote.creditNoteChangeRate" placeholder="Tasa de cambio" [disabled]="true" />
                </div>
              </div>
              <!-- <div class="col-md-2">
                <label>Tipo de pago</label>
                <div class="form-group">
                  <select class="form-control" [ngModel]="creditNote.creditNotePayment.creditNotePaymentMethod">
                    <option value="1">Efectivo</option>
                    <option value="2">Tarjeta</option>
                    <option value="3">Cheque</option>
                    <option value="4">Deposito</option>
                    <option value="5">Transferencia</option>
                  </select>
                </div>
              </div> -->
              <!-- <div class="col-md-2" *ngIf="creditNote.creditNotePayment.creditNotePaymentMethod != 1">
                <label>Numero Autorización</label>
                <div class="form-group">
                  <input type="number" class="form-control" style="background: #FFFFFF;"
                    [(ngModel)]="creditNote.creditNotePayment.creditNotePaymentTransferNumber" placeholder="Numero Autorización"
                    [disabled]="true" />
                </div>
              </div> -->
            </div>
            <div class="row" *ngIf="creditNoteAddItemMode">
              <div class="col-md-2">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailIsService"
                    (change)="changeDetailType($event.target.value)">
                    <option style="display:none">Seleccione</option>
                    <option value="B" *ngIf="creditNoteSaleInventory">Bien</option>
                    <option value="S" *ngIf="creditNoteSaleService">Servicio</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="creditNoteAddItemTypeMode == 'B'">
                <label>Productos</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-3" *ngIf="creditNoteAddItemTypeMode == 'S'">
                <label>Descripción</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailDescription"
                    placeholder="Descripción" />
                </div>
              </div>
              <div class="col-md-1">
                <label>P. unitario</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailUnitPrice"
                    placeholder="Precio unitario" (blur)="calculate()" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Cantidad</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailQuantity"
                    placeholder="Cantidad" (blur)="calculate()" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Descuento</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailDiscount"
                    placeholder="Descuento" (blur)="calculate()" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Subtotal</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailSubtotal"
                    placeholder="Subtotal" disabled="true" />
                </div>
              </div>
              <div class="col-md-2">
                <label>Total</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="creditNoteDetail.creditNoteDetailTotal"
                    placeholder="Total" disabled="true" />
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar:</label>
                <div class="form-group">
                  <button class="btn btn-sm btn-info btn-simple" (click)="insertItem()"><i
                      class="fas fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="creditNote.creditNoteDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
              [rows]="creditNote.creditNoteDetail" (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descripción" prop="creditNoteDetailDescription"></ngx-datatable-column>
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.creditNoteDetailUnitPrice | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.creditNoteDetailQuantity | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descuento">
                <ng-template ngx-datatable-header-template>
                  <span>Descuento</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.creditNoteDetailDiscount | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Subtotal">
                <ng-template ngx-datatable-header-template>
                  <span>Subtotal</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.creditNoteDetailSubtotal | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{row.creditNoteDetailTotal | number:'1.2-2'}}
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
