import { Injectable } from '@angular/core';
import * as xlsx from 'xlsx';
@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor() {}

  async xlsxExport(rows, headers, bookName: string, reportName: string) {
    const exportFile = new Array<any>();
    for (const row of rows) {
      let json = {};
      for (const header of headers) {
        switch (header['pipe']) {
          case 'currency':
            json[header['name']] = parseFloat(row[header['prop']]);
            break;
          default:
            json[header['name']] = row[header['prop']];
            break;
        }
      }
      exportFile.push(json);
    }
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, bookName);
    xlsx.writeFile(wb, `${reportName}.xlsx`);
  }

  async xlsxExportWithTotal(
    rows,
    headers,
    bookName: string,
    reportName: string,
    saleTotals
  ) {
    const exportFile = new Array<any>();
    for (const row of rows) {
      let json = {};
      for (const header of headers) {
        switch (header['pipe']) {
          case 'currency':
            json[header['name']] = parseFloat(row[header['prop']]);
            break;
          default:
            json[header['name']] = row[header['prop']];
            break;
        }
      }
      exportFile.push(json);
    }
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, bookName);

    const exportTotalFile = new Array<any>();
    let rowTotals = Object.keys(saleTotals).map((_) => {
      return saleTotals[_];
    });
    for (const rowTotal of rowTotals) {
      let json = {};
      json['Total'] = rowTotal['header'];
      json['Monto'] = parseFloat(rowTotal['value']);
      exportTotalFile.push(json);
    }
    const wsTotal: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportTotalFile);
    xlsx.utils.book_append_sheet(wb, wsTotal, 'Totales');

    xlsx.writeFile(wb, `${reportName}.xlsx`);
  }

  async downloadImage(imageUrl, fileName) {

    const response = await fetch(imageUrl);

    const blob = await response.blob();

    const downloadLink = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = downloadLink;
    a.download = `${fileName}.pdf`;
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(downloadLink);
    
  }
}