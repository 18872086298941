import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async purchaseRequest(purchase) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-purchase/request', purchase, {
        headers,
      })
      .toPromise();
  }

  async purchaseInsert(purchase) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-purchase', purchase, { headers })
      .toPromise();
  }

  async purchaseGetStore(userStore): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-purchase/store/' + userStore + queryParam,
        { headers }
      )
      .toPromise();
  }

  async purchaseGetCompamy(company, start, end): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-purchase/company/' +
          company +
          '/' +
          start +
          '/' +
          end +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async purchaseAuthorize(purchase): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-purchase/authorized', purchase, {
        headers,
      })
      .toPromise();
  }

  async purchaseUnauthorize(purchase): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-purchase/unauthorized', purchase, {
        headers,
      })
      .toPromise();
  }

  async purchaseDelete(purchase): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-purchase/delete', purchase, {
        headers,
      })
      .toPromise();
  }
}
