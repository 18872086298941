import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { SalesImage } from 'src/app/models/salesImage';
import { SalesService } from 'src/app/services/sales.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/export.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-client-voucher',
  templateUrl: './client-voucher.component.html',
  styleUrls: ['./client-voucher.component.scss']
})
export class ClientVoucherComponent implements OnInit {
  loading;
  @Input() headerActionType;
  @Input() sales: any;
  @Input() client: any;

  @Output() cancelImageAction = new EventEmitter<any>();
  @Output() saveImageAction = new EventEmitter<any>();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;
  saleImageList: Array<SalesImage> = new Array();
  saleImageListDelete: Array<SalesImage> = new Array();
  classImageBtnRead = "";
  classImageBtnDownload = "";
  classImageBtnDelete = "";
  public saleImageListView: any[] = [];


  constructor(
    private sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private salesService: SalesService,
    private exportService: ExportService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const images = await this.getSalesGetImages(this.sales.saleCode, this.sales.saleType);    

    console.log(this.sales);
    console.log(images);

    this.saleImageList = images.salesImages.map((sale, index) => {
      const urlObj = new URL(sale.saleImageUrl);
      const fileNameWithExtension = urlObj.pathname.split('/').pop();
      const fileName = fileNameWithExtension.replace(/\.[^/.]+$/, ""); 
      const decodedFileName = decodeURIComponent(fileName);    
      return {
        index: index + 1,
        saleImageFileName: decodedFileName, 
        ...sale,
      };
    });
    
  }

  async readImage(imageUrl) {
    window.open(imageUrl, 'popup', 'width=600,height=600');
  }

  downloadImage(imageUrl, fileName) {
    this.exportService.downloadImage(
      imageUrl,
      fileName
    );
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  async delete(saleImageCode) {
    const index = this.saleImageList.findIndex(x => x.saleImageCode === saleImageCode);
  
    if (index !== -1) {
      const deletedImage = this.saleImageList[index];
  
      this.saleImageList = this.saleImageList.filter((_, i) => i !== index);
  
      this.saleImageListDelete.push(deletedImage);
    }
  }

  async deleteImage(saleImageCode) {   
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.delete(saleImageCode);
      }
    });
  }
  

  async getSalesGetImages(code, type) {
    this.loading = true;
    return this.salesService.salesGetImages(code, type).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async cancel(event) {
    event.preventDefault();
    const client = this.client;
    this.cancelImageAction.emit(client);
  }

  async save(event) {
    const client = this.client;
    const sales = await this.getImageModel();
    await this.saveImage(sales);
    this.cancelImageAction.emit(client);
  }

  async getImageModel() {
    const saleImageUpload = {
      saleCode: this.sales.saleCode,
      saleType: this.sales.saleType,
      salesImage: new Array()
    };
    for (const saleImage of this.saleImageListDelete) {
      if (saleImage.saleImageCode != undefined)
        saleImageUpload.salesImage.push({
          saleImageCode: saleImage.saleImageCode
        });
    }
    return saleImageUpload;
  }

  async saveImage(sales) {
    let response;
    this.loading = true;

    for (const saleImage of sales.salesImage) {
      const saleImageUpload = {
        saleCode: sales.saleCode,
        saleType: sales.saleType,
        saleImageCode: saleImage.saleImageCode,
        saleImage: undefined,
      };
      if (saleImage.saleImageCode != undefined) {
        response = await this.salesService.salesUpdateImage(
          saleImageUpload
        );
      }
    }    
    this.loading = false;
  }

}
