<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Generar ruta</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-danger btn-simple"
            (click)="cancel()"
            title="Cancelar"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            class="btn btn-sm btn-default btn-simple"
            (click)="refresh()"
            title="Refrescar"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success btn-simple"
            (click)="save()"
            title="Guardar"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <!-- <div class="col-md-4">
            <label>Desde:</label>
            <div class="form-group">
              <input type="date" class="form-control" [(ngModel)]="reportStartDate" disabled />
            </div>
          </div> -->
          <div class="col-md-4">
            <label>Fecha de Ruta:</label>
            <div class="form-group">
              <input
                type="date"
                class="form-control"
                [(ngModel)]="reportEndDate"
              />
            </div>
          </div>
          <div class="col-md-3">
            <br />
            <div class="form-group">
              <button
                class="btn btn-info btn-sale"
                style="width: 100%"
                (click)="getReport()"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class="form-control form-control-sm"
                    (change)="entriesChange($event)"
                  >
                    <option value="20" [selected]="entries == 20">20</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="100" [selected]="entries == 100">100</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input
                    type="search"
                    class="form-control form-control-md"
                    placeholder="Buscar"
                    aria-controls="datatable"
                    (keyup)="filterTable($event)"
                  />
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            *ngIf="reportData !== undefined && tableHeaders.length > 0"
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="entries != -1 ? entries : undefined"
            [rows]="reportDataFilter"
            (activate)="onActivate($event)"
          >
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="true"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                Rutas
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <select
                  class="form-control pa"
                  (change)="changeRoute($event.target.value)"
                >
                  <option style="display: none">Seleccione</option>
                  <option
                    value="{{ routes.routesCode }}"
                    *ngFor="let routes of routesList"
                  >
                    {{ routes.routesName }}
                  </option>
                </select>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              *ngFor="let tableHeader of tableHeaders"
              [name]="tableHeader.name"
            >
              <ng-template ngx-datatable-header-template>
                <span>{{ tableHeader.name }}</span>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                [ngSwitch]="tableHeader.pipe"
              >
                <span *ngSwitchCase="'text'">
                  {{ row[tableHeader.prop] }}
                </span>
                <span *ngSwitchCase="'date'">
                  {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                </span>
                <span *ngSwitchCase="'currency'">
                  {{ row[tableHeader.prop] | number : "1.2-2" }}
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
