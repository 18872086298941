<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success btn-simple"
            (click)="save()"
            ngbTooltip="Guardar"
            container="body"
            *ngIf="!companyViewMode"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <form #form="ngForm">
        <div class="card-body">
          <div class="row" *ngIf="attributes">
            <div class="col-md-3" *ngFor="let attribute of attributes">
              <label>{{ attribute.attributeName }}</label>
              <label style="color: red" *ngIf="attribute.attributeRequired == 1"
                >*</label
              >
              <div class="form-group" [ngSwitch]="attribute.attributeType">
                <div *ngSwitchCase="'text'">
                  <input
                    type="text"
                    class="{{ attribute.attributeClass }}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    name="name_{{ attribute.attributeBusinessCode }}"
                    [required]="attribute.attributeRequired == 1"
                    [(ngModel)]="company[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}"
                    disabled="{{ companyViewMode }}"
                    [disabled]="attribute.attributeUpdateReadonly == 1 && companyUpdateMode || companyViewMode || resolution == true && attribute.attributeBusinessCode == 'companyResolutionNumber'"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && company[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
                </div>
                <!-- <div *ngSwitchCase="'email'">
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [(ngModel)]="company[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.permissionMaxlength }}"
                    name="name_{{ attribute.attributeBusinessCode }}"
                    [required]="attribute.attributeRequired == 1"
                    [readonly]="
                      (companyViewMode == true || companyUpdateMode == true) &&
                      attribute.attributeBusinessCode == 'companyEmail'
                    "
                    [disabled]="companyViewMode"
                  />
                </div> -->
                <div *ngSwitchCase="'number'">
                  <input
                    type="number"
                    class="form-control"
                    autocomplete="off"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [(ngModel)]="company[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.permissionMaxlength }}"
                    name="name_{{ attribute.attributeBusinessCode }}"
                    [required]="attribute.attributeRequired == 1"
                    [disabled]="companyViewMode || personality == true && attribute.attributeBusinessCode == 'companyPersonality'"
                    min="0"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && company[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
                </div>
                <div *ngSwitchCase="'select'">
                  <angular2-multiselect
                    [data]="list[attribute.attributeBusinessCode]"
                    name="name_{{ attribute.attributeBusinessCode }}"
                    *ngIf="list[attribute.attributeBusinessCode]"
                    [(ngModel)]="selected[attribute.attributeBusinessCode]"
                    [settings]="dropdownSettings"
                    (onSelect)="
                      onItemSelect($event, attribute.attributeBusinessCode)
                    "
                    [disabled]="!companyViewMode || attribute.attributeUpdateReadonly == 1"
                  >
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && selected[attribute.attributeBusinessCode] == null">Campo obligatorio.</label>
                  </div>
                </div>
                <div *ngSwitchCase="'image'">
                  <div *ngIf="company.companyLogoUrl">
                    <img [src]="company.companyLogoUrl" alt="Logo" />
                  </div>
                  <span [class]="classImageBtn">
                    <span class="fileinput-new">JPG/PNG</span>
                    <input
                      *ngIf="!companyViewMode"
                      type="file"
                      (change)="addFile($event)"
                    />
                  </span>
                </div>
                <div *ngSwitchCase="'textarea'">
                  <textarea
                    class="{{ attribute.attributeClass }}"
                    autocomplete="{{ attribute.attributeAutocomplete }}"
                    placeholder="{{ attribute.attributePlaceholder }}"
                    [(ngModel)]="company[attribute.attributeBusinessCode]"
                    maxlength="{{ attribute.attributeMaxlength }}"
                    disabled="{{ companyViewMode }}"
                    name="name_{{ attribute.attributeBusinessCode }}"
                  >
                    Política de recibo
                  </textarea>
                </div>
                <div *ngSwitchCase="'date'">
                  <input type="date" class="form-control" name="name_{{ attribute.attributeBusinessCode }}"
                    [(ngModel)]="company[attribute.attributeBusinessCode]" [readonly]="companyViewMode" 
                    [disabled]="resolution == true && attribute.attributeBusinessCode == 'companyResolutionDate'"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="attribute.attributeRequired == 1 && company[attribute.attributeBusinessCode] == null">
                      Campo obligatorio.
                    </label>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer"></div>
    </div>
  </div>
</div>
<!-- <div class="row" *ngIf="companyViewMode">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Establecimientos</h4>
      </div>
      <div class="card-body">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class="form-control form-control-sm"
                    (change)="entriesChange($event)"
                  >
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input
                    type="search"
                    class="form-control form-control-md"
                    placeholder="Buscar"
                    aria-controls="datatable"
                    (keyup)="filterTable($event)"
                  />
                </label>
              </div>
            </div>
          </div>

          <ngx-datatable
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="entries != -1 ? entries : undefined"
            [rows]="storeListFilter"
            (activate)="onActivate($event)"
          >
            <ngx-datatable-column
              *ngFor="let tableHeader of tableHeaders"
              [name]="tableHeader.name"
              [prop]="tableHeader.prop"
            >
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</div> -->
