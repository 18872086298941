import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-user-forgot-password-modal',
  templateUrl: './user-forgot-password-modal.component.html',
  styleUrls: ['./user-forgot-password-modal.component.scss']
})
export class UserForgotPasswordModalComponent implements OnInit {
  code;
  newPassword;
  public showPassword: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss('Cancelado por usuario.');
  }

  passBack() {

    if (!this.validatePasswordPolicy(this.newPassword)) {
      return;
    }
    
    const reset = {
      verificationCode: this.code,
      newPassword: this.newPassword
    };
    this.activeModal.close(reset);
  }

  showHidePassword() {
    let elemento: any = document.getElementById('pass');

    if (this.showPassword) {
      this.showPassword = false;
      elemento.type = "password";
    }
    else {
      this.showPassword = true;
      elemento.type = "text";
    }
  }

  validatePasswordPolicy(password: string): boolean {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-]{8,}$/;

    if (regex.test(password)) {
      return true;
    } else {
      this.notificationService.showError('La clave no cumple con los requisitos necesarios. Verifica y vuelve a intentar.');
      return false;
    }
  }
}
