import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';
import { StoreService } from 'src/app/services/store.service';
import swal from 'sweetalert2';
import { Store } from 'src/app/models/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-maintenance',
  templateUrl: './company-maintenance.component.html',
  styleUrls: ['./company-maintenance.component.scss'],
})
export class CompanyMaintenanceComponent implements OnInit {
  loading = false;
  companyList: Company[];
  company: Company;
  store: Store;

  headerActionType: string;
  companyCreateMode: boolean = false;
  companyUpdateMode: boolean = false;
  companyDeleteMode: boolean = false;
  companyViewMode: boolean = false;
  companyStoreCreationMode: boolean = false;
  companyShowMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  createStorePermission: boolean = false;
  showPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  page = 1;
  entries = environment.tableEntrys;

  constructor(
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private storeService: StoreService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Mantenimiento');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
    if (this.readPermission) {
      this.companyList = await this.getCompanys(
        user.userCode,
        this.page,
        this.entries
      );
    }
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
          case 'createStore':
            this.createStorePermission = true;
            break;
          case 'show':
            this.showPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getCompanys(userCode, page, entries) {
    this.loading = true;
    return this.companyService
      .companyGetUser(userCode, page, entries)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Crear empresa';
    this.companyCreateMode = true;
    this.companyViewMode = false;
    this.company = {} as Company;
    this.company.companyUser = user.userCode;
    this.company.companyInventoryImage = 6;
    this.company.companyAttempt = 5;
    this.company.companyStatus = 1;
    this.company.companyReceipt = 0;
    this.company.companyReceiptRecurring = 0;
    this.company.companyReceiptBusiness = 0;
    this.company.companyInvoice = 0;
    this.company.companyInvoiceRecurring = 0;
    this.company.companyInvoiceExchange = 0;
    this.company.companyReceiptSend = 0;
    this.company.companyInventory = 0;
    this.company.companyClient = 0;
    this.company.companyResolution = 0;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver empresa';
    this.companyCreateMode = false;
    this.companyViewMode = true;
    this.company = event as Company;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar empresa';
    this.companyUpdateMode = true;
    this.companyViewMode = false;
    this.company = event as Company;
    this.company.companyLogo = undefined;
  }

  async storeCreationAction(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Agregar establecimiento';
    this.companyStoreCreationMode = true;
    this.company = event as Company;
    this.store = {} as Store;
    this.store.storeCompany = user.userCompany;
  }

  async companyShowAction(event) {
    this.headerActionType = 'Ver Empresa';
    this.companyShowMode = true;
    this.company = event as Company;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar empresa';
    this.companyDeleteMode = true;
    const companyDeleted = {
      companyCode: event.companyCode,
      companyDeleted: 1,
    };

    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(companyDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.companyCreateMode = false;
    this.companyViewMode = false;
    this.companyUpdateMode = false;
    this.companyDeleteMode = false;
    this.companyStoreCreationMode = false;

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.companyList = await this.getCompanys(
      user.userCode,
      this.page,
      this.entries
    );
  }

  async saveAction(company) {
    if (company != false) {
      let response;
      this.loading = true;

      if (this.companyCreateMode == true) {
        response = await this.companyService.companyInsert(company);
      }
      if (this.companyUpdateMode == true) {
        company['companyModifyDate'] = new Date();
        response = await this.companyService.companyUpdate(company);
      }
      if (this.companyDeleteMode == true) {
        company['companyDeletedDate'] = new Date();
        response = await this.companyService.companyUpdate(company);
      }
      if (this.companyStoreCreationMode == true) {
        response = await this.storeService.storeInsert(company);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(event);
      }
      this.loading = false;
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }
}
