import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { ClientService } from 'src/app/services/client.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.scss'],
})
export class ClientHistoryComponent implements OnInit {
  loading = false;

  clientVoucherMode: boolean = false;

  @Input() client: Client;
  @Input() headerActionType: string;

  @Output() cancelAction = new EventEmitter<any>();

  salesTotal = [];
  salesTotalFilter = [];
  salesTotalHeader = {
    clientCreditAmount: 0,
    clientCreditTime: 0,
    totalBalance: 0,
    totalCreditAmount: 0,
    totalPaymentMade: 0,
    totalSales: 0
  };

  sales;

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  totalDays: number = 0;
  totalHours;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private clientService: ClientService
  ) { }

  async ngOnInit(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'clientMaintenanceSummary'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );

    await this.setDefault();    
  }

  async setDefault() {
    const data = await this.getData(this.client.clientCode);
    this.salesTotal = [...data.salesTotal];
    this.salesTotalFilter = [...this.salesTotal];
    this.salesTotalHeader = data.salesTotalHeader;
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getData(clientCode) {
    this.loading = true;
    return this.clientService
      .clientGetSummary(clientCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.salesTotalFilter = this.salesTotal.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  isValidDate(dateString): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) && dateString !== '0000-00-00' && dateString !== '00-00-0000';
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async viewSale(event) {
    event.preventDefault();
    if (this.activeRow.saleType === 'Factura Cambiaria' || this.activeRow.saleType === 'Factura') {
      window.open(
        environment.infilePdfUrl + this.activeRow.saleInvoiceAuthorization,
        'popup',
        'width=600,height=600'
      );
    }

    if (this.activeRow.saleType === 'Recibo') {
      window.open(
        this.activeRow.saleReceiptUrl,
        'popup',
        'width=600,height=600'
      );
    }  
  }

  async viewVoucher(event) {
    event.preventDefault();
    this.clientVoucherMode = true;
    this.client as Client;
    this.sales = this.activeRow;
  }

  async cancelImageAction(event) {
    this.clientVoucherMode = false;
    this.setDefault();
  }

}
