import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-maintenance-toolbox',
  templateUrl: './user-maintenance-toolbox.component.html',
  styleUrls: ['./user-maintenance-toolbox.component.scss']
})
export class UserMaintenanceToolboxComponent implements OnInit {

  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();
  @Output() downloadExcelAction = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  async downloadToExcel(){
    this.downloadExcelAction.emit();
   }

  async createUser() {
    this.createAction.emit();
  }

  async refreshUser() {
    this.refreshAction.emit();
  }
  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

}
