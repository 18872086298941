import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptRecurringService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async receiptRecurringInsert(receiptRecurring) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-receiptRecurring', receiptRecurring, { headers })
      .toPromise();
  }

  async receiptRecurringGetDate(
    userStore,
    reportStartDate,
    reportEndDate
  ): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-receiptRecurring/By/Date/' +
        userStore +
        '/' +
        reportStartDate +
        '/' +
        reportEndDate +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async receiptRecurringUpdate(receiptRecurring) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + '/imfel-receiptRecurring/', receiptRecurring, { headers })
      .toPromise();
  }
}
