<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row" *ngIf="!(sellerCreateMode || sellerUpdateMode || sellerViewMode)">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Listado de vendedores
          </h4>
          <div class="toolbar">
            <app-seller-maintenance-toolbox [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"></app-seller-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-seller-maintenance-table [sellerList]="sellerList" (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission" [updatePermission]="updatePermission"
            [deletePermission]="deletePermission" [viewPermission]="viewPermission" [xlsxExportDate]="xlsxExportDate">
          </app-seller-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-seller-maintenance-fields *ngIf="sellerCreateMode || sellerUpdateMode || sellerViewMode"
    [headerActionType]="headerActionType" [sellerViewMode]="sellerViewMode" [sellerUpdateMode]="sellerUpdateMode"
    [seller]="seller" (cancelAction)="cancelAction()" (saveAction)="saveAction($event)">
  </app-seller-maintenance-fields>
</div>
