<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    *ngIf="
      !(
        receiptGenerateMode ||
        receiptListMode ||
        receiptBusinessGenerateMode ||
        receiptBusinessListMode ||
        invoiceGenerateMode ||
        invoiceListMode ||
        invoiceExchangeGenerateMode ||
        invoiceExchangeListMode ||
        creditNoteGenerateMode ||
        creditNoteListMode
      )
    "
  >
    <div class="row" *ngIf="company.companyReceipt == 1">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title"><i class="fas fa-receipt"></i> Recibo</h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="receiptGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="receiptList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="company.companyReceiptBusiness == 1">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-file-invoice"></i> Recibo comercial
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="receiptBusinessGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="receiptBusinessList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="company.companyInvoice == 1">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-file-invoice"></i> Factura comercial
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="invoiceGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="invoiceList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="store.storeOnline == 1 && company.companyInvoiceExchange == 1"
    >
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-file-invoice"></i> Factura cambiaria
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="invoiceExchangeGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="invoiceExchangeList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-receipt-generate
    *ngIf="receiptGenerateMode"
    (cancelAction)="cancelAction()"
    [clientSaleList]="clientSaleList"
    [quotationList]="quotationList"
  ></app-receipt-generate>
  <app-receipt-list
    *ngIf="receiptListMode"
    (cancelAction)="cancelAction()"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
  ></app-receipt-list>

  <app-invoice-generate
    *ngIf="invoiceGenerateMode"
    (cancelAction)="cancelAction()"
    [clientSaleList]="clientSaleList"
    [quotationList]="quotationList"
  ></app-invoice-generate>
  <app-invoice-list
    *ngIf="invoiceListMode"
    (cancelAction)="cancelAction()"
    (creditNoteAction)="creditNoteInvoiceGenerate($event)"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
  >
  </app-invoice-list>

  <app-receipt-business-generate
    *ngIf="receiptBusinessGenerateMode"
    (cancelAction)="cancelAction()"
    [clientSaleList]="clientSaleList"
    [quotationList]="quotationList"
  ></app-receipt-business-generate>
  <app-receipt-business-list
    *ngIf="receiptBusinessListMode"
    (cancelAction)="cancelAction()"
    (creditNoteAction)="creditNoteReceiptBusinessGenerate($event)"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
  >
  </app-receipt-business-list>

  <app-invoice-exchange-generate
    *ngIf="invoiceExchangeGenerateMode"
    (cancelAction)="cancelAction()"
    [clientSaleList]="clientSaleList"
    [quotationList]="quotationList"
  >
  </app-invoice-exchange-generate>
  <app-invoice-exchange-list
    *ngIf="invoiceExchangeListMode"
    (cancelAction)="cancelAction()"
    (creditNoteAction)="creditNoteInvoiceExchangeGenerate($event)"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
  >
  </app-invoice-exchange-list>

  <app-credit-note-generate
    *ngIf="creditNoteGenerateMode"
    (cancelAction)="cancelAction()"
    [invoice]="creditNoteInvoice"
    [invoiceExchange]="creditNoteInvoiceExchange"
  >
  </app-credit-note-generate>
</div>
