import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from 'src/app/models/company';
import { TableHeader } from 'src/app/models/tableHeader';
import { NotificationService } from 'src/app/services/notification.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { ExportService } from 'src/app/services/export.service';
@Component({
  selector: 'app-company-maintenance-table',
  templateUrl: './company-maintenance-table.component.html',
  styleUrls: ['./company-maintenance-table.component.scss'],
})
export class CompanyMaintenanceTableComponent implements OnInit {
  loading = false;

  @Input() companyList: Array<Company>;

  @Input() readPermission: boolean;
  @Input() updatePermission: boolean;
  @Input() deletePermission: boolean;
  @Input() viewPermission: boolean;
  @Input() createStorePermission: boolean;
  @Input() showPermission: boolean;

  @Input() xlsxExportDate: any;

  @Input() page;
  @Input() entries = environment.tableEntrys;

  @Output() updateAction = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();
  @Output() storeCreationAction = new EventEmitter<any>();
  @Output() companyShowAction = new EventEmitter<any>();
  clientListFilter: Array<Company>;
  activeRow: any;
  tableHeaders: TableHeader[] = new Array<TableHeader>();
  lastxlsxExportDate;

  constructor(
    public sharedUtils: SharedUtils,
    private tableHeader: TableHeaderService,
    private notificationService: NotificationService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'companyMaintenance'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode'
    );
  }

  async ngOnChanges() {
    this.clientListFilter = this.companyList;
    if (this.lastxlsxExportDate !== this.xlsxExportDate) {
      await this.exportXLSX();
    }
  }

  async exportXLSX() {
    this.exportService.xlsxExport(
      this.clientListFilter,
      this.tableHeaders,
      'Reporte',
      'Reporte de empresas'
    );
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.clientListFilter = this.companyList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  view(event) {
    event.preventDefault();
    this.viewAction.emit(this.activeRow);
  }

  update(event) {
    event.preventDefault();
    this.updateAction.emit(this.activeRow);
  }

  delete(event) {
    event.preventDefault();
    this.deleteAction.emit(this.activeRow);
  }

  storeCreate(event) {
    event.preventDefault();
    this.storeCreationAction.emit(this.activeRow);
  }

  companyShow(event) {
    event.preventDefault();
    this.companyShowAction.emit(this.activeRow);
  }
}
