import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Package } from 'src/app/models/package';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';
import { environment } from 'src/environments/environment';
import { InventoryService } from 'src/app/services/inventory.service';
import { PackageDetail } from 'src/app/models/packageDetail';
const uuid = require('uuid');

@Component({
  selector: 'app-package-maintenance-fields',
  templateUrl: './package-maintenance-fields.component.html',
  styleUrls: ['./package-maintenance-fields.component.scss'],
})
export class PackageMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() packageViewMode: boolean = false;
  @Input() packageUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() package: Package = {} as Package;
  packageDetail: PackageDetail = {} as PackageDetail;

  attributes: Attribute[] = new Array<Attribute>();

  packageAddItemMode = false;

  packageList = new Array();
  inventoryList = new Array();
  list = {};
  selected = {};

  dropdownSettings = {};

  entries: number = environment.tableEntrys;
  activeRow: any;
  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private inventoryService: InventoryService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'packageMaintenance'
    );

    const statusList = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'packageStatus');

    const inventoryList = await this.getInventory(user.userStore);
    await this.addSelectList(
      inventoryList,
      'inventoryCode',
      'inventoryNameShow',
      'packageDetailInventory'
    );

    if (this.packageViewMode) {
      this.fntDropdownSettings(true);
    } else {
      this.fntDropdownSettings(false);
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.package[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.package[attributeBusinessCode]
        );
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    this.package[attributeBusinessCode] = item.id;
  }

  async onItemSelectDetail(item: ListArray, attributeBusinessCode) {
    switch (attributeBusinessCode) {
      case 'packageDetailInventory':
        this.packageDetail[attributeBusinessCode] = item.id;
        this.packageDetail['packageDetailInventoryShow'] = item.itemName;
        break;
      default:
        this.packageDetail[attributeBusinessCode] = item.id;
        break;
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  addPackageDetail() {
    this.packageAddItemMode = true;
  }

  async save() {
    const validatedUser = this.package;
    this.saveAction.emit(validatedUser);
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }

  insertPackageDetail() {
    this.packageDetail.packageDetailCode = uuid.v4();
    if (this.package.packageDetail == undefined) {
      this.package.packageDetail = new Array<PackageDetail>();
    }
    this.package.packageDetail.push(this.packageDetail);
    this.package.packageDetail = [...this.package.packageDetail];
    this.packageDetail = {} as PackageDetail;
    this.selected['packageDetailInventory'] = undefined;
    this.packageAddItemMode = false;
  }

  deleteItem(event) {
    event.preventDefault();
    const findDetail = this.package.packageDetail.find(
      (x) => x.packageDetailCode == this.activeRow.packageDetailCode
    );
    const indexDetail = this.package.packageDetail.indexOf(findDetail);
    this.package.packageDetail.splice(indexDetail, 1);
    this.package.packageDetail = [...this.package.packageDetail];
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
