import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-maintenance-toolbox',
  templateUrl: './inventory-maintenance-toolbox.component.html',
  styleUrls: ['./inventory-maintenance-toolbox.component.scss'],
})
export class InventoryMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;
  @Input() packagePermission: boolean;
  @Input() productionPermission: boolean;
  @Input() dropPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();
  @Output() dropAction = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {}

  async createInventory() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async package() {
    this.router.navigate(['inventoryMaintenance/packageMaintenance']);
  }

  async production() {
    this.router.navigate(['inventoryMaintenance/productionMaintenance']);
  }

  async drop() {
    this.dropAction.emit();
  }

  async refreshInventory() {
    this.refreshAction.emit();
  }
}
