import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BankAccount } from 'src/app/models/bankAccount';
import { BankAccountService } from 'src/app/services/bank-account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-bank-account-maintenance',
  templateUrl: './bank-account-maintenance.component.html',
  styleUrls: ['./bank-account-maintenance.component.scss']
})
export class BankAccountMaintenanceComponent implements OnInit {
  loading = false;
  bankAccountList: BankAccount[];
  bankAccount: BankAccount;
  tableHeaders: [] = [];

  headerActionType: string;
  bankAccountCreateMode: boolean = false;
  bankAccountUpdateMode: boolean = false;
  bankAccountDeleteMode: boolean = false;
  bankAccountViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private bankAccountService: BankAccountService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Cuentas bancarias"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.bankAccountList = await this.getBankAccounts(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getBankAccounts(company) {
    this.loading = true;
    return this.bankAccountService.bankAccountGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear cuenta bancaria';
    this.bankAccountCreateMode = true;
    this.bankAccount = {} as BankAccount;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.bankAccount.bankAccountCompany = user.userCompany;
    this.bankAccount.bankAccountStore = user.userStore;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver cuenta bancaria';
    this.bankAccountViewMode = true;
    this.bankAccount = event as BankAccount;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar cuenta bancaria';
    this.bankAccountUpdateMode = true;
    this.bankAccount = event as BankAccount;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar cuenta bancaria';
    this.bankAccountDeleteMode = true;
    const bankAccountDeleted = {
      bankAccountCode: event.bankAccountCode,
      bankAccountDeleted: 1
    };
    swal.fire({
      title: "Atención",
      text: "¿Desea eliminar cuenta bancaria?",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(bankAccountDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.bankAccountCreateMode = false;
    this.bankAccountViewMode = false;
    this.bankAccountUpdateMode = false;
    this.bankAccountDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.bankAccountList = await this.getBankAccounts(user.userCompany);
  }

  async saveAction(bankAccount) {
    let response;
    this.loading = true;
    if (this.bankAccountCreateMode == true) {
      response = await this.bankAccountService.bankAccountInsert(bankAccount);
    }
    if (this.bankAccountUpdateMode == true) {
      bankAccount['bankAccountModifyDate'] = new Date();
      response = await this.bankAccountService.bankAccountUpdate(bankAccount);
    }
    if (this.bankAccountDeleteMode == true) {
      bankAccount['bankAccountDeletedDate'] = new Date();
      response = await this.bankAccountService.bankAccountUpdate(bankAccount);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;

  }
}