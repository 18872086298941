import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async shippingGetStore(userStore): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-shipping/store/' + userStore + queryParam,
        { headers }
      )
      .toPromise();
  }

  async shippingSchedule(userStore, startDate, endDate): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-shipping/schedule/' +
          userStore +
          '/' +
          startDate +
          '/' +
          endDate +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async shippingInsert(shipping): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-shipping/', shipping, { headers })
      .toPromise();
  }

  async shippingCancel(shipping): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-shipping/cancel/', shipping, {
        headers,
      })
      .toPromise();
  }
}
