import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-store-maintenance-toolbox',
  templateUrl: './store-maintenance-toolbox.component.html',
  styleUrls: ['./store-maintenance-toolbox.component.scss']
})
export class StoreMaintenanceToolboxComponent implements OnInit {
  loading = false;

  @Input() createPermission: boolean; @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  async createStore() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshStore() {
    this.refreshAction.emit();
  }

}
