import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { InvoiceExchange } from 'src/app/models/invoiceExchange';
import { InvoiceExchangeDetail } from 'src/app/models/invoiceExchangeDetail';
import { InvoiceExchangeSubscription } from 'src/app/models/InvoiceExchangeSubscription';
import { ListArray } from 'src/app/models/listArray';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { InvoiceExchangeService } from 'src/app/services/invoiceExchange.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PackageService } from 'src/app/services/package.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');
import swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-exchange-generate',
  templateUrl: './invoice-exchange-generate.component.html',
  styleUrls: ['./invoice-exchange-generate.component.scss'],
})
export class InvoiceExchangeGenerateComponent implements OnInit {
  loading = false;

  @Input() clientSaleList;
  @Input() quotationList;

  @Output() cancelAction = new EventEmitter<any>();

  invoiceExchange: InvoiceExchange = {
    invoiceExchangeClient: 0,
    invoiceExchangeClientAddressShow: 'Ciudad',
    invoiceExchangeDetail: new Array<InvoiceExchangeDetail>(),
    invoiceExchangeSubscription: new Array<InvoiceExchangeSubscription>(),
    invoiceExchangePayment: {
      invoiceExchangePaymentMethod: 1,
    },
  } as InvoiceExchange;

  company = {} as Company;
  invoiceExchangeDetail = {
    invoiceExchangeDetailQuantity: 0,
    invoiceExchangeDetailSubtotal: 0,
    invoiceExchangeDetailTotal: 0,
    invoiceExchangeDetailUnitPrice: 0,
    invoiceExchangeDetailDiscount: 0,
    invoiceExchangeDetailCost: 0,
    invoiceExchangeDetailMeasure: 'UNI',
    invoiceExchangeDetailInventory: 0,
  } as InvoiceExchangeDetail;
  invoiceExchangeClient = {} as Client;
  client = {} as Client;

  invoiceExchangeSaleService = false;
  invoiceExchangeSaleBenefit = false;
  invoiceExchangeSaleInventory = false;
  invoiceExchangeInventory = false;

  invoiceExchangeAddItemTypeMode = 'N';
  items = false;

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  public nowDay: string;
  public now: string;
  public date: string;

  alert = false;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;

  clientInf;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private invoiceExchangeService: InvoiceExchangeService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private quotationService: QuotationService
  ) { }

  async ngOnInit() {
    await this.setDefault();

    //OBTENER FECHA
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.now = yyyy + '-' + mm + '-' + dd;
    this.invoiceExchange.invoiceExchangeDate = yyyy + '-' + mm + '-' + dd;
    this.nowDay = this.invoiceExchange.invoiceExchangeDate;
    this.date = this.now;
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.invoiceExchange.invoiceExchangeDate =
      await this.sharedUtils.formatDate();
    this.invoiceExchange.invoiceExchangeUser = user.userCode;
    this.invoiceExchange.invoiceExchangeCompany = user.userCompany;
    this.invoiceExchange.invoiceExchangeStore = user.userStore;
    const companys = (await this.getCompany(user.userCompany)) as Company[];
    this.company = companys[0];
    this.invoiceExchangeClient.clientCode = undefined;
    this.invoiceExchangeClient.clientAddress = environment.clientDefaultAddress;
    this.invoiceExchangeClient.clientEmail = this.company.companyEmail;
    this.invoiceExchangeClient.clientCompany = this.company.companyCode;
    this.invoiceExchangeClient.clientPhoneOne = null;
    this.invoiceExchangeClient.clientPhoneTwo = null;
    this.invoiceExchangeClient.clientPhoneThree = null;
    this.invoiceExchangeClient.clientAddressZone = '0';
    this.invoiceExchangeClient.clientDepartment = null;
    this.invoiceExchangeClient.clientMunicipality = null;
    this.invoiceExchangeClient.clientCredit = null;
    this.invoiceExchangeClient.clientCreditTime = null;
    this.invoiceExchangeClient.clientCreditAmount = null;
    this.invoiceExchange.invoiceExchangeCurrency = 'GTQ';
    this.invoiceExchange.invoiceExchangeChangeRate = 1;
    this.invoiceExchangeSaleInventory = Boolean(
      this.company.companySaleInventory
    );
    this.invoiceExchangeSaleService = Boolean(this.company.companySaleService);
    this.invoiceExchangeSaleBenefit = Boolean(this.company.companySaleBenefit);
    this.invoiceExchangeInventory = Boolean(this.company.companyInventory);
    this.invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentMethod = 1;
    this.invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentQuantity = 1;
    this.invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentTime = 1;
    this.invoiceExchange.invoiceExchangePayment.invoiceExchangePaymentTimeMethod = 1;
    this.invoiceExchange.invoiceExchangeTotal = 0;

    this.invoiceExchange.invoiceExchangeDocumentSearch = 1;
    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
    ]).then(async (response) => {
      this.invoiceExchangeClient.clientDepartment =
        response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.invoiceExchangeClient.clientDepartment
      );
      this.invoiceExchangeClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];
      this.company = companys[0];
      this.invoiceExchangeClient.clientEmail = this.company.companyEmail;
      this.invoiceExchangeClient.clientCompany = this.company.companyCode;
    });

    if (this.clientSaleList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      const client = (await this.getClient(this.clientSaleList.clientCode || '')) as Client;

      this.invoiceExchangeClient.clientCode = client.clientCode;
      this.invoiceExchangeClient.clientName = client.clientName;
      this.invoiceExchangeClient.clientTaxNumber = client.clientTaxNumber;
      this.invoiceExchangeClient.clientIdentificationDocument = client.clientIdentificationDocument;
      this.invoiceExchangeClient.clientPhoneOne = client.clientPhoneOne + ' ' + client.clientPhoneTwo + ' ' + client.clientPhoneThree;
      this.invoiceExchangeClient.clientPhone = client.clientPhoneOne;
      this.invoiceExchangeClient.clientEmail = client.clientEmail || this.invoiceExchangeClient.clientEmail;
      this.invoiceExchangeClient.clientAddress = client.clientAddress || this.invoiceExchangeClient.clientAddress;
      this.invoiceExchangeClient.clientAddressZone = client.clientAddressZone || this.invoiceExchangeClient.clientAddressZone;

      if (client['clientDepartment'] != undefined) {
        this.invoiceExchangeClient.clientDepartment = client.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceExchangeClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === client.clientMunicipality
        );
        if (!validMunicipality) {
          this.invoiceExchangeClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceExchangeClient.clientMunicipality = client.clientMunicipality || null;
        } 
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

      if (client.clientTaxNumber && client.clientTaxNumber != 'CF' && client.clientTaxNumber.length > 5) {
        this.invoiceExchange.invoiceExchangeDocumentSearch = 1;
      } else if (client.clientIdentificationDocument && client.clientIdentificationDocument.length == 13) {
        this.invoiceExchange.invoiceExchangeDocumentSearch = 0;
      } else {
        if (!client.clientTaxNumber || client.clientTaxNumber === '') {
          client.clientTaxNumber = 'CF';
        }
        this.invoiceExchange.invoiceExchangeDocumentSearch = 1;
      }

    }

    if (this.quotationList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      this.clientInf = (await this.getClient(this.quotationList.quotationClient || '')) as Client;

      for (const items of this.quotationList.quotationDetail) {
        this.invoiceExchangeDetail.invoiceExchangeDetailCode = uuid.v4();
        this.invoiceExchangeDetail.invoiceExchangeDetailDescription = items.quotationDetailDescription;
        this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice = parseFloat(items.quotationDetailUnitPrice);
        this.invoiceExchangeDetail.invoiceExchangeDetailQuantity = parseFloat(items.quotationDetailQuantity);
        this.invoiceExchangeDetail.invoiceExchangeDetailDiscount = 0;
        this.invoiceExchangeDetail.invoiceExchangeDetailSubtotal = parseFloat(items.quotationDetailTotal);
        this.invoiceExchangeDetail.invoiceExchangeDetailTotal = parseFloat(items.quotationDetailTotal);
        this.invoiceExchangeDetail.invoiceExchangeDetailInventory = parseFloat(items.quotationDetailInventory);
        this.invoiceExchangeDetail.invoiceExchangeDetailIsService = items.quotationDetailType;
        this.invoiceExchange.invoiceExchangeDetail.push(this.invoiceExchangeDetail);
        this.invoiceExchange.invoiceExchangeDetail = [...this.invoiceExchange.invoiceExchangeDetail];
        this.invoiceExchangeDetail = {
          invoiceExchangeDetailDescription: undefined,
          invoiceExchangeDetailQuantity: 0,
          invoiceExchangeDetailSubtotal: 0,
          invoiceExchangeDetailTotal: 0,
          invoiceExchangeDetailUnitPrice: 0,
          invoiceExchangeDetailDiscount: 0,
          invoiceExchangeDetailCost: 0,
          invoiceExchangeDetailInventory: 0,
        } as InvoiceExchangeDetail;
        await this.calculateTotals();
      }

      this.invoiceExchangeClient.clientCode = this.clientInf.clientCode;
      this.invoiceExchangeClient.clientName = this.clientInf.clientName;
      this.invoiceExchangeClient.clientTaxNumber = this.clientInf.clientTaxNumber;
      this.invoiceExchangeClient.clientIdentificationDocument = this.clientInf.clientIdentificationDocument;
      this.invoiceExchangeClient.clientPhoneOne = this.clientInf.clientPhoneOne + ' ' + this.clientInf.clientPhoneTwo + ' ' + this.clientInf.clientPhoneThree;
      this.invoiceExchangeClient.clientPhone = this.clientInf.clientPhoneOne;
      this.invoiceExchangeClient.clientEmail = this.clientInf.clientEmail || this.invoiceExchangeClient.clientEmail;
      this.invoiceExchangeClient.clientAddress = this.clientInf.clientAddress || this.invoiceExchangeClient.clientAddress;
      this.invoiceExchangeClient.clientAddressZone = this.clientInf.clientAddressZone || this.invoiceExchangeClient.clientAddressZone;

      if (this.clientInf['clientDepartment'] != undefined) {
        this.invoiceExchangeClient.clientDepartment = this.clientInf.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceExchangeClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          this.clientInf.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.clientInf.clientMunicipality
        );
        if (!validMunicipality) {
          this.invoiceExchangeClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceExchangeClient.clientMunicipality = this.clientInf.clientMunicipality || null;
        }  
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

    }

  }

  async cancel() {
    this.clientSaleList = undefined;
    this.quotationList = undefined;
    await this.setDefault();
    this.cancelAction.emit();
  }

  async searchClient() {
    if (this.clientSaleList == undefined && this.quotationList == undefined) {
      if (this.invoiceExchange.invoiceExchangeDocumentSearch == 1
        && (this.invoiceExchangeClient.clientTaxNumber == '' || this.invoiceExchangeClient.clientTaxNumber == undefined)) {
        this.notificationService.showError("Debes ingresar un NIT.");
      } else if (this.invoiceExchange.invoiceExchangeDocumentSearch ==
        0 && (this.invoiceExchangeClient.clientIdentificationDocument == '' || this.invoiceExchangeClient.clientIdentificationDocument == undefined)) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      } else {

        if (this.invoiceExchange.invoiceExchangeDocumentSearch == 1) {
          this.client = (await this.getClientGetTaxNumber(
            this.invoiceExchangeClient?.clientTaxNumber || 'CF'
          )) as Client;
        } else {
          this.client = (await this.getClientGetIdentificationDocument(
            this.invoiceExchangeClient?.clientIdentificationDocument || ''
          )) as Client;
        }
        this.invoiceExchangeClient.clientCode = this.client.clientCode;
        this.invoiceExchangeClient.clientName = this.client.clientName;
        this.invoiceExchangeClient.clientIdentificationDocument =
          this.client.clientIdentificationDocument;
        this.invoiceExchangeClient.clientEmail =
          this.client.clientEmail || this.invoiceExchangeClient.clientEmail;  
        if (this.client['clientDepartment'] != undefined) {
          this.invoiceExchangeClient.clientDepartment = this.client.clientDepartment;
          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.invoiceExchangeClient['clientDepartment'] || null
          );
          const municipalityList = await this.getMunicipality(
            this.client.clientDepartment
          );
          const validMunicipality = municipalityList.find(
            (mun) => mun.municipalityCode === this.client.clientMunicipality
          );
    
          if (!validMunicipality) {
            this.invoiceExchangeClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
          } else {
            this.invoiceExchangeClient.clientMunicipality = this.client.clientMunicipality || null;
          }
    
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }

      }
    }
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  } 

  async validateDate($event) {
    $event.preventDefault();

    const nowDate = new Date(this.nowDay);
    const inputDate = new Date(this.invoiceExchange.invoiceExchangeDate);

    const minDate = new Date(nowDate);
    minDate.setDate(nowDate.getDate() - 5);

    const maxDate = new Date(nowDate);
    maxDate.setDate(nowDate.getDate() + 5);

    if (inputDate >= minDate && inputDate <= maxDate) {
    } else {
      this.invoiceExchange.invoiceExchangeDate = this.nowDay;
      this.notificationService.showWarning('Fecha fuera de rango.');
    }
  }

  async changeCurrency(event) {
    this.invoiceExchange.invoiceExchangeChangeRate =
      event == 'GTQ' ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.invoiceExchange.invoiceExchangeDocumentSearch = event;
  }

  async changeAddress(event) {

    if (event == 0) {
      this.invoiceExchangeClient.clientAddress = 'Ciudad';
    } else {
      this.invoiceExchangeClient.clientAddress = this.client.clientAddress || this.invoiceExchangeClient.clientAddress;;
      this.invoiceExchangeClient.clientAddressZone = this.client.clientAddressZone || this.invoiceExchangeClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.invoiceExchangeClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.invoiceExchangeClient['clientDepartment']
        );
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.client.clientMunicipality
        );
  
        if (!validMunicipality) {
          this.invoiceExchangeClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.invoiceExchangeClient.clientMunicipality = this.client.clientMunicipality || null;
        }
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }

    }
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceExchangeAddItemTypeMode =
      this.invoiceExchangeDetail.invoiceExchangeDetailIsService;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      );
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      );
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.invoiceExchangeDetail.invoiceExchangeDetailDescription =
        inventoryItem.inventoryName;
      this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice =
        inventoryItem.inventoryPrice;
      this.invoiceExchangeDetail.invoiceExchangeDetailInventory =
        inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.invoiceExchangeDetail.invoiceExchangeDetailDescription =
          packageItem.packageName;
        this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice =
          packageItem.packagePrice;
        this.invoiceExchangeDetail.invoiceExchangeDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.invoiceExchangeClient[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.invoiceExchangeClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      default:
        this.invoiceExchangeClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.invoiceExchangeClient[attributeBusinessCode] == undefined ||
        this.invoiceExchangeClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.invoiceExchangeClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.invoiceExchangeDetail.invoiceExchangeDetailSubtotal =
      this.invoiceExchangeDetail.invoiceExchangeDetailQuantity *
      this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice;
    this.invoiceExchangeDetail.invoiceExchangeDetailTotal =
      this.invoiceExchangeDetail.invoiceExchangeDetailQuantity *
      this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice -
      this.invoiceExchangeDetail.invoiceExchangeDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.invoiceExchangeDetail = {
      invoiceExchangeDetailQuantity: 0,
      invoiceExchangeDetailSubtotal: 0,
      invoiceExchangeDetailTotal: 0,
      invoiceExchangeDetailUnitPrice: 0,
      invoiceExchangeDetailDiscount: 0,
      invoiceExchangeDetailCost: 0,
      invoiceExchangeDetailMeasure: 'UNI',
      invoiceExchangeDetailInventory: 0,
      invoiceExchangeDetailIsService: this.invoiceExchangeDetail.invoiceExchangeDetailIsService
    } as InvoiceExchangeDetail;
    this.invoiceExchangeDetail.invoiceExchangeDetailQuantity = 0;
    this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice = 0;
    if (this.invoiceExchangeDetail.invoiceExchangeDetailIsService == undefined) {
      this.invoiceExchangeDetail.invoiceExchangeDetailIsService = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.invoiceExchangeDetail.invoiceExchangeDetailIsService == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.invoiceExchangeDetail.invoiceExchangeDetailIsService = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;

      if (this.invoiceExchangeDetail.invoiceExchangeDetailIsService == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //   let packageList = await this.getPackage(user.userStore);
        //   let packageListInsert = new Array();
        //   for (const _package of packageList) {
        //     let insertPackage = true;
        //     for (const packageDetail of _package.packageDetail) {
        //       if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //         insertPackage = false;
        //       }
        //     }
        //     if (insertPackage) {
        //       packageListInsert.push(_package);
        //       inventoryList.push({
        //         inventoryCode: 'PACKAGE-' + _package.packageCode,
        //         inventoryName: _package.packageName,
        //         inventoryNameShow: _package.packageName,
        //       });
        //     }
        //   }
        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }
    }
    this.invoiceExchangeAddItemTypeMode = this.invoiceExchangeDetail.invoiceExchangeDetailIsService;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.invoiceExchangeDetail.invoiceExchangeDetailDescription == undefined ||
      this.invoiceExchangeDetail.invoiceExchangeDetailUnitPrice == 0 ||
      this.invoiceExchangeDetail.invoiceExchangeDetailQuantity == 0 ||
      this.invoiceExchangeDetail.invoiceExchangeDetailSubtotal == 0 ||
      this.invoiceExchangeDetail.invoiceExchangeDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.invoiceExchange.invoiceExchangeSubscription = [];
      this.invoiceExchange.invoiceExchangePaymentQuantity = '';
      this.invoiceExchange.invoiceExchangePaymentFrequency = '';
      this.invoiceExchangeDetail.invoiceExchangeDetailCode = uuid.v4();
      this.invoiceExchange.invoiceExchangeDetail.push(this.invoiceExchangeDetail);
      this.invoiceExchange.invoiceExchangeDetail = [
        ...this.invoiceExchange.invoiceExchangeDetail,
      ];
      this.invoiceExchangeDetail = {
        invoiceExchangeDetailQuantity: 0,
        invoiceExchangeDetailSubtotal: 0,
        invoiceExchangeDetailTotal: 0,
        invoiceExchangeDetailUnitPrice: 0,
        invoiceExchangeDetailIsService: this.invoiceExchangeDetail.invoiceExchangeDetailIsService
      } as InvoiceExchangeDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
      if (this.invoiceExchange.invoiceExchangeDetail.length > 0) {
        this.items = true;
      } else {
        this.items = false;
      }
    }
  }

  async calculateComplement() {
    this.invoiceExchange.invoiceExchangeTotal = 0;
    this.invoiceExchange.invoiceExchangeSubscription = [];
    for (const invoiceExchangeDetail of this.invoiceExchange
      .invoiceExchangeDetail) {
      this.invoiceExchange.invoiceExchangeTotal +=
        invoiceExchangeDetail.invoiceExchangeDetailTotal;
    }

    const amaunt =
      parseFloat(this.invoiceExchange.invoiceExchangeTotal.toFixed(4)) /
      this.invoiceExchange.invoiceExchangePaymentQuantity;

    var date = this.invoiceExchange.invoiceExchangeDate.split('-');
    const invoiceExchangeDate = new Date(date[0], date[1] - 1, date[2]);
    let _date;
    for (
      let i = 0;
      i < this.invoiceExchange.invoiceExchangePaymentQuantity;
      i++
    ) {
      _date = invoiceExchangeDate.setDate(
        invoiceExchangeDate.getDate() +
        parseInt(this.invoiceExchange.invoiceExchangePaymentFrequency)
      );
      const d = new Date(_date);
      var dd = String(d.getDate()).padStart(2, '0');
      var mm = String(d.getMonth() + 1).padStart(2, '0');
      var yyyy = d.getFullYear();

      const invoiceExchangeSubscription: InvoiceExchangeSubscription = {
        invoiceExchangeSubscriptionNumber: i + 1,
        invoiceExchangeSubscriptionAmount: parseFloat(amaunt.toFixed(4)),
        invoiceExchangeSubscriptionDate: yyyy + '-' + mm + '-' + dd,
      };
      await this.invoiceExchange.invoiceExchangeSubscription.push(
        invoiceExchangeSubscription
      );
    }
    this.invoiceExchange.invoiceExchangeSubscription = [
      ...this.invoiceExchange.invoiceExchangeSubscription,
    ];
  }

  addDaysToDate(date, days) {
    var res = new Date(date);
    res.setDate(date.getDate() + days);
    return res;
  }

  async deleteItem(event) {
    event.preventDefault();
    this.invoiceExchange.invoiceExchangeSubscription = [];
    this.invoiceExchange.invoiceExchangePaymentQuantity = '';
    this.invoiceExchange.invoiceExchangePaymentFrequency = '';
    const findDetail = this.invoiceExchange.invoiceExchangeDetail.find(
      (x) =>
        x.invoiceExchangeDetailCode == this.activeRow.invoiceExchangeDetailCode
    );
    const indexDetail =
      this.invoiceExchange.invoiceExchangeDetail.indexOf(findDetail);
    this.invoiceExchange.invoiceExchangeDetail.splice(indexDetail, 1);
    this.invoiceExchange.invoiceExchangeDetail = [
      ...this.invoiceExchange.invoiceExchangeDetail,
    ];
    await this.calculateTotals();
    if (this.invoiceExchange.invoiceExchangeDetail.length > 0) {
      this.items = true;
    } else {
      this.items = false;
    }
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.invoiceExchange.invoiceExchangeSubtotal = 0;
    this.invoiceExchange.invoiceExchangeTotal = 0;
    for (const iterator of this.invoiceExchange.invoiceExchangeDetail) {
      this.invoiceExchange.invoiceExchangeSubtotal +=
        iterator.invoiceExchangeDetailSubtotal;
      this.invoiceExchange.invoiceExchangeTotal +=
        iterator.invoiceExchangeDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.invoiceExchangeClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(
        this.invoiceExchangeClient
      );
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoiceExchange.invoiceExchangeClient =
          response.records[0].insertId;
          this.notificationService.showWarning('Cliente creado con éxito.');
          return true;
      }
    } else {
      this.invoiceExchangeClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.invoiceExchangeClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdateSale(
        this.invoiceExchangeClient
      );
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.invoiceExchange.invoiceExchangeClient =
          this.invoiceExchangeClient.clientCode;
          this.notificationService.showWarning('Cliente actualizado con éxito.');
          return true;
      }
    }
  }

  async save() {
    await this.calculateTotals();
    if (this.invoiceExchange.invoiceExchangeDocumentSearch == 1) {
      if (
        this.invoiceExchangeClient.clientTaxNumber == undefined || this.invoiceExchangeClient.clientTaxNumber == '' ||
        this.invoiceExchangeClient.clientTaxNumber == "CF" || this.invoiceExchangeClient.clientTaxNumber == "C/F" ||
        this.invoiceExchangeClient.clientTaxNumber == "cf" || this.invoiceExchangeClient.clientTaxNumber == "c/f"
      ) {
        this.notificationService.showError("Tipo de factura no permite consumidor final.");
      }
    } else if (
      this.invoiceExchange.invoiceExchangeDocumentSearch == 0 &&
      (
        this.invoiceExchangeClient.clientIdentificationDocument == '' ||
        this.invoiceExchangeClient.clientIdentificationDocument == undefined
      )
    ) {
      this.notificationService.showError("Debes ingresar un número de DPI.");
    } else if (this.invoiceExchange.invoiceExchangeDetail.length == 0) {
      this.notificationService.showError(
        'Debes agregar detalle de venta.'
      );
    } else {
      if (this.quotationList != null) {
        this.invoiceExchange.invoiceExchangeQuotation = this.quotationList.quotationCode;
      } else {
        this.invoiceExchange.invoiceExchangeQuotation = null;
      }

      if (this.addressSelected == 0) {
        this.invoiceExchange.invoiceExchangeClientAddressShow = 'Ciudad';
      } else {
        this.invoiceExchange.invoiceExchangeClientAddressShow = `${this.invoiceExchangeClient.clientAddress}, Zona ${this.invoiceExchangeClient.clientAddressZone}, ${this.selected['clientDepartment'][0]['itemName']}, ${this.selected['clientMunicipality'][0]['itemName']}`;             
      }

      this.loading = true;
      if (await this.validateClient()) {
        let response;
        response = await this.invoiceExchangeService.invoiceExchangeInsert(
          this.invoiceExchange
        );
        if (response.errorMessage !== undefined) {
          this.notificationService.showError(response.errorMessage);
        } else {
          if (response.result == true) {

            if (this.quotationList != undefined) {
              await this.saveStatusQuotation();
            }

            await window.open(
              response.receiptPdfUrl,
              'popup',
              'width=600,height=600'
            );
            await this.notificationService.showSuccess("Factura generada con éxito.");
            await window.open(
              environment.infilePdfUrl + response.invoiceExchangeAuthorization,
              'popup',
              'width=600,height=600'
            );
            this.cancel();
          } else {
            this.notificationService.showError(response.message);
            // for (const err of response.errorList) {
            //   console.error(err.mensaje_error);
            // }
          }
        }
      }
      this.loading = false;
    }
  }

  async saveStatusQuotation() {
    let response;

    const quotationUpdate = {
      quotationCode: this.quotationList.quotationCode,
      quotationStatus: 3
    }
    response = await this.quotationService.quotationUpdate(quotationUpdate);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.quotationList = undefined;
    }
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.invoiceExchange.invoiceExchangeDetail[rowIndex][cell] = val;
    this.invoiceExchange.invoiceExchangeDetail = [...this.invoiceExchange.invoiceExchangeDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
