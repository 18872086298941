<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-sm-6 col-md-8">
    <button
      class="btn btn-sm btn-success btn-simple"
      *ngIf="createPermission"
      (click)="createInventory()"
      ngbTooltip="Crear"
      container="body"
    >
      <i class="fas fa-plus"></i>
    </button>
    <button
      class="btn btn-sm btn-default btn-simple"
      (click)="refreshInventory()"
      ngbTooltip="Refrescar"
      container="body"
    >
      <i class="fas fa-sync-alt"></i>
    </button>
    <button
      class="btn btn-sm btn-default btn-simple"
      *ngIf="xlsxExportPermission"
      (click)="xlsxExport()"
      ngbTooltip="Exportar XLSX"
      container="body"
    >
      <i class="fas fa-file-excel" style="color: green"></i>
    </button>
    <button
      class="btn btn-sm btn-default btn-simple"
      *ngIf="packagePermission"
      (click)="package()"
      ngbTooltip="Promociones"
      container="body"
    >
      <i class="fas fa-boxes"></i>
    </button>
    <button
      class="btn btn-sm btn-default btn-simple"
      *ngIf="productionPermission"
      (click)="production()"
      ngbTooltip="Recetas"
      container="body"
    >
      <i class="fas fa-atom"></i>
    </button>
    <button
      class="btn btn-sm btn-default btn-simple"
      *ngIf="dropPermission"
      (click)="drop()"
      ngbTooltip="Baja"
      container="body"
    >
      <i class="fab fa-dropbox"></i>
    </button>
  </div>
</div>
