import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-confirm-automatic',
  templateUrl: './user-confirm-automatic.component.html',
  styleUrls: ['./user-confirm-automatic.component.scss']
})
export class UserConfirmAutomaticComponent implements OnInit {
  loading = false;
  userName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.logOut()
    this.route.paramMap.subscribe(async (params: any) => {
      const userEmail = params.params.userEmail;
      const verificationCode = params.params.verificationCode;
      if (userEmail !== undefined || verificationCode !== undefined) {
        await this.userAutoValidation(userEmail, verificationCode);
      }
    });
  }

  async logOut() {
    this.userService.userLogout();
  }

  async userAutoValidation(userEmail, verificationCode) {
    const user = await this.userGetEmail(userEmail);
    const confirm = {
      userCognitoCode: user.userCognitoCode,
      userConfirmationCode: verificationCode
    };
    await this.userConfirm(confirm);
  }

  userGetEmail(userEmail) {
    return this.userService.oneUserEmailBackend(userEmail).then(
      (result: any) => {
        const selectedUser = result.records[0];
        return selectedUser;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(() => {
    });

  }

  userConfirm(confirm) {
    return this.userService.confirm(confirm).then(
      (result: any) => {
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.router.navigate(['/userLogin']);
      }
    );

  }
}
