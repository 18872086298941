<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row">
    <div class="col-md-12 ml-auto mr-auto">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Cuentas por pagar</h4>
        </div>
        <div class="card-body">
          <app-account-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
          >
          </app-account-maintenance-toolbox>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-12 col-md-6 offset-md-6 text-right">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input type="search" class="form-control form-control-md" placeholder="Buscar"
                    aria-controls="datatable" (keyup)="filterTable($event)" />
                </label>
              </div>
            </div>
          </div>        
          <div class="card-footer">
            <ngx-datatable
              *ngIf="reportDataFilter !== undefined && tableHeaders.length > 0"
              class="bootstrap selection-cell"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [limit]="entries != -1 ? entries : undefined"
              [rows]="reportDataFilter"
              (activate)="onActivate($event)"
              [scrollbarH]="true"
            >
              <ngx-datatable-column
                *ngFor="let tableHeader of tableHeaders"
                [name]="tableHeader.name"
              >
                <ng-template ngx-datatable-header-template>
                  <span>{{ tableHeader.name }}</span>
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-row="row"
                  [ngSwitch]="tableHeader.pipe"
                >
                  <span *ngSwitchCase="'text'">
                    {{ row[tableHeader.prop] }}
                  </span>
                  <span *ngSwitchCase="'date'">
                    {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                  </span>
                  <span *ngSwitchCase="'currency'">
                    {{ row[tableHeader.prop] | number : "1.2-2" }}
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Método">
                <ng-template
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                  let-value="value"
                  let-row="row"
                >
                  <select
                    class="form-control pa"
                    (change)="
                      changePayment($event, 'purchasePaymentMethod', rowIndex)
                    "
                    *ngIf="row.purchaseBalance != 0"
                  >
                    <option style="display: none">Seleccionar</option>
                    <option [value]="1">Efectivo</option>
                    <option [value]="2">TC/TD</option>
                    <option [value]="3">Cheque</option>
                    <option [value]="4">Depósito</option>
                    <option [value]="5">Transferencia</option>
                  </select>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Monto">
                <ng-template
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                  let-value="value"
                  let-row="row"
                >
                  <input
                    autofocus
                    (keyup)="
                      changePayment($event, 'purchasePaymentAmount', rowIndex)
                    "
                    type="number"
                    *ngIf="row.purchaseBalance != 0"
                    [value]="row.purchasePaymentAmount || 0"
                    min="0"
                    style="width: 100%;"
                  />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Autorización">
                <ng-template
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                  let-value="value"
                  let-row="row"
                >
                  <input
                    autofocus
                    (keyup)="
                      changePayment($event, 'purchasePaymentVoucher', rowIndex)
                    "
                    type="number"
                    *ngIf="
                      row.purchaseBalance != 0 && row.purchasePaymentMethod != 1
                    "
                    [value]="row.purchasePaymentVoucher || ''"
                    min="0"
                    style="width: 100%;"
                  />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="true"
              >
                <ng-template
                  ngx-datatable-header-template
                  let-value="value"
                  let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn"
                >
                  Acciones
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-row="row"
                  let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn"
                >
                  <a
                    href="#"
                    class="btn btn-success btn-icon btn-sm btn-simple like"
                    ngbTooltip="Registar pago"
                    container="body"
                    (click)="addPay($event)"
                    *ngIf="row.purchaseBalance != 0"
                    style="margin-right: 2px;"
                    ><i class="fas fa-plus"></i
                  ></a>
                  <a
                    href="#"
                    class="btn btn-default btn-icon btn-sm btn-simple like"
                    ngbTooltip="Ver factura"
                    container="body"
                    *ngIf="row.purchaseInvoiceFileUrl != ''"
                    (click)="showInvoice($event)"
                    ><i class="fas fa-eye"></i
                  ></a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>
