import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async transferInsert(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer', transfer, { headers })
      .toPromise();
  }

  async transferGetDate(
    userStore,
    reportStartDate,
    reportEndDate
  ): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-transfer/By/Date/' +
          userStore +
          '/' +
          reportStartDate +
          '/' +
          reportEndDate +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async transferAuthorize(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer/authorize/', transfer, {
        headers,
      })
      .toPromise();
  }

  async transferUnAuthorize(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer/unauthorize/', transfer, {
        headers,
      })
      .toPromise();
  }

  async transferReceive(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer/receive/', transfer, {
        headers,
      })
      .toPromise();
  }

  async transferReceiveForce(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer/receive/force', transfer, {
        headers,
      })
      .toPromise();
  }

  async transferUnReceive(transfer) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-transfer/unreceive/', transfer, {
        headers,
      })
      .toPromise();
  }
}
