import { Component, EventEmitter, Input, OnInit, Output, Provider } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Company } from 'src/app/models/company';
import { ListArray } from 'src/app/models/listArray';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseDetail } from 'src/app/models/purchaseDetail';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProviderService } from 'src/app/services/provider.service';
import { PurchaseService } from 'src/app/services/purchase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-receive',
  templateUrl: './purchase-receive.component.html'
})
export class PurchaseReceiveComponent implements OnInit {
  loading = false;

  company = {} as Company;

  providerList: Provider[];
  providerListSelect = new Array();
  providerSelected;

  classImageBtn = "btn btn-block btn-round btn-file";
  classImageBtnGreen = "btn btn-success btn-block btn-round btn-file";
  classImageBtnGray = "btn btn-block btn-round btn-file";

  @Output() cancelAction = new EventEmitter<any>();

  @Input() purchase = {
    purchaseDetail: new Array<PurchaseDetail>()
  } as Purchase;

  dropdownSettings = {};

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  editing = {};

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private providerService: ProviderService,
    private purchaseService: PurchaseService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.providerList = await this.getProviders(user.userCompany);
    this.providerListSelect = await this.sharedUtils.transformToList(this.providerList, 'providerCode', 'providerName');
    this.fntDropdownSettings(true);
    if (this.purchase.purchaseProvider != undefined) {
      this.providerSelected = await this.sharedUtils.selectFromList(this.providerListSelect, this.purchase.purchaseProvider);
    }
    this.purchase.purchaseProcessUser = user.userCode;
  }

  async getProviders(company) {
    this.loading = true;
    return this.providerService.providerGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );

  }

  updateValue(event, cell, rowIndex) {
    var val = event instanceof Date ? event : event.target.value;
    this.purchase.purchaseDetail[rowIndex][cell] = val;
    this.purchase.purchaseDetail = [...this.purchase.purchaseDetail];
  }

  async onItemSelectProvider(item: ListArray) {
    this.purchase.purchaseProvider = item.id;
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async addFile(e) {
    this.purchase.purchaseInvoiceFileName = e.target.files[0].name;
    this.purchase.purchaseInvoiceFile = await this.sharedUtils.convertBase64(e.target.files.item(0));
    this.classImageBtn = this.classImageBtnGreen;
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    let correct = true;
    this.purchase.purchaseDetail.forEach(e => {
      if(e['purchaseDetailRecieveQuantity'] == 0){
        correct = false;
        return;
      }
    });

    if(!correct){
      this.notificationService.showError("Debe ingresar la cantidad recibida");
    }else{
      let response;
      this.loading = true;
      response = await this.purchaseService.purchaseInsert(this.purchase);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancel();
      }
      this.loading = false;
    }

  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
        disabled: true
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

}
