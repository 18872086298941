<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row">
     <div class="col-md-6 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <!-- <h5 class="title">Editar perfil (cambio de clave)</h5> -->
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 col-xl-6">
            <div class="form-group">
              <label>Usuario</label>
              <input type="text" class="form-control" [(ngModel)]="user.userEmail" disabled />
            </div>
          </div>
          <div class="col-lg-12 col-xl-6">
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" [(ngModel)]="user.userName" disabled />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label>Clave actual</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <a (click)="changeText()" class="a-yes-image">
                    <i class="fa fa-eye"></i>
                  </a>
                </div>
              </div>
              <input type="password" id="pass" class="form-control" [(ngModel)]="password.previousPassword"
                placeholder="" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-12 col-xl-6">
                <label>Clave nueva</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <a (click)="changeTextTwo()" class="a-yes-image">
                        <i class="fa fa-eye"></i>
                      </a>
                    </div>
                  </div>
                  <input type="password" id="passTwo" class="form-control" (keyup)="confirm()"
                    [(ngModel)]="password.proposedPassword" placement="bottom"
                    />
                </div>
              </div>
              <div class="col-lg-12 col-xl-6">
                <label>Confirmar clave nueva</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <a (click)="changeTextConfirm()" class="a-yes-image"><i class="fa fa-eye"></i></a>
                    </div>
                  </div>
                  <input type="password" id="passConfirm" [class]="isDisabled ? 'text-danger' : 'text-black'"
                    class="form-control" (keyup)="confirm()" [(ngModel)]="password.proposedPasswordConfirm"
                    placement="bottom"
                    />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>* La clave debe tener como
                  mínimo 8 caracteres. Debe incluir 1 letra mayúscula, 1 letra minúscula, 1 número
                  y 1 carácter especial.</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <button type="button" class="btn btn-success btn-simple" (click)="changePassword();"
          [disabled]="isDisabled ">Cambiar clave</button>
      </div>
    </div>
     </div> 
  </div>
</div>
