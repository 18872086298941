import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-user-resend-code',
  templateUrl: './user-resend-code.component.html',
  styleUrls: ['./user-resend-code.component.scss']
})
export class UserResendCodeComponent implements OnInit {
  loading = false;
  currentDate = new Date();
  user = {} as User;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.changeBackground();
  }

  ngOnDestroy() {
    const $page = document.getElementsByClassName("full-page")[0];
    $page.classList.remove("login-page");
  }

  async changeBackground() {
    const $page = document.getElementsByClassName("full-page")[0];
    const image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage = "url(assets/img/background.jpg)";
    $page.appendChild(image_container);
    $page.classList.add("login-page");
  }

  returnLogin() {
    this.router.navigate(['userLogin']);
  }

  async resendCode() {
    this.loading = true;
    try {
      const send = await this.userService.resendCode(this.user.userEmail);
      await this.notificationService.showSuccess('Nuevo correo de confirmación de usuario enviado. Revisa tu correo.');
      await this.router.navigate(['userLogin']);
    } catch (err) {
      let message;
        if (err.message === 'User is already confirmed.') { message = 'El usuario ya esta validado no se le puede enviar el código.'; }
        if (err.message === 'Username/client id combination not found.') { message = 'Usuario no existe. Verifica tu usuario y vuelve a intentar.'; }
        if (err.message === 'Attempt limit exceeded, please try after some time.') { message = 'Se ha excedido el límite de intentos, intente mas tarde.'; }

      if (message !== undefined) {
        this.notificationService.showError(message);
      }
    } finally {
      this.loading = false;
    }
  }

}