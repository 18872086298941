import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { CreditNote } from 'src/app/models/creditNote';
import { CreditNoteDetail } from 'src/app/models/creditNoteDetail';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { InvoiceExchange } from 'src/app/models/invoiceExchange';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { CreditNoteService } from 'src/app/services/creditNote.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');

@Component({
  selector: 'app-credit-note-generate',
  templateUrl: './credit-note-generate.component.html',
  styleUrls: ['./credit-note-generate.component.scss']
})
export class CreditNoteGenerateComponent implements OnInit {
  loading = false;

  @Input() invoice;
  @Input() invoiceExchange;

  @Output() cancelAction = new EventEmitter<any>();

  creditNote: CreditNote = {
    creditNoteClient: 0,
    creditNoteDetail: new Array<CreditNoteDetail>(),
  } as CreditNote;

  company = {} as Company
  creditNoteDetail = {} as CreditNoteDetail;
  creditNoteClient = {} as Client;

  creditNoteSaleService = false;
  creditNoteSaleInventory = false;

  creditNoteAddItemMode = false;
  creditNoteAddItemTypeMode = 'B';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  public nowDay: string;
  public now: string;
  public date: string;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  inventorySelected;
  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private creditNoteService: CreditNoteService
  ) { }

  async ngOnInit() {
    await this.setDefault();

    //OBTENER FECHA
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.now = yyyy + '-' + mm + '-' + dd;
    this.creditNote.creditNoteDate = yyyy + '-' + mm + '-' + dd;
    this.nowDay = this.creditNote.creditNoteDate;
    this.date = this.now;

  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.creditNote.creditNoteDate = await this.sharedUtils.formatDate();
    this.creditNote.creditNoteUser = user.userCode;
    this.creditNote.creditNoteCompany = user.userCompany;
    this.creditNote.creditNoteStore = user.userStore;
    const companys = await this.getCompany(user.userCompany) as Company[];
    this.company = companys[0];
    if (this.invoice != undefined) {
      this.creditNoteClient.clientTaxNumber = this.invoice.invoiceClientTaxNumberShow;
      this.creditNote.creditNoteClient = this.invoice.invoiceClient;
      this.creditNote.creditNoteInvoice = this.invoice.invoiceCode;
      this.creditNote.creditNoteInvoiceAuthorization = this.invoice.invoiceAuthorization;
      this.creditNote.creditNoteInvoiceDate = this.invoice.invoiceDate;
      await this.searchClient();
      this.creditNote.creditNoteCurrency = this.invoice.invoiceCurrency;
      this.creditNote.creditNoteChangeRate = this.invoice.invoiceChangeRate;
      this.invoice.invoiceDetail = JSON.parse(this.invoice.invoiceDetail);
      for (const invoiceDetail of this.invoice.invoiceDetail) {
        this.creditNoteDetail = {
          creditNoteDetailCode: uuid.v4(),
          creditNoteDetailInvoiceDetail: invoiceDetail.invoiceDetailCode,
          creditNoteDetailDescription: invoiceDetail.invoiceDetailDescription,
          creditNoteDetailQuantity: invoiceDetail.invoiceDetailQuantity,
          creditNoteDetailUnitPrice: invoiceDetail.invoiceDetailUnitPrice,
          creditNoteDetailInventory: invoiceDetail.invoiceDetailInventory,
          creditNoteDetailTotal: invoiceDetail.invoiceDetailTotal,
          creditNoteDetailSubtotal: invoiceDetail.invoiceDetailSubtotal,
          creditNoteDetailDiscount: invoiceDetail.invoiceDetailDiscount,
          creditNoteDetailMeasure: invoiceDetail.invoiceDetailMeasure,
          creditNoteDetailIsService: invoiceDetail.invoiceDetailIsService

        } as CreditNoteDetail;
        this.creditNote.creditNoteDetail.push(this.creditNoteDetail)
      }
    }
    if (this.invoiceExchange != undefined) {
      this.creditNoteClient.clientTaxNumber = this.invoiceExchange.invoiceExchangeClientTaxNumberShow;
      this.creditNote.creditNoteClient = this.invoiceExchange.invoiceExchangeClient;
      this.creditNote.creditNoteInvoice = this.invoiceExchange.invoiceExchangeCode;
      this.creditNote.creditNoteInvoiceAuthorization = this.invoiceExchange.invoiceExchangeAuthorization;
      this.creditNote.creditNoteInvoiceDate = this.invoiceExchange.invoiceExchangeDate;
      await this.searchClient();
      this.creditNote.creditNoteCurrency = this.invoiceExchange.invoiceExchangeCurrency;
      this.creditNote.creditNoteChangeRate = this.invoiceExchange.invoiceExchangeChangeRate;
      this.invoiceExchange.invoiceExchangeDetail = JSON.parse(this.invoiceExchange.invoiceExchangeDetail);
      for (const invoiceExchangeDetail of this.invoiceExchange.invoiceExchangeDetail) {
        this.creditNoteDetail = {
          creditNoteDetailCode: uuid.v4(),
          creditNoteDetailInvoiceDetail: invoiceExchangeDetail.invoiceExchangeDetailCode,
          creditNoteDetailDescription: invoiceExchangeDetail.invoiceExchangeDetailDescription,
          creditNoteDetailQuantity: invoiceExchangeDetail.invoiceExchangeDetailQuantity,
          creditNoteDetailUnitPrice: invoiceExchangeDetail.invoiceExchangeDetailUnitPrice,
          creditNoteDetailInventory: invoiceExchangeDetail.invoiceExchangeDetailInventory,
          creditNoteDetailTotal: invoiceExchangeDetail.invoiceExchangeDetailTotal,
          creditNoteDetailSubtotal: invoiceExchangeDetail.invoiceExchangeDetailSubtotal,
          creditNoteDetailDiscount: invoiceExchangeDetail.invoiceExchangeDetailDiscount,
          creditNoteDetailMeasure: invoiceExchangeDetail.invoiceExchangeDetailMeasure,
          creditNoteDetailIsService: invoiceExchangeDetail.invoiceExchangeDetailIsService

        } as CreditNoteDetail;
        this.creditNote.creditNoteDetail.push(this.creditNoteDetail)
      }
    }
    this.creditNote.creditNoteDetail = [...this.creditNote.creditNoteDetail];
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async searchClient() {
    const clientTaxNumber = this.creditNoteClient?.clientTaxNumber || 'CF';
    const client = await this.getClient(clientTaxNumber) as Client;
    this.creditNoteClient.clientCode = client.clientCode;
    this.creditNoteClient.clientName = client.clientName;
    this.creditNoteClient.clientEmail = client.clientEmail || this.creditNoteClient.clientEmail;
    this.creditNoteClient.clientAddress = client.clientAddress || this.creditNoteClient.clientAddress;
  }

  async getClient(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService.clientGetTaxNumber(user.userCompany, clientTaxNumber).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService.companyGetCompany(companyCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async validateDate($event) {
    $event.preventDefault();

    var nowDate = this.nowDay.split('-');
    var date = this.creditNote.creditNoteDate.split('-');

    if (
      date[0] == nowDate[0] &&
      date[1] == nowDate[1] &&
      parseFloat(date[2]) <= parseFloat(nowDate[2]) + 5 &&
      parseFloat(date[2]) >= parseFloat(nowDate[2]) - 5
    ) {
    } else {
      this.creditNote.creditNoteDate = this.nowDay;
      this.notificationService.showWarning('Fecha fuera de rango');
    }
  }

  async changeCurrency(event) {
    this.creditNote.creditNoteChangeRate = event == "GTQ" ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.creditNoteAddItemTypeMode = this.creditNoteDetail.creditNoteDetailIsService;
    if (event == 'B') {
      this.inventoryList = await this.sharedUtils.transformToList(await this.getInventory(user.userStore), 'inventoryCode', 'inventoryName');
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService.inventoryGetStore(store).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(x => x.inventoryCode == item.id);
    this.creditNoteDetail.creditNoteDetailDescription = inventoryItem.inventoryName;
    this.creditNoteDetail.creditNoteDetailUnitPrice = inventoryItem.inventoryPrice;
    this.creditNoteDetail.creditNoteDetailInventory = inventoryItem.inventoryCode;
  }

  async calculate() {
    this.creditNoteDetail.creditNoteDetailSubtotal =
      (this.creditNoteDetail.creditNoteDetailQuantity * this.creditNoteDetail.creditNoteDetailUnitPrice);
    this.creditNoteDetail.creditNoteDetailTotal =
      (this.creditNoteDetail.creditNoteDetailQuantity * this.creditNoteDetail.creditNoteDetailUnitPrice) - this.creditNoteDetail.creditNoteDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.creditNoteAddItemMode = true;
    this.creditNoteDetail = {
      creditNoteDetailQuantity: 0,
      creditNoteDetailSubtotal: 0,
      creditNoteDetailTotal: 0,
      creditNoteDetailUnitPrice: 0,
      creditNoteDetailDiscount: 0,
      creditNoteDetailCost: 0,
      creditNoteDetailMeasure: "UNI",
      creditNoteDetailInventory: 0
    } as CreditNoteDetail;
    this.creditNoteDetail.creditNoteDetailQuantity = 0;
    this.creditNoteDetail.creditNoteDetailUnitPrice = 0;
    this.creditNoteDetail.creditNoteDetailIsService = Boolean(this.company.companySaleInventory) ? 'B' : undefined;
    if (this.creditNoteDetail.creditNoteDetailIsService == undefined && Boolean(this.company.companySaleService))
      this.creditNoteDetail.creditNoteDetailIsService = Boolean(this.company.companySaleService) ? 'S' : undefined;

    this.creditNoteAddItemTypeMode = this.creditNoteDetail.creditNoteDetailIsService;
    if (this.creditNoteDetail.creditNoteDetailIsService == 'B') {
      this.inventoryList = await this.sharedUtils.transformToList(await this.getInventory(user.userStore), 'inventoryCode', 'inventoryName');
    }
  }

  async insertItem() {
    this.creditNoteDetail.creditNoteDetailCode = uuid.v4();
    this.creditNote.creditNoteDetail.push(this.creditNoteDetail);
    this.creditNote.creditNoteDetail = [...this.creditNote.creditNoteDetail];
    this.creditNoteDetail = {
      creditNoteDetailQuantity: 0,
      creditNoteDetailSubtotal: 0,
      creditNoteDetailTotal: 0,
      creditNoteDetailUnitPrice: 0,
    } as CreditNoteDetail;
    this.creditNoteAddItemMode = false;
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.creditNote.creditNoteDetail.find(x => x.creditNoteDetailCode == this.activeRow.creditNoteDetailCode);
    const indexDetail = this.creditNote.creditNoteDetail.indexOf(findDetail);
    this.creditNote.creditNoteDetail.splice(indexDetail, 1);
    this.creditNote.creditNoteDetail = [...this.creditNote.creditNoteDetail];
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.creditNote.creditNoteSubtotal = 0;
    this.creditNote.creditNoteTotal = 0;
    for (const iterator of this.creditNote.creditNoteDetail) {
      this.creditNote.creditNoteSubtotal += iterator.creditNoteDetailSubtotal;
      this.creditNote.creditNoteSubtotal += iterator.creditNoteDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.creditNoteClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.creditNoteClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.creditNote.creditNoteClient = response.records[0].insertId;
        this.notificationService.showSuccess(response.message);
        return true;
      }
    } else {
      this.creditNote.creditNoteClient = this.creditNoteClient.clientCode;
      return true;
    }
  }

  async save() {
    this.loading = true;
    let response;
    response = await this.creditNoteService.creditNoteInsert(this.creditNote);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      if (response.result == true) {
        await this.notificationService.showSuccess(response.message);
        await window.open(environment.infilePdfUrl + response.creditNoteAuthorization, 'popup', 'width=600,height=600');
        this.cancel();
      } else {
        this.notificationService.showError(response.message);
        for (const err of response.errorList) {
          console.error(err.mensaje_error);
        }
      }
    }
    this.loading = false;
  }

}
