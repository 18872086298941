import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Company } from '../models/company';
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }
  // comapany maintenance
  async companyGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + '/imfel-company/one/' + company + queryParam, {
        headers,
      })
      .toPromise();
  }

  async companyGetUser(user, page, entries): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const queryParamPage = '&page=' + page;
    const queryParamEntries = '&entries=' + entries;
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          '/imfel-company/Get/By/User/' +
          user +
          queryParam +
          queryParamPage +
          queryParamEntries,
        { headers }
      )
      .toPromise();
  }

  /*async companyInsert(company: Company) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this._http.post(environment.apiUrl + 'imfel-company', company, { headers }).toPromise();
  }
  */

  async companyInsert(company: Company) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-company', company, { headers })
      .toPromise();
  }

  async companyUpdate(company: Company) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-company', company, { headers })
      .toPromise();
  }
}
