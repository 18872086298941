import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { ListArray } from 'src/app/models/listArray';
import { Receipt } from 'src/app/models/receipt';
import { ReceiptDetail } from 'src/app/models/receiptDetail';
import { Store } from 'src/app/models/store';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PackageService } from 'src/app/services/package.service';
import { ReceiptService } from 'src/app/services/receipt.service';
import { environment } from 'src/environments/environment';
import { SellerService } from 'src/app/services/seller.service';

const uuid = require('uuid');
import swal from 'sweetalert2';
import { QuotationService } from 'src/app/services/quotation.service';

@Component({
  selector: 'app-receipt-generate',
  templateUrl: './receipt-generate.component.html',
  styleUrls: ['./receipt-generate.component.scss'],
})
export class ReceiptGenerateComponent implements OnInit {
  loading = false;

  @Input() clientSaleList;

  @Input() quotationList;

  @Output() cancelAction = new EventEmitter<any>();

  receipt: Receipt = {
    receiptClient: 0,
    receiptClientAddressShow: 'Ciudad',
    receiptDetail: new Array<ReceiptDetail>(),
  } as Receipt;

  company = {} as Company;
  store = {} as Store;
  receiptDetail = {
    receiptDetailDescription: undefined,
    receiptDetailQuantity: 0,
    receiptDetailSubtotal: 0,
    receiptDetailTotal: 0,
    receiptDetailUnitPrice: 0,
    receiptDetailDiscount: 0,
    receiptDetailCost: 0,
    receiptDetailInventory: 0,
  } as ReceiptDetail;
  receiptClient = {} as Client;
  client = {} as Client;

  receiptSaleService = false;
  receiptSaleInventory = false;
  receiptSaleBenefit = false;
  receiptInventory = false;

  receiptAddItemTypeMode = 'N';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;
  sellerCode: any;

  alert = false;
  alertHeader = false;

  clientInf;

  contactMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  dpiMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  phoneMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private receiptService: ReceiptService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private sellerService: SellerService,
    private quotationService: QuotationService
  ) { }

  async ngOnInit() {
    await this.setDefault();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    this.receipt.receiptCreationDate = await this.sharedUtils.formatDate();
    this.receipt.receiptCreationUser = user.userCode;
    this.receipt.receiptCompany = user.userCompany;
    this.receipt.receiptStore = user.userStore;
    this.receiptClient.clientAddress = environment.clientDefaultAddress;

    this.receipt.receiptCurrency = 1;
    this.receipt.receiptExchangeRate = 1;
    this.receiptClient.clientAddressZone = '0';    
    this.receipt.receiptPaymentMethod = 1;
    this.receipt.receiptVoucher = '';
    this.receipt.receiptDocumentSearch = 2;
    this.receipt.receiptTotal = 0;
    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
      // this.getSeller(user.userCompany),
    ]).then(async (response) => {
      // const sellerList = response[2][0].sellerName;
      // await this.addSelectList(
      //   response[2],
      //   'sellerCode',
      //   'sellerName',
      //   'sellerShow'
      // );

      this.receiptClient.clientDepartment = response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.receiptClient.clientDepartment
      );
      this.receiptClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];
      this.company = companys[0];
      this.receiptClient.clientEmail = this.company.companyEmail;
      this.receiptClient.clientCompany = this.company.companyCode;

      this.receiptSaleInventory = Boolean(this.company.companySaleInventory);
      this.receiptSaleService = Boolean(this.company.companySaleService);
      this.receiptSaleBenefit = Boolean(this.company.companySaleBenefit);
      this.receiptInventory = Boolean(this.company.companyInventory);

    });

    if (this.clientSaleList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      const client  = (await this.getClient(this.clientSaleList.clientCode || '')) as Client;      

      this.receiptClient.clientCode = client.clientCode;
      this.receiptClient.clientName = client.clientName;
      this.receiptClient.clientTaxNumber = client.clientTaxNumber;
      this.receiptClient.clientIdentificationDocument = client.clientIdentificationDocument;
      this.receiptClient.clientPhoneOne = client.clientPhoneOne + ' ' + client.clientPhoneTwo + ' ' + client.clientPhoneThree;      
      this.receiptClient.clientPhone = client.clientPhoneOne;      
      this.receiptClient.clientEmail = client.clientEmail || this.receiptClient.clientEmail;
      this.receiptClient.clientAddress = client.clientAddress || this.receiptClient.clientAddress;
      this.receiptClient.clientAddressZone = client.clientAddressZone || this.receiptClient.clientAddressZone;
      if (client['clientDepartment'] != undefined) {
        this.receiptClient.clientDepartment = client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === client.clientMunicipality
        );
        if (!validMunicipality) {
          this.receiptClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptClient.clientMunicipality = client.clientMunicipality || null;
        }  
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }            
    }

    if (this.quotationList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
      this.clientInf = (await this.getClient(this.quotationList.quotationClient || '')) as Client;

      for (const items of this.quotationList.quotationDetail) {
        this.receiptDetail.receiptDetailCode = uuid.v4();
        this.receiptDetail.receiptDetailDescription = items.quotationDetailDescription;
        this.receiptDetail.receiptDetailUnitPrice = parseFloat(items.quotationDetailUnitPrice);
        this.receiptDetail.receiptDetailQuantity = parseFloat(items.quotationDetailQuantity);
        this.receiptDetail.receiptDetailDiscount = 0;
        this.receiptDetail.receiptDetailSubtotal = parseFloat(items.quotationDetailTotal);
        this.receiptDetail.receiptDetailTotal = parseFloat(items.quotationDetailTotal);
        this.receiptDetail.receiptDetailInventory = parseFloat(items.quotationDetailInventory);
        this.receiptDetail.receiptDetailType = items.quotationDetailType;
        this.receipt.receiptDetail.push(this.receiptDetail);
        this.receipt.receiptDetail = [...this.receipt.receiptDetail];
        this.receiptDetail = {
          receiptDetailDescription: undefined,
          receiptDetailQuantity: 0,
          receiptDetailSubtotal: 0,
          receiptDetailTotal: 0,
          receiptDetailUnitPrice: 0,
          receiptDetailDiscount: 0,
          receiptDetailCost: 0,
          receiptDetailInventory: 0,
        } as ReceiptDetail;
        await this.calculateTotals();
      }

      this.receiptClient.clientCode = this.clientInf.clientCode;
      this.receiptClient.clientName = this.clientInf.clientName;
      this.receiptClient.clientTaxNumber = this.clientInf.clientTaxNumber;
      this.receiptClient.clientIdentificationDocument = this.clientInf.clientIdentificationDocument;
      this.receiptClient.clientPhoneOne = this.clientInf.clientPhoneOne + ' ' + this.clientInf.clientPhoneTwo + ' ' + this.clientInf.clientPhoneThree;
      this.receiptClient.clientPhone = this.clientInf.clientPhoneOne;
      this.receiptClient.clientEmail = this.clientInf.clientEmail || this.receiptClient.clientEmail;
      this.receiptClient.clientAddress = this.clientInf.clientAddress || this.receiptClient.clientAddress;
      this.receiptClient.clientAddressZone = this.clientInf.clientAddressZone || this.receiptClient.clientAddressZone;

      if (this.clientInf['clientDepartment'] != undefined) {
        this.receiptClient.clientDepartment = this.clientInf.clientDepartment;

        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          this.clientInf.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.clientInf.clientMunicipality
        );
        if (!validMunicipality) {
          this.receiptClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptClient.clientMunicipality = this.clientInf.clientMunicipality || null;
        }      
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }    
       
    }
  }

  async cancel() {
    this.quotationList = undefined;
    this.clientSaleList = undefined;
    this.cancelAction.emit();
  }

  async searchClient() {
    if(this.quotationList == undefined && this.clientSaleList == undefined) {
      if (
        (
          this.receiptClient.clientIdentificationDocument == '' ||
          this.receiptClient.clientIdentificationDocument == undefined
        ) && (
          this.receiptClient.clientPhone == '' ||
          this.receiptClient.clientPhone == undefined
        )
      ) {
        if (this.receipt.receiptDocumentSearch == 2) {
          this.notificationService.showError("Debes ingresar un número de teléfono.");
        } else if (this.receipt.receiptDocumentSearch == 0) {
          this.notificationService.showError("Debes ingresar un número de DPI.");
        }
      } else {
        if (this.receipt.receiptDocumentSearch == 2) {
          this.client = (await this.getClientGetPhone(
            this.receiptClient?.clientPhone || ''
          )) as Client;
        } else {
          this.client = (await this.getClientGetIdentificationDocument(
            this.receiptClient?.clientIdentificationDocument || ''
          )) as Client;
        }
        this.receiptClient.clientCode = this.client.clientCode;
        this.receiptClient.clientName = this.client.clientName;
        this.receiptClient.clientIdentificationDocument =
          this.client.clientIdentificationDocument;
        this.receiptClient.clientPhoneOne =
          this.client.clientPhoneOne +
          ' ' +
          this.client.clientPhoneTwo +
          ' ' +
          this.client.clientPhoneThree;
        this.receiptClient.clientEmail =
          this.client.clientEmail || this.receiptClient.clientEmail;
        if (this.client['clientDepartment'] != undefined) {
          this.receiptClient.clientDepartment = this.client.clientDepartment;
          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.receiptClient['clientDepartment'] || null
          );
          const municipalityList = await this.getMunicipality(
            this.client.clientDepartment
          );
          const validMunicipality = municipalityList.find(
            (mun) => mun.municipalityCode === this.client.clientMunicipality
          );
    
          if (!validMunicipality) {
            this.receiptClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
          } else {
            this.receiptClient.clientMunicipality = this.client.clientMunicipality || null;
          }
    
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }        
      }
    }    
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        if (response.records.clientCode == undefined) {
          this.notificationService.showWarning("Cliente nuevo.");
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetPhone(clientPhone) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetPhone(
        user.userCompany,
        clientPhone
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        if (response.records == undefined) {
          this.notificationService.showWarning("Cliente nuevo.");
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService
      .selleryGetCompanyEnable(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async validateDate($event) {
    $event.preventDefault();
  }

  async changeCurrency(event) {
    this.receipt.receiptExchangeRate =
      event == 1 ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.receipt.receiptDocumentSearch = event;
    this.receiptClient.clientIdentificationDocument = '';
    this.receiptClient.clientPhone = '';
  }

  async changeAddress(event) {
    if (event == 0) {
      this.receiptClient.clientAddress = 'Ciudad';
    } else {
      this.receiptClient.clientAddress = this.client.clientAddress || this.receiptClient.clientAddress;;
      this.receiptClient.clientAddressZone = this.client.clientAddressZone || this.receiptClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.receiptClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptClient['clientDepartment'] || null
        );
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        const validMunicipality = municipalityList.find(
          (mun) => mun.municipalityCode === this.client.clientMunicipality
        );
  
        if (!validMunicipality) {
          this.receiptClient.clientMunicipality = municipalityList[0]?.municipalityCode || null;
        } else {
          this.receiptClient.clientMunicipality = this.client.clientMunicipality || null;
        }
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }
    }
  }

  async changePaymentMethod(event) {
    this.receipt.receiptPaymentMethod = event;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptAddItemTypeMode = this.receiptDetail.receiptDetailType;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.receiptDetail.receiptDetailDescription = inventoryItem.inventoryName;
      this.receiptDetail.receiptDetailUnitPrice = inventoryItem.inventoryPrice;
      this.receiptDetail.receiptDetailInventory = inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.receiptDetail.receiptDetailDescription = packageItem.packageName;
        this.receiptDetail.receiptDetailUnitPrice = packageItem.packagePrice;
        this.receiptDetail.receiptDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.receiptClient[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.receiptClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      case 'sellerShow':
        this.receipt.receiptSeller = item.id;
        break;
      case 'clientMunicipality':
        this.receiptClient['clientMunicipality'] = item.id;
        break;
      default:
        this.receipt.receiptClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.receiptClient[attributeBusinessCode] == undefined ||
        this.receiptClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.receiptClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.receiptDetail.receiptDetailSubtotal =
      this.receiptDetail.receiptDetailQuantity *
      this.receiptDetail.receiptDetailUnitPrice;
    this.receiptDetail.receiptDetailTotal =
      this.receiptDetail.receiptDetailQuantity *
      this.receiptDetail.receiptDetailUnitPrice -
      this.receiptDetail.receiptDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptDetail = {
      receiptDetailQuantity: 0,
      receiptDetailSubtotal: 0,
      receiptDetailTotal: 0,
      receiptDetailUnitPrice: 0,
      receiptDetailDiscount: 0,
      receiptDetailCost: 0,
      receiptDetailInventory: 0,
      receiptDetailType: this.receiptDetail.receiptDetailType
    } as ReceiptDetail;
    this.receiptDetail.receiptDetailQuantity = 0;
    this.receiptDetail.receiptDetailUnitPrice = 0;
    if (this.receiptDetail.receiptDetailType == undefined) {
      this.receiptDetail.receiptDetailType = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.receiptDetail.receiptDetailType == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.receiptDetail.receiptDetailType = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;


      if (this.receiptDetail.receiptDetailType == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //  let packageList = await this.getPackage(user.userStore);
        //  let packageListInsert = new Array();
        //  for (const _package of packageList) {
        //    let insertPackage = true;
        //    for (const packageDetail of _package.packageDetail) {
        //      if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //        insertPackage = false;
        //      }
        //    }
        //    if (insertPackage) {
        //      packageListInsert.push(_package);
        //      inventoryList.push({
        //        inventoryCode: 'PACKAGE-' + _package.packageCode,
        //        inventoryName: _package.packageName,
        //        inventoryNameShow: _package.packageName,
        //      });
        //    }
        //  }

        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }

    }
    this.receiptAddItemTypeMode = this.receiptDetail.receiptDetailType;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.receiptDetail.receiptDetailDescription == undefined ||
      this.receiptDetail.receiptDetailUnitPrice == 0 ||
      this.receiptDetail.receiptDetailQuantity == 0 ||
      this.receiptDetail.receiptDetailSubtotal == 0 ||
      this.receiptDetail.receiptDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.receiptDetail.receiptDetailCode = uuid.v4();
      this.receipt.receiptDetail.push(this.receiptDetail);
      this.receipt.receiptDetail = [...this.receipt.receiptDetail];
      this.receiptDetail = {
        receiptDetailQuantity: 1,
        receiptDetailSubtotal: 0,
        receiptDetailTotal: 0,
        receiptDetailUnitPrice: 0,
        receiptDetailType: this.receiptDetail.receiptDetailType
      } as ReceiptDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.receipt.receiptDetail.find(
      (x) => x.receiptDetailCode == this.activeRow.receiptDetailCode
    );
    const indexDetail = this.receipt.receiptDetail.indexOf(findDetail);
    this.receipt.receiptDetail.splice(indexDetail, 1);
    this.receipt.receiptDetail = [...this.receipt.receiptDetail];
    await this.calculateTotals();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.receipt.receiptSubtotal = 0;
    this.receipt.receiptTotal = 0;
    for (const iterator of this.receipt.receiptDetail) {
      this.receipt.receiptSubtotal += iterator.receiptDetailSubtotal;
      this.receipt.receiptTotal += iterator.receiptDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.receiptClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.receiptClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receipt.receiptClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {
      this.receiptClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.receiptClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdateSale(this.receiptClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receipt.receiptClient = this.receiptClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  async save() {
    this.alertHeader = true;
    await this.calculateTotals();     
    if (
      this.receiptClient.clientName == undefined ||
      this.receiptClient.clientName == '' ||
      this.receiptClient.clientName == null

    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else if (
      (
        this.receipt.receiptDocumentSearch == 2 &&
        this.company.companyClient == 1 &&
        (
          this.receiptClient.clientPhoneOne == '' ||
          this.receiptClient.clientPhoneOne == undefined
        )
      ) || (
        this.receipt.receiptDocumentSearch == 0 &&
        this.company.companyClient == 1 &&
        (
          this.receiptClient.clientIdentificationDocument == '' ||
          this.receiptClient.clientIdentificationDocument == undefined
        )
      ) || (
        this.receipt.receiptDocumentSearch == 2 &&
        this.company.companyClient == 1 &&
        (
          this.receiptClient.clientPhone == '' ||
          this.receiptClient.clientPhone == undefined
        )
      )
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else if (this.receipt.receiptDetail.length == 0) {
      this.notificationService.showError(
        'Debes agregar detalle de venta.'
      );
    } else {
      if (
        this.store.storeOnline == 0 &&
        this.receipt.receiptPaymentMethod == 1
      ) {
        swal
          .fire({
            title:
              'Total Q' +
              this.receipt.receiptTotal +
              '<br/>Cantidad de efectivo:',
            //text: "Cantidad de efectivo:",
            icon: 'question',
            html:
              '<div class="form-group">' +
              '<input id="input-field" type="number" class="form-control" />' +
              '</div>',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
            },
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              const efectivo = (
                document.getElementById('input-field') as HTMLInputElement
              ).value;
              swal.fire({
                title: 'Cambio',
                text:
                  'su cambio es ' +
                  (parseFloat(efectivo) -
                    parseFloat(this.receipt.receiptTotal)) +
                  '.',
                icon: 'info',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
              this.saveAction();
            }
          });
      } else {
        this.saveAction();
      }
    }
  }

  async saveAction() {
    if (this.receiptClient.clientPhoneOne != undefined) {
      let phones = this.receiptClient.clientPhoneOne.split(' ');
      this.receiptClient.clientPhoneOne = phones[0] ? phones[0] : '0';
      this.receiptClient.clientPhoneTwo = phones[1] ? phones[1] : '0';
      this.receiptClient.clientPhoneThree = phones[2] ? phones[2] : '0';
    } else {
      this.receiptClient.clientPhoneOne = '0';
    }

    if (this.quotationList != null) {
      this.receipt.receiptQuotation = this.quotationList.quotationCode;
    } else {
      this.receipt.receiptQuotation = null;
    }   

    if (this.addressSelected == 0) {
      this.receipt.receiptClientAddressShow = 'Ciudad';
    } else {
      this.receipt.receiptClientAddressShow = `${this.receiptClient.clientAddress}, Zona ${this.receiptClient.clientAddressZone}, ${this.selected['clientDepartment'][0]['itemName']}, ${this.selected['clientMunicipality'][0]['itemName']}`;             
    }

    this.loading = true;
    if (await this.validateClient()) {
      let response;            
      response = await this.receiptService.receiptInsert(this.receipt);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {

        if (this.quotationList != undefined) {
          await this.saveStatusQuotation();
        }

        this.notificationService.showSuccess(response.message);
        window.open(response.receiptPdfUrl, 'popup', 'width=600,height=600');
        this.cancel();
      }
    }
    this.loading = false;
  }

  async saveStatusQuotation() {
    let response;

    const quotationUpdate = {
      quotationCode: this.quotationList.quotationCode,
      quotationStatus: 3
    }
    response = await this.quotationService.quotationUpdate(quotationUpdate);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.quotationList = undefined;
    }
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.receipt.receiptDetail[rowIndex][cell] = val;
    this.receipt.receiptDetail = [...this.receipt.receiptDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
