import { Attribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client.service';
import { ListArray } from 'src/app/models/listArray';
import { Quotation } from 'src/app/models/quotation';
import { QuotationDetail } from 'src/app/models/quotationDetail';
import { NotificationService } from 'src/app/services/notification.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from 'src/app/services/inventory.service';
import { Company } from 'src/app/models/company';
import { TitleService } from 'src/app/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { QuotationDetailObservation } from 'src/app/models/quotationDetailObservation';
import { CompanyService } from 'src/app/services/company.service';
import swal from 'sweetalert2';

const uuid = require('uuid');

@Component({
  selector: 'app-quotation-generate',
  templateUrl: './quotation-generate.component.html',
  styleUrls: ['./quotation-generate.component.scss']
})
export class QuotationGenerateComponent implements OnInit {
  loading = false;

  @Input() clientQuotationList;

  @Output() cancelAction = new EventEmitter<any>();

  quotation: Quotation = {
    quotationClient: 0,
    quotationDetail: new Array<QuotationDetail>(),
    quotationDetailObservation: new Array<QuotationDetailObservation>(),
  } as Quotation;

  quotationDetail = {   
    quotationDetailDescription: undefined,
    quotationDetailInventory: 0,
    quotationDetailUnitPrice: 0,
    quotationDetailQuantity: 0,
    quotationDetailTotal: 0,  
  } as QuotationDetail;

  quotationDetailObservation = {
    observation: undefined
  } as QuotationDetailObservation; 

  quotationClient = {} as Client;
  company = {} as Company;

  quotationAddItemMode = false;
  quotationInventory = false;
  quotationAddItemTypeMode = 'N';

  clientCode;
  client;

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
    maxHeight: 150,
  };

  dropdownSettingsClient = {};

  list = {};
  selected = {};

  inventoryList = new Array();
  clientList;
  serviceList = new Array();
  inventoryCompleteList = new Array();
  inventorySelected;
  ngSelect = 0;

  totalFooter = 0;

  alert = false;
  alertHeader = false;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private quotationService: QuotationService,
    private inventoryService: InventoryService,
    private titleService: TitleService,
    private _route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
  ) { this.titleService.emitChange("Cotizaciones"); }

  async ngOnInit() {
    await this.setDefault();
    this._route.paramMap.subscribe(async (params: any) => {
      this.clientCode = params.params.clientCode;
    })
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.quotation.quotationDate = await this.sharedUtils.formatDate();
    this.quotation.quotationCreationDate = await this.sharedUtils.formatDate();
    this.quotation.quotationCompany = user.userCompany;
    this.quotation.quotationTotal = 0;
    this.quotation.quotationUser = user.userCode;
    this.quotation.quotationStore = user.userStore;
    this.inventorySelected = undefined;
    await this.typeClients(0);  
    this.fnDropdownSettingsClient(false); 
    Promise.all([
      this.getCompany(user.userCompany)
    ]).then(async (response) => {
      const companys = response[0] as Company[];
      this.company = companys[0];

      this.quotationInventory = Boolean(this.company.companyInventory);
    })

    if (this.clientQuotationList != undefined) {
      swal.fire({
        title: 'Datos añadidos',
        text: 'Verificar los datos',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        }
      });
           
      this.ngSelect = 0;
      this.quotation.quotationClient = this.clientQuotationList.clientCode;
      this.quotationClient.clientCode = this.clientQuotationList.clientCode;
      this.quotationClient.clientEmail = this.clientQuotationList.clientEmail;
      this.quotationClient.clientName = this.clientQuotationList.clientName;
      this.quotationClient.clientPhone = this.clientQuotationList.clientPhoneOne;
      this.quotationClient.clientPhoneOne = this.clientQuotationList.clientPhoneOne;

      this.selected['clientList'] = [{
        id: this.clientQuotationList.clientCode,
        itemName: this.clientQuotationList.clientName
      }];
      this.fnDropdownSettingsClient(true);    
    }
  } 

  async cancel() {
    this.clientQuotationList = undefined;
    this.cancelAction.emit();
  }

  async typeClients($event) {
    if ($event == 0) {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const clientList = await this.getClients(user.userCompany);
      await this.addSelectList(clientList, 'clientCode', 'clientName', 'clientList');
    } else {
      this.selected['clientList'] = [];
      this.quotation.quotationClient = 0;
      this.quotationClient.clientCode = 0;
      this.quotationClient.clientName = undefined;
      this.quotationClient.clientPhone = undefined;
      this.quotationClient.clientPhoneOne = undefined;
      this.quotationClient.clientEmail = undefined;
    }
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClients(company) {
    this.loading = true;
    return this.clientService.clientGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }

        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async validateDate($event) {
    $event.preventDefault();
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.quotationAddItemTypeMode = this.quotationDetail.quotationDetailType;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.quotationDetail.quotationDetailInventory = inventoryItem.inventoryCode;
      this.quotationDetail.quotationDetailDescription = inventoryItem.inventoryName;
      this.quotationDetail.quotationDetailUnitPrice = inventoryItem.inventoryPrice;
      await this.calculate();
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    switch (attributeBusinessCode) {
      case 'clientList':
        if (this.clientCode !== undefined) {
          item = item[0];
        }
        let data = {} as Client;
        data = (await this.getClient(item.id || '')) as Client;
        this.quotation.quotationClient = data[0].clientCode;
        this.quotationClient.clientCode = data[0].clientCode;
        this.quotationClient.clientEmail = data[0].clientEmail;
        this.quotationClient.clientName = data[0].clientName;
        this.quotationClient.clientPhone = data[0].clientPhoneOne;
        this.quotationClient.clientPhoneOne = data[0].clientPhoneOne;
        break;   
      default:
        this.notificationService.showError(
          'Seleccione una opción'
        );
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.quotation[attributeBusinessCode] == undefined ||
        this.quotation[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.quotation[attributeBusinessCode]
        );
    }
  }

  async calculate() {    
    this.quotationDetail.quotationDetailTotal =
      this.quotationDetail.quotationDetailQuantity *
      this.quotationDetail.quotationDetailUnitPrice;
  }

  async insertObservation() {
    this.quotation.quotationDetailObservation.push(this.quotationDetailObservation);
    this.quotation.quotationDetailObservation = [...this.quotation.quotationDetailObservation];
    this.quotationDetailObservation = {
      observation: '',
    } as QuotationDetailObservation;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.quotationDetail = {
      quotationDetailQuantity: 0,
      quotationDetailTotal: 0,
      quotationDetailUnitPrice: 0,
      quotationDetailInventory: 0,
      quotationDetailType: this.quotationDetail.quotationDetailType
    } as QuotationDetail;
    this.quotationDetail.quotationDetailQuantity = 0;
    this.quotationDetail.quotationDetailUnitPrice = 0;
    if (this.quotationDetail.quotationDetailType == undefined) {
      this.quotationDetail.quotationDetailType = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.quotationDetail.quotationDetailType == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.quotationDetail.quotationDetailType = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;


      if (this.quotationDetail.quotationDetailType == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );       

        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }

    }
    this.quotationAddItemTypeMode = this.quotationDetail.quotationDetailType;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.quotationDetail.quotationDetailDescription == undefined ||
      this.quotationDetail.quotationDetailUnitPrice == 0 ||
      this.quotationDetail.quotationDetailQuantity == 0 ||
      this.quotationDetail.quotationDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.quotationDetail.quotationDetailCode = uuid.v4();
      this.quotation.quotationDetail.push(this.quotationDetail);
      this.quotation.quotationDetail = [...this.quotation.quotationDetail];
      this.quotationDetail = {
        quotationDetailQuantity: 1,
        quotationDetailTotal: 0,
        quotationDetailUnitPrice: 0,
        quotationDetailType: this.quotationDetail.quotationDetailType
      } as QuotationDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.quotation.quotationDetail.find(
      (x) => x.quotationDetailCode == this.activeRow.quotationDetailCode
    );
    const indexDetail = this.quotation.quotationDetail.indexOf(findDetail);
    this.quotation.quotationDetail.splice(indexDetail, 1);
    this.quotation.quotationDetail = [...this.quotation.quotationDetail];
    await this.calculateTotals();
  }

  async deleteObservation(event) {
    event.preventDefault();
    const findDetail = this.quotation.quotationDetailObservation.find(
      (x) => x.observation == this.activeRow.observation
    );
    const indexDetail = this.quotation.quotationDetailObservation.indexOf(findDetail);
    this.quotation.quotationDetailObservation.splice(indexDetail, 1);
    this.quotation.quotationDetailObservation = [...this.quotation.quotationDetailObservation];
  }
  
  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.quotation.quotationTotal = 0;
    for (const iterator of this.quotation.quotationDetail) {
      this.quotation.quotationTotal += iterator.quotationDetailTotal;
    }
  }

  async save() {
    this.alertHeader = true;
    await this.calculateTotals();
    if (
      this.quotationClient.clientName == undefined || 
      this.quotationClient.clientName == null || 
      this.quotationClient.clientName == '' 
    ){
      this.notificationService.showError('Completa los campos obligatorios.');
    } else if (this.quotation.quotationDetail.length == 0) {
      this.notificationService.showError( 'Debes agregar detalle de cotización.');
    } else {
      this.saveAction();
    }
  }

  async saveAction() {
    // this.quotation.quotationDate =
    //   this.quotation.quotationDate +
    //   ' ' +
    //   new Date().getHours() +
    //   ':' +
    //   new Date().getMinutes() +
    //   ':' +
    //   new Date().getSeconds();
    // this.quotation.quotationCreationDate =
    //   this.quotation.quotationCreationDate +
    //   ' ' +
    //   new Date().getHours() +
    //   ':' +
    //   new Date().getMinutes() +
    //   ':' +
    //   new Date().getSeconds();
    this.loading = true;
    if (await this.validateClient()) {
      let response;
      response = await this.quotationService.quotationInsert(this.quotation);
      if (response.errorMessage !== undefined) {
        if (response.errorMessage.includes("of undefined")) {
          this.notificationService.showError('Completa los campos obligatorios.');
        } else {
          this.notificationService.showError(response.errorMessage);
        }
      } else {
        this.notificationService.showSuccess(response.message);
        window.open(response.quotationPdfUrl, 'popup', 'width=600,height=600');
        this.cancel();
      }
      this.loading = false;
    }    
  }

  async validateClient() {
    let response;
    if (this.ngSelect == 1) {
      response = await this.clientService.clientInsert(this.quotationClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.quotation.quotationClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {  
      response = await this.clientService.clientUpdateSale(this.quotationClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.quotation.quotationClient = this.quotationClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  fnDropdownSettingsClient(disabled) {
    if (disabled) {
      this.dropdownSettingsClient = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettingsClient = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

  async clientInfo() {
    if (this.clientCode !== undefined) {
      this.client = await this.getClient(this.clientCode);
      this.client = this.client[0];
      await this.typeClients(this.client.clientType);
      this.ngSelect = this.client.clientType;
      this.selected['clientList'] = await this.sharedUtils.selectFromList(
        this.list['clientList'],
        this.client.clientCode,
      );
      this.onItemSelectClient(this.selected['clientList'], 'clientList');      
    }
  }
}
