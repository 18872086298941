import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { CourierService } from 'src/app/services/courier.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-route-assign',
  templateUrl: './route-assign.component.html',
  styleUrls: ['./route-assign.component.scss'],
})
export class RouteAssignComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  reportStartDate;
  reportEndDate;

  courierList;
  userCourierList;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private routeService: RouteService,
    private courierService: CourierService,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'routeReportDate'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    this.courierList = await this.getCourier();
    this.userCourierList = await this.getUserDelivery();
    await this.getReport();
  }

  async refresh() {
    await this.ngOnInit();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async save() {
    let routeDetail = this.reportData.filter(
      (x) => x['routeType'] != undefined
    );
    routeDetail = this.reportData.filter((x) => x['routeCourier'] != undefined);
    let response;
    this.loading = true;
    response = await this.routeService.routeAssign(routeDetail);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.routeService
      .routeGetCompany(
        user.userCompany,
        this.reportStartDate,
        this.reportEndDate
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCourier() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.courierService
      .courierGetCompany()
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getUserDelivery() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.userService
      .userGetDelivery(user.userCompany)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async changeRoute(event, cell, rowIndex) {
    var val = event instanceof Date ? event : event.target.value;
    this.reportData[rowIndex][cell] = val;
    this.reportData = [...this.reportData];
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
