<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div *ngIf="!reportGenerateMode">
    <div class="row">
      <!-- <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Reportes</h4>
          </div>
          <div class="card-body"></div>
        </div>
      </div> -->
      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let report of reportList">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="{{ report.formIcon }}"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <!-- <p class="card-title">Reporte de</p> -->
                  <p class="card-category">Reporte de:<br/> {{ report.formName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <hr />
            <div class="stats">
              <button
                class="btn btn-sm btn-success btn-simple"
                ngbTooltip="Generar"
                container="body"
                (click)="reportGenerate(report)"
              >
                <i class="fas fa-plus"></i> Generar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-report-generate
    *ngIf="reportGenerateMode"
    [report]="report"
    (cancelAction)="cancelAction()"
  ></app-report-generate>
</div>
