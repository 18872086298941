import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ListArray } from 'src/app/models/listArray';
import { TitleService } from 'src/app/services/title.service';
import { SharedUtils } from '../utils/sharedUtils';

const misc: any = {
  sidebar_mini_active: true,
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterContentChecked, OnChanges {
  loading;

  title;
  @Input() companysDate;
  lastCompanysDate;
  sidebarVisible: boolean;
  toggleButton;

  companyList: Array<ListArray> = new Array<ListArray>();
  companySelectedItem: Array<ListArray> = new Array<ListArray>();

  storeList: Array<ListArray> = new Array<ListArray>();
  storeSelectedItem: Array<ListArray> = new Array<ListArray>();

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: 'multiselect form-control ',
    searchPlaceholderText: 'Buscar',
  };

  constructor(
    public sharedUtils: SharedUtils,
    private cdr: ChangeDetectorRef,
    private element: ElementRef,
    private titleService: TitleService
  ) {}

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    await this.getTittle();
  }

  async getTittle() {
    this.titleService.changeEmitted$.subscribe((text) => {
      this.title = text;
      if (text == '' || text == undefined) {
        this.title = 'Tablero';
      }
    });
  }

  async ngOnChanges() {
    if (this.lastCompanysDate !== this.companysDate) {
      await this.getUserCompanies();
    }
  }

  async getUserCompanies() {
    const user = await JSON.parse(localStorage.getItem('user') || '{}');
    if (user.userCompanys !== undefined && user.userCompanys !== null) {
      if (user.userCompanys.length > 0) {
        this.companyList = await this.sharedUtils.transformToList(
          user.userCompanys,
          'companyCode',
          'companyName'
        );
        if (user.userCompany !== undefined) {
          this.companySelectedItem = await this.sharedUtils.selectFromList(
            this.companyList,
            user.userCompany
          );
          const companySelected = user.userCompanys.find(
            (x) => x.companyCode == user.userCompany
          );
          this.storeList = await this.sharedUtils.transformToList(
            companySelected.companyStores,
            'storeCode',
            'storeName'
          );
          if (user.userStore !== undefined && this.storeList.length > 0) {
            const storeSelected = this.storeList.find(
              (x) => x.id == user.userStore
            );
            if (storeSelected != undefined) {
              this.storeSelectedItem = await this.sharedUtils.selectFromList(
                this.storeList,
                user.userStore
              );
            }
          }
        }
      }
    }
    await localStorage.setItem(
      'company',
      JSON.stringify(
        user.userCompanys.find((x) => x.companyCode == user.userCompany)
      )
    );
    await localStorage.setItem(
      'store',
      JSON.stringify(
        user.userCompanys
          .find((x) => x.companyCode == user.userCompany)
          .companyStores.find((x) => x.storeCode == user.userStore)
      )
    );
  }

  async onItemSelectCompany(item: ListArray) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    user.userCompany = item.id;
    await localStorage.setItem('user', JSON.stringify(user));
    await localStorage.setItem(
      'company',
      JSON.stringify(user.userCompanys.find((x) => x.companyCode == item.id))
    );
    window.location.reload();
  }

  async onItemSelectStore(item: ListArray) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    user.userStore = item.id;
    await localStorage.setItem('user', JSON.stringify(user));
    await localStorage.setItem(
      'store',
      JSON.stringify(
        user.userCompanys
          .find((x) => x.companyCode == user.userCompany)
          .companyStores.find((x) => x.storeCode == item.id)
      )
    );
    window.location.reload();
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }

    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName('main-panel')[0]
    );
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName('main-panel')[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
  }
}