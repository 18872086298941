import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { ListArray } from 'src/app/models/listArray';
import { ReceiptRecurring } from 'src/app/models/receiptRecurring';
import { ReceiptRecurringDetail } from 'src/app/models/receiptRecurringDetail';
import { Store } from 'src/app/models/store';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { DepartmentService } from 'src/app/services/department.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PackageService } from 'src/app/services/package.service';
import { ReceiptRecurringService } from 'src/app/services/receipt-recurring.service';
import { environment } from 'src/environments/environment';
import { SellerService } from 'src/app/services/seller.service';

const uuid = require('uuid');
import swal from 'sweetalert2';

@Component({
  selector: 'app-receipt-recurring-generate',
  templateUrl: './receipt-recurring-generate.component.html',
  styleUrls: ['./receipt-recurring-generate.component.scss']
})
export class ReceiptRecurringGenerateComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() cancelActionList = new EventEmitter<any>();

  @Input() headerActionType: string;
  @Input() receiptRecurringViewMode: boolean = false;
  @Input() receiptRecurringUpdateMode: boolean = false;
  @Input() _receiptRecurring;

  receiptRecurring: ReceiptRecurring = {
    receiptRecurringClient: 0,
    receiptRecurringDetail: new Array<ReceiptRecurringDetail>(),
  } as ReceiptRecurring;

  company = {} as Company;
  store = {} as Store;
  receiptRecurringDetail = {
    receiptRecurringDetailDescription: undefined,
    receiptRecurringDetailQuantity: 0,
    receiptRecurringDetailSubtotal: 0,
    receiptRecurringDetailTotal: 0,
    receiptRecurringDetailUnitPrice: 0,
    receiptRecurringDetailDiscount: 0,
    receiptRecurringDetailCost: 0,
    receiptRecurringDetailInventory: 0,
  } as ReceiptRecurringDetail;
  receiptRecurringClient = {} as Client;
  client = {} as Client;

  receiptRecurringSaleService = false;
  receiptRecurringSaleInventory = false;
  receiptRecurringSaleBenefit = false;
  receiptRecurringInventory = false;

  receiptRecurringAddItemTypeMode = 'N';

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  dropdownSettings = {};
  dropdownSettingsProduct = {};

  list = {};
  selected = {};

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  packageCompleteList = new Array();
  inventorySelected;
  addressSelected = 0;

  totalFooter = 0;
  sellerCode: any;

  alert = false;
  alertHeader = false;
  minDateStart: string;

  contactMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  dpiMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  phoneMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private packageService: PackageService,
    private receiptRecurringService: ReceiptRecurringService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private sellerService: SellerService
  ) { }

  async ngOnInit() {
    await this.setDefault();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    this.receiptRecurring.receiptRecurringCreationDate = await this.sharedUtils.formatDate();
    this.receiptRecurring.receiptRecurringDateStart = await this.sharedUtils.formatDate();
    this.minDateStart = await this.sharedUtils.formatDate();
    this.receiptRecurring.receiptRecurringCreationUser = user.userCode;
    this.receiptRecurring.receiptRecurringCompany = user.userCompany;
    this.receiptRecurring.receiptRecurringStore = user.userStore;
    this.receiptRecurringClient.clientAddress = environment.clientDefaultAddress;

    this.receiptRecurring.receiptRecurringCurrency = 1;
    this.receiptRecurring.receiptRecurringChangeRate = 1;
    this.receiptRecurringClient.clientAddressZone = '0';
    this.receiptRecurring.receiptRecurringPaymentMethod = 1;
    this.receiptRecurring.receiptRecurringVoucher = '';
    this.receiptRecurring.receiptRecurringDocumentSearch = 2;
    this.receiptRecurring.receiptRecurringTotal = 0;
    this.receiptRecurring.receiptRecurringFrequency = 0;
    this.receiptRecurring.receiptRecurringDuration = undefined;
    this.receiptRecurring.receiptRecurringDurationType = 0;
    this.receiptRecurring.receiptRecurringFrequencyType = 0;
    this.receiptRecurring.receiptRecurringAddressSelected = 0;

    await Promise.all([
      this.getDepartment(company.companyCountry),
      this.getCompany(user.userCompany),
      // this.getSeller(user.userCompany),
    ]).then(async (response) => {
      this.receiptRecurringClient.clientDepartment = response[0][0].departmentCode;
      this.addSelectList(
        response[0],
        'departmentCode',
        'departmentName',
        'clientDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.receiptRecurringClient.clientDepartment
      );
      this.receiptRecurringClient.clientMunicipality =
        municipalityList[0].municipalityCode;
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      const companys = response[1] as Company[];
      this.company = companys[0];
      this.receiptRecurringClient.clientEmail = this.company.companyEmail;
      this.receiptRecurringClient.clientCompany = this.company.companyCode;

      this.receiptRecurringSaleInventory = Boolean(this.company.companySaleInventory);
      this.receiptRecurringSaleService = Boolean(this.company.companySaleService);
      this.receiptRecurringSaleBenefit = Boolean(this.company.companySaleBenefit);
      this.receiptRecurringInventory = Boolean(this.company.companyInventory);

    });

    if (this._receiptRecurring != undefined) {
      const clientInf = (await this.getClient(this._receiptRecurring.receiptRecurringClient || '')) as Client;

      for (const items of this._receiptRecurring.receiptRecurringDetail) {

        this.receiptRecurringDetail.receiptRecurringDetailCode = uuid.v4();
        this.receiptRecurringDetail.receiptRecurringDetailDescription = items.receiptRecurringDetailDescription;
        this.receiptRecurringDetail.receiptRecurringDetailUnitPrice = parseFloat(items.receiptRecurringDetailUnitPrice);
        this.receiptRecurringDetail.receiptRecurringDetailQuantity = parseFloat(items.receiptRecurringDetailQuantity);
        this.receiptRecurringDetail.receiptRecurringDetailDiscount = parseFloat(items.receiptRecurringDetailDiscount);
        this.receiptRecurringDetail.receiptRecurringDetailSubtotal = parseFloat(items.receiptRecurringDetailSubtotal);
        this.receiptRecurringDetail.receiptRecurringDetailTotal = parseFloat(items.receiptRecurringDetailTotal);
        this.receiptRecurringDetail.receiptRecurringDetailInventory = parseFloat(items.receiptRecurringDetailInventory);
        this.receiptRecurringDetail.receiptRecurringDetailType = items.receiptRecurringDetailType;
        this.receiptRecurring.receiptRecurringDetail.push(this.receiptRecurringDetail);
        this.receiptRecurring.receiptRecurringDetail = [...this.receiptRecurring.receiptRecurringDetail];
        this.receiptRecurringDetail = {
          receiptRecurringDetailDescription: undefined,
          receiptRecurringDetailQuantity: 0,
          receiptRecurringDetailSubtotal: 0,
          receiptRecurringDetailTotal: 0,
          receiptRecurringDetailUnitPrice: 0,
          receiptRecurringDetailDiscount: 0,
          receiptRecurringDetailCost: 0,
          receiptRecurringDetailInventory: 0,
        } as ReceiptRecurringDetail;
        await this.calculateTotals();
      }

      this.receiptRecurring.receiptRecurringCode = this._receiptRecurring.receiptRecurringCode;
      this.receiptRecurring.receiptRecurringDeleted = this._receiptRecurring.receiptRecurringDeleted;
      this.receiptRecurring.receiptRecurringFrequency = this._receiptRecurring.receiptRecurringFrequency;
      this.receiptRecurring.receiptRecurringFrequencyType = this._receiptRecurring.receiptRecurringFrequencyType;
      this.receiptRecurring.receiptRecurringDuration = this._receiptRecurring.receiptRecurringDuration;
      this.receiptRecurring.receiptRecurringDurationType = this._receiptRecurring.receiptRecurringDurationType;
      this.receiptRecurring.receiptRecurringDateStart = this._receiptRecurring.receiptRecurringDateStart;

      this.receiptRecurringClient.clientCode = clientInf.clientCode;
      this.receiptRecurringClient.clientName = clientInf.clientName;
      this.receiptRecurringClient.clientTaxNumber = clientInf.clientTaxNumber;
      this.receiptRecurringClient.clientIdentificationDocument = clientInf.clientIdentificationDocument;
      this.receiptRecurringClient.clientPhoneOne = clientInf.clientPhoneOne + ' ' + clientInf.clientPhoneTwo + ' ' + clientInf.clientPhoneThree;
      this.receiptRecurringClient.clientEmail = clientInf.clientEmail || this.receiptRecurringClient.clientEmail;
      this.receiptRecurringClient.clientPhone = clientInf.clientPhoneOne;

      this.addressSelected = this._receiptRecurring.receiptRecurringAddressSelected;

      if (this._receiptRecurring.receiptRecurringAddressSelected == 0) {
        this.receiptRecurringClient.clientDepartment = '1';
        this.receiptRecurringClient.clientMunicipality = '1';
        this.receiptRecurringClient.clientAddress = 'Ciudad';
        this.receiptRecurringClient.clientAddressZone = '0';
      } else {
        this.receiptRecurringClient.clientAddress = clientInf.clientAddress || this.receiptRecurringClient.clientAddress;
        this.receiptRecurringClient.clientAddressZone = clientInf.clientAddressZone || this.receiptRecurringClient.clientAddressZone;

        if (clientInf['clientDepartment'] != undefined) {
          this.receiptRecurringClient.clientDepartment = clientInf.clientDepartment;

          this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
            this.list['clientDepartment'],
            this.receiptRecurringClient['clientDepartment'] || null
          );
          this.receiptRecurringClient.clientMunicipality = clientInf.clientMunicipality || null;
          const municipalityList = await this.getMunicipality(
            clientInf.clientDepartment
          );
          await this.addSelectList(
            municipalityList,
            'municipalityCode',
            'municipalityName',
            'clientMunicipality'
          );
        }
      }
    }

    if (this.receiptRecurringViewMode) {
      this.fntDropdownSettings(true);
      this.fntDropdownSettings(true);
    } else if (this.receiptRecurringUpdateMode) {
      this.fntDropdownSettings(true);
      this.fntDropdownSettingsProduct(false);
    } else {
      this.fntDropdownSettings(false);
      this.fntDropdownSettingsProduct(false);
    }
  }

  async cancel() {
    if (this._receiptRecurring != undefined) {
      this.cancelActionList.emit();
    } else {
      await this.setDefault();
      this.cancelAction.emit();
    }
  }

  async searchClient() {
    if (
      (
        this.receiptRecurringClient.clientIdentificationDocument == '' ||
        this.receiptRecurringClient.clientIdentificationDocument == undefined
      ) && (
        this.receiptRecurringClient.clientPhone == '' ||
        this.receiptRecurringClient.clientPhone == undefined
      )
    ) {
      if (this.receiptRecurring.receiptRecurringDocumentSearch == 2) {
        this.notificationService.showError("Debes ingresar un número de teléfono.");
      } else if (this.receiptRecurring.receiptRecurringDocumentSearch == 0) {
        this.notificationService.showError("Debes ingresar un número de DPI.");
      }
    } else {
      if (this.receiptRecurring.receiptRecurringDocumentSearch == 2) {
        this.client = (await this.getClientGetPhone(
          this.receiptRecurringClient?.clientPhone || ''
        )) as Client;
      } else {
        this.client = (await this.getClientGetIdentificationDocument(
          this.receiptRecurringClient?.clientIdentificationDocument || ''
        )) as Client;
      }
      this.receiptRecurringClient.clientCode = this.client.clientCode;
      this.receiptRecurringClient.clientName = this.client.clientName;
      this.receiptRecurringClient.clientIdentificationDocument =
        this.client.clientIdentificationDocument;
      this.receiptRecurringClient.clientPhoneOne =
        this.client.clientPhoneOne +
        ' ' +
        this.client.clientPhoneTwo +
        ' ' +
        this.client.clientPhoneThree;
      this.receiptRecurringClient.clientEmail =
        this.client.clientEmail || this.receiptRecurringClient.clientEmail;
      this.receiptRecurringClient.clientDepartment = '1';
      this.receiptRecurringClient.clientMunicipality = '1';
      this.receiptRecurringClient.clientAddress = 'Ciudad';
      this.receiptRecurringClient.clientAddressZone = '0';
    }
  }

  async getClient(clientCode) {
    this.loading = true;
    return this.clientService.clientGetCode(clientCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records[0];
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getClientGetTaxNumber(clientTaxNumber) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetTaxNumber(user.userCompany, clientTaxNumber)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetIdentificationDocument(clientIdentificationDocument) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetIdentificationDocument(
        user.userCompany,
        clientIdentificationDocument
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          if (response.errorMessage == "Cannot read properties of null (reading 'cui')") {
            throw new Error("DPI inválido. Verifica el número y vuelve a intentar.");
          } else {
            throw new Error(response.errorMessage);
          }
        }
        if (response.records.clientCode == undefined) {
          this.notificationService.showWarning("Cliente nuevo.");
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getClientGetPhone(clientPhone) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.clientService
      .clientGetPhone(
        user.userCompany,
        clientPhone
      )
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        if (response.records == undefined) {
          this.notificationService.showWarning("Cliente nuevo.");
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompany(companyCode) {
    this.loading = true;
    return this.companyService
      .companyGetCompany(companyCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService
      .selleryGetCompanyEnable(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async validateDate($event) {
    $event.preventDefault();
    this.receiptRecurring.receiptRecurringCreationDate = this.receiptRecurring.receiptRecurringDateStart;
    this.receiptRecurring.receiptRecurringDateNext = this.receiptRecurring.receiptRecurringDateStart;
  }

  async changeCurrency(event) {
    this.receiptRecurring.receiptRecurringChangeRate =
      event == 1 ? 1 : this.company.companyExchangeRateUSD;
  }

  async changeDocumentType(event) {
    this.receiptRecurring.receiptRecurringDocumentSearch = event;
    this.receiptRecurringClient.clientIdentificationDocument = '';
    this.receiptRecurringClient.clientPhone = '';
  }

  async changeFrequencyType(event) {
    this.receiptRecurring.receiptRecurringFrequencyType = event;
  }

  async changeDurationType(event) {
    this.receiptRecurring.receiptRecurringDurationType = event;
    if (event == 0) {
      this.receiptRecurring.receiptRecurringDuration = undefined;
    } else {
      this.receiptRecurring.receiptRecurringDuration = 0;
    }
  }

  async changeAddress(event) {

    this.receiptRecurring.receiptRecurringAddressSelected = event;

    if (event == 0) {
      this.receiptRecurringClient.clientDepartment = '';
      this.receiptRecurringClient.clientMunicipality = '';
      this.receiptRecurringClient.clientAddress = 'Ciudad';
      this.receiptRecurringClient.clientAddressZone = '';
    } else {
      this.receiptRecurringClient.clientAddress = this.client.clientAddress || this.receiptRecurringClient.clientAddress;;
      this.receiptRecurringClient.clientAddressZone = this.client.clientAddressZone || this.receiptRecurringClient.clientAddressZone;;
      if (this.client['clientDepartment'] != undefined) {
        this.receiptRecurringClient.clientDepartment = this.client.clientDepartment;
        this.selected['clientDepartment'] = await this.sharedUtils.selectFromList(
          this.list['clientDepartment'],
          this.receiptRecurringClient['clientDepartment'] || null
        );
        this.receiptRecurringClient.clientMunicipality = this.client.clientMunicipality || null;
        const municipalityList = await this.getMunicipality(
          this.client.clientDepartment
        );
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
      }
    }
  }

  async changePaymentMethod(event) {
    this.receiptRecurring.receiptRecurringPaymentMethod = event;
  }

  async changeDetailType(event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptRecurringAddItemTypeMode = this.receiptRecurringDetail.receiptRecurringDetailType;
    if (event == 'B') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    } else if (event == 'SD') {
      let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 0);
      this.inventoryList = await this.sharedUtils.transformToList(
        inventoryList,
        'inventoryCode',
        'inventoryNameShow'
      )
    }
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService
      .inventoryGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getPackage(store) {
    this.loading = true;
    return this.packageService
      .packageGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.packageCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item) {
    const inventoryItem = this.inventoryCompleteList.find(
      (x) => x.inventoryCode == item.id
    );
    if (inventoryItem != undefined) {
      this.receiptRecurringDetail.receiptRecurringDetailDescription = inventoryItem.inventoryName;
      this.receiptRecurringDetail.receiptRecurringDetailUnitPrice = inventoryItem.inventoryPrice;
      this.receiptRecurringDetail.receiptRecurringDetailInventory = inventoryItem.inventoryCode;
      await this.calculate();
    } else {
      const packageItem = this.packageCompleteList.find(
        (x) => 'PACKAGE-' + x.packageCode == item.id
      );
      if (packageItem != undefined) {
        this.receiptRecurringDetail.receiptRecurringDetailDescription = packageItem.packageName;
        this.receiptRecurringDetail.receiptRecurringDetailUnitPrice = packageItem.packagePrice;
        this.receiptRecurringDetail.receiptRecurringDetailInventory =
          'PACKAGE-' + packageItem.packageCode;
        await this.calculate();
      }
    }
  }

  async onItemSelectClient(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.receiptRecurringClient[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.receiptRecurringClient['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      case 'sellerShow':
        this.receiptRecurring.receiptRecurringSeller = item.id;
        break;
      case 'clientMunicipality':
        this.receiptRecurringClient['clientMunicipality'] = item.id;
        break;
      default:
        this.receiptRecurring.receiptRecurringClient[attributeBusinessCode] = item.id;
        break;
    }
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.receiptRecurringClient[attributeBusinessCode] == undefined ||
        this.receiptRecurringClient[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.receiptRecurringClient[attributeBusinessCode]
        );
    }
  }

  async calculate() {
    this.receiptRecurringDetail.receiptRecurringDetailSubtotal =
      this.receiptRecurringDetail.receiptRecurringDetailQuantity *
      this.receiptRecurringDetail.receiptRecurringDetailUnitPrice;
    this.receiptRecurringDetail.receiptRecurringDetailTotal =
      this.receiptRecurringDetail.receiptRecurringDetailQuantity *
      this.receiptRecurringDetail.receiptRecurringDetailUnitPrice -
      this.receiptRecurringDetail.receiptRecurringDetailDiscount;
  }

  async addItem() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.receiptRecurringDetail = {
      receiptRecurringDetailQuantity: 0,
      receiptRecurringDetailSubtotal: 0,
      receiptRecurringDetailTotal: 0,
      receiptRecurringDetailUnitPrice: 0,
      receiptRecurringDetailDiscount: 0,
      receiptRecurringDetailCost: 0,
      receiptRecurringDetailInventory: 0,
      receiptRecurringDetailType: this.receiptRecurringDetail.receiptRecurringDetailType
    } as ReceiptRecurringDetail;
    this.receiptRecurringDetail.receiptRecurringDetailQuantity = 0;
    this.receiptRecurringDetail.receiptRecurringDetailUnitPrice = 0;
    if (this.receiptRecurringDetail.receiptRecurringDetailType == undefined) {
      this.receiptRecurringDetail.receiptRecurringDetailType = Boolean(
        this.company.companySaleInventory
      )
        ? 'N'
        : undefined;
      if (
        this.receiptRecurringDetail.receiptRecurringDetailType == undefined &&
        Boolean(this.company.companySaleService)
      )
        this.receiptRecurringDetail.receiptRecurringDetailType = Boolean(
          this.company.companySaleService
        )
          ? 'N'
          : undefined;


      if (this.receiptRecurringDetail.receiptRecurringDetailType == 'B') {
        let inventoryList: Array<any> = await this.getInventoryActive(user.userStore, 1);
        inventoryList = inventoryList.filter(
          (x) => parseFloat(x.inventoryQuantity) > 0
        );
        //  let packageList = await this.getPackage(user.userStore);
        //  let packageListInsert = new Array();
        //  for (const _package of packageList) {
        //    let insertPackage = true;
        //    for (const packageDetail of _package.packageDetail) {
        //      if (parseFloat(packageDetail.packageDetailInventoryQuantity) <= 0) {
        //        insertPackage = false;
        //      }
        //    }
        //    if (insertPackage) {
        //      packageListInsert.push(_package);
        //      inventoryList.push({
        //        inventoryCode: 'PACKAGE-' + _package.packageCode,
        //        inventoryName: _package.packageName,
        //        inventoryNameShow: _package.packageName,
        //      });
        //    }
        //  }

        this.inventoryList = await this.sharedUtils.transformToList(
          inventoryList,
          'inventoryCode',
          'inventoryNameShow'
        );
      }

    }
    this.receiptRecurringAddItemTypeMode = this.receiptRecurringDetail.receiptRecurringDetailType;
  }

  async insertItem() {
    this.alert = true;
    if (
      this.receiptRecurringDetail.receiptRecurringDetailDescription == undefined ||
      this.receiptRecurringDetail.receiptRecurringDetailUnitPrice == 0 ||
      this.receiptRecurringDetail.receiptRecurringDetailQuantity == 0 ||
      this.receiptRecurringDetail.receiptRecurringDetailSubtotal == 0 ||
      this.receiptRecurringDetail.receiptRecurringDetailTotal == 0
    ) {
      this.notificationService.showError('Completa los campos obligatorios.');
    } else {
      this.receiptRecurringDetail.receiptRecurringDetailCode = uuid.v4();
      this.receiptRecurring.receiptRecurringDetail.push(this.receiptRecurringDetail);
      this.receiptRecurring.receiptRecurringDetail = [...this.receiptRecurring.receiptRecurringDetail];
      this.receiptRecurringDetail = {
        receiptRecurringDetailQuantity: 1,
        receiptRecurringDetailSubtotal: 0,
        receiptRecurringDetailTotal: 0,
        receiptRecurringDetailUnitPrice: 0,
        receiptRecurringDetailType: this.receiptRecurringDetail.receiptRecurringDetailType
      } as ReceiptRecurringDetail;

      await this.addItem();
      this.alert = false;
      this.inventorySelected = undefined;
      await this.calculateTotals();
    }
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.receiptRecurring.receiptRecurringDetail.find(
      (x) => x.receiptRecurringDetailCode == this.activeRow.receiptRecurringDetailCode
    );
    const indexDetail = this.receiptRecurring.receiptRecurringDetail.indexOf(findDetail);
    this.receiptRecurring.receiptRecurringDetail.splice(indexDetail, 1);
    this.receiptRecurring.receiptRecurringDetail = [...this.receiptRecurring.receiptRecurringDetail];
    await this.calculateTotals();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async calculateTotals() {
    this.receiptRecurring.receiptRecurringSubtotal = 0;
    this.receiptRecurring.receiptRecurringTotal = 0;
    for (const iterator of this.receiptRecurring.receiptRecurringDetail) {
      this.receiptRecurring.receiptRecurringSubtotal += iterator.receiptRecurringDetailSubtotal;
      this.receiptRecurring.receiptRecurringTotal += iterator.receiptRecurringDetailTotal;
    }
  }

  async validateClient() {
    let response;
    if (this.receiptRecurringClient.clientCode == undefined) {
      response = await this.clientService.clientInsert(this.receiptRecurringClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receiptRecurring.receiptRecurringClient = response.records[0].insertId;
        this.notificationService.showWarning('Cliente creado con éxito.');
        return true;
      }
    } else {
      this.receiptRecurringClient['clientDepartment'] =
        this.selected['clientDepartment'][0]['id'];
      this.receiptRecurringClient['clientMunicipality'] =
        this.selected['clientMunicipality'][0]['id'];

      response = await this.clientService.clientUpdateSale(this.receiptRecurringClient);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
        return false;
      } else {
        this.receiptRecurring.receiptRecurringClient = this.receiptRecurringClient.clientCode;
        this.notificationService.showWarning('Cliente actualizado con éxito.');
        return true;
      }
    }
  }

  async save() {
    if (this.receiptRecurringUpdateMode) {
      await this.updateAction();
    } else {
      this.alertHeader = true;
      await this.calculateTotals();
      if (
        this.receiptRecurringClient.clientName == undefined ||
        this.receiptRecurringClient.clientName == '' ||
        this.receiptRecurringClient.clientName == null
  
      ) {
        this.notificationService.showError('Completa los campos obligatorios.');
      } else if (
        (
          this.receiptRecurring.receiptRecurringDocumentSearch == 2 &&
          this.company.companyClient == 1 &&
          (
            this.receiptRecurringClient.clientPhoneOne == '' ||
            this.receiptRecurringClient.clientPhoneOne == undefined
          )
        ) || (
          this.receiptRecurring.receiptRecurringDocumentSearch == 0 &&
          this.company.companyClient == 1 &&
          (
            this.receiptRecurringClient.clientIdentificationDocument == '' ||
            this.receiptRecurringClient.clientIdentificationDocument == undefined
          )
        ) || (
          this.receiptRecurring.receiptRecurringDocumentSearch == 2 &&
          this.company.companyClient == 1 &&
          (
            this.receiptRecurringClient.clientPhone == '' ||
            this.receiptRecurringClient.clientPhone == undefined
          )
        )
      ) {
        this.notificationService.showError('Completa los campos obligatorios.');
      } else if (this.receiptRecurring.receiptRecurringDetail.length == 0) {
        this.notificationService.showError(
          'Debes agregar detalle de venta.'
        );
      } else {
        if (
          this.store.storeOnline == 0 &&
          this.receiptRecurring.receiptRecurringPaymentMethod == 1
        ) {
          swal
            .fire({
              title:
                'Total Q' +
                this.receiptRecurring.receiptRecurringTotal +
                '<br/>Cantidad de efectivo:',
              //text: "Cantidad de efectivo:",
              icon: 'question',
              html:
                '<div class="form-group">' +
                '<input id="input-field" type="number" class="form-control" />' +
                '</div>',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
              },
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cancelar',
              buttonsStyling: false,
            })
            .then((result) => {
              if (result.value) {
                const efectivo = (
                  document.getElementById('input-field') as HTMLInputElement
                ).value;
                swal.fire({
                  title: 'Cambio',
                  text:
                    'su cambio es ' +
                    (parseFloat(efectivo) -
                      parseFloat(this.receiptRecurring.receiptRecurringTotal)) +
                    '.',
                  icon: 'info',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                });
                this.saveAction();
              }
            });
        } else {
          this.saveAction();
        }
      }
    }   
  }

  async saveAction() {
    if (this.receiptRecurringClient.clientPhoneOne != undefined) {
      let phones = this.receiptRecurringClient.clientPhoneOne.split(' ');
      this.receiptRecurringClient.clientPhoneOne = phones[0] ? phones[0] : '0';
      this.receiptRecurringClient.clientPhoneTwo = phones[1] ? phones[1] : '0';
      this.receiptRecurringClient.clientPhoneThree = phones[2] ? phones[2] : '0';
    } else {
      this.receiptRecurringClient.clientPhoneOne = '0';
    }

    this.loading = true;
    if (await this.validateClient()) {
      let response;
      response = await this.receiptRecurringService.receiptRecurringInsert(this.receiptRecurring);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        if (response.receiptRecurringPdfUrl != null) {
          this.notificationService.showSuccess(response.message);
          window.open(response.receiptRecurringPdfUrl, 'popup', 'width=600,height=600');
        } else {
          this.notificationService.showSuccess("Recibo recurrente creado con éxito.");
        }
        this.cancel();
      }
    }
    this.loading = false;
  }

  async updateAction() {
    this.loading = true;
    let response;

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    this.receiptRecurring['receiptRecurringModifyDate'] = new Date();
    this.receiptRecurring['receiptRecurringModifyUser'] = user.userCode;

    response = await this.receiptRecurringService.receiptRecurringUpdate(this.receiptRecurring);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }

  changeDescription(event, cell, rowIndex) {
    const val = event instanceof Date ? event : event.target.value;
    this.receiptRecurring.receiptRecurringDetail[rowIndex][cell] = val;
    this.receiptRecurring.receiptRecurringDetail = [...this.receiptRecurring.receiptRecurringDetail];
  }

  async getInventoryActive(company, type) {
    this.loading = true;
    return this.inventoryService
      .inventoryActive(company, type)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

  fntDropdownSettingsProduct(disabled) {
    if (disabled) {
      this.dropdownSettingsProduct = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettingsProduct = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
