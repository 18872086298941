import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Addendum } from 'src/app/models/addendum';
import { AddendumService } from 'src/app/services/addendum.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from 'sweetalert2';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-addendum-maintenance',
  templateUrl: './addendum-maintenance.component.html',
  styleUrls: ['./addendum-maintenance.component.scss'],
})
export class AddendumMaintenanceComponent implements OnInit {
  loading = false;
  addendumList: Addendum[];
  addendum: Addendum;
  tableHeaders: [] = [];

  headerActionType: string;
  addendumCreateMode: boolean = false;
  addendumUpdateMode: boolean = false;
  addendumDeleteMode: boolean = false;
  addendumViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private addendumService: AddendumService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Mantenimiento');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
    this.addendumList = await this.getAddendums(user.userStore);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getAddendums(store) {
    this.loading = true;
    return this.addendumService
      .addendumGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear adenda';
    this.addendumCreateMode = true;
    this.addendum = {} as Addendum;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.addendum.addendumCompany = user.userCompany;
    this.addendum.addendumStore = user.userStore;
    this.addendum.addendumStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver Adendas';
    this.addendumViewMode = true;
    this.addendum = event as Addendum;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar adenda';
    this.addendumUpdateMode = true;
    this.addendum = event as Addendum;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar Adendas';
    this.addendumDeleteMode = true;
    const addendumDeleted = {
      addendumCode: event.addendumCode,
      addendumDeleted: 1,
    };
    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(addendumDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.addendumCreateMode = false;
    this.addendumViewMode = false;
    this.addendumUpdateMode = false;
    this.addendumDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.addendumList = await this.getAddendums(user.userStore);
  }

  async saveAction(addendum) {
    if(addendum != false){
      let response;
      this.loading = true;
      if (this.addendumCreateMode == true) {
        response = await this.addendumService.addendumInsert(addendum);
      }
      if (this.addendumUpdateMode == true) {
        addendum['addendumModifyDate'] = new Date();
        response = await this.addendumService.addendumUpdate(addendum);
      }
      if (this.addendumDeleteMode == true) {
        addendum['addendumDeletedDate'] = new Date();
        response = await this.addendumService.addendumDelete(addendum);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(event);
      }
      this.loading = false;
    }else{
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }
}
