import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Phrase } from 'src/app/models/phrase';
import { PhraseService } from 'src/app/services/phrase.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-phrase-maintenance',
  templateUrl: './phrase-maintenance.component.html',
  styleUrls: ['./phrase-maintenance.component.scss']
})
export class PhraseMaintenanceComponent implements OnInit {
  loading = false;
  phraseList: Phrase[];
  phrase: Phrase;

  headerActionType: string;
  phraseCreateMode: boolean = false;
  phraseUpdateMode: boolean = false;
  phraseDeleteMode: boolean = false;
  phraseViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  page = 1;
  entries = environment.tableEntrys;

  constructor(
    private phraseService: PhraseService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) {
    this.titleService.emitChange('Mantenimiento');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);

    if (this.readPermission) {
      this.phraseList = await this.getPhrases(
        user.userCompany
      );
    }
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getPhrases(company) {
    this.loading = true;
    return this.phraseService
      .phraseGetCompany(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });

  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Crear frase';
    this.phraseCreateMode = true;
    this.phraseViewMode = false;
    this.phrase = {} as Phrase;
    this.phrase.phraseStatus = 1;
    this.phrase.phraseCompany = user.userCompany;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver frase';
    this.phraseCreateMode = false;
    this.phraseViewMode = true;
    this.phrase = event as Phrase;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar frase';
    this.phraseUpdateMode = true;
    this.phraseViewMode = false;
    this.phrase = event as Phrase;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar frase';
    this.phraseDeleteMode = true;
    const phraseDeleted = {
      phraseCode: event.phraseCode,
      phraseDeleted: 1,
    };

    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(phraseDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.phraseCreateMode = false;
    this.phraseViewMode = false;
    this.phraseUpdateMode = false;
    this.phraseDeleteMode = false;

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.phraseList = await this.getPhrases(
      user.userCompany
    );
  }

  async saveAction(phrase) {
    let response;
    this.loading = true;

    if (this.phraseCreateMode == true) {
      response = await this.phraseService.phraseInsert(phrase);
    }
    if (this.phraseUpdateMode == true) {
      phrase['phraseModifyDate'] = new Date();
      response = await this.phraseService.phraseUpdate(phrase);
    }
    if (this.phraseDeleteMode == true) {
      phrase['phraseDeletedDate'] = new Date();
      response = await this.phraseService.phraseUpdate(phrase);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError("Faltó llenar campos obligatorios.");
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }
}
