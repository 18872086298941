import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Routes } from 'src/app/models/routes';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';
import { environment } from 'src/environments/environment';
import { RoutesService } from 'src/app/services/routes.service';
import { RoutesDetail } from 'src/app/models/routesDetail';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
const uuid = require('uuid');
@Component({
  selector: 'app-routes-maintenance-fields',
  templateUrl: './routes-maintenance-fields.component.html',
  styleUrls: ['./routes-maintenance-fields.component.scss'],
})
export class RoutesMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() routesViewMode: boolean = false;
  @Input() routesUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() routes: Routes = {
    routesDetail: new Array<RoutesDetail>(),
  } as Routes;
  routesDetail: RoutesDetail = {} as RoutesDetail;

  attributes: Attribute[] = new Array<Attribute>();

  routesAddItemMode = false;

  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar',
    lazyLoading: true,
  };

  list = {
    routesDetailDepartment: new Array(),
    routesDetailMunicipality: new Array(),
  };
  selected = {};

  entries: number = environment.tableEntrys;
  activeRow: any;
  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private routesService: RoutesService,
    private attributeService: AttributeService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'routesMaintenance'
    );
    const departmentList = await this.getDepartment(company.companyCountry);
    await this.addSelectList(
      departmentList,
      'departmentCode',
      'departmentName',
      'routesDetailDepartment'
    );
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'routesDetailDepartment':
        this.routesDetail[attributeBusinessCode] = item.id;
        this.routesDetail['routesDetailDepartmentShow'] = item.itemName;
        const municipalityList = await this.getMunicipality(item.id);
        this.routesDetail['routesDetailMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'routesDetailMunicipality'
        );
        break;
      case 'routesDetailMunicipality':
        this.routesDetail[attributeBusinessCode] = item.id;
        this.routesDetail['routesDetailMunicipalityShow'] = item.itemName;
        break;
      default:
        this.routesDetail[attributeBusinessCode] = item.id;

        break;
    }
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.routes[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.routes[attributeBusinessCode]
        );
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    const validatedUser = this.routes;
    this.saveAction.emit(validatedUser);
    /*if (await this.validate(validatedUser, this.attributeList)) {
    
     }*/
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  addRouteDetail() {
    this.routesAddItemMode = true;
  }

  insertRouteDetail() {
    this.routesDetail.routesDetailCode = uuid.v4();
    if (this.routes.routesDetail == undefined) {
      this.routes.routesDetail = new Array<RoutesDetail>();
    }
    this.routes.routesDetail.push(this.routesDetail);
    this.routes.routesDetail = [...this.routes.routesDetail];
    this.routesDetail = {} as RoutesDetail;
    this.selected = {};
    this.routesAddItemMode = false;
  }

  deleteItem() {
    event.preventDefault();
    const findDetail = this.routes.routesDetail.find(
      (x) => x.routesDetailCode == this.activeRow.routesDetailCode
    );
    const indexDetail = this.routes.routesDetail.indexOf(findDetail);
    this.routes.routesDetail.splice(indexDetail, 1);
    this.routes.routesDetail = [...this.routes.routesDetail];
  }
}
