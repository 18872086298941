import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceExchangeService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async invoiceExchangeInsert(invoiceExchange) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-invoiceExchange', invoiceExchange, {
        headers,
      })
      .toPromise();
  }

  async invoiceExchangeGetDate(
    userStore,
    reportStartDate,
    reportEndDate
  ): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-invoiceExchange/By/Date/' +
          userStore +
          '/' +
          reportStartDate +
          '/' +
          reportEndDate +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async invoiceExchangeDelete(invoiceExchange) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(
        environment.apiUrl + 'imfel-invoiceExchange/delete',
        invoiceExchange,
        { headers }
      )
      .toPromise();
  }
}
