<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Reporte de {{ report.formName }}</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="exportXLSX()"
            ngbTooltip="Exportar XLSX"
            container="body"
          >
            <i class="fas fa-file-excel" style="color: green"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center" *ngIf="attributesShow">
          <div class="col-md-3" *ngFor="let attribute of attributesShow">
            <label>{{ attribute.attributeName }}</label>
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'date'">
                <input
                  type="date"
                  class="{{ attribute.attributeClass }}"
                  [(ngModel)]="reportParams[attribute.attributeBusinessCode]"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <div class="form-group w-100">
              <button
                class="btn btn-info btn-sale w-100"
                (click)="getReport()"
                style="margin-top: 2.2rem;"
              >
                Generar
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="card-footer" style="padding-bottom: 0">
        <div class="dataTables_wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class="form-control form-control-sm"
                    (change)="entriesChange($event)"
                  >
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">Todos</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input
                    type="search"
                    class="form-control form-control-md"
                    placeholder="Buscar"
                    aria-controls="datatable"
                    (keyup)="filterTable($event)"
                  />
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            *ngIf="reportData !== undefined && tableHeaders.length > 0"
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [limit]="entries != -1 ? entries : undefined"
            [rows]="reportDataFilter"
            (activate)="onActivate($event)"
          >
            <ngx-datatable-column
              *ngFor="let tableHeader of tableHeaders"
              [name]="tableHeader.name"
            >
              <ng-template ngx-datatable-header-template>
                <span>{{ tableHeader.name }}</span>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-row="row"
                [ngSwitch]="tableHeader.pipe"
              >
                <span *ngSwitchCase="'text'">
                  {{ row[tableHeader.prop] }}
                </span>
                <span *ngSwitchCase="'date'">
                  {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
                </span>
                <span *ngSwitchCase="'currency'">
                  {{ row[tableHeader.prop] | number : "1.2-2" }}
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <div
        *ngIf="
          report.total != undefined &&
          (reportParams.reportSp == 'reportSale' ||
            reportParams.reportSp == 'reportSaleUser')
        "
        class="card"
        style="padding-left: 1.25rem; padding-right: 1.25rem"
      >
        <div class="card-body" style="background: #424242; color: #ededed">
          <div class="row">
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalCard.header }}:
              {{ report.total.totalCard.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalCash.header }}:
              {{ report.total.totalCash.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalDeposit.header }}:
              {{ report.total.totalDeposit.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalCheck.header }}:
              {{ report.total.totalCheck.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalTransfer.header }}:
              {{ report.total.totalTransfer.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalPending.header }}:
              {{ report.total.totalPending.value | number : "1.2-2" }}
            </div>
            <div
              class="col-lg-3"
              style="
                padding: 5px 10px;
                font-weight: bold;
                position: relative;
                left: 20px;
              "
            >
              {{ report.total.totalTotal.header }}:
              {{ report.total.totalTotal.value | number : "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
