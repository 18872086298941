import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Account } from 'src/app/models/account';
import { TableHeader } from 'src/app/models/tableHeader';
import { ExportService } from 'src/app/services/export.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-account-maintenance-table',
  templateUrl: './account-maintenance-table.component.html',
  styleUrls: ['./account-maintenance-table.component.scss']
})
export class AccountMaintenanceTableComponent implements OnInit, OnChanges {
  loading = false;

  @Input() accountList: Array<Account>;
  @Input() readPermission: boolean;
  @Input() updatePermission: boolean;
  @Input() deletePermission: boolean;
  @Input() viewPermission: boolean;
  @Input() xlsxExportDate: any;

  @Output() updateAction = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();


  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;
  accountListFilter: Array<Account>;
  lastxlsxExportDate;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    private sharedUtils: SharedUtils,
    private tableHeader: TableHeaderService,
    private notificationService: NotificationService,
    private exportService: ExportService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(await this.getTableHeader(user.userRole, user.userCode, 'accountMaintenance'), 'tableHeaderTitle', 'tableHeaderBusinessCode', 'tableHeaderPipe');
  }

  async ngOnChanges() {
    this.accountListFilter = this.accountList;
    if (this.lastxlsxExportDate !== this.xlsxExportDate) {
      await this.exportXLSX();
    }
  }

  async exportXLSX() {
    this.exportService.xlsxExport(this.accountListFilter, this.tableHeaders, 'Reporte', 'Reporte de pólizas manuales');
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader.tableHeaderGetRoleUserTable(role, user, table).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.accountListFilter = this.accountList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  view(event) {
    event.preventDefault();
    this.viewAction.emit(this.activeRow);
  }

  update(event) {
    event.preventDefault();
    this.updateAction.emit(this.activeRow);
  }

  delete(event) {
    event.preventDefault();
    this.deleteAction.emit(this.activeRow);
  }
}
