import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  loading;
  currentDate = new Date();
  focus;
  focus2;

  public user = {} as User;
  public showPassword: boolean = false;
  public show: boolean;
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  ngOnInit(): void {



    this.checkFullPageBackgroundImage();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
  }

  checkFullPageBackgroundImage() {
    var $page = document.getElementsByClassName("full-page")[0];
    const image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage = "url(assets/img/background.jpg)";
    $page.appendChild(image_container);
  };

  async signIn() {
    if ((this.user.userEmail == undefined ||
      this.user.userEmail.trim() == '' ||
      this.user.userPassword == undefined ||
      this.user.userPassword.trim() == ''
    )) {
      await this.notificationService.showError('Completa los campos solicitados.');
    } else {
      this.loading = true;
      await this.userService.userSignin(this.user.userEmail, this.user.userPassword)
        .finally(async () => {
          await localStorage.setItem('refresh', '1');
          await this.sleep(5000);
          this.loading = false;
        });

    }

  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnDestroy() {
    var $page = document.getElementsByClassName("full-page")[0];
    $page.classList.remove("login-page");
  }

  resendCode() {
    this.router.navigate(['/userResendCode']);
  }

  forgotPassword() {
    this.router.navigate(['/userForgotPassword']);
  }

  showHidePassword() {
    let elemento: any = document.getElementById('pass');

    if (this.showPassword) {
      this.showPassword = false;
      elemento.type = "password";
    }
    else {
      this.showPassword = true;
      elemento.type = "text";
    }
  }

}
