import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { User } from '../../app/models/user';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let result = true;
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user') || '{}');
      (await this.userService.isUserLogged()) ? true : this.takeOutApp();

      var menu = JSON.parse(localStorage.getItem('menu') || '[]');
      if (menu.length > 0) {
        var findMenu = false;
        for (const menuItem of menu) {
          if (menuItem.path == state.url) {
            findMenu = true;
          }
          if (menuItem.children != undefined || menuItem.children != null) {
            var findChild = menuItem.children.find((x) => x.path == state.url);
            if (findChild != undefined) {
              findMenu = true;
            }
          }
        }
        if (!findMenu) {
          this.sendHome();
        }
      }
    } else {
      state.url !== '/userLogin' ? this.sendLogin() : false;
    }

    return result;
  }

  takeOutApp() {
    // this.notificationService.showError('Usuario no encontrado \n Redirigiendo al inicio');
    this.userService.userLogout();
    this.router.navigate(['/userLogin']);
  }

  sendLogin() {
    // this.notificationService.showError('Usuario no encontrado \n Redirigiendo al inicio');
    this.router.navigate(['/userLogin']);
  }

  sendHome() {
    // this.notificationService.showError('Usuario no encontrado \n Redirigiendo al inicio');
    this.router.navigate(['/home']);
  }
}
