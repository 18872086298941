import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/models/menuItem';
import { RouteInfo } from 'src/app/models/routeInfo';
import { User } from 'src/app/models/user';
import { MenuService } from 'src/app/services/menu.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

const misc: any = {
  sidebar_mini_active: true,
};
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  loading;
  menuItems: any[];
  isCollapsed = true;
  isCollapsedMenu = true;
  user = {} as User;
  routes: RouteInfo[] = new Array();
  fullName;
  fullNameArray = new Array();
  activeMenu = 'active-menu';

  @Output() companysFilled = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    await this.getUserCognito(user);
  }

  async getUserCognito(user) {
    if (user == null) this.logOut();
    await Promise.all([
      this.getUser(user.userCognitoCode),
      this.getMenu(user.userCognitoCode),
    ]).then(async (response) => {
      const userRds = response[0];      
      if (userRds == undefined) {
        this.notificationService.showError(
          'Usuario inactivo, comuniquese con el adminstrador'
        );
        this.logOut();
      }
      this.fullNameArray = userRds.userName.split(' ');
      userRds.userCompanys = JSON.parse(userRds.userCompanys || '[]');
      if (user.userCompany !== undefined)
        userRds.userCompany = user.userCompany;
      if (user.userStore !== undefined) userRds.userStore = user.userStore;
      localStorage.setItem('user', JSON.stringify(userRds));
      this.companysFilled.emit();
      const menuWithRemoveItems = await this.removeItemFromMenu(response[1]);
      this.routes = menuWithRemoveItems;
      this.menuItems = await this.routes.filter((menuItem) => menuItem);
      localStorage.setItem('menu', JSON.stringify(this.menuItems));
      if(userRds.userForceChangePassword == 1){
        this.forceChangePassword();
      }
    });
  }

  forceChangePassword() {
    this.router.navigate(['/userChangePassword']);
  }

  async getUser(userCognitoCode) {
    this.loading = true;
    return this.userService
      .oneUserCognitoBackend(userCognitoCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMenu(userCognitoCode) {
    this.loading = true;
    return this.menuService
      .menuGetUserCognito(userCognitoCode)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async removeItemFromMenu(menu) { 
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    for (const menuItem of menu) {
      //Se remueve el menu inventario si la empresa no lo tiene activo
          const findMenu = menu.find(
            (x) => x.path == menuItem.path
          );
          const indexMenu = menu.indexOf(findMenu);
          switch (menuItem.path) {
            case '/productMaintenance':
              if (company.companyInventory == 0)
                menu.splice(indexMenu, 1)
            break;
            case '/saleRecurring':
              if (company.companyReceiptRecurring == 0 && company.companyInvoiceRecurring == 0)
                menu.splice(indexMenu, 1)
            break;
      }

      if (menuItem.children){
        for (const submenuItem of menuItem.children) {
          const findSubMenu = menuItem.children.find(
            (x) => x.path == submenuItem.path
          );
          const indexSubMenu = menuItem.children.indexOf(findSubMenu);
          switch (submenuItem.path) {
            case '/clientMaintenance':
              if (company.companyClient == 0)
                menuItem.children.splice(indexSubMenu, 1)
            break;
          }
        }
      }
    }
    return menu
  }

  async toogleCollapseProfile(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
    if (menuitem.isCollapsing == undefined) {
      menuitem.isCollapsing = true;

      let element = event.target;
      while (
        element.getAttribute('data-toggle') != 'collapse' &&
        element != document.getElementsByTagName('html')[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains('collapse') &&
        !element.classList.contains('show')
      ) {
        element.classList = 'before-collapsing';
        var style = element.scrollHeight;

        element.classList = 'collapsing';
        setTimeout(() => {
          element.setAttribute('style', 'height:' + style + 'px');
        }, 1);
        setTimeout(() => {
          element.classList = 'collapse show';
          element.removeAttribute('style');
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(() => {
          element.setAttribute('style', 'height:' + (style + 20) + 'px');
        }, 3);
        setTimeout(() => {
          element.classList = 'collapsing';
        }, 3);
        setTimeout(() => {
          element.removeAttribute('style');
        }, 20);
        setTimeout(() => {
          element.classList = 'collapse';
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }

  async toogleCollapseMenu(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.isCollapsedMenu = !this.isCollapsedMenu;
    if (menuitem.isCollapsing == undefined) {
      menuitem.isCollapsing = true;

      let element = event.target;

      while (
        element.getAttribute('data-toggle') != 'collapse' &&
        element != document.getElementsByTagName('html')[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];
      if (
        element.classList.contains('collapse') &&
        !element.classList.contains('show')
      ) {
        element.classList = 'before-collapsing';
        var style = element.scrollHeight;

        element.classList = 'collapsing';
        setTimeout(() => {
          element.setAttribute('style', 'height:' + style + 'px');
        }, 1);
        setTimeout(() => {
          element.classList = 'collapse show';
          element.removeAttribute('style');
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(() => {
          element.setAttribute('style', 'height:' + (style + 20) + 'px');
        }, 3);
        setTimeout(() => {
          element.classList = 'collapsing';
        }, 3);
        setTimeout(() => {
          element.removeAttribute('style');
        }, 20);
        setTimeout(() => {
          element.classList = 'collapse';
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }

  async logOut() {
    this.userService.userLogout();
  }

  async minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }

    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  async goToRoute(route) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if(user.userForceChangePassword == 1){
      this.forceChangePassword();
    }else{
      const findCompany = user.userCompanys.find(
        (x) => x.companyCode == user.userCompany
      );
      if (findCompany != undefined) {
        var findStore = findCompany.companyStores.find(
          (x) => x.storeCode == user.userStore
        );
      } else {
        findStore = {
          storePurchase: 1,
          storeOnline: 1,
        };
      }
      switch (route) {
        case '/purchase':
          if (findStore.storePurchase == 1) {
            this.router.navigate([route]);
          } else {
            this.notificationService.showWarning(
              'Establecimiento no autorizado para esta opción'
            );
          }
          break;
        case '/shipping':
          if (findStore.storeOnline == 1) {
            this.router.navigate([route]);
          } else {
            this.notificationService.showWarning(
              'Establecimiento no autorizado para esta opción'
            );
          }
          break;
        case '/routes':
          if (findStore.storeOnline == 1) {
            this.router.navigate([route]);
          } else {
            this.notificationService.showWarning(
              'Establecimiento no autorizado para esta opción'
            );
          }
          break;
        default:
          this.router.navigate([route]);
          break;
      }
    }
    
  }
}
