import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { Attribute } from 'src/app/models/attribute';
import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';
import { User } from 'src/app/models/user';
import { AttributeService } from 'src/app/services/attribute.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StoreService } from 'src/app/services/store.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import { TreeviewItem, TreeviewConfig, TreeItem } from 'ngx-treeview';

@Component({
  selector: 'app-user-maintenance-assign-company',
  templateUrl: './user-maintenance-assign-company.component.html',
  styleUrls: ['./user-maintenance-assign-company.component.scss'],
})
export class UserMaintenanceAssignCompanyComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() user: User = {} as User;

  attributes: Attribute[] = new Array<Attribute>();

  items: TreeviewItem[];
  values: any[];
  companys;
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });

  page = 1;
  entrys = environment.tableEntrys;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private storeService: StoreService,
    private tableHeader: TableHeaderService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'userMaintenanceAssignCompany'
    );
    this.companys = await this.getCompanys(
      user.userCode,
      this.page,
      this.entrys
    );
    this.items = await this.companyTransformToTreeView(this.companys);
  }

  async companyTransformToTreeView(companys): Promise<TreeviewItem[]> {
    const treeviewItems = new Array<TreeviewItem>();
    for (const company of companys) {
      const companyStores = JSON.parse(company.companyStores);
      const treeItems = new Array<TreeItem>();
      const findCompany = this.user.userCompanys.find(
        (x) => x.companyCode == company.companyCode
      );
      for (const store of companyStores.companyStores) {
        let checked = false;
        if (findCompany != undefined) {
          const findStore = findCompany.companyStores.find(
            (x) => x.storeCode == store.storeCode
          );
          if (findStore !== undefined) {
            checked = true;
          }
        }
        const treeItem = {
          text: store.storeName,
          value: store.storeCode,
          checked: checked,
        } as TreeItem;
        treeItems.push(treeItem);
      }
      const treeviewItem = new TreeviewItem({
        text: companyStores.companyName,
        value: companyStores.companyName,
        children: treeItems,
      });
      treeviewItems.push(treeviewItem);
    }
    return Promise.resolve(treeviewItems);
  }

  onFilterChange(value: string): void {}

  selectionChange(value) {
    this.values = value;
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        user;
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCompanys(userCode, page, entrys) {
    this.loading = true;
    return this.companyService
      .companyGetUser(userCode, page, entrys)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async transformSaveData() {
    const userCompany = {
      userCode: this.user.userCode,
      userCompanys: [],
    };
    for (const company of this.companys) {
      const companyStores = JSON.parse(company.companyStores);
      let addCompany = false;
      for (const store of companyStores.companyStores) {
        const findStore = this.values.find((x) => x == store.storeCode);
        if (findStore != undefined) {
          addCompany = true;
        }
      }
      if (addCompany) {
        userCompany.userCompanys.push({
          id: company.companyCode,
        });
      }
    }
    const userStore = {
      userCode: this.user.userCode,
      userStores: [],
    };
    for (const store of this.values) {
      userStore.userStores.push({
        id: store,
      });
    }
    return { userCompany, userStore };
  }

  async save() {
    this.saveAction.emit(await this.transformSaveData());
  }
}
