import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { ExportService } from 'src/app/services/export.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss'],
})
export class QuotationListComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportStartDate;
  reportEndDate;

  saleTrackingMode: boolean = false;
  quotationList;
  optionQuotation;
  cancelQuotationPermission: boolean = false;


  @Input() viewPermission: boolean;
  @Input() sendPermission: boolean;
  @Input() saleTrackingPermission: boolean;
  @Input() deletePermission: boolean;
  @Input() xlsxExportPermission: boolean;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private quotationService: QuotationService,
    private exportService: ExportService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(
        user.userRole,
        user.userCode,
        'quotationReportDate'
      ),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    await this.getReport();
  }

  async setDefault() {
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async view(event) {
    event.preventDefault();
    window.open(this.activeRow.quotationPdfUrl, 'popup', 'width=600,height=600');
  }

  async delete(event) {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const quotationDeleted = {
      quotationCode: this.activeRow.quotationCode,
      quotationDeleted: 1,
      quotationDeletedUser: user.userCode
    };
    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.deleteQuotation(quotationDeleted);
        }
      });
  }

  async deleteQuotation(quotationDeleted) {
    let response;
    this.loading = true;
    response = await this.quotationService.quotationUpdate(quotationDeleted);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.getReport();
    }
    this.loading = false;
  }

  async cancelQuotation(){
    this.saleTrackingMode = false;
    await this.getReport();
  }

  async xlsxExport() {
    this.exportService.xlsxExport(
      this.reportData,
      this.tableHeaders,
      'Reporte',
      'Reporte de cotizaciones'
    );
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.quotationService
      .quotationGetStore(user.userStore, this.reportStartDate, this.reportEndDate)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  
  async send(event) {
    event.preventDefault();
    if (!this.activeRow.clientEmail) {
      this.notificationService.showError('El cliente no cuenta con correo electrónico registrado.');
    } else {
      swal
      .fire({
        title: '¿Desea enviar la cotización por correo?',
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then(async (result) => {    
        if (result.value) {
          let response;
          this.loading = true;
          response = await this.quotationService.quotationSend(this.activeRow);
          if (response.errorMessage !== undefined) {
            this.notificationService.showError(response.errorMessage);
          } else {
            this.notificationService.showSuccess(response.message);
            this.getReport();
          }
          this.loading = false;
        }
      });  
    }         
  }

  async tracking(event) {
    event.preventDefault();
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    let buttons = '';

    if (company.companyReceipt) {
      buttons += '<button id="recibo" class="swal2-button swal2-styled">Recibo</button>';
    }    
    if (company.companyReceiptBusiness) {
      buttons += '<button id="reciboComercial" class="swal2-button swal2-styled">Recibo comercial</button>';
    }
    if (company.companyInvoice) {
      buttons += '<button id="facturaComercial" class="swal2-button swal2-styled">Factura comercial</button>';
    }
    if (company.companyInvoiceExchange) {
      buttons += '<button id="facturaCambiaria" class="swal2-button swal2-styled">Factura cambiaria</button>'; 
    }    

    swal.fire({
      title: '¿Deseas facturar?',
      html: `
        <style>
          .swal2-buttons-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .swal2-button {
            width: 200px;
            padding: 10px;
            font-size: 16px;
            margin: 5px 0;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            border: none;
            display: block;
            color: white;
            background-color: #28a745;
            transition: background-color 0.3s ease;
          }
          .swal2-button:hover {
            background-color: #218838;
          }
          #recibo:hover,
          #facturaComercial:hover,
          #facturaCambiaria:hover {
            background-color: #218838;
          }
          #cancelar {
            background-color: #e76b3b;
          }
        </style>
        <p>Selecciona el tipo de documento que deseas generar.</p>
        <div class="swal2-buttons-container">
          ${buttons}
          <button id="cancelar" class="swal2-button swal2-styled">Cancelar</button>
        </div>
      `,
      showConfirmButton: false,
      icon: 'warning',
      willOpen: () => {
        document.getElementById('recibo').addEventListener('click', () => {
          swal.close();
          this.saleTracking(1);
        });
        document.getElementById('facturaComercial').addEventListener('click', () => {
          swal.close();
          this.saleTracking(2);
        });
        document.getElementById('facturaCambiaria').addEventListener('click', () => {
          swal.close();
          this.saleTracking(3);
        });
        document.getElementById('reciboComercial').addEventListener('click', () => {
          swal.close();
          this.saleTracking(4);
        });
        document.getElementById('cancelar').addEventListener('click', () => {
          swal.close();
        });
      },
    });
  }

  async saleTracking(option){
    this.saleTrackingMode = true;
    this.quotationList = this.activeRow;
    this.cancelQuotationPermission = true;
    this.optionQuotation = option;
  }
  
}
