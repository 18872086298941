import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Color } from 'src/app/models/color';
import { ColorService } from 'src/app/services/color.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-color-maintenance',
  templateUrl: './color-maintenance.component.html',
  styleUrls: ['./color-maintenance.component.scss']
})
export class ColorMaintenanceComponent implements OnInit {
  loading = false;
  colorList: Color[];
  color: Color;
  tableHeaders: [] = [];

  headerActionType: string;
  colorCreateMode: boolean = false;
  colorUpdateMode: boolean = false;
  colorDeleteMode: boolean = false;
  colorViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private colorService: ColorService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Colores"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.colorList = await this.getColors(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getColors(company) {
    this.loading = true;
    return this.colorService.colorGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear color';
    this.colorCreateMode = true;
    this.color = {} as Color;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.color.colorCompany = user.userCompany;
    this.color.colorStore = user.userStore;
    this.color.colorStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver color';
    this.colorViewMode = true;
    this.color = event as Color;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar color';
    this.colorUpdateMode = true;
    this.color = event as Color;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar color';
    this.colorDeleteMode = true;
    const colorDeleted = {
      colorCode: event.colorCode,
      colorDeleted: 1
    };
    swal.fire({
      title: "Atención",
      text: "¿Desea eliminar color?",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(colorDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.colorCreateMode = false;
    this.colorViewMode = false;
    this.colorUpdateMode = false;
    this.colorDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.colorList = await this.getColors(user.userCompany);
  }

  async saveAction(color) {
    let response;
    this.loading = true;
    if (this.colorCreateMode == true) {
      response = await this.colorService.colorInsert(color);
    }
    if (this.colorUpdateMode == true) {
      color['colorModifyDate'] = new Date();
      response = await this.colorService.colorUpdate(color);
    }
    if (this.colorDeleteMode == true) {
      color['colorDeletedDate'] = new Date();
      response = await this.colorService.colorDelete(color);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;

  }
}
