<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    class="row"
    *ngIf="
      !(
        userCreateMode ||
        userUpdateMode ||
        userViewMode ||
        userAssignCompanyMode
      )
    "
  >
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de usuarios</h4>
          <div class="toolbar">
            <app-user-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
            >
            </app-user-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-user-maintenance-table
            [userList]="userList"
            (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)"
            (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission"
            [updatePermission]="updatePermission"
            [deletePermission]="deletePermission"
            [viewPermission]="viewPermission"
            [xlsxExportDate]="xlsxExportDate"
            [userAssignCompanyPermission]="userAssignCompanyPermission"
            (userAssignCompanyAction)="userAssignCompanyAction($event)"
          >
          </app-user-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-user-maintenance-fields
    *ngIf="userCreateMode || userUpdateMode || userViewMode"
    [headerActionType]="headerActionType"
    [userViewMode]="userViewMode"
    [userUpdateMode]="userUpdateMode"
    [user]="user"
    (cancelAction)="cancelAction()"
    (saveAction2)="saveAction2($event)"
    (saveAction)="saveAction($event)"
  >
  </app-user-maintenance-fields>
  <app-user-maintenance-assign-company
    *ngIf="userAssignCompanyMode"
    [headerActionType]="headerActionType"
    [user]="user"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-user-maintenance-assign-company>
</div>
