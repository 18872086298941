import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { AccountService } from 'src/app/services/account.service';
import { ExportService } from 'src/app/services/export.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReceiptService } from 'src/app/services/receipt.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/account';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss'],
})
export class AccountBalanceComponent implements OnInit {
  loading = false;

  accountList: Account[];
  account: Account;

  headerActionType: string;
  accountCreateMode: boolean = false;
  accountUpdateMode: boolean = false;
  accountDeleteMode: boolean = false;
  accountViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportStartDate;
  reportEndDate;

  //@Input() viewPermission: boolean;
  //@Input() deletePermission: boolean;
  //@Input() xlsxExportPermission: boolean;

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private accountService: AccountService,
    private exportService: ExportService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(user.userRole, user.userCode, 'balance'),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );

    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);

  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async view(event) {
    event.preventDefault();
    window.open(this.activeRow.receiptPdfUrl, 'popup', 'width=600,height=600');
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async xlsxExportAction() {
    this.exportService.xlsxExport(
      this.reportData,
      this.tableHeaders,
      'Reporte',
      'Reporte de balance'
    );
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear propietario';
    this.accountCreateMode = true;
    this.account = {} as Account;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.account.accountCompany = user.userCompany;
    this.account.accountStatus = 1;
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.accountService
      .accountGetBalance({
        company: user.userCompany,
        begDate: this.reportStartDate,
        endDate: this.reportEndDate,
        limit: 1000000,
        page: 1,
      })
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
