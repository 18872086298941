import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BankAccount } from '../models/bankAccount';
import { Accountant } from '../models/accountant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AccountantService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // client maintenance
  async accountantGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-accountant/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async accountantInsert(accountant: Accountant) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-accountant', accountant, { headers })
      .toPromise();
  }

  async accountantUpdate(accountant: Accountant) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-accountant', accountant, { headers })
      .toPromise();
  }
}
