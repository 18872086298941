import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankAccount } from 'src/app/models/bankAccount';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { BankAccountService } from 'src/app/services/bank-account.service';
import { ListArray } from 'src/app/models/listArray';
@Component({
  selector: 'app-bank-account-maintenance-fields',
  templateUrl: './bank-account-maintenance-fields.component.html',
  styleUrls: ['./bank-account-maintenance-fields.component.scss']
})
export class BankAccountMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() bankAccountViewMode: boolean = false;
  @Input() bankAccountUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() bankAccount: BankAccount = {} as BankAccount;

  attributes: Attribute[] = new Array<Attribute>();

  bankList = new Array();
  list = {};
  selected = {};


  dropdownSettings = {
    singleSelection: true,
    text: 'Seleccionar...',
    enableSearchFilter: true,
    classes: '',
    searchPlaceholderText: 'Buscar'
  };

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private bankAccountService: BankAccountService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(user.userRole, user.userCode, 'bankAccountMaintenance');

    const bankAccountTypeList = await this.sharedUtils.getBankAccountTypes();
    await this.addSelectList(bankAccountTypeList, 'id', 'itemName', 'bankAccountType');

    this.bankList = await this.getBank();
    await this.addSelectList(this.bankList, 'bankCode', 'bankName', 'bankAccountBank');
  }


  async getBank() {
    this.loading = true;
    return this.bankAccountService.bankGetAll().then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(list, id, name);
    if (this.bankAccount[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] = await this.sharedUtils.selectFromList(this.list[attributeBusinessCode], this.bankAccount[attributeBusinessCode]);
    }
  }


  async onItemSelect(item: ListArray, attributeBusinessCode) {
    this.bankAccount[attributeBusinessCode] = item.id;
  }


  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService.attributeGetRoleUserForm(role, user, form).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    const validatedUser = this.bankAccount;
    this.saveAction.emit(validatedUser);
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }

}
