<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <div class="row" *ngIf="attributes">
          <div class="col-md-3" *ngFor="let attribute of attributes">
            <label>{{ attribute.attributeName }}</label>
            <label style="color: red;" *ngIf="attribute.attributeRequired == 1">*</label>
            <div class="form-group" [ngSwitch]="attribute.attributeType">
              <div *ngSwitchCase="'text'">
                <input type="text" class="{{ attribute.attributeClass }}"
                  autocomplete="{{ attribute.attributeAutocomplete }}"
                  placeholder="{{ attribute.attributePlaceholder }}" [(ngModel)]="user[attribute.attributeBusinessCode]"
                  maxlength="{{ attribute.attributeMaxlength }}" [disabled]="attribute.attributeEnabled == 0">
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="form-group">
              <ngx-treeview [config]="config" [items]="items" (filterChange)="onFilterChange($event)"
                (selectedChange)="selectionChange($event)">
              </ngx-treeview>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
