import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private _http: HttpClient, private userService: UserService) {}

  async menuGetRoleUser(roleCode, userCode): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-menu/' +
          roleCode +
          '/' +
          userCode +
          '/' +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async menuGetUserCognito(userCognitoCode): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-menu/cognito/' +
          userCognitoCode +
          queryParam,
        { headers }
      )
      .toPromise();
  }
}
