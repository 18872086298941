import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Provider } from 'src/app/models/provider';
import { ProviderService } from 'src/app/services/provider.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from 'sweetalert2';
import { TitleService } from 'src/app/services/title.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';

@Component({
  selector: 'app-provider-maintenance',
  templateUrl: './provider-maintenance.component.html',
  styleUrls: ['./provider-maintenance.component.scss'],
})
export class ProviderMaintenanceComponent implements OnInit {
  loading = false;
  providerList: Provider[];
  provider: Provider;
  tableHeaders: [] = [];

  headerActionType: string;
  providerCreateMode: boolean = false;
  providerUpdateMode: boolean = false;
  providerDeleteMode: boolean = false;
  providerViewMode: boolean = false;
  providerShowMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  showPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private providerService: ProviderService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService,
    private tableHeaderService: TableHeaderService,
    private sharedUtils: SharedUtils
  ) {
    this.titleService.emitChange('Mantenimiento');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    await Promise.all([
      this.permissionGetRoleUser(
        user.userRole,
        user.userCode,
        this.router.url.replace('/', '')
      ),
      this.getTableHeader(user.userRole, user.userCode, 'providerMaintenance'),
    ]).then(async (response) => {
      await this.permissionReview(response[0]),
        (this.tableHeaders = await this.sharedUtils.transformToTableHeader(
          response[1],
          'tableHeaderTitle',
          'tableHeaderBusinessCode'
        ));
    });
    if (this.readPermission)
      this.providerList = await this.getProviders(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'show':
            this.showPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getProviders(company) {
    this.loading = true;
    return this.providerService
      .providerGetCompany(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeaderService
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async providerShowAction(event) {
    this.headerActionType = 'Ver Proveedor';
    this.providerShowMode = true;
    this.provider = event as Provider;
  }
  async createAction() {
    this.xlsxExportDate = undefined;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Crear proveedor';
    this.providerCreateMode = true;
    this.provider = {} as Provider;
    this.provider.providerCompany = user.userCompany;
    this.provider.providerCredit = false;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver proveedor';
    this.providerViewMode = true;
    this.provider = event as Provider;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar proveedor';
    this.providerUpdateMode = true;
    this.provider = event as Provider;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar proveedor';
    this.providerDeleteMode = true;
    const providerDeleted = {
      providerCode: event.providerCode,
      providerDeleted: 1,
    };
    swal
      .fire({
        title: "¿Estás seguro?",
        text: "El registro será eliminado y no podrá ser recuperado.",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.saveAction(providerDeleted);
        }
      });
  }

  async cancelAction(event) {
    this.providerCreateMode = false;
    this.providerViewMode = false;
    this.providerUpdateMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.providerList = await this.getProviders(user.userCompany);
  }

  async saveAction(provider) {
    if (provider != false) {
      let response;
      this.loading = true;
      if (this.providerCreateMode == true) {
        response = await this.providerService.providerInsert(provider);
      }
      if (this.providerUpdateMode == true) {
        provider['providerModifyDate'] = new Date();
        response = await this.providerService.providerUpdate(provider);
      }
      if (this.providerDeleteMode == true) {
        provider['providerDeletedDate'] = new Date();
        response = await this.providerService.providerDelete(provider);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(event);
      }
      this.loading = false;
    }else{
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }
}
