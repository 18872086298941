import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from 'src/app/models/store';
import { NgForm } from '@angular/forms';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
import { Company } from 'src/app/models/company';
import { CertifierService } from 'src/app/services/certifier.service';
import { TableHeader } from 'src/app/models/tableHeader';
import { environment } from 'src/environments/environment';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
@Component({
  selector: 'app-store-maintenance-fields',
  templateUrl: './store-maintenance-fields.component.html',
  styleUrls: ['./store-maintenance-fields.component.scss'],
})
export class StoreMaintenanceFieldsComponent implements OnInit {
  loading = false;
  
  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() storeViewMode: boolean = false;
  @Input() storeUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();
  @Input() store: Store = {} as Store;

  @Input() user: Company = {} as Company;
  attributes: Attribute[] = new Array<Attribute>();
  dropdownSettings = {};

  list = {};
  selected = {};
  alert = false;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private certifierService: CertifierService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'storeMaintenance'
    );

    const storeOnlineList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(storeOnlineList, 'id', 'itemName', 'storeOnline');

    const departmentList = await this.getDepartment(company.companyCountry);
    await this.addSelectList(
      departmentList,
      'departmentCode',
      'departmentName',
      'storeDepartment'
    );

    if (this.storeViewMode) {
      const municipalityList = await this.getMunicipality(
        this.store.storeDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'storeMunicipality'
      );
      await this.fntDropdownSettings(true);
    } else {
      await this.fntDropdownSettings(false);
    }
    if (this.storeUpdateMode) {
      const municipalityList = await this.getMunicipality(
        this.store.storeDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'storeMunicipality'
      );
      await this.fntDropdownSettings(false);
    }
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.store[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.store[attributeBusinessCode]
        );
    }
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'storeDepartment':
        this.store[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.store['storeMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'storeMunicipality'
        );
        break;
      default:
        this.store[attributeBusinessCode] = item.id;
        break;
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    if (this.form.valid) {
      const validatedUser = this.store;
      this.saveAction.emit(validatedUser);
    } else {
      const validatedUser = false;
      this.saveAction.emit(validatedUser);
    }

    // const validatedUser = this.store;
    // this.saveAction.emit(validatedUser);
    // if (await this.validate(validatedUser, this.attributeList)) { }
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
