import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { Location, PopStateEvent } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { UserService } from "./services/user.service";
import { filter } from 'rxjs/operators';


declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  lastPoppedUrl: string;
  yScrollStack: number[] = [];

  loading: boolean = false;
  isLogged: boolean = false;
  isLoginPage: boolean = false;

  companysDate;

  constructor(
    private location: Location,
    private router: Router,
    private userService: UserService
  ) {
    this.location = location;
  }

  async ngOnInit() {
    await this.changeStyleLogin();
    await this.verifyLogged();
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  async changeStyleLogin() {
    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
    if (isWindows) {
      document.getElementsByTagName('html')[0].classList.add('perfect-scrollbar-on');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    await this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    await this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);

        if (event.url == '/userLogin') {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
      }
    });
  }

  async verifyLogged() {
    this.userService.userLogged.subscribe(async (isLogged) => {
      this.isLogged = isLogged;
      if (!isLogged) {
        const pathUrl = await this.location.path().split('/');
        if (
          !(pathUrl[1] == 'userAutoConfirm' ||
            pathUrl[1] == 'forgotPassword'
          )) {
          this.router.navigate(['/userLogin']);
        }
      }
    });
    await this.userService.initUserSecurity();
  }

  runOnRouteChange(): void {
    if (this.isLoginPage == true) {
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const ps = new PerfectScrollbar(elemMainPanel);
        ps.update();
      }
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  async companysFilled() {
    this.companysDate = new Date();
  }
}
