import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleMaintenanceComponent } from './pages/article/article-maintenance/article-maintenance.component';
import { ClientMaintenanceComponent } from './pages/client/client-maintenance/client-maintenance.component';
import { CategoryMaintenanceComponent } from './pages/category/category-maintenance/category-maintenance.component';
import { HomeComponent } from './pages/home/home.component';
import { UserLoginComponent } from './pages/user/user-login/user-login.component';
import { AuthGuard } from './services/auth-guard.service';
import { AccountantMaintenanceComponent } from './pages/accountant/accountant-maintenance/accountant-maintenance.component';
import { BankAccountMaintenanceComponent } from './pages/bankAccount/bank-account-maintenance/bank-account-maintenance.component';
import { CompanyMaintenanceComponent } from './pages/company/company-maintenance/company-maintenance.component';
import { UserResendCodeComponent } from './pages/user/user-resend-code/user-resend-code.component';
import { UserConfirmAutomaticComponent } from './pages/user/user-confirm-automatic/user-confirm-automatic.component';
import { UserForgotPasswordComponent } from './pages/user/user-forgot-password/user-forgot-password.component';
import { UserChangePasswordComponent } from './pages/user/user-change-password/user-change-password.component';
import { UserForgotPasswordModalComponent } from './pages/user/user-forgot-password-modal/user-forgot-password-modal.component';
import { ProfileMaintenanceComponent } from './pages/profile/profile-maintenance/profile-maintenance.component';
import { ProviderMaintenanceComponent } from './pages/provider/provider-maintenance/provider-maintenance.component';
import { RoutesMaintenanceComponent } from './pages/routes/routes-maintenance/routes-maintenance.component';
import { UserMaintenanceComponent } from './pages/user/user-maintenance/user-maintenance.component';
import { SizeMaintenanceComponent } from './pages/size/size-maintenance/size-maintenance.component';
import { StoreMaintenanceComponent } from './pages/store/store-maintenance/store-maintenance.component';
import { SubCategoryMaintenanceComponent } from './pages/subCategory/sub-category-maintenance/sub-category-maintenance.component';
import { ColorMaintenanceComponent } from './pages/color/color-maintenance/color-maintenance.component';
import { InventoryMaintenanceComponent } from './pages/inventory/inventory-maintenance/inventory-maintenance.component';
import { PurchaseManagerComponent } from './pages/purchase/purchase-manager/purchase-manager.component';
import { SaleManagerComponent } from './pages/sale/sale-manager/sale-manager.component';
import { DeliveryManagerComponent } from './pages/delivery/delivery-manager/delivery-manager.component';
import { TransferManagerComponent } from './pages/transfer/transfer-manager/transfer-manager.component';
import { ReturnManagerComponent } from './pages/return/return-manager/return-manager.component';
import { RouteManagerComponent } from './pages/route/route-manager/route-manager.component';
import { TrackingManagerComponent } from './pages/tracking/tracking-manager/tracking-manager.component';
import { ShippingManagerComponent } from './pages/shipping/shipping-manager/shipping-manager.component';
import { AccountCollectComponent } from './pages/account/account-collect/account-collect.component';
import { AccountPayComponent } from './pages/account/account-pay/account-pay.component';
import { AccountMaintenanceComponent } from './pages/account/account-maintenance/account-maintenance.component';
import { PhraseMaintenanceComponent } from './pages/phrase/phrase-maintenance/phrase-maintenance.component';
import { ProductionManagerComponent } from './pages/production/production-manager/production-manager.component';
import { InProgressComponent } from './pages/maintenance/in-progress/in-progress.component';
import { PackageMaintenanceComponent } from './pages/package/package-maintenance/package-maintenance.component';
import { ProductionMaintenanceComponent } from './pages/production/production-maintenance/production-maintenance.component';
import { ReportManagerComponent } from './pages/report/report-manager/report-manager.component';
import { AccountDailyBookComponent } from './pages/account/account-daily-book/account-daily-book.component';
import { AccountBalanceComponent } from './pages/account/account-balance/account-balance.component';
import { SellerMaintenanceComponent } from './pages/seller/seller-maintenance/seller-maintenance.component';
import { AddendumMaintenanceComponent } from './pages/addendum/addendum-maintenance/addendum-maintenance.component';
import { SaleRecurringManagerComponent } from './pages/saleRecurring/sale-recurring-manager/sale-recurring-manager.component';
import { QuotationManagerComponent } from './pages/quotation/quotation-manager/quotation-manager.component';
import { RolePermissionMaintenanceComponent } from './pages/rolePermission/role-permission-maintenance/role-permission-maintenance.component';
const routes: Routes = [
  // home
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
  // company
  {
    path: 'companyMaintenance',
    canActivate: [AuthGuard],
    component: CompanyMaintenanceComponent,
  },
  // Store
  {
    path: 'storeMaintenance',
    canActivate: [AuthGuard],
    component: StoreMaintenanceComponent,
  },
  // user
  {
    path: 'userLogin',
    canActivate: [AuthGuard],
    component: UserLoginComponent,
  },
  { path: 'userResendCode', component: UserResendCodeComponent },
  { path: 'userForgotPassword', component: UserForgotPasswordComponent },
  { path: 'userChangePassword', component: UserChangePasswordComponent },
  {
    path: 'userAutoConfirm/:userEmail/:verificationCode',
    component: UserConfirmAutomaticComponent,
  },
  {
    path: 'userMaintenance',
    canActivate: [AuthGuard],
    component: UserMaintenanceComponent,
  },
  // Article
  {
    path: 'articleMaintenance',
    canActivate: [AuthGuard],
    component: ArticleMaintenanceComponent,
  },
  // client
  {
    path: 'clientMaintenance',
    canActivate: [AuthGuard],
    component: ClientMaintenanceComponent,
  },
  // provider
  {
    path: 'providerMaintenance',
    canActivate: [AuthGuard],
    component: ProviderMaintenanceComponent,
  },
  // bank Account
  {
    path: 'bankAccountMaintenance',
    canActivate: [AuthGuard],
    component: BankAccountMaintenanceComponent,
  },
  // Accountant
  {
    path: 'accountantMaintenance',
    canActivate: [AuthGuard],
    component: AccountantMaintenanceComponent,
  },
  // Category
  {
    path: 'categoryMaintenance',
    canActivate: [AuthGuard],
    component: CategoryMaintenanceComponent,
  },
  // profile
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileMaintenanceComponent,
  },
  // Routes
  {
    path: 'routesMaintenance',
    canActivate: [AuthGuard],
    component: RoutesMaintenanceComponent,
  },
  // Size
  {
    path: 'sizeMaintenance',
    canActivate: [AuthGuard],
    component: SizeMaintenanceComponent,
  },
  // subCategory
  {
    path: 'subCategoryMaintenance',
    canActivate: [AuthGuard],
    component: SubCategoryMaintenanceComponent,
  },
  // color
  {
    path: 'colorMaintenance',
    canActivate: [AuthGuard],
    component: ColorMaintenanceComponent,
  },
  // inventory
  {
    path: 'productMaintenance',
    canActivate: [AuthGuard],
    component: InventoryMaintenanceComponent,
  },
  // purchase
  {
    path: 'purchase',
    canActivate: [AuthGuard],
    component: PurchaseManagerComponent,
  },
  // sale
  { path: 'sale', canActivate: [AuthGuard], component: SaleManagerComponent },
  // shipping
  {
    path: 'shipping',
    canActivate: [AuthGuard],
    component: ShippingManagerComponent,
  },
  // delivery
  {
    path: 'delivery',
    canActivate: [AuthGuard],
    component: DeliveryManagerComponent,
  },
  // transfer
  {
    path: 'transfer',
    canActivate: [AuthGuard],
    component: TransferManagerComponent,
  },
  // return
  {
    path: 'return',
    canActivate: [AuthGuard],
    component: ReturnManagerComponent,
  },
  // routes
  {
    path: 'routes',
    canActivate: [AuthGuard],
    component: RouteManagerComponent,
  },
  // account
  {
    path: 'accountCollect',
    canActivate: [AuthGuard],
    component: AccountCollectComponent,
  },
  {
    path: 'accountPay',
    canActivate: [AuthGuard],
    component: AccountPayComponent,
  },
  {
    path: 'accountMaintenance',
    canActivate: [AuthGuard],
    component: AccountMaintenanceComponent,
  },
  // tracking
  {
    path: 'tracking',
    canActivate: [AuthGuard],
    component: TrackingManagerComponent,
  },
  // package
  {
    path: 'inventoryMaintenance/packageMaintenance',
    canActivate: [AuthGuard],
    component: PackageMaintenanceComponent,
  },
  // production
  {
    path: 'production',
    canActivate: [AuthGuard],
    component: ProductionManagerComponent,
  },
  {
    path: 'inventoryMaintenance/productionMaintenance',
    canActivate: [AuthGuard],
    component: ProductionMaintenanceComponent,
  },
  // phrase
  {
    path: 'phraseMaintenance',
    canActivate: [AuthGuard],
    component: PhraseMaintenanceComponent,
  },
  // addendum
  {
    path: 'addendumMaintenance',
    canActivate: [AuthGuard],
    component: AddendumMaintenanceComponent,
  },
  // reports
  {
    path: 'report',
    canActivate: [AuthGuard],
    component: ReportManagerComponent,
  },
  // reports
  {
    path: 'dailyBook',
    canActivate: [AuthGuard],
    component: AccountDailyBookComponent,
  },
  // reports
  {
    path: 'balance',
    canActivate: [AuthGuard],
    component: AccountBalanceComponent,
  },
  //seller
  {
    path: 'sellerMaintenance',
    canActivate: [AuthGuard],
    component: SellerMaintenanceComponent,
  },
  // saleRecurring
  {
    path: 'saleRecurring',
    canActivate: [AuthGuard], component: SaleRecurringManagerComponent
  },
  // quotation
  {
    path: 'quotation',
    canActivate: [AuthGuard],
    component: QuotationManagerComponent,
  },
  //rolePermission
  {
    path: 'roleMaintenance',
    canActivate: [AuthGuard],
    component: RolePermissionMaintenanceComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
