<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    *ngIf="
      !(
        receiptRecurringGenerateMode ||
        receiptRecurringListMode ||
        invoiceRecurringGenerateMode ||
        invoiceRecurringListMode
      )
    "
  >
    <div class="row" *ngIf="company.companyReceiptRecurring == 1">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title"><i class="fas fa-receipt"></i> Recibo recurrente</h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="receiptRecurringGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="receiptRecurringList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="company.companyInvoiceRecurring == 1">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-file-invoice"></i> Factura comercial recurrente
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="invoiceRecurringGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="invoiceRecurringList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-receipt-recurring-generate
    *ngIf="receiptRecurringGenerateMode"
    [headerActionType]="headerActionType"
    (cancelAction)="cancelAction()"
    [clientsaleRecurringList]="clientsaleRecurringList"
  ></app-receipt-recurring-generate>
  <app-receipt-recurring-list
    *ngIf="receiptRecurringListMode"
    (cancelAction)="cancelAction()"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
    [updatePermission]="updatePermission"
  ></app-receipt-recurring-list>

  <app-invoice-recurring-generate
    *ngIf="invoiceRecurringGenerateMode"
    [headerActionType]="headerActionType"
    (cancelAction)="cancelAction()"
    [clientsaleRecurringList]="clientsaleRecurringList"
  ></app-invoice-recurring-generate>
  <app-invoice-recurring-list
    *ngIf="invoiceRecurringListMode"
    (cancelAction)="cancelAction()"
    (creditNoteAction)="creditNoteInvoiceGenerate($event)"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [xlsxExportPermission]="xlsxExportPermission"
    [updatePermission]="updatePermission"
  >
  </app-invoice-recurring-list>
  
</div>
