<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Aceptar traslado
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" title="Cancelar">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" title="Guardar">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <label>Fecha</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="transfer.transferCreationDate"
                  disabled />
              </div>
              <div class="col-md-4">
                <label>Empresa</label>
                <div class="form-group">
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="transfer.transferCompanyName"
                    disabled />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label>Establecimiento origen</label>
                <div class="form-group">
                  <angular2-multiselect [data]="storeList" [(ngModel)]="transferStoreTransmitterSelected"
                    [settings]="dropdownSettingsTransmitter" (onSelect)="onItemTransmitterSelect($event)">
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-md-2">
                <label>Establecimiento destino</label>
                <div class="form-group">
                  <angular2-multiselect [data]="storeListReceive" [(ngModel)]="transferStoreReceiverSelected"
                    [settings]="dropdownSettingsReceiver" (onSelect)="onItemReceiverSelect($event)">
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Comentario</label>
                <div class="form-group">
                  <textarea [(ngModel)]="transfer.transferComment"></textarea>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="transfer.transferDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
              [rows]="transfer.transferDetail" (activate)="onActivate($event)">
              <ngx-datatable-column name="Descripción" prop="transferDetailDescription"></ngx-datatable-column>
              <ngx-datatable-column name="Cantidad Enviada" prop="transferDetailQuantity"></ngx-datatable-column>
              <ngx-datatable-column name="Cantidad Recibida">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                  <input autofocus (keyup)="updateValue($event, 'transferDetailQuantityReceived', rowIndex)" type="number"
                    [value]="row.transferDetailQuantityReceived" min="0"/>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
