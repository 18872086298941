import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Size } from '../models/size';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SizeService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // size maintenance
  async sizeGetStore(store): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-size/store/' + store + queryParam, {
        headers,
      })
      .toPromise();
  }

  async sizeGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-size/company/' + company + queryParam, {
        headers,
      })
      .toPromise();
  }

  async sizeGetCompanyEnable(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-size/enabled/' + company + queryParam, {
        headers,
      })
      .toPromise();
  }

  async sizeInsert(size: Size) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-size', size, { headers })
      .toPromise();
  }

  async sizeUpdate(size: Size) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-size', size, { headers })
      .toPromise();
  }

  async sizeDelete(size: Size) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-size/delete', size, { headers })
      .toPromise();
  }
}
