import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { TableHeader } from 'src/app/models/tableHeader';
import { CourierService } from 'src/app/services/courier.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-route-tracking',
  templateUrl: './route-tracking.component.html',
  styleUrls: ['./route-tracking.component.scss'],
})
export class RouteTrackingComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  reportData = new Array();
  reportDataFilter = new Array();

  public entries: number = environment.tableEntrys;
  public selected: any[] = [];
  public activeRow: any;

  tableHeaders: TableHeader[] = new Array<TableHeader>();

  reportStartDate;
  reportEndDate;

  courierList;
  userCourierList;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private tableHeader: TableHeaderService,
    private routeService: RouteService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setDefault();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.tableHeaders = await this.sharedUtils.transformToTableHeader(
      await this.getTableHeader(user.userRole, user.userCode, 'routeAssigned'),
      'tableHeaderTitle',
      'tableHeaderBusinessCode',
      'tableHeaderPipe'
    );
    await this.getReport();
  }

  async refresh() {
    await this.ngOnInit();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportStartDate = await this.sharedUtils.formatDate();
    this.reportEndDate = await this.sharedUtils.formatDate();
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async save(event, received) {
    event.preventDefault();
    let response;
    this.loading = true;
    this.activeRow['routeDetailStatus'] = received ? 2 : 3;
    response = await this.routeService.routeTracking(this.activeRow);
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.ngOnInit();
    }
    this.loading = false;
  }

  async getReport() {
    const report = await this.getData();
    this.reportData = [...report];
    this.reportDataFilter = [...this.reportData];
  }

  async getData() {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return this.routeService
      .routeAssigned(user.userCode, this.reportEndDate, user.userStore)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.reportDataFilter = this.reportData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }
}
