<app-loading *ngIf="loading"></app-loading>
<div class="main-content" *ngIf="readPermission">
  <div class="row" style="margin-top: -3em">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" *ngIf="!(notificationPermission || summaryPermission || graphicPermission)">
            {{ greetingIni }} {{ user.userName }}
          </h4>
          <div class="nav-tabs-navigation" *ngIf="notificationPermission || summaryPermission || graphicPermission">
            <div class="nav-tabs-wrapper">
              <ul id="tabs" class="nav nav-tabs" role="tablist">
                <li class="nav-item" *ngIf="summaryPermission">
                  <a class="nav-link active" data-toggle="tab" href="#summary" role="tab" (click)="selectOption(1)"
                    aria-expanded="true">Resumen</a>
                </li>
                <li class="nav-item" *ngIf="notificationPermission">
                  <a class="nav-link" data-toggle="tab" href="#notifications" role="tab"
                    (click)="selectOption(2)" aria-expanded="false">Notificaciones
                    <span class="notification-dot" *ngIf="notificationShow"></span>
                  </a>
                </li>
                <li class="nav-item" *ngIf="graphicPermission">
                  <a class="nav-link" data-toggle="tab" href="#graphics" role="tab" (click)="selectOption(3)"
                    aria-expanded="false">Gráficas</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row" id="summary" *ngIf="option === 1 && summaryPermission">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-3 d-flex align-items-center justify-content-center">
              <input type="month" class="form-control form-control-sm mr-2" [(ngModel)]="summaryDate"
                (change)="validateDate($event)" />
              <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataSummary($event)"
                ngbTooltip="Buscar" container="body">
                <i class="fas fa-search"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">FACTURAS</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.invoice.invoiceAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">RECIBOS</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.receipt.receiptAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/dollar.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">TOTAL VENTAS</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.sale.saleAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_deleted.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">IVA POR PAGAR</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.tax.taxPayAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/purchase.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">COMPRAS</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.purchase.purchaseAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">IVA POR COBRAR</p>
                <p class="card-title size-card-title">
                  Q.{{ summary.tax.taxCollectAmount | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">FACTURAS</p>
                <p class="card-title size-card-title">
                  {{ summary.invoice.invoiceQuantity | number : "1.0-0" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file_deleted.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">FACTURAS ANULADAS</p>
                <p class="card-title size-card-title">
                  {{
                  summary.invoice.invoiceQuantityCancel | number : "1.0-0"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">RECIBOS</p>
                <p class="card-title size-card-title">
                  {{ summary.receipt.receiptQuantity | number : "1.0-0" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center">
                <img src="/assets/img/home/invoice_file_deleted.png" alt="" style="width: 4rem; height: auto;">
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">RECIBOS ANULADOS</p>
                <p class="card-title size-card-title">
                  {{
                  summary.receipt.receiptQuantityCancel | number : "1.0-0"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>

  <div class="row" id="notifications" *ngIf="option === 2 && notificationPermission">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="tab-pane active" id="home" role="tabpanel" aria-expanded="true">
          <div class="row" style="text-align: center; justify-content: space-around;">

            <div class="card card-plain col-sm-5 col-md-2" style="margin-bottom: 0;"
              (click)="showDetail(1, notification.countAccountCollectExpired)">
              <br>Cuentas por cobrar vencidas              
              <div class="card-body d-flex flex-column align-items-center">                    
                <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
                  <div class="card card-plain">
                    <div role="tab" id="headingOne">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <div class="d-flex justify-content-center align-items-center">
                          <img src="/assets/img/home/alert_red.png" alt="" style="max-height: 3rem;">
                          <h5 style="padding-left: 1rem; margin-bottom: 0;">{{notification.countAccountCollectExpired}}
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-plain col-sm-5 col-md-2" style="margin-bottom: 0;"
              (click)="showDetail(2, notification.countAccountCollectNextExpired)">
              <br>Cuentas por cobrar próximas a vencer
              <div class="card-body d-flex flex-column align-items-center">                
                <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
                  <div class="card card-plain">
                    <div role="tab" id="headingTwo">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true"
                        aria-controls="collapseTwo">
                        <div class="d-flex justify-content-center align-items-center">
                          <img src="/assets/img/home/notification.png" alt="" style="max-height: 3rem;">
                          <h5 style="padding-left: 1rem; margin-bottom: 0;">
                            {{notification.countAccountCollectNextExpired}}</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-plain col-sm-5 col-md-2" style="margin-bottom: 0;"
              (click)="showDetail(3, notification.countAccountPayExpired)">
              <br>Cuentas por pagar vencidas
              <div class="card-body d-flex flex-column align-items-center">
                <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
                  <div class="card card-plain">
                    <div role="tab" id="headingThree">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true"
                        aria-controls="collapseThree">
                        <div class="d-flex justify-content-center align-items-center">
                          <img src="/assets/img/home/alert_red.png" alt="" style="max-height: 3rem;">
                          <h5 style="padding-left: 1rem; margin-bottom: 0;">{{notification.countAccountPayExpired}}</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-plain col-sm-5 col-md-2" style="margin-bottom: 0;"
              (click)="showDetail(4, notification.countAccountPayNextExpired)">
              <br>Cuentas por pagar próximas a vencer
              <div class="card-body d-flex flex-column align-items-center">
                <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
                  <div class="card card-plain">
                    <div role="tab" id="headingFour">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="true"
                        aria-controls="collapseFour">
                        <div class="d-flex justify-content-center align-items-center">
                          <img src="/assets/img/home/notification.png" alt="" style="max-height: 3rem;">
                          <h5 style="padding-left: 1rem; margin-bottom: 0;">{{notification.countAccountPayNextExpired}}
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div 
              *ngIf="company.companyReceiptRecurring == 1 || company.companyInvoiceRecurring == 1"
              class="card card-plain col-sm-5 col-md-2" style="margin-bottom: 0;"
              (click)="showDetail(5, notification.salesRecurrentNextEmit)">
              <br>Ventas recurrentes próximas a emitir
              <div class="card-body d-flex flex-column align-items-center">
                <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
                  <div class="card card-plain">
                    <div role="tab" id="headingFive">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="true"
                        aria-controls="collapseFive">
                        <div class="d-flex justify-content-center align-items-center">
                          <img src="/assets/img/home/return.png" alt="" style="max-height: 3rem;">
                          <h5 style="padding-left: 1rem; margin-bottom: 0;">{{notification.salesRecurrentNextEmit}}
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="option === 2">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="tab-pane active" id="home" role="tabpanel" aria-expanded="true">
          <div class="row" style="overflow-x: hidden; overflow-y: scroll; max-height: 400px; padding: 0 1rem;">
            <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne"
              *ngIf="notification.countAccountCollectExpired > 0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-sm-4 col-md-3 col-lg-3 d-flex align-items-stretch"
                    *ngFor="let item of notificationDetail">
                    <div class="card card-stats flex-fill">
                      <div class="card-header" style="background-color: #f1916e;"></div>
                      <div class="card-body">
                        <div class="stats">
                          <p>{{item.notification}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
              *ngIf="notification.countAccountCollectNextExpired > 0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-sm-4 col-md-3 col-lg-3 d-flex align-items-stretch"
                    *ngFor="let item of notificationDetail">
                    <div class="card card-stats flex-fill">
                      <div class="card-header" style="background-color: #f7d884;"></div>
                      <div class="card-body">
                        <div class="stats">
                          <p>{{item.notification}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
              *ngIf="notification.countAccountPayExpired > 0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-sm-4 col-md-3 col-lg-3 d-flex align-items-stretch"
                    *ngFor="let item of notificationDetail">
                    <div class="card card-stats flex-fill">
                      <div class="card-header" style="background-color: #f1916e;"></div>
                      <div class="card-body">
                        <div class="stats">
                          <p>{{item.notification}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour"
              *ngIf="notification.countAccountPayNextExpired > 0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-sm-4 col-md-3 col-lg-3 d-flex align-items-stretch"
                    *ngFor="let item of notificationDetail">
                    <div class="card card-stats flex-fill">
                      <div class="card-header" style="background-color: #f7d884;"></div>
                      <div class="card-body">
                        <div class="stats">
                          <p>{{item.notification}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive"
              *ngIf="notification.salesRecurrentNextEmit > 0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-sm-4 col-md-3 col-lg-3 d-flex align-items-stretch"
                    *ngFor="let item of notificationDetail">
                    <div class="card card-stats flex-fill">
                      <div class="card-header" style="background-color: #3caf6b;"></div>
                      <div class="card-body">
                        <div class="stats">
                          <p>{{item.notification}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" id="graphics" *ngIf="option === 3 && graphicPermission">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0">Ventas anuales</h5>
          <div class="d-flex align-items-center">
            <input type="number" class="form-control form-control-sm mr-2" [(ngModel)]="salesAnnualYear"
              min="1999" max="2199" (change)="validateYear($event)"
            />                           
            <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicAnnual($event, 1)" ngbTooltip="Buscar" container="body">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <canvas id="graphicSalesAnnual" width="826" height="80"></canvas>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0">Ventas mensuales</h5>
          <div class="d-flex align-items-center">
            <input type="month" class="form-control form-control-sm mr-2" [(ngModel)]="salesMonthlyMonth" (change)="validateDate($event)" />
            <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicMonthly($event, 1)" ngbTooltip="Buscar" container="body">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <canvas id="graphicSalesMonthly" width="826" height="80"></canvas>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0">Compras anuales</h5>
          <div class="d-flex align-items-center">
            <input type="number" class="form-control form-control-sm mr-2" [(ngModel)]="purchasesAnnualYear"
              min="1999" max="2199" (change)="validateYear($event)"
            />                           
            <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicAnnual($event, 2)" ngbTooltip="Buscar" container="body">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <canvas id="graphicPurchasesAnnual" width="826" height="80"></canvas>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0">Compras mensuales</h5>
          <div class="d-flex align-items-center">
            <input type="month" class="form-control form-control-sm mr-2" [(ngModel)]="purchasesMonthlyMonth" (change)="validateDate($event)" />
            <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicMonthly($event, 2)" ngbTooltip="Buscar" container="body">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <canvas id="graphicPurchasesMonthly" width="826" height="80"></canvas>
        </div>
      </div>
    </div>

    <div 
      *ngIf="company.companyInventory == 1"
      class="col-lg-6 col-sm-12">
      <div class="card">
        <div class="card-header text-center">
          <h5 class="card-title mb-0">Productos más vendidos anual</h5>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">          
              <input type="number" class="form-control form-control-sm mr-2" [(ngModel)]="inventoryAnnualDate"
                min="1999" max="2199" (change)="validateYear($event)"
              /> 
              <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicInventory($event, 1)"
                ngbTooltip="Buscar" container="body">
                <i class="fas fa-search"></i>
              </a>
            </div>
          </div>
          <canvas id="graphicInventoryAnnual" width="400" height="400"></canvas>
        </div>
      </div>
    </div>
  
    <div 
      *ngIf="company.companyInventory == 1"
      class="col-lg-6 col-sm-12" >
      <div class="card">
        <div class="card-header text-center">
          <h5 class="card-title mb-0">Productos más vendidos mensual</h5>
        </div>    
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
              <input type="month" class="form-control form-control-sm mr-2" [(ngModel)]="inventoryMonthlyDate"
                (change)="validateDate($event)" />
              <a href="#" class="btn btn-success btn-icon btn-sm btn-simple edit" (click)="getDataGraphicInventory($event, 2)"
                ngbTooltip="Buscar" container="body">
                <i class="fas fa-search"></i>
              </a>
            </div>
          </div>
          <canvas id="graphicInventoryMonthly" width="400" height="400"></canvas>
        </div>
      </div>
    </div>

  </div> 
</div>