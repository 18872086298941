import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inventory } from 'src/app/models/inventory';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ListArray } from 'src/app/models/listArray';
import { CategoryService } from 'src/app/services/category.service';
import { SubCategoryService } from 'src/app/services/sub-category.service';
import { ColorService } from 'src/app/services/color.service';
import { ArticleService } from 'src/app/services/article.service';
import { SizeService } from 'src/app/services/size.service';
import { MenuItem } from '../../../models/menuItem';

@Component({
  selector: 'app-inventory-maintenance-fields',
  templateUrl: './inventory-maintenance-fields.component.html',
  styleUrls: ['./inventory-maintenance-fields.component.scss']
})
export class InventoryMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @Input() headerActionType: string;
  @Input() inventoryViewMode: boolean = false;
  @Input() inventoryUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() inventory: Inventory = {} as Inventory;

  attributes: Attribute[] = new Array<Attribute>();

  bankList = new Array();
  list = {};
  selected = {};
  alert = false;


  dropdownSettings = {};

  dropdownSettingsMultipleSelect = {};

  subCategoryList: Array<any>;
  articleList: Array<any>;
  colorList: Array<any>;
  sizeList: Array<any>;

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private inventoryService: InventoryService,
    private catogoryService: CategoryService,
    private subCategoryService: SubCategoryService,
    private articleServices: ArticleService,
    private sizeService: SizeService,
    private colorService: ColorService,

  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(user.userRole, user.userCode, 'productMaintenance');
    const onlineList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(onlineList, 'id', 'itemName', 'inventoryOnline');
    const statusList = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'inventoryStatus');
    const inventoryTypeList = await this.sharedUtils.getServiceSelect();
    await this.addSelectList(inventoryTypeList, 'id', 'itemName', 'inventoryType');

    if (this.inventoryViewMode) {
      this.fntDropdownSettings(true);
      this.fntDropdownSettingsMultiSelect(true);
    } else {
      this.fntDropdownSettings(false);
      this.fntDropdownSettingsMultiSelect(false);
    }

  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(list, id, name);
    if (this.inventory[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] = await this.sharedUtils.selectFromList(this.list[attributeBusinessCode], this.inventory[attributeBusinessCode]);
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService.attributeGetRoleUserForm(role, user, form).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getCategory(company) {
    this.loading = true;
    return this.catogoryService.categoryGetCompanyEnable(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getSubCategory(company) {
    this.loading = true;
    return this.subCategoryService.subCategoryGetCompanyEnable(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getArticle(company) {
    this.loading = true;
    return this.articleServices.articleGetCompanyEnable(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getColor(company) {
    this.loading = true;
    return this.colorService.colorGetCompanyEnable(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async getSize(company) {
    this.loading = true;
    return this.sizeService.sizeGetCompanyEnable(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    switch (attributeBusinessCode) {
      case 'inventoryArticle':
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const fullArticle = this.articleList.find(x => x.articleCode == item.id);
        const sizeList = this.sizeList.filter(x => x.sizeType == fullArticle.articleType);
        await this.addSelectList(sizeList, 'sizeCode', 'sizeName', 'inventorySize');
        this.inventory[attributeBusinessCode] = item.id;
        break;

      default:
        this.inventory[attributeBusinessCode] = item.id;
        break;
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    //await this.getSubCategories();
    this.inventory['inventoryCreationUser'] = user.userCode;
    const validatedUser = this.inventory;
    this.saveAction.emit(validatedUser); 
    // if (await this.validate(validatedUser, this.attributeList)) {
    //   this.saveAction.emit(validatedUser);
    // }
  }

  async getSubCategories() {
    this.inventory['inventorySubcategories'] = new Array();
    for (const iterator of this.selected['inventorySubCategory']) {
      await this.inventory['inventorySubcategories'].push(
        {
          'inventorySubCategorySubCategory': iterator.id
        }
      );
    }
  }

  // Función para manipular el dropdown
  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
        disabled: true
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: "Seleccione",
        enableSearchFilter: true,
        classes: "",
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }

  fntDropdownSettingsMultiSelect(disabled) {
    if (disabled) {
      this.dropdownSettingsMultipleSelect = {
        singleSelection: false,
        text: 'Seleccionar...',
        enableSearchFilter: true,
        classes: '',
        searchPlaceholderText: 'Buscar',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true
      };
    } else {
      this.dropdownSettingsMultipleSelect = {
        singleSelection: false,
        text: 'Seleccionar...',
        enableSearchFilter: true,
        classes: '',
        searchPlaceholderText: 'Buscar',
        lazyLoading: true,
        maxHeight: 150
      };
    }
  }

}
