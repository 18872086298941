import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { UserCompanyService } from 'src/app/services/userCompany';
import { TitleService } from 'src/app/services/title.service';
import { UserStoreService } from 'src/app/services/userStore';
@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit {
  loading = false;
  userList: User[];
  user: User;

  headerActionType: string;
  userCreateMode: boolean = false;
  userUpdateMode: boolean = false;
  userDeleteMode: boolean = false;
  userViewMode: boolean = false;
  userAssignCompanyMode: boolean = false;
  userAssignStoreMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;
  userAssignCompanyPermission: boolean = false;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private userCompanyService: UserCompanyService,
    private userStoreService: UserStoreService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Mantenimiento"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.userList = await this.getUsers(user.userCompany);

  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          case 'assignCompany':
            this.userAssignCompanyPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getUsers(companyCode) {
    this.loading = true;
    return this.userService.userGetCompany(companyCode).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.headerActionType = 'Crear usuario';
    this.userCreateMode = true;
    this.user = {
      userCompany: user.userCompany,
      userStore: user.userStore,
      userStatus: true
    } as User;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver usuario';
    this.userViewMode = true;
    this.user = event as User;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar usuario';
    this.userUpdateMode = true;
    this.user = event as User;
  }

  async userAssignCompanyAction(event) {
    this.headerActionType = 'Asignar empresa a usuario';
    this.userAssignCompanyMode = true;
    this.user = event as User;
  }

  async deleteAction(event) {

    this.headerActionType = 'Borrar usuario';
    this.userDeleteMode = true;
    const userDeleted = {
      userCode: event.userCode,
      userName: event.userName,
      userRole: event.userRole,
      userCognitoCode: event.userCognitoCode,
      userDeleted: 1
    };
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(userDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.userCreateMode = false;
    this.userViewMode = false;
    this.userUpdateMode = false;
    this.userAssignCompanyMode = false;
    const userLogged = JSON.parse(localStorage.getItem('user') || '{}');
    this.userList = await this.getUsers(userLogged.userCompany);
    if (this.userAssignStoreMode == true) {
      this.userAssignStoreMode = false;
      window.location.reload();
    }
  }
  async saveAction2(user){
    this.userCreateMode = true;
    this.userUpdateMode = false;
    this.userDeleteMode = false;
    this.userViewMode = false;
    this.userAssignCompanyMode = false;
    this.userAssignStoreMode = false;
    this.saveAction(user);
  }

  async saveAction(user) {
    //console.log(user)
    if (user != false) {
      const userLogged = JSON.parse(localStorage.getItem('user') || '{}');
      let response;
      this.loading = true;
      if (this.userCreateMode == true) {
        user.userCompany = userLogged.userCompany;
        response = await this.userService.userInsert(user);
      }
      if (this.userUpdateMode == true) {
        user['userModifyDate'] = new Date();
        response = await this.userService.userUpdate(user);
      }
      if (this.userDeleteMode == true) {
        user['userDeletedDate'] = new Date();
        response = await this.userService.userUpdate(user);
      }
      if (this.userAssignCompanyMode == true) {
        response = await this.userCompanyService.userCompanyUpdate(user.userCompany);
        this.userAssignCompanyMode = false;
        this.userAssignStoreMode = true;
        await this.saveAction(user)
      }
      if (this.userAssignStoreMode == true) {
        response = await this.userStoreService.userStoreUpdate(user.userStore);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(user);
      }
      this.loading = false;
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }

}


