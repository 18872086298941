import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Client } from 'src/app/models/client';
import { User } from 'src/app/models/user';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { ListArray } from 'src/app/models/listArray';
import { RoleService } from 'src/app/services/role.service';
import { ClientService } from 'src/app/services/client.service';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-client-maintenance-fields',
  templateUrl: './client-maintenance-fields.component.html',
  styleUrls: ['./client-maintenance-fields.component.scss'],
})
export class ClientMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() clientViewMode: boolean = false;
  @Input() clientUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() client: Client = {} as Client;
  @Input() user: User = {} as User;

  attributes: Attribute[] = new Array<Attribute>();

  list = {};
  selected = {};
  alert = false;

  dropdownSettings = {};

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private roleService: RoleService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'clientMaintenance'
    );
    const departmentList = await this.getDepartment(company.companyCountry);
    await this.addSelectList(
      departmentList,
      'departmentCode',
      'departmentName',
      'clientDepartment'
    );
    const statusList = await this.sharedUtils.getSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'clientCredit');

    if (this.clientViewMode) {
      const municipalityList = await this.getMunicipality(
        this.client.clientDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      this.fntDropdownSettings(true);
    } else {
      this.fntDropdownSettings(false);
    }
    if (this.clientUpdateMode) {
      const municipalityList = await this.getMunicipality(
        this.client.clientDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'clientMunicipality'
      );
      this.fntDropdownSettings(false);
    }
  }
  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (
      !(
        this.client[attributeBusinessCode] == undefined ||
        this.client[attributeBusinessCode] == null
      )
    ) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.client[attributeBusinessCode]
        );
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'clientDepartment':
        this.client[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.client['clientMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'clientMunicipality'
        );
        break;
      default:
        this.client[attributeBusinessCode] = item.id;
        break;
    }
  }

  async cancel() {
    this.cancelAction.emit();
  }

  async save() {
    this.alert = true;
    if (this.form.valid) {
      const validatedClient = this.client;
      this.saveAction.emit(validatedClient);
    } else {
      const validatedClient = false;
      //console.log(validatedClient);
      this.saveAction.emit(validatedClient);
    }
  }

  // Función para manipular el dropdown
  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
