import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from 'src/app/models/invoice';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-sale-manager',
  templateUrl: './sale-manager.component.html',
  styleUrls: ['./sale-manager.component.scss'],
})
export class SaleManagerComponent implements OnInit {
  loading = false;

  @Input() quotationList;
  @Input() optionQuotation;  
  @Input() cancelQuotationPermission: boolean;
  @Output() cancelQuotation = new EventEmitter<any>();

  @Input() clientSaleList;
  @Input() clientSaleOption;  
  @Input() cancelSalePermission: boolean;
  @Output() cancelClientSale = new EventEmitter<any>();

  receiptGenerateMode: boolean = false;
  receiptListMode: boolean = false;

  receiptBusinessGenerateMode: boolean = false;
  receiptBusinessListMode: boolean = false;

  invoiceGenerateMode: boolean = false;
  invoiceListMode: boolean = false;

  invoiceExchangeGenerateMode: boolean = false;
  invoiceExchangeListMode: boolean = false;

  creditNoteGenerateMode: boolean = false;
  creditNoteListMode: boolean = false;

  creditNoteInvoice;
  creditNoteInvoiceExchange;
  creditNoteReceiptBusiness;

  createPermission: boolean = false;
  readPermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  store;
  company;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.titleService.emitChange('Ventas');
  }

  async ngOnInit() {   
    
    if (this.quotationList != undefined) {
      switch (this.optionQuotation) {
        case 1:
          await this.receiptGenerate();
          break;
        case 2:
          await this.invoiceGenerate();
          break;
        case 3:
          await this.invoiceExchangeGenerate();
          break;
        case 4:
          await this.receiptBusinessGenerate();
          break;
        default:
          break;
      }
    }

    if (this.clientSaleList != undefined) {
      switch (this.clientSaleOption) {
        case 1:
          await this.receiptGenerate();
          break;
        case 2:
          await this.invoiceGenerate();
          break;
        case 3:
          await this.invoiceExchangeGenerate();
          break;
        case 4:
          await this.receiptBusinessGenerate();
          break;
        default:
          break;
      }
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.company = JSON.parse(localStorage.getItem('company') || '{}');
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async receiptGenerate() {
    this.receiptGenerateMode = true;
  }

  async receiptList() {
    this.receiptListMode = true;
  }

  async receiptBusinessGenerate() {
    this.receiptBusinessGenerateMode = true;
  }

  async receiptBusinessList() {
    this.receiptBusinessListMode = true;
  }

  async invoiceGenerate() {
    this.invoiceGenerateMode = true;
  }

  async invoiceList() {
    this.invoiceListMode = true;
  }

  async invoiceExchangeGenerate() {
    this.invoiceExchangeGenerateMode = true;
  }

  async invoiceExchangeList() {
    this.invoiceExchangeListMode = true;
  }

  async creditNoteInvoiceGenerate(event) {
    await this.cancelAction();
    this.creditNoteInvoice = event;
    this.creditNoteGenerateMode = true;
  }

  async creditNoteReceiptBusinessGenerate(event) {
    await this.cancelAction();
    this.creditNoteReceiptBusiness = event;
    this.creditNoteGenerateMode = true;
  }

  async creditNoteInvoiceExchangeGenerate(event) {
    await this.cancelAction();
    this.creditNoteInvoiceExchange = event;
    this.creditNoteGenerateMode = true;
  }

  async creditNoteList() {
    this.invoiceExchangeListMode = true;
  }

  async cancelAction() {
    this.receiptGenerateMode = false;
    this.receiptListMode = false;

    this.receiptBusinessGenerateMode = false;
    this.receiptBusinessListMode = false;

    this.invoiceGenerateMode = false;
    this.invoiceListMode = false;

    this.invoiceExchangeGenerateMode = false;
    this.invoiceExchangeListMode = false;

    this.creditNoteGenerateMode = false;
    this.creditNoteListMode = false;

    this.cancelSale();
    this.cancelQuotationAction();
  }

  async cancelSale(){
    this.cancelClientSale.emit();
  }

  async cancelQuotationAction(){
    this.cancelQuotation.emit();
  }
}
