<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    class="row"
    *ngIf="
      !(
        inventoryCreateMode ||
        inventoryUpdateMode ||
        inventoryViewMode ||
        inventoryUploadImageMode ||
        inventoryDropMode
      )
    "
  >
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de productos</h4>
          <div class="toolbar">
            <app-inventory-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              [packagePermission]="packagePermission"
              [productionPermission]="productionPermission"
              [dropPermission]="dropPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
              (dropAction)="dropAction()"
            ></app-inventory-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-inventory-maintenance-table
            [inventoryList]="inventoryList"
            (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)"
            (deleteAction)="deleteAction($event)"
            (uploadImageAction)="uploadImageAction($event)"
            [readPermission]="readPermission"
            [updatePermission]="updatePermission"
            [uploadImagePermission]="uploadImagePermission"
            [deletePermission]="deletePermission"
            [viewPermission]="viewPermission"
            [xlsxExportDate]="xlsxExportDate"
          >
          </app-inventory-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-inventory-maintenance-fields
    *ngIf="inventoryCreateMode || inventoryUpdateMode || inventoryViewMode"
    [headerActionType]="headerActionType"
    [inventoryViewMode]="inventoryViewMode"
    [inventoryUpdateMode]="inventoryUpdateMode"
    [inventory]="inventory"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-inventory-maintenance-fields>
  <app-inventory-maintenance-upload-image
    *ngIf="inventoryUploadImageMode"
    [headerActionType]="headerActionType"
    [inventory]="inventory"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-inventory-maintenance-upload-image>
  <app-inventory-maintenance-drop
    *ngIf="inventoryDropMode"
    [headerActionType]="headerActionType"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-inventory-maintenance-drop>
</div>
