import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AttributeService {
  constructor(
    private _http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  async attributeGetRoleUserForm(role, user, form): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-attribute/role-user-form/' +
          role +
          '/' +
          user +
          '/' +
          form +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async attributeGetModuleCompanyForm(role, user, form): Promise<any> {
    /*
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this._http.get(environment.apiUrl + 'imfel-menu/modules/' + role + '/' + user + '/' + form + queryParam, { headers }).toPromise();
  }*/
  }
}
