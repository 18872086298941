import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Company } from 'src/app/models/company';
import { NgForm } from '@angular/forms';
import { Attribute } from 'src/app/models/attribute';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { NotificationService } from 'src/app/services/notification.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { CertifierService } from 'src/app/services/certifier.service';
import { ListArray } from 'src/app/models/listArray';
import { TableHeader } from 'src/app/models/tableHeader';
import { environment } from 'src/environments/environment';
import { TableHeaderService } from 'src/app/services/table-header.service';
import { StoreService } from 'src/app/services/store.service';
import { startWith } from 'rxjs/operators';
import { DepartmentService } from 'src/app/services/department.service';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-company-maintenance-fields',
  templateUrl: './company-maintenance-fields.component.html',
  styleUrls: ['./company-maintenance-fields.component.scss'],
})
export class CompanyMaintenanceFieldsComponent implements OnInit {
  loading = false;

  @ViewChild('form') form!: NgForm;

  @Input() headerActionType: string;
  @Input() companyViewMode: boolean = false;
  @Input() companyUpdateMode: boolean = false;

  @Output() cancelAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();

  @Input() company: Company = {} as Company;

  attributes: Attribute[] = new Array<Attribute>();

  certifierList = new Array();
  moduleList = new Array();

  dropdownSettings = {};

  list = {};
  selected = {};
  alert = false;

  personality = true;
  resolution = true;

  storeList = [];
  storeListFilter = [];
  entries: number = environment.tableEntrys;
  activeRow: any;
  tableHeaders: TableHeader[] = new Array<TableHeader>();
  classImageBtn = 'btn btn-block btn-round btn-file';

  constructor(
    public sharedUtils: SharedUtils,
    private attributeService: AttributeService,
    private notificationService: NotificationService,
    private certifierService: CertifierService,
    private countryService: CountryService,
    private departmentService: DepartmentService,
    private municipalityService: MunicipalityService,
    private tableHeader: TableHeaderService,
    private storeService: StoreService
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.attributes = await this.getAttribute(
      user.userRole,
      user.userCode,
      'companyMaintenance'
    );
    await this.reviewAttribute(this.attributes);    
    
    this.certifierList = await this.getCertifier();
    await this.addSelectList(
      this.certifierList,
      'certifierCode',
      'certifierName',
      'companyCertifier'
    );
    const countryList = await this.getCountry();
    await this.addSelectList(
      countryList,
      'countryCode',
      'countryName',
      'companyCountry'
    );
    const companyClientList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(companyClientList, 'id', 'itemName', 'companyClient');
    const statusList = await this.sharedUtils.getStatusSelect();
    await this.addSelectList(statusList, 'id', 'itemName', 'companyStatus');
    const receiptList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(receiptList, 'id', 'itemName', 'companyReceipt');
    const receiptBusinessList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(receiptBusinessList, 'id', 'itemName', 'companyReceiptBusiness');
    const invoiceList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(invoiceList, 'id', 'itemName', 'companyInvoice');
    const invoiceExchangeList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(invoiceExchangeList, 'id', 'itemName', 'companyInvoiceExchange');
    const receiptSend = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(receiptSend, 'id', 'itemName', 'companyReceiptSend');
    const inventory = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(inventory, 'id', 'itemName', 'companyInventory');
    const regimeList = await this.sharedUtils.getRegimeSelect();
    await this.addSelectList(regimeList, 'id', 'itemName', 'companyRegime');
    const taxtoPayList = await this.sharedUtils.getTaxToPaySelect();
    this.company.companyTaxToPay =
      this.company.companyTaxToPay == null ? 0 : this.company.companyTaxToPay;
    await this.addSelectList(taxtoPayList, 'id', 'itemName', 'companyTaxToPay');
    const resolutionList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(resolutionList, 'id', 'itemName', 'companyResolution');
    const receiptRecurringList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(receiptRecurringList, 'id', 'itemName', 'companyReceiptRecurring');
    const invoiceRecurringList = await this.sharedUtils.getBooleanYesNoSelect();
    await this.addSelectList(invoiceRecurringList, 'id', 'itemName', 'companyInvoiceRecurring');

    if (this.companyViewMode) {
      this.tableHeaders = await this.sharedUtils.transformToTableHeader(
        await this.getTableHeader(
          user.userRole,
          user.userCode,
          'storeMaintenance'
        ),
        'tableHeaderTitle',
        'tableHeaderBusinessCode'
      );
      this.storeList = await this.getStore(this.company.companyCode);
      this.storeListFilter = this.storeList;
      const departmentList = await this.getDepartment(
        this.company.companyCountry
      );
      await this.addSelectList(
        departmentList,
        'departmentCode',
        'departmentName',
        'companyDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.company.companyDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'companyMunicipality'
      );
      this.fntDropdownSettings(true);
    } else {
      this.fntDropdownSettings(false);
    }
    if (this.companyUpdateMode) {

      if (this.company.companyReceiptBusiness == 1) {
        this.personality = false;
      } else {
        this.personality = true;
      }  

      if (this.company.companyResolution == 1) {
        this.resolution = false;
      } else {
        this.resolution = true;
      }  

      const departmentList = await this.getDepartment(
        this.company.companyCountry
      );
      await this.addSelectList(
        departmentList,
        'departmentCode',
        'departmentName',
        'companyDepartment'
      );
      const municipalityList = await this.getMunicipality(
        this.company.companyDepartment
      );
      await this.addSelectList(
        municipalityList,
        'municipalityCode',
        'municipalityName',
        'companyMunicipality'
      );
      this.fntDropdownSettings(false);
    }
  }

  async reviewAttribute(attributes) {
    if (!(this.companyViewMode == true || this.companyUpdateMode == true)) {
      for (const attribute of attributes) {
        switch (attribute.attributeType) {
          case 'select':
            if (
              !(attribute.attributeBusinessCode == 'companyStatus' ||
                attribute.attributeBusinessCode == 'companyReceipt' ||
                attribute.attributeBusinessCode == 'companyReceiptBusiness' ||
                attribute.attributeBusinessCode == 'companyInvoice' ||
                attribute.attributeBusinessCode == 'companyReceiptSend' ||
                attribute.attributeBusinessCode == 'companyInventory' ||
                attribute.attributeBusinessCode == 'companyClient' ||
                attribute.attributeBusinessCode == 'companyInvoiceExchange' ||
                attribute.attributeBusinessCode == 'companyResolution' ||
                attribute.attributeBusinessCode == 'companyReceiptRecurring' ||
                attribute.attributeBusinessCode == 'companyInvoiceRecurring'
              )
            ) {
              this.company[attribute.attributeBusinessCode] = undefined;
            }
            break;

          default:
            if (this.company[attribute.attributeBusinessCode] == undefined)
              this.company[attribute.attributeBusinessCode] = null;
            break;
        }
      }
    }
    if (this.companyViewMode == true || this.companyUpdateMode == true) {
      for (const attribute of attributes) {
        switch (attribute.attributeType) {
          case 'select':
            if (this.company[attribute.attributeBusinessCode] == null) {
              this.company[attribute.attributeBusinessCode] = undefined;
            }
            break;

          default:
            break;
        }
      }
    }
  }

  async getAttribute(role, user, form) {
    this.loading = true;
    return this.attributeService
      .attributeGetRoleUserForm(role, user, form)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getModule() {
    this.loading = true;
    return this.certifierService
      .moduleGetAll()
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCertifier() {
    this.loading = true;
    return this.certifierService
      .certifierGetAll()
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getCountry() {
    this.loading = true;
    return this.countryService
      .countryGetAll()
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDepartment(country) {
    this.loading = true;
    return this.departmentService
      .departmentGetCountry(country)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getMunicipality(department) {
    this.loading = true;
    return this.municipalityService
      .municipalityGetAll(department)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getTableHeader(role, user, table) {
    this.loading = true;
    return this.tableHeader
      .tableHeaderGetRoleUserTable(role, user, table)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getStore(company) {
    this.loading = true;
    return this.storeService
      .storeGetCompany(company)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.list[attributeBusinessCode] = await this.sharedUtils.transformToList(
      list,
      id,
      name
    );
    if (this.company[attributeBusinessCode] !== undefined) {
      this.selected[attributeBusinessCode] =
        await this.sharedUtils.selectFromList(
          this.list[attributeBusinessCode],
          this.company[attributeBusinessCode]
        );
    }
  }

  public state: any = {};
  async addFile(e) {
    this.company.companyLogoName = e.target.files[0].name;
    this.company.companyLogo = e.target.files.item(0);
    this.classImageBtn = 'btn btn-success btn-block btn-round btn-file';
  }

  async cancel() {
    this.cancelAction.emit();
    this.classImageBtn = 'btn btn-block btn-round btn-file';
  }

  async save() {
    this.alert = true;
    if (this.form.valid) {
      const validatedUser = this.company;
      if (
        !(
          this.company.companyLogo == undefined ||
          this.company.companyLogo == null
        )
      ) {
        await this.sharedUtils
          .convertBase64(this.company.companyLogo)
          .then((data: string) => {
            this.company.companyLogo = data;
          });
      }
      this.saveAction.emit(validatedUser);
    } else {
      const validatedUser = false;
      this.saveAction.emit(validatedUser);
    }

  }

  async onItemSelect(item: ListArray, attributeBusinessCode) {
    //this.user[attributeBusinessCode] = item.id;
    switch (attributeBusinessCode) {
      case 'companyCountry':
        this.company[attributeBusinessCode] = item.id;
        const departmentList = await this.getDepartment(item.id);
        this.company['companyDepartment'] = undefined;
        await this.addSelectList(
          departmentList,
          'departmentCode',
          'departmentName',
          'companyDepartment'
        );
        break;
      case 'companyDepartment':
        this.company[attributeBusinessCode] = item.id;
        const municipalityList = await this.getMunicipality(item.id);
        this.company['companyMunicipality'] = undefined;
        await this.addSelectList(
          municipalityList,
          'municipalityCode',
          'municipalityName',
          'companyMunicipality'
        );
        break;
      case 'companyRegime':
        this.company[attributeBusinessCode] = item.id;
        this.company['companyTaxToPay'] = item.id == '1' ? 2 : 1;
        break;
      case 'companyReceiptBusiness':
        this.company[attributeBusinessCode] = item.id;

        if (this.company[attributeBusinessCode] == 1) {
          this.personality = false;
        } else {
          this.personality = true;
        }   
        break;
      case 'companyResolution':
        this.company[attributeBusinessCode] = item.id;

        if (this.company[attributeBusinessCode] == 1) {
          this.resolution = false;
          this.company.companyResolutionDate = await this.sharedUtils.formatDate();
        } else {
          this.resolution = true;
          this.company.companyResolutionDate = null;
          this.company.companyResolutionNumber = null;
        }   
        break;
      default:
        this.company[attributeBusinessCode] = item.id;
        break;
    }
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.storeListFilter = this.storeList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  fntDropdownSettings(disabled) {
    if (disabled) {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
        disabled: true,
      };
    } else {
      this.dropdownSettings = {
        singleSelection: true,
        text: 'Seleccione',
        enableSearchFilter: true,
        classes: '',
        lazyLoading: true,
        maxHeight: 150,
      };
    }
  }
}
