<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ headerActionType }}
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>

      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" *ngFor="let inventoryImage of inventoryImageList ; let i = index">
            <label>Imagen {{i+1}}</label>
            <div>
              <img [src]="inventoryImage.inventoryImagePreview" *ngIf="inventoryImage.inventoryImagePreview"
                alt="imagen" height="200">
              <img [src]="inventoryImage.inventoryImageUrl" *ngIf="inventoryImage.inventoryImageUrl" alt="imagen"
                height="200" />
            </div>
            <span [class]="inventoryImage.classImageBtn">
              <span class="fileinput-new">JPG/PNG</span>
              <input type="file" (change)="addFile($event, inventoryImage.inventoryImageCode)" />
            </span>
          </div>
        </div>
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
