import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { ReportService } from 'src/app/services/report.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-report-manager',
  templateUrl: './report-manager.component.html',
  styleUrls: ['./report-manager.component.scss'],
})
export class ReportManagerComponent implements OnInit {
  loading = false;

  reportList: any[];

  reportGenerateMode: boolean = false;

  report;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private reportService: ReportService,
    private router: Router
  ) {
    this.titleService.emitChange('Reportes');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.reportList = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode
    );
  }

  async permissionGetRoleUser(role, user) {
    this.loading = true;
    return await this.reportService
      .reportGetRoleUserForm(role, user, 1)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async reportGenerate(report) {
    this.reportGenerateMode = true;
    this.report = report;
  }

  async cancelAction() {
    this.reportGenerateMode = false;
  }
}
