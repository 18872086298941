<div class="modal-content">
  <div class="modal-header justify-content-center">
    <button type="button" class="close" (click)="close()">
      <i class="now-ui-icons ui-1_simple-remove"></i>
    </button>
    <h4 class="title title-up">Cambiar clave</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>Código</label>
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="code" />
        </div>
      </div>
      <div class="col-md-12">
        <label>Nueva clave</label>
        <!-- <div class="form-group">
          <div class="input-group-prepend">
            <span class="input-group-text" (click)="showHidePassword()">
              <i class="nc-icon nc-key-25"></i>
            </span>
          </div>
          <input type="password" id="pass" class="form-control" [(ngModel)]="newPassword" />
        </div> -->
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" (click)="showHidePassword()">
              <i class="nc-icon nc-key-25"></i>
            </span>
          </div>
          <input type="password" id="pass" placeholder="Clave" class="form-control" [(ngModel)]="newPassword" />
        </div>
      </div>
      <p>
        * La clave debe tener como mínimo 8 caracteres. Debe incluir 1 letra mayúscula, 1 letra minúscula, 1 número y 1
        carácter
        especial.
      </p>
      <div class="col-md-12 float-right">
        <button type="button" class="btn btn-success btn-simple float-right" (click)="passBack()">Guardar</button>
      </div>
    </div>
  </div>
</div>