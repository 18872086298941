<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    class="row"
    *ngIf="!(storeCreateMode || storeUpdateMode || storeViewMode)"
  >
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de establecimientos</h4>
          <div class="toolbar">
            <app-store-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
            ></app-store-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-store-maintenance-table
            [storeList]="storeList"
            (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)"
            (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission"
            [updatePermission]="updatePermission"
            [deletePermission]="deletePermission"
            [viewPermission]="viewPermission"
            [xlsxExportDate]="xlsxExportDate"
          >
          </app-store-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-store-maintenance-fields
    *ngIf="storeCreateMode || storeUpdateMode || storeViewMode"
    [headerActionType]="headerActionType"
    [storeViewMode]="storeViewMode"
    [storeUpdateMode]="storeUpdateMode"
    [store]="store"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-store-maintenance-fields>
</div>
