<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row" *ngIf="!(subCategoryCreateMode || subCategoryUpdateMode || subCategoryViewMode)">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Listado de subcategorías
          </h4>
          <div class="toolbar">
            <app-sub-category-maintenance-toolbox [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"></app-sub-category-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-sub-category-maintenance-table [subCategoryList]="subCategoryList" (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission" [updatePermission]="updatePermission"
            [deletePermission]="deletePermission" [viewPermission]="viewPermission" [xlsxExportDate]="xlsxExportDate">
          </app-sub-category-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-sub-category-maintenance-fields *ngIf="subCategoryCreateMode || subCategoryUpdateMode || subCategoryViewMode"
    [headerActionType]="headerActionType" [subCategoryViewMode]="subCategoryViewMode"
    [subCategoryUpdateMode]="subCategoryUpdateMode" [subCategory]="subCategory" (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)">
  </app-sub-category-maintenance-fields>
</div>
