// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// User
import { UserLoginComponent } from './pages/user/user-login/user-login.component';
import { UserChangePasswordComponent } from './pages/user/user-change-password/user-change-password.component';
import { UserConfirmAutomaticComponent } from './pages/user/user-confirm-automatic/user-confirm-automatic.component';
import { UserForgotPasswordComponent } from './pages/user/user-forgot-password/user-forgot-password.component';
import { UserForgotPasswordModalComponent } from './pages/user/user-forgot-password-modal/user-forgot-password-modal.component';
import { UserResendCodeComponent } from './pages/user/user-resend-code/user-resend-code.component';
import { UserMaintenanceComponent } from './pages/user/user-maintenance/user-maintenance.component';
import { UserMaintenanceFieldsComponent } from './pages/user/user-maintenance-fields/user-maintenance-fields.component';
import { UserMaintenanceTableComponent } from './pages/user/user-maintenance-table/user-maintenance-table.component';
import { UserMaintenanceToolboxComponent } from './pages/user/user-maintenance-toolbox/user-maintenance-toolbox.component';
import { UserConfirmComponent } from './pages/user/user-confirm/user-confirm.component';
// Core
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { LoadingComponent } from './core/loading/loading.component';
import { FooterComponent } from './core/footer/footer.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SharedUtils } from './core/utils/sharedUtils';
// Servicios
import { AuthGuard } from './services/auth-guard.service';
import { UserService } from './services/user.service';
// Client
import { ClientMaintenanceFieldsComponent } from './pages/client/client-maintenance-fields/client-maintenance-fields.component';
import { ClientMaintenanceTableComponent } from './pages/client/client-maintenance-table/client-maintenance-table.component';
import { ClientMaintenanceToolboxComponent } from './pages/client/client-maintenance-toolbox/client-maintenance-toolbox.component';
import { ClientMaintenanceComponent } from './pages/client/client-maintenance/client-maintenance.component';
// Library
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TreeviewModule } from 'ngx-treeview';
// Provider
import { ProviderMaintenanceComponent } from './pages/provider/provider-maintenance/provider-maintenance.component';
import { ProviderMaintenanceFieldsComponent } from './pages/provider/provider-maintenance-fields/provider-maintenance-fields.component';
import { ProviderMaintenanceTableComponent } from './pages/provider/provider-maintenance-table/provider-maintenance-table.component';
import { ProviderMaintenanceToolboxComponent } from './pages/provider/provider-maintenance-toolbox/provider-maintenance-toolbox.component';
// Company
import { CompanyMaintenanceComponent } from './pages/company/company-maintenance/company-maintenance.component';
import { CompanyMaintenanceFieldsComponent } from './pages/company/company-maintenance-fields/company-maintenance-fields.component';
import { CompanyMaintenanceTableComponent } from './pages/company/company-maintenance-table/company-maintenance-table.component';
import { CompanyMaintenanceToolboxComponent } from './pages/company/company-maintenance-toolbox/company-maintenance-toolbox.component';
// Profile
import { ProfileMaintenanceComponent } from './pages/profile/profile-maintenance/profile-maintenance.component';
import { ProfileMaintenanceFieldsComponent } from './pages/profile/profile-maintenance-fields/profile-maintenance-fields.component';
import { ProfileMaintenanceTableComponent } from './pages/profile/profile-maintenance-table/profile-maintenance-table.component';
import { ProfileMaintenanceToolboxComponent } from './pages/profile/profile-maintenance-toolbox/profile-maintenance-toolbox.component';
// Store
import { StoreMaintenanceComponent } from './pages/store/store-maintenance/store-maintenance.component';
import { StoreMaintenanceFieldsComponent } from './pages/store/store-maintenance-fields/store-maintenance-fields.component';
import { StoreMaintenanceTableComponent } from './pages/store/store-maintenance-table/store-maintenance-table.component';
import { StoreMaintenanceToolboxComponent } from './pages/store/store-maintenance-toolbox/store-maintenance-toolbox.component';
import { UserMaintenanceAssignCompanyComponent } from './pages/user/user-maintenance-assign-company/user-maintenance-assign-company.component';
import { BankAccountMaintenanceComponent } from './pages/bankAccount/bank-account-maintenance/bank-account-maintenance.component';
import { BankAccountMaintenanceFieldsComponent } from './pages/bankAccount/bank-account-maintenance-fields/bank-account-maintenance-fields.component';
import { BankAccountMaintenanceTableComponent } from './pages/bankAccount/bank-account-maintenance-table/bank-account-maintenance-table.component';
import { BankAccountMaintenanceToolboxComponent } from './pages/bankAccount/bank-account-maintenance-toolbox/bank-account-maintenance-toolbox.component';
import { AccountantMaintenanceComponent } from './pages/accountant/accountant-maintenance/accountant-maintenance.component';
import { AccountantMaintenanceFieldsComponent } from './pages/accountant/accountant-maintenance-fields/accountant-maintenance-fields.component';
import { AccountantMaintenanceTableComponent } from './pages/accountant/accountant-maintenance-table/accountant-maintenance-table.component';
import { AccountantMaintenanceToolboxComponent } from './pages/accountant/accountant-maintenance-toolbox/accountant-maintenance-toolbox.component';
import { CategoryMaintenanceComponent } from './pages/category/category-maintenance/category-maintenance.component';
import { CategoryMaintenanceFieldsComponent } from './pages/category/category-maintenance-fields/category-maintenance-fields.component';
import { CategoryMaintenanceTableComponent } from './pages/category/category-maintenance-table/category-maintenance-table.component';
import { CategoryMaintenanceToolboxComponent } from './pages/category/category-maintenance-toolbox/category-maintenance-toolbox.component';
import { RoutesMaintenanceComponent } from './pages/routes/routes-maintenance/routes-maintenance.component';
import { RoutesMaintenanceFieldsComponent } from './pages/routes/routes-maintenance-fields/routes-maintenance-fields.component';
import { RoutesMaintenanceTableComponent } from './pages/routes/routes-maintenance-table/routes-maintenance-table.component';
import { RoutesMaintenanceToolboxComponent } from './pages/routes/routes-maintenance-toolbox/routes-maintenance-toolbox.component';
import { SubCategoryMaintenanceComponent } from './pages/subCategory/sub-category-maintenance/sub-category-maintenance.component';
import { SubCategoryMaintenanceFieldsComponent } from './pages/subCategory/sub-category-maintenance-fields/sub-category-maintenance-fields.component';
import { SubCategoryMaintenanceTableComponent } from './pages/subCategory/sub-category-maintenance-table/sub-category-maintenance-table.component';
import { SubCategoryMaintenanceToolboxComponent } from './pages/subCategory/sub-category-maintenance-toolbox/sub-category-maintenance-toolbox.component';
import { ArticleMaintenanceComponent } from './pages/article/article-maintenance/article-maintenance.component';
import { ArticleMaintenanceFieldsComponent } from './pages/article/article-maintenance-fields/article-maintenance-fields.component';
import { ArticleMaintenanceTableComponent } from './pages/article/article-maintenance-table/article-maintenance-table.component';
import { ArticleMaintenanceToolboxComponent } from './pages/article/article-maintenance-toolbox/article-maintenance-toolbox.component';
import { SizeMaintenanceComponent } from './pages/size/size-maintenance/size-maintenance.component';
import { SizeMaintenanceFieldsComponent } from './pages/size/size-maintenance-fields/size-maintenance-fields.component';
import { SizeMaintenanceTableComponent } from './pages/size/size-maintenance-table/size-maintenance-table.component';
import { SizeMaintenanceToolboxComponent } from './pages/size/size-maintenance-toolbox/size-maintenance-toolbox.component';
import { ColorMaintenanceComponent } from './pages/color/color-maintenance/color-maintenance.component';
import { ColorMaintenanceTableComponent } from './pages/color/color-maintenance-table/color-maintenance-table.component';
import { ColorMaintenanceToolboxComponent } from './pages/color/color-maintenance-toolbox/color-maintenance-toolbox.component';
import { ColorMaintenanceFieldsComponent } from './pages/color/color-maintenance-fields/color-maintenance-fields.component';
import { InventoryMaintenanceFieldsComponent } from './pages/inventory/inventory-maintenance-fields/inventory-maintenance-fields.component';
import { InventoryMaintenanceComponent } from './pages/inventory/inventory-maintenance/inventory-maintenance.component';
import { InventoryMaintenanceToolboxComponent } from './pages/inventory/inventory-maintenance-toolbox/inventory-maintenance-toolbox.component';
import { InventoryMaintenanceTableComponent } from './pages/inventory/inventory-maintenance-table/inventory-maintenance-table.component';
import { InvoiceGenerateComponent } from './pages/invoice/invoice-generate/invoice-generate.component';
import { InvoiceCancelComponent } from './pages/invoice/invoice-cancel/invoice-cancel.component';
import { SaleManagerComponent } from './pages/sale/sale-manager/sale-manager.component';
import { PurchaseManagerComponent } from './pages/purchase/purchase-manager/purchase-manager.component';
import { DeliveryManagerComponent } from './pages/delivery/delivery-manager/delivery-manager.component';
import { TransferManagerComponent } from './pages/transfer/transfer-manager/transfer-manager.component';
import { ReturnManagerComponent } from './pages/return/return-manager/return-manager.component';
import { RouteManagerComponent } from './pages/route/route-manager/route-manager.component';
import { TrackingManagerComponent } from './pages/tracking/tracking-manager/tracking-manager.component';
import { ReceiptGenerateComponent } from './pages/receipt/receipt-generate/receipt-generate.component';
import { ReceiptCancelComponent } from './pages/receipt/receipt-cancel/receipt-cancel.component';
import { InventoryMaintenanceUploadImageComponent } from './pages/inventory/inventory-maintenance-upload-image/inventory-maintenance-upload-image.component';
import { ReceiptListComponent } from './pages/receipt/receipt-list/receipt-list.component';
import { InvoiceListComponent } from './pages/invoice/invoice-list/invoice-list.component';
import { TransferGenerateComponent } from './pages/transfer/transfer-generate/transfer-generate.component';
import { TransferListComponent } from './pages/transfer/transfer-list/transfer-list.component';
import { PurchaseListComponent } from './pages/purchase/purchase-list/purchase-list.component';
import { PurchaseGenerateComponent } from './pages/purchase/purchase-generate/purchase-generate.component';
import { PurchaseReceiveComponent } from './pages/purchase/purchase-receive/purchase-receive.component';
import { TransferReceiveComponent } from './pages/transfer/transfer-receive/transfer-receive.component';
import { ShippingManagerComponent } from './pages/shipping/shipping-manager/shipping-manager.component';
import { ShippingScheduleComponent } from './pages/shipping/shipping-schedule/shipping-schedule.component';
import { ShippingListComponent } from './pages/shipping/shipping-list/shipping-list.component';
import { RouteGenerateComponent } from './pages/route/route-generate/route-generate.component';
import { RouteAssignComponent } from './pages/route/route-assign/route-assign.component';
import { RouteTrackingComponent } from './pages/route/route-tracking/route-tracking.component';
import { InvoiceExchangeGenerateComponent } from './pages/invoice-exchange/invoice-exchange-generate/invoice-exchange-generate.component';
import { InvoiceExchangeListComponent } from './pages/invoice-exchange/invoice-exchange-list/invoice-exchange-list.component';
import { InvoiceExchangeCancelComponent } from './pages/invoice-exchange/invoice-exchange-cancel/invoice-exchange-cancel.component';
import { CreditNoteGenerateComponent } from './pages/credit-note/credit-note-generate/credit-note-generate.component';
import { AccountCollectComponent } from './pages/account/account-collect/account-collect.component';
import { AccountPayComponent } from './pages/account/account-pay/account-pay.component';
import { AccountMaintenanceComponent } from './pages/account/account-maintenance/account-maintenance.component';
import { AccountMaintenanceToolboxComponent } from './pages/account/account-maintenance-toolbox/account-maintenance-toolbox.component';
import { AccountMaintenanceTableComponent } from './pages/account/account-maintenance-table/account-maintenance-table.component';
import { AccountMaintenanceFieldsComponent } from './pages/account/account-maintenance-fields/account-maintenance-fields.component';
import { PhraseMaintenanceComponent } from './pages/phrase/phrase-maintenance/phrase-maintenance.component';
import { ProductionManagerComponent } from './pages/production/production-manager/production-manager.component';
import { ProductionGenerateComponent } from './pages/production/production-generate/production-generate.component';
import { ProductionListComponent } from './pages/production/production-list/production-list.component';
import { InProgressComponent } from './pages/maintenance/in-progress/in-progress.component';
import { PackageMaintenanceComponent } from './pages/package/package-maintenance/package-maintenance.component';
import { PackageMaintenanceTableComponent } from './pages/package/package-maintenance-table/package-maintenance-table.component';
import { PackageMaintenanceToolboxComponent } from './pages/package/package-maintenance-toolbox/package-maintenance-toolbox.component';
import { PackageMaintenanceFieldsComponent } from './pages/package/package-maintenance-fields/package-maintenance-fields.component';
import { PhraseMaintenanceFieldsComponent } from './pages/phrase/phrase-maintenance-fields/phrase-maintenance-fields.component';
import { PhraseMaintenanceTableComponent } from './pages/phrase/phrase-maintenance-table/phrase-maintenance-table.component';
import { PhraseMaintenanceToolboxComponent } from './pages/phrase/phrase-maintenance-toolbox/phrase-maintenance-toolbox.component';
import { ProductionMaintenanceComponent } from './pages/production/production-maintenance/production-maintenance.component';
import { ProductionMaintenanceTableComponent } from './pages/production/production-maintenance-table/production-maintenance-table.component';
import { ProductionMaintenanceToolboxComponent } from './pages/production/production-maintenance-toolbox/production-maintenance-toolbox.component';
import { ProductionMaintenanceFieldsComponent } from './pages/production/production-maintenance-fields/production-maintenance-fields.component';
import { InventoryMaintenanceDropComponent } from './pages/inventory/inventory-maintenance-drop/inventory-maintenance-drop.component';
import { ReportManagerComponent } from './pages/report/report-manager/report-manager.component';
import { ReportGenerateComponent } from './pages/report/report-generate/report-generate.component';
import { AccountDailyBookComponent } from './pages/account/account-daily-book/account-daily-book.component';
import { AccountBalanceComponent } from './pages/account/account-balance/account-balance.component';
import { SellerMaintenanceComponent } from './pages/seller/seller-maintenance/seller-maintenance.component';
import { SellerMaintenanceFieldsComponent } from './pages/seller/seller-maintenance-fields/seller-maintenance-fields.component';
import { SellerMaintenanceTableComponent } from './pages/seller/seller-maintenance-table/seller-maintenance-table.component';
import { SellerMaintenanceToolboxComponent } from './pages/seller/seller-maintenance-toolbox/seller-maintenance-toolbox.component';
import { HomeComponent } from './pages/home/home.component';
import { AddendumMaintenanceComponent } from './pages/addendum/addendum-maintenance/addendum-maintenance.component';
import { AddendumMaintenanceTableComponent } from './pages/addendum/addendum-maintenance-table/addendum-maintenance-table.component';
import { AddendumMaintenanceToolboxComponent } from './pages/addendum/addendum-maintenance-toolbox/addendum-maintenance-toolbox.component';
import { AddendumMaintenanceFieldsComponent } from './pages/addendum/addendum-maintenance-fields/addendum-maintenance-fields.component';
import { ReceiptBusinessCancelComponent } from './pages/receiptBusiness/receipt-business-cancel/receipt-business-cancel.component';
import { ReceiptBusinessListComponent } from './pages/receiptBusiness/receipt-business-list/receipt-business-list.component';
import { ReceiptBusinessGenerateComponent } from './pages/receiptBusiness/receipt-business-generate/receipt-business-generate.component';
import { ClientHistoryComponent } from './pages/client/client-history/client-history.component';
import { SaleRecurringManagerComponent } from './pages/saleRecurring/sale-recurring-manager/sale-recurring-manager.component';
import { ReceiptRecurringCancelComponent } from './pages/receiptRecurring/receipt-recurring-cancel/receipt-recurring-cancel.component';
import { ReceiptRecurringGenerateComponent } from './pages/receiptRecurring/receipt-recurring-generate/receipt-recurring-generate.component';
import { ReceiptRecurringListComponent } from './pages/receiptRecurring/receipt-recurring-list/receipt-recurring-list.component';
import { InvoiceRecurringCancelComponent } from './pages/invoiceRecurring/invoice-recurring-cancel/invoice-recurring-cancel.component';
import { InvoiceRecurringGenerateComponent } from './pages/invoiceRecurring/invoice-recurring-generate/invoice-recurring-generate.component';
import { InvoiceRecurringListComponent } from './pages/invoiceRecurring/invoice-recurring-list/invoice-recurring-list.component';
import { ClientVoucherComponent } from './pages/client/client-voucher/client-voucher.component';
import { QuotationGenerateComponent } from './pages/quotation/quotation-generate/quotation-generate.component';
import { QuotationListComponent } from './pages/quotation/quotation-list/quotation-list.component';
import { QuotationManagerComponent } from './pages/quotation/quotation-manager/quotation-manager.component';
import { RolePermissionMaintenanceComponent } from './pages/rolePermission/role-permission-maintenance/role-permission-maintenance.component';
import { RolePermissionMaintenanceFieldsComponent } from './pages/rolePermission/role-permission-maintenance-fields/role-permission-maintenance-fields.component';
import { RolePermissionMaintenanceTableComponent } from './pages/rolePermission/role-permission-maintenance-table/role-permission-maintenance-table.component';
import { RolePermissionMaintenanceToolboxComponent } from './pages/rolePermission/role-permission-maintenance-toolbox/role-permission-maintenance-toolbox.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    UserLoginComponent,
    UserChangePasswordComponent,
    UserConfirmAutomaticComponent,
    UserForgotPasswordComponent,
    UserForgotPasswordModalComponent,
    UserResendCodeComponent,
    UserMaintenanceComponent,
    UserMaintenanceFieldsComponent,
    UserMaintenanceTableComponent,
    UserMaintenanceToolboxComponent,
    UserConfirmComponent,
    SidebarComponent,
    LoadingComponent,
    FooterComponent,
    NavbarComponent,
    ClientMaintenanceComponent,
    ClientMaintenanceFieldsComponent,
    ClientMaintenanceTableComponent,
    ClientMaintenanceToolboxComponent,
    ProviderMaintenanceComponent,
    ProviderMaintenanceFieldsComponent,
    ProviderMaintenanceTableComponent,
    ProviderMaintenanceToolboxComponent,
    CompanyMaintenanceComponent,
    CompanyMaintenanceFieldsComponent,
    CompanyMaintenanceTableComponent,
    CompanyMaintenanceToolboxComponent,
    ProfileMaintenanceComponent,
    ProfileMaintenanceFieldsComponent,
    ProfileMaintenanceTableComponent,
    ProfileMaintenanceToolboxComponent,
    StoreMaintenanceComponent,
    StoreMaintenanceFieldsComponent,
    StoreMaintenanceTableComponent,
    StoreMaintenanceToolboxComponent,
    UserMaintenanceAssignCompanyComponent,
    BankAccountMaintenanceComponent,
    BankAccountMaintenanceFieldsComponent,
    BankAccountMaintenanceTableComponent,
    BankAccountMaintenanceToolboxComponent,
    AccountantMaintenanceComponent,
    AccountantMaintenanceFieldsComponent,
    AccountantMaintenanceTableComponent,
    AccountantMaintenanceToolboxComponent,
    CategoryMaintenanceComponent,
    CategoryMaintenanceFieldsComponent,
    CategoryMaintenanceTableComponent,
    CategoryMaintenanceToolboxComponent,
    RoutesMaintenanceComponent,
    RoutesMaintenanceFieldsComponent,
    RoutesMaintenanceTableComponent,
    RoutesMaintenanceToolboxComponent,
    SubCategoryMaintenanceComponent,
    SubCategoryMaintenanceFieldsComponent,
    SubCategoryMaintenanceTableComponent,
    SubCategoryMaintenanceToolboxComponent,
    ArticleMaintenanceComponent,
    ArticleMaintenanceFieldsComponent,
    ArticleMaintenanceTableComponent,
    ArticleMaintenanceToolboxComponent,
    SizeMaintenanceComponent,
    SizeMaintenanceFieldsComponent,
    SizeMaintenanceTableComponent,
    SizeMaintenanceToolboxComponent,
    ColorMaintenanceComponent,
    ColorMaintenanceTableComponent,
    ColorMaintenanceToolboxComponent,
    ColorMaintenanceFieldsComponent,
    InventoryMaintenanceFieldsComponent,
    InventoryMaintenanceComponent,
    InventoryMaintenanceToolboxComponent,
    InventoryMaintenanceTableComponent,
    InvoiceGenerateComponent,
    InvoiceCancelComponent,
    SaleManagerComponent,
    PurchaseManagerComponent,
    DeliveryManagerComponent,
    TransferManagerComponent,
    ReturnManagerComponent,
    RouteManagerComponent,
    TrackingManagerComponent,
    ReceiptGenerateComponent,
    ReceiptCancelComponent,
    InventoryMaintenanceUploadImageComponent,
    ReceiptListComponent,
    InvoiceListComponent,
    TransferGenerateComponent,
    TransferListComponent,
    PurchaseListComponent,
    PurchaseGenerateComponent,
    PurchaseReceiveComponent,
    TransferReceiveComponent,
    ShippingManagerComponent,
    ShippingScheduleComponent,
    ShippingListComponent,
    RouteGenerateComponent,
    RouteAssignComponent,
    RouteTrackingComponent,
    InvoiceExchangeGenerateComponent,
    InvoiceExchangeListComponent,
    InvoiceExchangeCancelComponent,
    CreditNoteGenerateComponent,
    AccountCollectComponent,
    AccountPayComponent,
    AccountMaintenanceComponent,
    AccountMaintenanceToolboxComponent,
    AccountMaintenanceTableComponent,
    AccountMaintenanceFieldsComponent,
    PhraseMaintenanceComponent,
    ProductionManagerComponent,
    ProductionGenerateComponent,
    ProductionListComponent,
    InProgressComponent,
    PackageMaintenanceComponent,
    PackageMaintenanceTableComponent,
    PackageMaintenanceToolboxComponent,
    PackageMaintenanceFieldsComponent,
    PhraseMaintenanceFieldsComponent,
    PhraseMaintenanceTableComponent,
    PhraseMaintenanceToolboxComponent,
    ProductionMaintenanceComponent,
    ProductionMaintenanceTableComponent,
    ProductionMaintenanceToolboxComponent,
    ProductionMaintenanceFieldsComponent,
    InventoryMaintenanceDropComponent,
    ReportManagerComponent,
    ReportGenerateComponent,
    AccountDailyBookComponent,
    AccountBalanceComponent,
    SellerMaintenanceComponent,
    SellerMaintenanceFieldsComponent,
    SellerMaintenanceTableComponent,
    SellerMaintenanceToolboxComponent,
    AddendumMaintenanceComponent,
    AddendumMaintenanceTableComponent,
    AddendumMaintenanceToolboxComponent,
    AddendumMaintenanceFieldsComponent,
    ReceiptBusinessCancelComponent,
    ReceiptBusinessListComponent,
    ReceiptBusinessGenerateComponent,
    ClientHistoryComponent,
    SaleRecurringManagerComponent,
    ReceiptRecurringCancelComponent,
    ReceiptRecurringGenerateComponent,
    ReceiptRecurringListComponent,
    InvoiceRecurringCancelComponent,
    InvoiceRecurringGenerateComponent,
    InvoiceRecurringListComponent,
    ClientVoucherComponent,
    QuotationGenerateComponent,
    QuotationListComponent,
    QuotationManagerComponent,
    RolePermissionMaintenanceComponent,
    RolePermissionMaintenanceFieldsComponent,
    RolePermissionMaintenanceTableComponent,
    RolePermissionMaintenanceToolboxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    NgbTooltipModule,
    // Library
    ToastrModule.forRoot(),
    NgxDatatableModule,
    AngularMultiSelectModule,
    TreeviewModule.forRoot(),
  ],
  providers: [UserService, AuthGuard, SharedUtils],
  bootstrap: [AppComponent],
})
export class AppModule {}
