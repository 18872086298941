import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Console } from 'console';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { ListArray } from 'src/app/models/listArray';
import { Transfer } from 'src/app/models/transfer';
import { TransferDetail } from 'src/app/models/transferDetail';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TransferService } from 'src/app/services/transfer.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');

@Component({
  selector: 'app-transfer-receive',
  templateUrl: './transfer-receive.component.html',
  styleUrls: ['./transfer-receive.component.scss']
})
export class TransferReceiveComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  @Input() transfer = {
    transferDetail: new Array<TransferDetail>()
  } as Transfer;

  transferDetail = {} as TransferDetail;

  storeList = new Array<ListArray>();
  storeListReceive = new Array<ListArray>();

  dropdownSettingsTransmitter = {
    singleSelection: true,
    text: "Seleccione",
    enableSearchFilter: true,
    classes: "",
    lazyLoading: true,
    maxHeight: 150,
    disabled: true
  };

  dropdownSettingsReceiver = {
    singleSelection: true,
    text: "Seleccione",
    enableSearchFilter: true,
    classes: "",
    lazyLoading: true,
    maxHeight: 150,
    disabled: true
  };

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  inventorySelected;

  transferStoreTransmitterSelected;
  transferStoreReceiverSelected;

  transferAddItemMode = false;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private inventoryService: InventoryService,
    private transferService: TransferService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const companys = await user.userCompanys.find(x => x.companyCode == user.userCompany);
    const stores = companys.companyStores.filter(x => x.storeCode != user.userStore);
    this.storeList = await this.sharedUtils.transformToList(
      companys.companyStores, 'storeCode', 'storeName');
    this.storeListReceive = await this.sharedUtils.transformToList(
      stores, 'storeCode', 'storeName');
    await this.setDefault();
    this.transfer.transferDetail = [...this.transfer.transferDetailShow]
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.transfer.transferCreationDate = await this.sharedUtils.formatDate();
    this.transfer.transferCompany = user.userCompany;
    this.transfer.transferCompanyName = user.userCompanys.find(x => x.companyCode == user.userCompany).companyName;
    this.transferStoreTransmitterSelected = await this.sharedUtils.selectFromList(
      this.storeList, this.transfer.transferStoreTransmitter);
    // this.transfer.transferStoreTransmitter = this.transferStoreTransmitterSelected[0].id;
    this.transferStoreReceiverSelected = await this.sharedUtils.selectFromList(
      this.storeList, this.transfer.transferStoreReceiver);
    // this.transfer.transferStoreReceiver = this.transferStoreReceiverSelected[0].id;
    this.transfer.transferUserRequest = user.userCode;
    for (const transferDetail of this.transfer.transferDetailShow) {
      transferDetail.transferDetailQuantityReceived = 0;
    }
  }

  async onItemSelect(item: ListArray) {
    const inventoryItem = this.inventoryCompleteList.find(x => x.inventoryCode == item.id);
    this.transferDetail.transferDetailInventoryOrigin = inventoryItem.inventoryCode;
    this.transferDetail.transferDetailInventoryDescription = inventoryItem.inventoryName;
  }

  async onItemTransmitterSelect(item: ListArray) {
    this.transfer.transferStoreTransmitter = item.id;
  }

  async onItemReceiverSelect(item: ListArray) {
    this.transfer.transferStoreReceiver = item.id;
  }

  async addDetail() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.transferAddItemMode = true;
    this.transferDetail = {
    } as TransferDetail;
    this.inventoryList = await this.sharedUtils.transformToList(await this.getInventory(user.userStore), 'inventoryCode', 'inventoryName');
  }

  async insertItem() {
    this.transferDetail.transferDetailCode = uuid.v4();
    this.transfer.transferDetail.push(this.transferDetail);
    this.transfer.transferDetail = [...this.transfer.transferDetail];
    this.transferDetail = {
      transferDetailQuantity: 0,
    } as TransferDetail;
    this.transferAddItemMode = false;
  }

  async getInventory(store) {
    this.loading = true;
    return this.inventoryService.inventoryGetStore(store).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async deleteItem(event) {
    event.preventDefault();
    const findDetail = this.transfer.transferDetail.find(x => x.transferDetailCode == this.activeRow.transferDetailCode);
    const indexDetail = this.transfer.transferDetail.indexOf(findDetail);
    this.transfer.transferDetail.splice(indexDetail, 1);
    this.transfer.transferDetail = [...this.transfer.transferDetail];
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async save() {
    let correct = true;
    this.transfer.transferDetail.forEach(e => {
      if(e['transferDetailQuantityReceived'] == 0){
        correct = false;
        return;
      }
    });

    if(!correct){
      this.notificationService.showError("Debe ingresar la cantidad recibida");
    }else{
      this.loading = true;
      let response;
      response = await this.transferService.transferReceive(this.transfer);
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancel();
      }
      this.loading = false;
    }
  }

  updateValue(event, cell, rowIndex) {
    var val = event instanceof Date ? event : event.target.value;
    this.transfer.transferDetail[rowIndex][cell] = val;
    this.transfer.transferDetail = [...this.transfer.transferDetail];
  }

}
