import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseDetail } from 'src/app/models/purchaseDetail';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-purchase-manager',
  templateUrl: './purchase-manager.component.html',
})
export class PurchaseManagerComponent implements OnInit {
  loading = false;

  purchaseGenerateMode: boolean = false;
  purchaseListMode: boolean = false;
  purchaseReceiveMode: boolean = false;
  purchaseViewMode: boolean = false;

  purchase = undefined;

  createPermission: boolean = false;
  readPermission: boolean = false;
  authorizePermission: boolean = false;
  unauthorizePermission: boolean = false;
  deletePermission: boolean = false;
  receivePermission: boolean = false;
  xlsxExportPermission: boolean = false;

  reportStartDate;
  reportEndDate;

  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.titleService.emitChange('Compras');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const findCompany = user.userCompanys.find(
      (x) => x.companyCode == user.userCompany
    );
    const findStore = findCompany.companyStores.find(
      (x) => x.storeCode == user.userStore
    );
    if (findStore.storePurchase != 1) {
      this.router.navigate(['/home']);
      this.notificationService.showWarning(
        'Establecimiento no autorizado para esta opción'
      );
    }
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'auth':
            this.authorizePermission = true;
            break;
          case 'unauth':
            this.unauthorizePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'receive':
            this.receivePermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  purchaseGenerate() {
    this.purchase = {
      purchaseDetail: new Array<PurchaseDetail>(),
    } as Purchase;
    this.purchaseGenerateMode = true;
  }

  purchaseList() {
    this.purchaseListMode = true;
  }

  purchaseReceive(purchase) {
    this.purchase = { ...purchase };
    this.purchaseListMode = false;
    this.purchaseReceiveMode = true;
  }

  purchaseView(purchase) {
    this.reportStartDate = purchase.reportStartDate;
    this.reportEndDate = purchase.reportEndDate;
    this.purchase = { ...purchase.activeRow };
    this.purchaseListMode = false;
    this.purchaseViewMode = true;
  }

  cancelAction() {
    this.purchase = {
      purchaseDetail: new Array<PurchaseDetail>(),
    } as Purchase;
    this.purchaseGenerateMode = false;
    this.purchaseViewMode = false;
    this.purchaseListMode = false;
    this.purchaseReceiveMode = false;
  }

  cancelViewAction() {
    this.purchase = {
      purchaseDetail: new Array<PurchaseDetail>(),
    } as Purchase;
    this.purchaseGenerateMode = false;
    this.purchaseViewMode = false;
    this.purchaseReceiveMode = false;

    this.purchaseListMode = true;
  }
}
