import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { UserService } from './user.service';
import { Store } from '../models/store';
declare var $: any;

const POOL_DATA = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId,
};
const userPool = new CognitoUserPool(POOL_DATA);
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async storeGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + '/imfel-store/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async storeInsert(store: Store) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-store/', store, { headers })
      .toPromise();
  }

  async storeUpdate(store: Store) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-store/', store, { headers })
      .toPromise();
  }

  async storeDelete(store: Store) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-store/', store, { headers })
      .toPromise();
  }
}
