import {  Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';

@Component({
  selector: 'app-provider-maintenance-toolbox',
  templateUrl: './provider-maintenance-toolbox.component.html',
  styleUrls: ['./provider-maintenance-toolbox.component.scss']
})
export class ProviderMaintenanceToolboxComponent implements OnInit {

  loading = false;

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  async createProvider() {
    this.createAction.emit();
  }

  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshProvider() {
    this.refreshAction.emit();
  }

}
