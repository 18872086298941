import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-production-manager',
  templateUrl: './production-manager.component.html',
  styleUrls: ['./production-manager.component.scss'],
})
export class ProductionManagerComponent implements OnInit {
  loading = false;

  productionGenerateMode: boolean = false;
  productionListMode: boolean = false;

  createPermission: boolean = false;
  readPermission: boolean = false;
  deletePermission: boolean = false;
  completePermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private titleService: TitleService,
    private permissionService: PermissionService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.titleService.emitChange('Producción');
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      user.userRole,
      user.userCode,
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService
      .permissionGetRoleUserRoute(role, user, route)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'complete':
            this.completePermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  productionGenerate() {
    this.productionGenerateMode = true;
  }

  productionList() {
    this.productionListMode = true;
  }

  cancelAction() {
    this.productionGenerateMode = false;
    this.productionListMode = false;
  }
}
