<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div class="row"
    *ngIf="!(routesCreateMode || routesUpdateMode || routesViewMode || routesStoreCreationMode || routesShowMode )">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Listado de rutas
          </h4>
          <div class="toolbar">
            <app-routes-maintenance-toolbox [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission" (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()">
            </app-routes-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-routes-maintenance-table [routesList]="routesList" (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)" (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission" [updatePermission]="updatePermission"
            [deletePermission]="deletePermission" [viewPermission]="viewPermission"
            [createStorePermission]="createStorePermission" (storeCreationAction)="storeCreationAction($event)"
            [showPermission]="showPermission" (routesShowAction)="routesShowAction($event)">

          </app-routes-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-routes-maintenance-fields *ngIf="routesCreateMode || routesUpdateMode || routesViewMode"
    [headerActionType]="headerActionType" [routesViewMode]="routesViewMode" [routesUpdateMode]="routesUpdateMode"
    [routes]="routes" (cancelAction)="cancelAction()" (saveAction)="saveAction($event)">
  </app-routes-maintenance-fields>

</div>
