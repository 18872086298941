import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Seller } from 'src/app/models/seller';
import { SellerService } from 'src/app/services/seller.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-seller-maintenance',
  templateUrl: './seller-maintenance.component.html',
  styleUrls: ['./seller-maintenance.component.scss']
})
export class SellerMaintenanceComponent implements OnInit {
  loading = false;
  sellerList: Seller[];
  seller: Seller;
  tableHeaders: [] = [];

  headerActionType: string;
  sellerCreateMode: boolean = false;
  sellerUpdateMode: boolean = false;
  sellerDeleteMode: boolean = false;
  sellerViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private sellerService: SellerService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Categorias"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.sellerList = await this.getSeller(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getSeller(company) {
    this.loading = true;
    return this.sellerService.sellerGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
}

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear vendedor';
    this.sellerCreateMode = true;
    this.seller = {} as Seller;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.seller.sellerCompany = user.userCompany;
    this.seller.sellerStore = user.userStore;
    this.seller.sellerStatus = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver vendedor';
    this.sellerViewMode = true;
    this.seller = event as Seller;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar vendedor';
    this.sellerUpdateMode = true;
    this.seller = event as Seller;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar vendedor';
    this.sellerDeleteMode = true;
    const sellerDeleted = {
      sellerCode: event.sellerCode,
      sellerDeleted: 1
    };
    swal.fire({
      title: "Atención",
      text: "¿Desea eliminar vendedor?",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(sellerDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.sellerCreateMode = false;
    this.sellerViewMode = false;
    this.sellerUpdateMode = false;
    this.sellerDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.sellerList = await this.getSeller(user.userCompany);
  }

  async saveAction(seller) {
    let response;
    this.loading = true;
    if (this.sellerCreateMode == true) {
      response = await this.sellerService.sellerInsert(seller);
    }
    if (this.sellerUpdateMode == true) {
      seller['sellerModifyDate'] = new Date();
      response = await this.sellerService.sellerUpdate(seller);
    }
    if (this.sellerDeleteMode == true) {
      seller['sellerDeletedDate'] = new Date();
      response = await this.sellerService.sellerDelete(seller);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }

}
