<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    *ngIf="
      !(
        quotationGenerateMode ||
        quotationListMode
      )
    "
  >
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="card card-stats">
          <div class="card-header">
            <h5 class="info-title">
              <i class="fas fa-calculator"></i> Cotizaciones
            </h5>
          </div>
          <div class="card-body">
            <button
              class="btn btn-sm btn-success btn-simple"
              ngbTooltip="Generar"
              container="body"
              (click)="quotationGenerate()"
              *ngIf="createPermission"
            >
              <i class="fas fa-plus"></i> Generar
            </button>
            <button
              class="btn btn-sm btn-info btn-simple"
              ngbTooltip="Listar"
              container="body"
              (click)="quotationList()"
              *ngIf="readPermission"
            >
              <i class="fas fa-list"></i> Listar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-quotation-generate
    *ngIf="quotationGenerateMode"
    (cancelAction)="cancelAction()"
    (cancelViewAction)="cancelViewAction()"
    [clientQuotationList]="clientQuotationList"
  >
  </app-quotation-generate>
  <app-quotation-list
    *ngIf="quotationListMode"
    (cancelAction)="cancelAction()"
    [viewPermission]="viewPermission"
    [deletePermission]="deletePermission"
    [sendPermission]="sendPermission"
    [saleTrackingPermission]="saleTrackingPermission"
    [xlsxExportPermission]="xlsxExportPermission"
    [reportStartDate]="reportStartDate"
    [reportEndDate]="reportEndDate"
  >
  </app-quotation-list>
</div>
