<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
  <div
    class="row"
    *ngIf="
      !(
        companyCreateMode ||
        companyUpdateMode ||
        companyViewMode ||
        companyStoreCreationMode ||
        companyShowMode
      )
    "
  >
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de empresas</h4>
          <div class="toolbar">
            <app-company-maintenance-toolbox
              [createPermission]="createPermission"
              [xlsxExportPermission]="xlsxExportPermission"
              (createAction)="createAction()"
              (refreshAction)="refreshAction()"
              (xlsxExportAction)="xlsxExportAction()"
            >
            </app-company-maintenance-toolbox>
          </div>
        </div>
        <div class="card-body">
          <app-company-maintenance-table
            [companyList]="companyList"
            (viewAction)="viewAction($event)"
            (updateAction)="updateAction($event)"
            (deleteAction)="deleteAction($event)"
            [readPermission]="readPermission"
            [updatePermission]="updatePermission"
            [deletePermission]="deletePermission"
            [viewPermission]="viewPermission"
            [createStorePermission]="createStorePermission"
            (storeCreationAction)="storeCreationAction($event)"
            [showPermission]="showPermission"
            (companyShowAction)="companyShowAction($event)"
            [xlsxExportDate]="xlsxExportDate"
          >
          </app-company-maintenance-table>
        </div>
      </div>
    </div>
  </div>
  <app-company-maintenance-fields
    *ngIf="companyCreateMode || companyUpdateMode || companyViewMode"
    [headerActionType]="headerActionType"
    [companyViewMode]="companyViewMode"
    [companyUpdateMode]="companyUpdateMode"
    [company]="company"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-company-maintenance-fields>
  <app-store-maintenance-fields
    *ngIf="companyStoreCreationMode"
    [headerActionType]="headerActionType"
    [companyStoreCreationMode]="companyStoreCreationMode"
    [company]="company"
    [store]="store"
    (cancelAction)="cancelAction()"
    (saveAction)="saveAction($event)"
  >
  </app-store-maintenance-fields>
</div>
