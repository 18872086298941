import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { StoreService } from 'src/app/services/store.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';
@Component({
  selector: 'app-store-maintenance',
  templateUrl: './store-maintenance.component.html',
  styleUrls: ['./store-maintenance.component.scss']
})
export class StoreMaintenanceComponent implements OnInit {
  loading = false;
  storeList: Store[];
  store: Store;
  tableHeaders: [] = [];

  headerActionType: string;
  storeCreateMode: boolean = false;
  storeUpdateMode: boolean = false;
  storeDeleteMode: boolean = false;
  storeViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private storeService: StoreService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Mantenimiento"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.storeList = await this.getStores(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getStores(company) {
    this.loading = true;
    return this.storeService.storeGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date;
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear establecimiento';
    this.storeCreateMode = true;
    this.store = {} as Store;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.store.storeCompany = user.userCompany;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver establecimiento';
    this.storeViewMode = true;
    this.store = event as Store;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar establecimiento';
    this.storeUpdateMode = true;
    this.store = event as Store;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar establecimiento';
    this.storeDeleteMode = true;
    const storeDeleted = {
      storeCode: event.storeCode,
      storeDeleted: 1
    };
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(storeDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.storeCreateMode = false;
    this.storeViewMode = false;
    this.storeUpdateMode = false;
    this.storeDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.storeList = await this.getStores(user.userCompany);
  }

  async saveAction(store) {
    if (store != false) {
      let response;
      this.loading = true;
      if (this.storeCreateMode == true) {
        response = await this.storeService.storeInsert(store);
      }
      if (this.storeUpdateMode == true) {
        store['storeModifyDate'] = new Date();
        response = await this.storeService.storeUpdate(store);
      }
      if (this.storeDeleteMode == true) {
        store['storeDeletedDate'] = new Date();
        response = await this.storeService.storeDelete(store);
      }
      if (response.errorMessage !== undefined) {
        this.notificationService.showError(response.errorMessage);
      } else {
        this.notificationService.showSuccess(response.message);
        this.cancelAction(event);
      }
      this.loading = false;
    } else {
      this.notificationService.showError('Completa los campos obligatorios.');
    }
  }
}
