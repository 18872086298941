import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Quotation } from '../models/quotation';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // quotation maintenance
  async quotationGetStore(store, begDate, endDate): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-quotation/store/' + 
        store + 
        '/' +
        begDate +
        '/' +
        endDate +
        '/' +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async quotationGetClient(company, client): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-quotation/client/' + 
        company + 
        '/' +
        client +
        '/' +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async quotationInsert(quotation: Quotation) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-quotation/', quotation, { headers })
      .toPromise();
  }

  async quotationUpdate(quotation) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-quotation/', quotation, { headers })
      .toPromise();
  }

  async quotationDelete(quotation: Quotation) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-quotation/', quotation, { headers })
      .toPromise();
  }

  async quotationSend(quotationCode) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-quotation/send/', quotationCode, { headers })
      .toPromise();
  }

}
