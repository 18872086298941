export const environment = {
  production: false,
  apiUrl: 'https://vleyd4mmk3.execute-api.us-east-1.amazonaws.com/dev/',
  userPoolId: 'us-east-1_M5WeW5a2c',
  clientId: '32ilpnjg1702p2vlntgnfcrd1u',
  tableEntrys: 10,
  clientDefaultAddress: 'Ciudad',
  infilePdfUrl:
    'https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=',
};

// https://meet.google.com/rmb-phhx-jbe