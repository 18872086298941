import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // dashboard maintenance
  async dashboardGet(store, date, option): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard/company/' +
        store +
        '/' +
        date +
        '/' +
        option +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetSummary(store, year, month): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-summary/store/' +
        store +
        '/' +
        year +
        '/' +
        month +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetNotification(store): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-notification/store/' +
        store +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetNotificationDetail(store, type): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-notification-detail/store/' +
        store +
        '/' +
        type +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetGraphicAnnual(store, year, type): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-graphic-annual/' +
        store +
        '/' +
        year +
        '/' +
        type +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetGraphicMonthly(store, year, month, type): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-graphic-monthly/' +
        store +
        '/' +
        year +
        '/' +
        month +
        '/' +
        type +
        queryParam,
        { headers }
      )
      .toPromise();
  }

  async dashboardGetGraphicInventory(store, year, month, type): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
        'imfel-dashboard-graphic-inventory/' +
        store +
        '/' +
        year +
        '/' +
        month +
        '/' +
        type +
        queryParam,
        { headers }
      )
      .toPromise();
  }

}
