import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/account';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermissionService } from 'src/app/services/permission.service';
import swal from "sweetalert2";
import { TitleService } from 'src/app/services/title.service';
@Component({
  selector: 'app-account-maintenance',
  templateUrl: './account-maintenance.component.html',
  styleUrls: ['./account-maintenance.component.scss']
})
export class AccountMaintenanceComponent implements OnInit {
  loading = false;
  accountList: Account[];
  account: Account;
  tableHeaders: [] = [];

  headerActionType: string;
  accountCreateMode: boolean = false;
  accountUpdateMode: boolean = false;
  accountDeleteMode: boolean = false;
  accountViewMode: boolean = false;
  xlsxExportDate;

  createPermission: boolean = false;
  readPermission: boolean = false;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  xlsxExportPermission: boolean = false;

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private router: Router,
    private titleService: TitleService
  ) { this.titleService.emitChange("Poliza Manual"); }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(user.userRole, user.userCode, this.router.url.replace('/', ''));
    await this.permissionReview(permissionList);
    this.accountList = await this.getAccount(user.userCompany);
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'create':
            this.createPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;
          case 'update':
            this.updatePermission = true;
            break;
          case 'delete':
            this.deletePermission = true;
            break;
          case 'view':
            this.viewPermission = true;
            break;
          case 'xlsxExport':
            this.xlsxExportPermission = true;
            break;
          default:
            break;
        }
      }
    }
  }

  async getAccount(company) {
    this.loading = true;
    return this.accountService.accountGetCompany(company).then(
      (response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return response.records;
      }
    ).catch(
      (error) => {
        this.notificationService.showError(error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async xlsxExportAction() {
    this.xlsxExportDate = new Date();
  }

  async refreshAction() {
    this.xlsxExportDate = undefined;
    this.ngOnInit();
  }

  async createAction() {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Crear póliza manual';
    this.accountCreateMode = true;
    this.account = {} as Account;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.account.accountStatus = 1;
    this.account.accountManual = 1;
  }

  async viewAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Ver póliza manual';
    this.accountViewMode = true;
    this.account = event as Account;
  }

  async updateAction(event) {
    this.xlsxExportDate = undefined;
    this.headerActionType = 'Actualizar póliza manual';
    this.accountUpdateMode = true;
    this.account = event as Account;
  }

  async deleteAction(event) {
    this.headerActionType = 'Borrar póliza manual';
    this.accountDeleteMode = true;
    const accountDeleted = {
      accountCode: event.accountCode,
      accountDeleted: 1
    };
    swal.fire({
      title: "¿Estás seguro?",
      text: "El registro será eliminado y no podrá ser recuperado.",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.saveAction(accountDeleted);
      }
    });
  }

  async cancelAction(event) {
    this.accountCreateMode = false;
    this.accountViewMode = false;
    this.accountUpdateMode = false;
    this.accountDeleteMode = false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountList = await this.getAccount(user.userCompany);
  }

  async saveAction(account) {
    let response;
    this.loading = true;
    if (this.accountCreateMode == true) {
      response = await this.accountService.accountInsert(account);
    }
    if (this.accountDeleteMode == true) {
      account['accountDeletedDate'] = new Date();
      response = await this.accountService.accountDelete(account);
    }
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancelAction(event);
    }
    this.loading = false;
  }

}
