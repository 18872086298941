import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receipt-business-cancel',
  templateUrl: './receipt-business-cancel.component.html',
  styleUrls: ['./receipt-business-cancel.component.scss']
})
export class ReceiptBusinessCancelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
