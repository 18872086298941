import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-phrase-maintenance-toolbox',
  templateUrl: './phrase-maintenance-toolbox.component.html',
  styleUrls: ['./phrase-maintenance-toolbox.component.scss']
})
export class PhraseMaintenanceToolboxComponent implements OnInit {
  loading = false;
  classRefresh = 'btn btn-sm btn-default btn-simple';

  @Input() createPermission: boolean;
  @Input() xlsxExportPermission: boolean;

  @Output() createAction = new EventEmitter<any>();
  @Output() xlsxExportAction = new EventEmitter<any>();
  @Output() refreshAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  async createPhrase() {
    this.createAction.emit();
  }
  async xlsxExport() {
    this.xlsxExportAction.emit();
  }

  async refreshPhrase() {
    this.classRefresh = 'btn btn-sm btn-default btn-simple';
    this.refreshAction.emit();
  }

}
