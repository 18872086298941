import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { TitleService } from 'src/app/services/title.service';
import Chart from 'chart.js';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { PermissionService } from 'src/app/services/permission.service';

declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loading = false;

  public canvas: any;

  notificationPermission: boolean = false;
  summaryPermission: boolean = false;
  graphicPermission: boolean = false;
  readPermission: boolean = false;

  store;
  company;
  greetingIni;

  summaryDate;
  salesAnnualYear;
  purchasesAnnualYear;
  salesMonthlyMonth;
  purchasesMonthlyMonth;
  inventoryAnnualDate;
  inventoryMonthlyDate;

  option = 1;
  notificationShow = false;

  user: User = {} as User;

  summary = {
    receipt: {
      receiptAmount: 0,
      receiptQuantity: 0,
      receiptQuantityCancel: 0,
    },
    invoice: {
      invoiceAmount: 0,
      invoiceQuantity: 0,
      invoiceQuantityCancel: 0,
    },
    invoiceExchange: {
      invoiceExchangeAmount: 0,
      invoiceExchangeQuantity: 0,
      invoiceExchangeQuantityCancel: 0,
    },
    receiptBusiness: {
      receiptBusinessAmount: 0,
      receiptBusinessQuantity: 0,
      receiptBusinessQuantityCancel: 0,
    },
    purchase: {
      purchaseAmount: 0,
    },
    tax: {
      taxCollectAmount: 0,
      taxPayAmount: 0,
    },
    sale: {
      saleAmount: 0
    },
  };

  notificationDetail = [
    {
      notification: ""
    }
  ];

  notification = {
    countAccountCollectExpired: 0,
    countAccountCollectNextExpired: 0,
    countAccountPayExpired: 0,
    countAccountPayNextExpired: 0,
    salesRecurrentNextEmit: 0
  }

  salesAnnual = {
    saleMonthly: {
      Ene: 0,
      Feb: 0,
      Mar: 0,
      Abr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Ago: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dic: 0,
    },
  };
  
  purchasesAnnual = {
    purchaseMonthly: {
      Ene: 0,
      Feb: 0,
      Mar: 0,
      Abr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Ago: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dic: 0,
    },
  };    

  salesMonthly: { [key: string]: number } = {};
  purchasesMonthly: { [key: string]: number } = {};  

  inventoryMonthly: { [key: string]: number } = {};
  inventoryAnnual: { [key: string]: number } = {};

  charts: { [key: string]: Chart } = {};

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  constructor(
    private titleService: TitleService,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    public sharedUtils: SharedUtils,
    private router: Router,
    public permissionService: PermissionService
  ) { }

  async ngOnInit() {
    let userForceChagePassword = JSON.parse(localStorage.getItem('userForceChagePassword')) ? JSON.parse(localStorage.getItem('userForceChagePassword')) : 0;
    this.summaryDate = await this.sharedUtils.formatDateMonth();
    if (userForceChagePassword == 1) {
      localStorage.removeItem('userForceChagePassword')
      window.location.reload();
    }
    this.store = JSON.parse(localStorage.getItem('store') || '{}');
    if (this.store.storeCode == undefined) {
      this.loading = true;
      await this.delay(5000);
      this.store = JSON.parse(localStorage.getItem('store') || '{}');
      this.loading = false;
    }

    this.company = JSON.parse(localStorage.getItem('company') || '{}');
    if (this.company.companyCode == undefined) {
      this.loading = true;
      await this.delay(5000);
      this.company = JSON.parse(localStorage.getItem('company') || '{}');
      this.loading = false;
    }

    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const permissionList: any[] = await this.permissionGetRoleUser(
      this.user.userRole,
      this.user.userCode, 
      this.router.url.replace('/', '')
    );
    await this.permissionReview(permissionList);

    this.greetingIni = "¡Hola, ";
    this.user.userName = this.user.userName + "!";

    if (this.summaryPermission) {
      this.option = 1;
      await this.getDataSummary();
      await this.getDataNotification();
    } else if (this.notificationPermission) {
      this.option = 2;
      await this.getDataNotification();
    } else if (this.graphicPermission) {
      this.option = 3;
      await this.getDataGraphic();
    }
    
    let refresh = JSON.parse(localStorage.getItem('refresh')) ? JSON.parse(localStorage.getItem('refresh')) : 0;
    if (refresh == 1) {
      localStorage.removeItem('refresh');
      window.location.reload();
    }
    this.titleService.emitChange('Tablero');
  }

  async permissionGetRoleUser(role, user, route) {
    this.loading = true;
    return await this.permissionService.permissionGetRoleUserRoute(role, user, route)
      .then(
        async (response) => {
          if (response.errorMessage !== undefined) {
            throw new Error(response.errorMessage);
          }
          return await Promise.resolve(response.records);
        }
      ).catch(
        (error) => {
          this.notificationService.showError(error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        });
  }

  async permissionReview(permissionList) {
    if (permissionList.length > 0) {
      for (const permission of permissionList) {
        switch (permission.actionType) {
          case 'notification':
            this.notificationPermission = true;
            break;
          case 'summary':
            this.summaryPermission = true;
            break;
          case 'graphic':
            this.graphicPermission = true;
            break;
          case 'read':
            this.readPermission = true;
            break;       
          default:
            break;
        }
      }
    }
  }
  async getDashboardNotification(store) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetNotification(store)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records[0]);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDashboardSummary(store, year, month) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetSummary(store, year, month)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records[0]);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDashboardNotificationDetail(store, type) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetNotificationDetail(store, type)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records[0]._detail);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDashboardGraphicAnnual(store, year, type) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetGraphicAnnual(store, year, type)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records[0]);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDashboardGraphicMonthly(store, year, month, type) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetGraphicMonthly(store, year, month, type)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async getDashboardGraphicInventory(store, year, month, type) {
    this.loading = true;
    return await this.dashboardService
      .dashboardGetGraphicInventory(store, year, month, type)
      .then(async (response) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        return await Promise.resolve(response.records);
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async selectOption(option: number) {
    this.option = option;
    switch (this.option) {
      case 1:
        await this.getDataSummary();
        break;
      case 2:
        await this.getDataNotification();
        break;
      case 3:
        await this.getDataGraphic(); 
        break;
    }

  }
  
  async getDataSummary($event?: Event) {
    if ($event) {
      $event.preventDefault();
    }

    const [year, month] = this.summaryDate.split('-');

    await this.setDashboardSummary(
      await this.getDashboardSummary(this.store.storeCode, year, month)
    );
  }
  
  async getDataNotification() {
    await this.setDashboardNotification(
      await this.getDashboardNotification(this.store.storeCode)
    );    
  }

  async getDataGraphic() {
    const date =  await this.sharedUtils.formatDateMonth();
    const [year, month] = date.split('-');

    this.salesAnnualYear = year; // #1
    this.purchasesAnnualYear = year; // #2
    this.salesMonthlyMonth = date; // #1
    this.purchasesMonthlyMonth = date; // #2
    this.inventoryAnnualDate = year; // #1
    this.inventoryMonthlyDate = date; // #2
    

    await this.setDashboardGraphicAnnual(
      await this.getDashboardGraphicAnnual(this.store.storeCode, this.salesAnnualYear, 1), 
      1    
    );
    await this.setDashboardGraphicAnnual(
      await this.getDashboardGraphicAnnual(this.store.storeCode, this.purchasesAnnualYear, 2), 
      2  
    );
    
    this.initializeMonthlyData(year, month, 1);
    await this.setDashboardGraphicMonthly(
      await this.getDashboardGraphicMonthly(this.store.storeCode, year, month, 1), 
      1    
    );     

    this.initializeMonthlyData(year, month, 2);
    await this.setDashboardGraphicMonthly(
      await this.getDashboardGraphicMonthly(this.store.storeCode, year, month, 2), 
      2  
    );     

    await this.setDashboardGraphicInventory(
      await this.getDashboardGraphicInventory(this.store.storeCode, year, month, 1), 
      1  
    );     

    await this.setDashboardGraphicInventory(
      await this.getDashboardGraphicInventory(this.store.storeCode, year, month, 2), 
      2 
    );     
  }

  async setDashboardSummary(dashboardData) {
    this.summary = {
      receipt: {
        receiptAmount: parseFloat(dashboardData.saleTotalReceipt) + parseFloat(dashboardData.saleTotalReceiptBusiness),
        receiptQuantity: parseFloat(dashboardData.saleCountReceiptActive) + parseFloat(dashboardData.saleCountReceiptBusinessActive),
        receiptQuantityCancel: parseFloat(dashboardData.saleCountReceiptInactive) + parseFloat(dashboardData.saleCountReceiptBusinessInactive),
      },
      invoice: {
        invoiceAmount: parseFloat(dashboardData.saleTotalInvoice) + parseFloat(dashboardData.saleTotalInvoiceExchange),
        invoiceQuantity: parseFloat(dashboardData.saleCountInvoiceActive) + parseFloat(dashboardData.saleCountInvoiceExchangeActive),
        invoiceQuantityCancel: parseFloat(dashboardData.saleCountInvoiceInactive) + parseFloat(dashboardData.saleCountInvoiceExchangeInactive),
      },
      invoiceExchange: {
        invoiceExchangeAmount: dashboardData.saleTotalInvoiceExchange,
        invoiceExchangeQuantity: dashboardData.saleCountInvoiceExchangeActive,
        invoiceExchangeQuantityCancel:
          dashboardData.saleCountInvoiceExchangeInactive,
      },
      receiptBusiness: {
        receiptBusinessAmount: dashboardData.saleTotalReceiptBusiness,
        receiptBusinessQuantity: dashboardData.saleCountReceiptBusinessActive,
        receiptBusinessQuantityCancel:
          dashboardData.saleCountReceiptBusinessInactive,
      },
      purchase: {
        purchaseAmount: dashboardData.saleTotalPurchase,
      },
      tax: {
        taxCollectAmount: dashboardData.saleTaxPurchase,
        taxPayAmount: dashboardData.saleTaxInvoice,
      },
      sale: {
        saleAmount: dashboardData.saleTotal
      },
    };
  } 

  async setDashboardNotification(dashboardData) {
    this.notification = {
      countAccountCollectExpired: dashboardData._countAccountCollectExpired,
      countAccountCollectNextExpired: dashboardData._countAccountCollectNextExpired,
      countAccountPayExpired: dashboardData._countAccountPayExpired,
      countAccountPayNextExpired: dashboardData._countAccountPayNextExpired,
      salesRecurrentNextEmit: dashboardData._salesRecurrentNextEmit
    }    

    if (
      this.notification.countAccountCollectExpired > 0 ||
      this.notification.countAccountCollectNextExpired > 0 ||
      this.notification.countAccountPayExpired > 0 ||
      this.notification.countAccountPayNextExpired > 0 ||
      this.notification.salesRecurrentNextEmit > 0
    ) {
      this.notificationShow = true;
    }
  }

  async setDashboardGraphicAnnual(dashboardData, option) {
    switch (option) {
      case 1:
        this.salesAnnual = {
          saleMonthly: {
            Ene: dashboardData.totalMonths[0].total,
            Feb: dashboardData.totalMonths[1].total,
            Mar: dashboardData.totalMonths[2].total,
            Abr: dashboardData.totalMonths[3].total,
            May: dashboardData.totalMonths[4].total,
            Jun: dashboardData.totalMonths[5].total,
            Jul: dashboardData.totalMonths[6].total,
            Ago: dashboardData.totalMonths[7].total,
            Sep: dashboardData.totalMonths[8].total,
            Oct: dashboardData.totalMonths[9].total,
            Nov: dashboardData.totalMonths[10].total,
            Dic: dashboardData.totalMonths[11].total,
          },    
        };
        this.chartGenerate(
          'graphicSalesAnnual',
          Object.keys(this.salesAnnual.saleMonthly),
          Object.values(this.salesAnnual.saleMonthly),
          'Ventas tienda',
          '#2fac66'
        );            
        break;
      case 2:
        this.purchasesAnnual = {
          purchaseMonthly: {
            Ene: dashboardData.totalMonths[0].total,
            Feb: dashboardData.totalMonths[1].total,
            Mar: dashboardData.totalMonths[2].total,
            Abr: dashboardData.totalMonths[3].total,
            May: dashboardData.totalMonths[4].total,
            Jun: dashboardData.totalMonths[5].total,
            Jul: dashboardData.totalMonths[6].total,
            Ago: dashboardData.totalMonths[7].total,
            Sep: dashboardData.totalMonths[8].total,
            Oct: dashboardData.totalMonths[9].total,
            Nov: dashboardData.totalMonths[10].total,
            Dic: dashboardData.totalMonths[11].total,
          },    
        };   
        this.chartGenerate(
          'graphicPurchasesAnnual',
          Object.keys(this.purchasesAnnual.purchaseMonthly),
          Object.values(this.purchasesAnnual.purchaseMonthly),
          'Compras tienda',
          '#f1916e'
        );     
        break;
      default:
        break;
    }    
  }

  async setDashboardGraphicMonthly(dashboardData, option) {
    switch (option) {
      case 1:
        dashboardData.forEach(item => {
          const dayKey = item.day.toString();
          this.salesMonthly[dayKey] = item.total;
        });           
        this.chartGenerateBar(
          'graphicSalesMonthly',
          Object.keys(this.salesMonthly),
          Object.values(this.salesMonthly),
          'Ventas diarias',
          '#2fac66'
        );   
        break;
      case 2:
        dashboardData.forEach(item => {
          const dayKey = item.day.toString();
          this.purchasesMonthly[dayKey] = item.total;
        }); 
        this.chartGenerateBar(
          'graphicPurchasesMonthly',
          Object.keys(this.purchasesMonthly),
          Object.values(this.purchasesMonthly),
          'Compras diarias',
          '#f1916e'
        ); 
        break;
      default:
        break;
    }    
  }

  async setDashboardGraphicInventory(dashboardData, option) {
    switch (option) {
      case 1:   
        this.inventoryAnnual = {};     
        dashboardData.forEach(item => {
          const productName = item.name;
          this.inventoryAnnual[productName] = item.total;
        });       
        this.chartGeneratePie(
          'graphicInventoryAnnual',
          Object.keys(this.inventoryAnnual),
          Object.values(this.inventoryAnnual),
          'Productos más vendidos'       
        );   
        break;
      case 2:
        this.inventoryMonthly = {};     
        dashboardData.forEach(item => {
          const productName = item.name;
          this.inventoryMonthly[productName] = item.total;
        }); 
        this.chartGeneratePie(
          'graphicInventoryMonthly',
          Object.keys(this.inventoryMonthly),
          Object.values(this.inventoryMonthly),
          'Productos más vendidos'
        ); 
        break;
      default:
        break;
    }    
  }

  chartGenerate(chartId: string, labels: string[], data: number[], label: string, borderColor: string) {
    this.destroyChart(chartId);
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
  
    this.charts[chartId] = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            borderColor: borderColor,
            pointRadius: 0,
            pointHoverRadius: 0,
            fill: true,
            borderWidth: 3,
            data: data,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#9f9f9f',
                beginAtZero: false,
                maxTicksLimit: 5,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'transparent',
                color: 'rgba(255,255,255,0.05)',
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: 'transparent',
                display: false,
              },
              ticks: {
                padding: 20,
                fontColor: '#9f9f9f',
              },
            },
          ],
        },
      },
    });
  }  

  chartGenerateBar(chartId: string, labels: string[], data: number[], label: string, backgroundColor: string) {
    this.destroyChart(chartId);
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;

    this.charts[chartId] = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            backgroundColor: backgroundColor,
            data: data,      
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },        
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: 'transparent',
                display: false,
              },
              ticks: {
                padding: 20,
                fontColor: '#9f9f9f',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#9f9f9f',
                beginAtZero: true,
                maxTicksLimit: 5,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: backgroundColor,
                zeroLineWidth: 3,
                color: 'rgba(255,255,255,0.05)',
              },
            },
          ],
        },
      },
    });
  }

  chartGeneratePie(chartId: string, labels: string[], data: number[], label: string) {
    const backgroundColors = ['#2fac66', '#f0e68c', '#f1916e'];

    this.destroyChart(chartId);
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
  
    this.charts[chartId] = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            backgroundColor: backgroundColors,   
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          position: 'right',
          display: true,
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const currentLabel = data.labels[tooltipItem.index];
              const currentValue = dataset.data[tooltipItem.index];
              return `${currentLabel}: ${currentValue}`;
            }
          }
        }
      },
    });
  }

  async showDetail(value, count) {
    if (count > 0) {
      this.notificationDetail = await this.getDashboardNotificationDetail(this.store.storeCode, value); 
    }
  }

  async validateDate($event) {
    $event.preventDefault();
  }

  async getDaysInMonth(year, month) {
    const days = new Date(year, month, 0).getDate();
    return days;
  }  

  async initializeMonthlyData(year, month, option) {
    const daysMonth = await this.getDaysInMonth(year, month);
    switch (option) {
      case 1:
        this.salesMonthly = {};
        for (let day = 1; day <= daysMonth; day++) {
          const dayKey = day.toString();
          this.salesMonthly[dayKey] = 0;
        }
        break;
      case 2:
        this.purchasesMonthly = {};
        for (let day = 1; day <= daysMonth; day++) {
          const dayKey = day.toString();
          this.purchasesMonthly[dayKey] = 0;
        }
        break;    
      default:
        break;
    }    
  }

  async getDataGraphicAnnual($event, option) {
    $event.preventDefault();
    switch (option) {
      case 1:
        await this.setDashboardGraphicAnnual(
          await this.getDashboardGraphicAnnual(this.store.storeCode, this.salesAnnualYear, 1), 
          1    
        );        
        break;
      case 2:
        await this.setDashboardGraphicAnnual(
          await this.getDashboardGraphicAnnual(this.store.storeCode, this.purchasesAnnualYear, 2), 
          2  
        );
        break;    
      default:
        break;
    }     
  }

  async getDataGraphicMonthly($event, option) {
    $event.preventDefault();
    let year, month;
    switch (option) {
      case 1:
        [year, month] = this.salesMonthlyMonth.split('-');
        this.initializeMonthlyData(year, month, 1);
        await this.setDashboardGraphicMonthly(
          await this.getDashboardGraphicMonthly(this.store.storeCode, year, month, 1), 
          1    
        );          
        break;
      case 2:
        [year, month] = this.purchasesMonthlyMonth.split('-');
        this.initializeMonthlyData(year, month, 2);
        await this.setDashboardGraphicMonthly(
          await this.getDashboardGraphicMonthly(this.store.storeCode, year, month, 2), 
          2  
        );  
        break;    
      default:
        break;            
    }     
  }

  async getDataGraphicInventory($event, option) {
    $event.preventDefault();
    let year, month;
    switch (option) {
      case 1:
        year = this.inventoryAnnualDate;
        month = 1;
        await this.setDashboardGraphicInventory(
          await this.getDashboardGraphicInventory(this.store.storeCode, year, month, 1), 
          1    
        );          
        break;
      case 2:
        [year, month] = this.inventoryMonthlyDate.split('-');
        await this.setDashboardGraphicInventory(
          await this.getDashboardGraphicInventory(this.store.storeCode, year, month, 2), 
          2  
        );  
        break;    
      default:
        break;            
    }     
  }

  async destroyChart(chartId: string) {
    if (this.charts[chartId]) {
      this.charts[chartId].destroy();
      delete this.charts[chartId];
    }
  }
}
