<app-loading *ngIf="loading"></app-loading>
<div class="dataTables_wrapper" *ngIf="readPermission">


  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="dataTables_length" id="datatable_length">
        <label>
          Mostrar
          <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
            (change)="entriesChange($event)">
            <option value="10" [selected]="entries==10">10</option>
            <option value="25" [selected]="entries==25">25</option>
            <option value="50" [selected]="entries==50">50</option>
            <option value="-1" [selected]="entries==-1">Todos</option>
          </select>
          registros
        </label>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div id="datatable_filter" class="dataTables_filter">
        <label>
          <input type="search" class="form-control form-control-md" placeholder="Buscar" aria-controls="datatable"
            (keyup)="filterTable($event)" />
        </label>
      </div>
    </div>
  </div>



  <ngx-datatable *ngIf="(clientListFilter !== undefined) && (tableHeaders.length > 0)" class="bootstrap selection-cell"
    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
    [limit]="entries != -1 ? entries:undefined" [rows]="clientListFilter" (activate)="onActivate($event)"
    [scrollbarH]="true">
    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
      <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        Acciones
      </ng-template>
      <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn">
        <a href="#" *ngIf="viewPermission" class="btn btn-default btn-icon btn-sm btn-simple like"
          (click)="view($event)" ngbTooltip="Ver" container="body"><i class="fas fa-eye"></i></a>
        <a href="#" *ngIf="updatePermission" class="btn btn-info btn-icon btn-sm btn-simple edit"
          (click)="update($event)" ngbTooltip="Editar" container="body"><i class="fas fa-pencil-alt"></i></a>
        <a href="#" *ngIf="deletePermission" class="btn btn-danger btn-icon btn-sm btn-simple remove"
          (click)="delete($event)" ngbTooltip="Eliminar" container="body"><i class="fas fa-times"></i></a>
        <a href="#" *ngIf="historyPermission" class="btn btn-default btn-icon btn-sm btn-simple remove"
          (click)="history($event)" ngbTooltip="Historial" container="body" style="border: 1px solid #319b6d;"><i class="fas fa-list-ul" style="color: #319b6d;"></i></a>   
        <a href="#" *ngIf="quotationPermission" class="btn btn-dark btn-icon btn-sm btn-simple remove"
          (click)="quotation($event)" ngbTooltip="Cotizar" container="body" style="display: inline-flex; align-items: center; padding: 0; line-height: normal; border: 1px solid #2a886f;">
          <img src="assets/img/quotation.png" alt="" style="width: 20px; height: 20px; margin: auto; display: block;">
        </a>
        <a href="#" *ngIf="salePermission" class="btn btn-dark btn-icon btn-sm btn-simple remove"
          (click)="sale($event)" ngbTooltip="Facturar" container="body" style="display: inline-flex; align-items: center; padding: 0; line-height: normal; border: 1px solid #277671;">
          <img src="assets/img/sale.png" alt="" style="width: 20px; height: 20px; margin: auto; display: block;">
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngFor="let tableHeader of tableHeaders" [name]="tableHeader.name">
      <ng-template ngx-datatable-header-template>
        <span>{{ tableHeader.name }}</span>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" [ngSwitch]="tableHeader.pipe">
        <span *ngSwitchCase="'text'">
          {{ row[tableHeader.prop] }}
        </span>
        <span *ngSwitchCase="'date'">
          {{ row[tableHeader.prop] | date : "dd/MM/yyyy" }}
        </span>
        <span *ngSwitchCase="'currency'">
          {{ row[tableHeader.prop] | number : "1.2-2" }}
        </span>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>