<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Generar cotización</h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel()" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save()" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label>Fecha</label>
                <label style="color: red;">*</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="quotation.quotationCreationDate"
                  (change)="validateDate($event)" />
              </div>
              <div class="col-md-3">
                <label>Tipo de cliente</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <select class="form-control" (change)="typeClients($event.target.value)" [(ngModel)]="ngSelect"
                    [disabled]="clientQuotationList != undefined">
                    <option value="0">Cliente existente</option>
                    <option value="1">Cliente nuevo</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" *ngIf="ngSelect == 0">
                <label>Cliente</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect 
                    [data]="list['clientList']" 
                    *ngIf="list['clientList']"
                    [(ngModel)]="selected['clientList']" 
                    [settings]="dropdownSettingsClient"
                    (onSelect)="onItemSelectClient($event, 'clientList')"
                    [disabled]="clientQuotationList != undefined">
                  </angular2-multiselect>
                  <div *ngIf="alertHeader">
                    <label style="color: red;" 
                      *ngIf="
                        ngSelect == 0 && selected['clientList'] === undefined"
                      >Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="ngSelect == 1">
                <label>Cliente</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa"
                    [(ngModel)]="quotationClient.clientName" placeholder="Cliente"
                  />
                  <div *ngIf="alertHeader">
                    <label style="color: red;" 
                      *ngIf="
                        ngSelect == 1 && (
                          quotationClient.clientName == undefined ||
                          quotationClient.clientName == null ||
                          quotationClient.clientName == ''
                        )"
                      >Campo obligatorio.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Teléfono</label>
                <div class="form-group">
                  <input type="number" class="form-control" [(ngModel)]="quotationClient.clientPhone"
                    placeholder="Teléfono" min="0"
                  />                 
                </div>
              </div>   
              <div class="col-md-3">
                <label>Correo electrónico</label>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="quotationClient.clientEmail"
                    placeholder="Correo electrónico" />
                </div>
              </div>                                  
            </div>
            <hr />
            <div class="row">            
              <div class="col-md-3">
                <label>Bien / Servicio</label>
                <div class="form-group">
                  <select class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailType"
                    (change)="changeDetailType($event.target.value)">
                    <option value="N">Seleccionar...</option>
                    <option value="B" *ngIf="quotationInventory">
                      Bien
                    </option>
                    <option value="SD" *ngIf="quotationInventory">
                      Servicio
                    </option>
                    <option value="BD">
                      Bien descripción
                    </option>
                    <option value="S">
                      Servicio descripción
                    </option>
                  </select>
                </div>
              </div>               
            </div>
            <div class="row">                
              <div class="col-md-5" *ngIf="quotationAddItemTypeMode == 'B' || quotationAddItemTypeMode == 'SD'">
                <label>Productos</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect [data]="inventoryList" *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                  </angular2-multiselect>
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-5" *ngIf="quotationAddItemTypeMode == 'S' || quotationAddItemTypeMode == 'BD'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailDescription"
                    placeholder="Descripción" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-5" *ngIf="quotationAddItemTypeMode == 'N'">
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="text" class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailDescription"
                    placeholder="Descripción" disabled 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailDescription == undefined">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <label>Precio unitario</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailUnitPrice"
                    placeholder="Precio unitario" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailUnitPrice == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailQuantity"
                    placeholder="Cantidad" (blur)="calculate()" min="0" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailQuantity == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>           
              <div class="col-md-2">
                <label>Total</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input type="number" class="form-control pa" [(ngModel)]="quotationDetail.quotationDetailTotal"
                    placeholder="Total" disabled="true" 
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="quotationDetail.quotationDetailTotal == 0">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button 
                    class="btn btn-sm btn-info btn-simple" 
                    (click)="insertItem()"
                    [disabled]="
                      quotationDetail.quotationDetailDescription == undefined ||
                      quotationDetail.quotationDetailUnitPrice == 0 ||
                      quotationDetail.quotationDetailQuantity == 0 ||
                      quotationDetail.quotationDetailTotal == 0
                    "
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="quotation.quotationDetail">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined"
              [rows]="quotation.quotationDetail" (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove" (click)="deleteItem($event)"><i
                      class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Descripción" prop="quotationDetailDescription"></ngx-datatable-column>
              <ngx-datatable-column name="Precio unitario">
                <ng-template ngx-datatable-header-template>
                  <span>Precio unitario</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.quotationDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.quotationDetailQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>      
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  {{ row.quotationDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total: {{ quotation.quotationTotal | number : "1.2-2" }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>            
          </div>
        </div>
        <br><hr/>
        <div class="row">
          <div class="col-md-11">
            <label>Observaciones</label>
            <div class="form-group">
              <input type="text" class="form-control pa" [(ngModel)]="quotationDetailObservation.observation"
                placeholder="Observaciones" />
            </div>
          </div>
          <div class="col-md-1">
            <label>Agregar</label>
            <div class="form-group">
              <button 
                class="btn btn-sm btn-info btn-simple" 
                (click)="insertObservation()"
                [disabled]="
                  quotationDetailObservation.observation == null ||
                  quotationDetailObservation.observation == undefined ||
                  quotationDetailObservation.observation == ''
                "
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="col-12" *ngIf="quotation.quotationDetailObservation != ''">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [columnWidth]="250"
              [headerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined"
              [rows]="quotation.quotationDetailObservation" (activate)="onActivate($event)">
              <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false"
                [resizeable]="true">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a href="#" class="btn btn-danger btn-icon btn-sm btn-simple remove"
                    (click)="deleteObservation($event)"><i class="fas fa-times"></i></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Observaciones" prop="observation"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>