<app-loading *ngIf="loading"></app-loading>

<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
            Comprobante de pago
        </h4>
        <div class="toolbar">
          <button type="button" class="btn btn-sm btn-default btn-simple" (click)="cancel($event)" ngbTooltip="Cancelar" container="body">
            <i class="fa fa-times"></i>
          </button>
          <button type="button" class="btn btn-sm btn-success btn-simple" (click)="save($event)" ngbTooltip="Guardar" container="body">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-2" *ngFor="let saleImage of saleImageList; let i = index">
            <div *ngIf="!saleImage.saleImageDelete">
             
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [scrollbarH]="true" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined"
              [rows]="saleImageList" (activate)="onActivate($event)">
              <ngx-datatable-column name="Acciones">
                <ng-template ngx-datatable-header-template>
                  <span>Acciones</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-row="row"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">                 
                    <span [class]="'btn btn-primary btn-icon btn-sm btn-simple add'">
                      <span class="fileinput-new" (click)="readImage(row.saleImageUrl)" ngbTooltip="Ver" container="body"><i
                          class="fas fa-eye"></i></span>
                    </span>
                    <span [class]="'btn btn-primary btn-icon btn-sm btn-simple add'">
                      <span class="fileinput-new"
                        (click)="downloadImage(row.saleImageUrl, row.saleImageFileName)" ngbTooltip="Descargar" container="body"><i
                          class="fas fa-upload fa-rotate-180"></i></span>
                    </span>
                    <span [class]="'btn btn-danger btn-icon btn-sm btn-simple delete'">
                      <span class="fileinput-new" (click)="deleteImage(row.saleImageCode)" ngbTooltip="Eliminar" container="body"><i
                          class="fas fa-times"></i></span>
                    </span>
                </ng-template>
              </ngx-datatable-column>                     

              <ngx-datatable-column name="Fecha de emision">
                <ng-template ngx-datatable-header-template>
                  <span>PDF</span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  <span>{{row.saleImageFileName}}</span>
                </ng-template>
              </ngx-datatable-column>      
         
            </ngx-datatable>
          </div>
        </div>   
        
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</div>