import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Inventory } from '../models/inventory';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  // inventory maintenance
  async inventoryGetCompany(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl + 'imfel-inventory/company/' + company + queryParam,
        { headers }
      )
      .toPromise();
  }

  async inventoryGetStore(store): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-inventory/store/' + store + queryParam, {
        headers,
      })
      .toPromise();
  }

  async inventoryInsert(inventory: Inventory) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-inventory', inventory, { headers })
      .toPromise();
  }

  async inventoryUpdate(inventory: Inventory) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-inventory', inventory, { headers })
      .toPromise();
  }

  async inventoryDelete(inventory: Inventory) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-inventory/delete', inventory, {
        headers,
      })
      .toPromise();
  }

  async inventoryInsertImage(inventory: any) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-inventory/image', inventory, {
        headers,
      })
      .toPromise();
  }

  async inventoryUpdateImage(inventory: any) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .put(environment.apiUrl + 'imfel-inventory/image', inventory, {
        headers,
      })
      .toPromise();
  }

  async inventoryDrop(inventory) {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-inventory/drop/', inventory, {
        headers,
      })
      .toPromise();
  }

  async inventoryGetCost(company): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-inventory/one/' + company + queryParam, {
        headers,
      })
      .toPromise();
  }

  async inventoryActive(company, type): Promise<any>{
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(environment.apiUrl + 'imfel-inventory/active/' + company + '/' + type + queryParam, {
        headers
      })
      .toPromise();
  }
}
