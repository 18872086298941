import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TableHeaderService {
  constructor(
    private _http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  async tableHeaderGetRoleUserTable(role, user, table): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-table-header/role-user-table/' +
          role +
          '/' +
          user +
          '/' +
          table +
          queryParam,
        { headers }
      )
      .toPromise();
  }
}
