<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Generar producción</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-default btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success btn-simple"
            (click)="save()"
            ngbTooltip="Guardar"
            container="body"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label>Productos</label>
                <angular2-multiselect
                  [data]="inventoryList"
                  *ngIf="inventoryList.length > 0"
                  [(ngModel)]="inventorySelected"
                  [settings]="dropdownSettingsHistoric"
                  (onSelect)="onItemSelect($event)"
                >
                </angular2-multiselect>
              </div>
              <div class="col-md-2">
                <label>Cantidad</label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    [(ngModel)]="productionHistoric.productionHistoricQuantity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</div>
