import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedUtils } from 'src/app/core/utils/sharedUtils';
import { ListArray } from 'src/app/models/listArray';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductionService } from 'src/app/services/production.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-production-generate',
  templateUrl: './production-generate.component.html',
  styleUrls: ['./production-generate.component.scss'],
})
export class ProductionGenerateComponent implements OnInit {
  loading = false;

  @Output() cancelAction = new EventEmitter<any>();

  productionHistoric = {} as any;

  storeList = new Array<ListArray>();
  storeListReceive = new Array<ListArray>();

  dropdownSettingsHistoric = {
    singleSelection: true,
    text: 'Seleccione',
    enableSearchFilter: true,
    classes: '',
    lazyLoading: true,
    maxHeight: 150,
  };

  public entries: number = environment.tableEntrys;
  public activeRow: any;

  inventoryList = new Array();
  inventoryCompleteList = new Array();
  inventorySelected;

  constructor(
    public sharedUtils: SharedUtils,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private productionService: InventoryService,
    private productionHistoricService: ProductionService
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const inventoryList = await this.getProduction(user.userStore);
    await this.addSelectList(
      inventoryList,
      'productionCode',
      'productionInventoryShow',
      'productionInventory'
    );
    await this.setDefault();
  }

  async setDefault() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  async addSelectList(list, id, name, attributeBusinessCode) {
    this.inventoryList = await this.sharedUtils.transformToList(list, id, name);
  }

  async onItemSelect(item: ListArray) {
    this.productionHistoric.productionHistoricProduction = item.id;
  }

  async getProduction(store) {
    this.loading = true;
    return this.productionHistoricService
      .productionGetStore(store)
      .then((response: any) => {
        if (response.errorMessage !== undefined) {
          throw new Error(response.errorMessage);
        }
        this.inventoryCompleteList = response.records;
        return response.records;
      })
      .catch((error) => {
        this.notificationService.showError(error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancel() {
    await this.setDefault();
    this.cancelAction.emit();
  }

  async save() {
    this.loading = true;
    let response;
    response = await this.productionHistoricService.productionHistoricInsert(
      this.productionHistoric
    );
    if (response.errorMessage !== undefined) {
      this.notificationService.showError(response.errorMessage);
    } else {
      this.notificationService.showSuccess(response.message);
      this.cancel();
    }
    this.loading = false;
  }
}
