<app-loading *ngIf="loading"></app-loading>
<div class="row">
  <div class="col-md-12 ml-auto mr-auto">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title" *ngIf="!purchaseViewMode">Ingresar compra</h4>
        <h4 class="card-title" *ngIf="purchaseViewMode">Ver compra</h4>
        <div class="toolbar">
          <button
            type="button"
            class="btn btn-sm btn-danger btn-simple"
            (click)="cancel()"
            ngbTooltip="Cancelar"
            container="body"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success btn-simple"
            (click)="save()"
            ngbTooltip="Guardar"
            container="body"
            *ngIf="!purchaseViewMode"
          >
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <label>Fecha</label>
                <label style="color: red;">*</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  [(ngModel)]="purchase.purchaseDate"
                  [disabled]="purchaseViewMode"
                />
                <div *ngIf="alertHeader">
                  <label style="color: red;" *ngIf="!purchase.purchaseDate">Campo obligatorio.</label>
                </div>
              </div>
              <div class="col-md-4">
                <label>Proveedor</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <angular2-multiselect
                    [data]="providerListSelect"
                    [(ngModel)]="providerSelected"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelectProvider($event)"
                  >
                  </angular2-multiselect>
                  <div *ngIf="alertHeader">
                    <label style="color: red;" *ngIf="!providerSelected">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Número de factura</label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control pa"
                    [(ngModel)]="purchase.purchaseQuotationNumber"
                    placeholder="Número de factura"
                    [disabled]="purchaseViewMode"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-3" *ngIf="!purchaseViewMode">
                
                <span [class]="classImageBtn">
                  <span class="fileinput-new">Cargar archivo</span>
                  <input type="file" (change)="addFile($event)" />
                </span>
              </div>
            </div>
            <hr />
            <div class="row" *ngIf="!purchaseViewMode">
              <div class="col-md-2">
                <label>Bien o servicio</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <select
                    class="form-control pa"
                    [(ngModel)]="purchaseDetail.purchaseDetailType"
                  >
                    <option value="D" selected>Bien</option>
                    <option value="D" selected>Servicio</option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-4"
                *ngIf="purchaseDetail.purchaseDetailType == 'D'"
              >
                <label>Descripción</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control pa"
                    [(ngModel)]="purchaseDetail.purchaseDetailDescription"
                    placeholder="Descripción"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="!purchaseDetail.purchaseDetailDescription">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4"
                *ngIf="purchaseDetail.purchaseDetailType == 'I'"
              >
                <label>Productos</label>
                <div class="form-group">
                  <angular2-multiselect
                    [data]="inventoryList"
                    *ngIf="inventoryList.length > 0"
                    [(ngModel)]="inventorySelected"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelectInventory($event)"
                  >
                  </angular2-multiselect>                 
                </div>
              </div>
              <div class="col-md-3">
                <label>Costo</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control pa"
                    [data]="inventoryList"
                    [(ngModel)]="purchaseDetail.purchaseDetailCost"
                    placeholder="Costo"
                    min="0"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="!purchaseDetail.purchaseDetailCost">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <label>Cantidad</label>
                <label style="color: red;">*</label>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control pa"
                    [(ngModel)]="purchaseDetail.purchaseDetailRequestQuantity"
                    placeholder="Cantidad"
                    min="0"
                  />
                  <div *ngIf="alert">
                    <label style="color: red;" *ngIf="!purchaseDetail.purchaseDetailRequestQuantity">Campo obligatorio.</label>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <label>Agregar</label>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-sm btn-info btn-simple"
                    ngbTooltip="Agregar"
                    container="body"
                    (click)="addItem()"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-md-12">
            <ngx-datatable
              class="bootstrap selection-cell"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [limit]="entries != -1 ? entries : undefined"
              [rows]="purchase.purchaseDetail"
              (activate)="onActivate($event)"
            >
              <ngx-datatable-column
                [sortable]="true"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="true"
              >
                <ng-template
                  ngx-datatable-header-template
                  let-value="value"
                  let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn"
                >
                  Acciones
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn"
                >
                  <a
                    href="#"
                    class="btn btn-danger btn-icon btn-sm btn-simple remove"
                    *ngIf="!purchaseViewMode"
                    (click)="deleteItem($event)"
                    ><i class="fas fa-times"></i
                  ></a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Descripción"
                prop="purchaseDetailDescription"
              ></ngx-datatable-column>
              <ngx-datatable-column name="Costo">
                <ng-template ngx-datatable-header-template>
                  <span>Costo</span>
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-row="row"
                >
                  {{ row.purchaseDetailUnitPrice | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Cantidad">
                <ng-template ngx-datatable-header-template>
                  <span>Cantidad</span>
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-row="row"
                >
                  {{ row.purchaseDetailRequestQuantity | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Total">
                <ng-template ngx-datatable-header-template>
                  <span>Total</span>
                </ng-template>
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-row="row"
                >
                  {{ row.purchaseDetailTotal | number : "1.2-2" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-offset="offset">
                  <div style="
                      padding: 5px 10px;
                      font-weight: bold;
                      position: absolute;
                      right: 0px;
                    ">
                    Total: {{ purchase.purchaseTotal | number : "1.2-2" }}
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
