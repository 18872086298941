import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  public url: string;

  constructor(private _http: HttpClient, private userService: UserService) {
    this.url = environment.apiUrl;
  }

  async routeGetCompany(userCompany, startDate, endDate): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-route/company/' +
          userCompany +
          '/' +
          startDate +
          '/' +
          endDate +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async routeGenerate(userStore, startDate, endDate): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-route/generate/' +
          userStore +
          '/' +
          startDate +
          '/' +
          endDate +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async routeAssigned(userCompany, endDate, storeCode): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const queryParam =
      '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .get(
        environment.apiUrl +
          'imfel-route/assigned/' +
          userCompany +
          '/' +
          endDate +
          '/' +
          storeCode +
          queryParam,
        { headers }
      )
      .toPromise();
  }

  async routeInsert(route): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-route/', route, { headers })
      .toPromise();
  }

  async routeAssign(route): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-route/assign/', route, { headers })
      .toPromise();
  }

  async routeTracking(route): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-route/tracking/', route, { headers })
      .toPromise();
  }

  async routeCancel(route): Promise<any> {
    const openedSession = await this.userService.userGetCurrentSession();
    const headers = new HttpHeaders({
      Authorization: openedSession.getIdToken().getJwtToken(),
    });
    return this._http
      .post(environment.apiUrl + 'imfel-route/cancel/', route, { headers })
      .toPromise();
  }
}
